import React, { useState, useEffect, useCallback } from "react"
// import {i18n} from "@lingui/core"
// import {messages as catalogEn} from "../locales/en/messages.js"
// import {I18nProvider} from "@lingui/react"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"

export type LanguageContextType = {
  availableLanguages: Language[]
  selectedLanguage: string
  selectedLocale: string
  setActiveLanguage(newLanguage: string): void | Promise<void>
  formatLocaleDate(date: Date): string
  formatShortLocaleDate(date: Date): string
  formatDate(date: Date, options: Intl.DateTimeFormatOptions): string
  formatPercents(
    value: string | number,
    type?: "ratio" | "raw",
    options?: Intl.NumberFormatOptions,
  ): string
  formatLocalTime(date: Date): string
  formatMoney(value: number, options?: Intl.NumberFormatOptions): string
  formatCrypto(
    value: number,
    token: string,
    options?: Intl.NumberFormatOptions,
  ): string
}

type Language = {
  id: string
  label: string
}

type LanguageProviderProps = {
  children: React.ReactNode | React.ReactNode[]
  availableLanguages: Language[]
}

const LanguageContext = React.createContext<LanguageContextType | undefined>(
  undefined,
)

const getLanguages = (): string[] => {
  // @ts-ignore
  const { languages, language, userLanguage } = window.navigator

  if (Array.isArray(languages)) {
    // Dedupe array of languages
    return [...new Set(languages.map((l) => l.split("-")[0]))]
  }

  if (language) {
    return [language.split("-")[0]]
  }

  if (userLanguage) {
    return [userLanguage.split("-")[0]]
  }
  // If language not detected use english
  return ["en"]
}

const getLocales = (): string[] => {
  // @ts-ignore
  const { languages, language, userLanguage } = window.navigator
  const localeRegex = new RegExp("[a-z]{2,3}-[A-Z]{2}")

  if (Array.isArray(languages)) {
    return languages.filter((l) => localeRegex.test(l))
  }

  if (language && localeRegex.test(language)) {
    return [language]
  }

  if (userLanguage && localeRegex.test(userLanguage)) {
    return [userLanguage]
  }
  // If language not detected use english
  return ["en-GB"]
}

const LanguageProvider = ({
  children,
  availableLanguages,
}: LanguageProviderProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>("")
  const userLocales = getLocales()

  dayjs.extend(localizedFormat)

  useEffect(() => {
    const userLanguages = getLanguages()

    const matchingLanguages = [...new Set(userLanguages)].filter((x) =>
      new Set(availableLanguages.map((l) => l.id)).has(x),
    )
    const defaultLanguage = matchingLanguages[0] || "en"
    //@ts-ignore
    // i18n.load(defaultLanguage, catalogEn)
    // i18n.activate(defaultLanguage)
    setSelectedLanguage(defaultLanguage)
  }, [availableLanguages])

  const formatLocaleDate = (date: Date) => {
    const result = new Intl.DateTimeFormat(userLocales[0], {
      timeZone: "UTC",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      month: "numeric",
      day: "numeric",
      year: "numeric",
    }).format(date)

    return result
  }

  const formatShortLocaleDate = (date: Date) => {
    const result = new Intl.DateTimeFormat(userLocales[0], {
      timeZone: "UTC",
      hour12: false,
      month: "numeric",
      day: "numeric",
      year: "numeric",
    }).format(date)

    return result
  }

  const formatDate = useCallback(
    (date: Date, options: Intl.DateTimeFormatOptions) => {
      const result = new Intl.DateTimeFormat(userLocales[0], {
        timeZone: "UTC",
        ...options,
      }).format(date)

      return result
    },
    [userLocales],
  )

  const formatPercents = useCallback(
    (
      value: string | number,
      type: "ratio" | "raw" = "raw",
      options?: Intl.NumberFormatOptions,
    ) => {
      const numberValue = typeof value === "number" ? value : parseFloat(value)

      return (
        (type === "ratio" ? numberValue : numberValue / 100) || 0
      ).toLocaleString(userLocales[0], {
        style: "percent",
        maximumFractionDigits: 2,
        ...options,
      })
    },
    [userLocales],
  )

  const formatLocalTime = (date: Date) => {
    const result = new Intl.DateTimeFormat(userLocales[0], {
      timeZone: "UTC",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    }).format(date)

    return result
  }

  const formatMoney = (value: number, options?: Intl.NumberFormatOptions) => {
    const result = new Intl.NumberFormat(userLocales[0], {
      style: "currency",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      ...options,
    }).format(value + 0) // Prevent -0 values

    return result
  }

  const formatCrypto = (
    value: number,
    token: string,
    options?: Intl.NumberFormatOptions,
  ) => {
    const result = new Intl.NumberFormat(userLocales[0], {
      style: "decimal",
      ...options,
    }).format(value + 0) // Prevent -0 values

    return `${result} ${token}`
  }

  const setLanguage = async (newLanguage: string) => {
    if (!availableLanguages.map((l) => l.id).includes(newLanguage)) {
      console.log("This locale is not available")
      return
    }

    // const newCatalog = await import(`../locales/${newLanguage}/messages.js`)
    // i18n.load(newLanguage, newCatalog.messages)
    // i18n.activate(newLanguage)
    setSelectedLanguage(newLanguage)
  }

  return (
    <LanguageContext.Provider
      value={{
        availableLanguages: availableLanguages,
        selectedLanguage: selectedLanguage,
        setActiveLanguage: setLanguage,
        selectedLocale: userLocales[0],
        formatLocaleDate,
        formatShortLocaleDate,
        formatDate,
        formatPercents,
        formatLocalTime,
        formatMoney,
        formatCrypto,
      }}
    >
      {/*<I18nProvider i18n={i18n}> */}
      {children}
      {/* </I18nProvider> */}
    </LanguageContext.Provider>
  )
}

function useLanguageContext() {
  const context = React.useContext(LanguageContext)
  if (context === undefined) {
    throw new Error("useLanguageContext must be used within a LanguageProvider")
  }
  return context
}

export { LanguageProvider, useLanguageContext }
