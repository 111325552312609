import { makeStyles } from "@mui/styles"
import { Theme } from "@mui/material"
import React, { ReactNode } from "react"

import { HStack } from "./Stack"

type FixedBottomPanelProps = {
  children: ReactNode
}

const useStyles = makeStyles<Theme>(({ spacing, palette, breakpoints }) => ({
  root: {
    position: "sticky",
    bottom: 10,
    backgroundColor: palette.colors.background0,
    width: "100%",
    borderRadius: 20,
    zIndex: 2,
    marginTop: spacing(4),

    [breakpoints.down("sm")]: {
      left: 10,
      width: "calc(100% - 20px)",
    },
  },
}))

export const FixedBottomPanel = ({ children }: FixedBottomPanelProps) => {
  const classes = useStyles()

  return (
    <HStack
      className={classes.root}
      justifyContent="center"
      space={1}
      px={3}
      py={2}
    >
      {children}
    </HStack>
  )
}
