import { createStyles, makeStyles } from "@mui/styles"
import { Theme } from "@mui/material"
import React from "react"

import { Typography } from "./Typography"
import { HStack, VStack, VStackProps } from "./Stack"

type DetailsBaseProps = VStackProps & {
  variant?: "filled" | "bordered" | "default"
}

const useStyles = makeStyles<Theme, Pick<DetailsBaseProps, "variant">>(
  ({ palette }: Theme) =>
    createStyles({
      detailsBase: ({ variant }) => {
        if (variant === "filled") {
          return {
            borderRadius: 10,
            backgroundColor: palette.colors.background2,
          }
        }

        if (variant === "bordered") {
          return {
            "& > *": {
              borderBottom: `1px solid ${palette.colors.stroke}`,
              paddingBottom: 10,
            },
          }
        }

        return {}
      },
    }),
)

const DetailsBase: React.FC<DetailsBaseProps> = ({
  children,
  variant = "default",
  ...rest
}) => {
  const classes = useStyles({ variant })

  return (
    <VStack
      className={classes.detailsBase}
      px={variant === "filled" ? 3 : 0}
      py={variant === "filled" ? 2 : 0}
      space={variant === "filled" ? 2 : 1}
      {...rest}
    >
      {children}
    </VStack>
  )
}

type DetailsRowProps = {
  label?: string
  value?: string | number
}

const DetailsRow: React.FC<DetailsRowProps> = ({ label, children, value }) => {
  return (
    <HStack justifyContent="space-between" alignItems="center">
      {label && (
        <Typography size="extra-small" color="textSecondary">
          {label}
        </Typography>
      )}

      {value || value === 0 ? (
        <Typography
          size="extra-small"
          weight="bold"
          title={String(value)}
          maxWidth={200}
          noWrap
        >
          {value}
        </Typography>
      ) : (
        children
      )}
    </HStack>
  )
}

export const Details = Object.assign(DetailsBase, {
  Row: DetailsRow,
})
