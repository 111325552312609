import BigNumber from "bignumber.js"
import dayjs from "dayjs"
import { ethers, providers } from "ethers"

const ADDRESS_ZERO = "0x0000000000000000000000000000000000000000"

// TODO: Need to figure out how to make this network aware
const LIGHT_DOMAIN_NAME = "SWAP_LIGHT_1155"
const LIGHT_DOMAIN_VERSION = "1"

type UnsignedLightOrder = {
  nonce: string
  expiry: string
  signerWallet: string
  signerToken: string
  signerAmount: string
  senderWallet: string
  senderToken: string
  senderTokenId: string
  senderAmount: string
}

type LightSignature = {
  v: string
  r: string
  s: string
}

export type LightOrder = {
  nonce: string
  seriesIndex: string
  expiry: string
  signerWallet: string
  signerToken: string
  signerAmount: string
  senderToken: string
  senderTokenId: string
  senderAmount: string
} & LightSignature

export type SirenLightOrderParams = {
  // The address of the buyer
  signerAddress: string
  // The address of the ERC20 to pay the premium
  signerTokenAddress: string
  // The amount of premium to be paid
  signerAmount: BigNumber
  senderAddress?: string
  // The address of the ERC1155 token contract
  senderTokenAddress: string
  // The token index of the bTokens to be bought
  tokenIndex: string
  // The amount of Options to be bought
  senderAmount: BigNumber
}

const EIP712Light = {
  EIP712Domain: [
    { name: "name", type: "string" },
    { name: "version", type: "string" },
    { name: "chainId", type: "uint256" },
    { name: "verifyingContract", type: "address" },
  ],
  LightOrder: [
    { name: "nonce", type: "uint256" },
    { name: "expiry", type: "uint256" },
    { name: "signerWallet", type: "address" },
    { name: "signerToken", type: "address" },
    { name: "signerAmount", type: "uint256" },
    { name: "senderWallet", type: "address" },
    { name: "senderToken", type: "address" },
    { name: "senderTokenId", type: "uint256" },
    { name: "senderAmount", type: "uint256" },
  ],
}

function createLightOrder({
  signerAddress,
  signerTokenAddress,
  signerAmount,
  senderAddress = ADDRESS_ZERO,
  senderTokenAddress,
  tokenIndex,
  senderAmount,
}: SirenLightOrderParams): UnsignedLightOrder {
  return {
    nonce: String(dayjs().valueOf()),
    expiry: String(dayjs().add(2, "d").unix()),
    signerWallet: signerAddress,
    signerToken: signerTokenAddress,
    signerAmount: signerAmount.decimalPlaces(0).toString(10),
    senderWallet: senderAddress,
    senderToken: senderTokenAddress,
    senderTokenId: String(tokenIndex),
    senderAmount: senderAmount.decimalPlaces(0).toString(10),
  }
}

async function createLightSignature(
  unsignedOrder: UnsignedLightOrder,
  signer: providers.JsonRpcSigner,
  swapContract: string,
  chainId: number,
): Promise<LightSignature> {
  const sig = await signer._signTypedData(
    {
      name: LIGHT_DOMAIN_NAME,
      version: LIGHT_DOMAIN_VERSION,
      chainId,
      verifyingContract: swapContract,
    },
    { LightOrder: EIP712Light.LightOrder },
    unsignedOrder,
  )

  const { r, s, v } = ethers.utils.splitSignature(sig)
  return { r, s, v: String(v) }
}

export async function createSignedOrder(
  orderParams: Omit<SirenLightOrderParams, "signerAddress">,
  signer: providers.JsonRpcSigner,
  chainId: number,
  seriesIndex: string,
  lighswap1155Address: string,
): Promise<LightOrder> {
  const unsignedOrder = createLightOrder({
    ...orderParams,
    signerAddress: await signer.getAddress(),
  })

  const sig = await createLightSignature(
    unsignedOrder,
    signer,
    lighswap1155Address,
    chainId,
  )

  return {
    ...unsignedOrder,
    ...sig,
    seriesIndex,
  }
}
