import React from "react"

interface IExternalLinkSvg {
  className?: string
}

const ExternalLinkSvg = ({ className }: IExternalLinkSvg) => {
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.9165 4.08317C2.76179 4.08317 2.61342 4.14463 2.50402 4.25403C2.39463 4.36342 2.33317 4.51179 2.33317 4.6665V11.0832C2.33317 11.2379 2.39463 11.3863 2.50402 11.4957C2.61342 11.605 2.76179 11.6665 2.9165 11.6665H9.33317C9.48788 11.6665 9.63625 11.605 9.74565 11.4957C9.85505 11.3863 9.9165 11.2379 9.9165 11.0832V7.58317C9.9165 7.261 10.1777 6.99984 10.4998 6.99984C10.822 6.99984 11.0832 7.261 11.0832 7.58317V11.0832C11.0832 11.5473 10.8988 11.9924 10.5706 12.3206C10.2424 12.6488 9.7973 12.8332 9.33317 12.8332H2.9165C2.45237 12.8332 2.00726 12.6488 1.67907 12.3206C1.35088 11.9924 1.1665 11.5473 1.1665 11.0832V4.6665C1.1665 4.20238 1.35088 3.75726 1.67907 3.42907C2.00726 3.10088 2.45238 2.9165 2.9165 2.9165H6.4165C6.73867 2.9165 6.99984 3.17767 6.99984 3.49984C6.99984 3.822 6.73867 4.08317 6.4165 4.08317H2.9165Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.1665 1.74984C8.1665 1.42767 8.42767 1.1665 8.74984 1.1665H12.2498C12.572 1.1665 12.8332 1.42767 12.8332 1.74984V5.24984C12.8332 5.572 12.572 5.83317 12.2498 5.83317C11.9277 5.83317 11.6665 5.572 11.6665 5.24984V2.33317H8.74984C8.42767 2.33317 8.1665 2.072 8.1665 1.74984Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6623 1.33736C12.8901 1.56516 12.8901 1.93451 12.6623 2.16232L6.24565 8.57898C6.01784 8.80679 5.6485 8.80679 5.42069 8.57898C5.19289 8.35118 5.19289 7.98183 5.42069 7.75403L11.8374 1.33736C12.0652 1.10955 12.4345 1.10955 12.6623 1.33736Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ExternalLinkSvg
