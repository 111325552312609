import { Box, BoxProps, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"
import React from "react"

const HEADER_HEIGHT_DESKTOP = 75
const HEADER_HEIGHT_MOBILE = 60

const LEFT_SIDE_WIDTH = 155

export const RIGHT_SIDE_WIDTH = 385

const useStyles = makeStyles<Theme>(({ breakpoints, palette }) => {
  return {
    root: {
      display: "grid",
      gridTemplateRows: `${HEADER_HEIGHT_DESKTOP}px min-content 1fr`,
      gridTemplateColumns: "min-content 1fr min-content",
      height: "100vh",

      [breakpoints.down("md")]: {
        gridTemplateRows: `${HEADER_HEIGHT_DESKTOP}px min-content min-content 1fr min-content`,
        gridTemplateColumns: "1fr",
      },

      [breakpoints.down("sm")]: {
        gridTemplateRows: `${HEADER_HEIGHT_MOBILE}px min-content min-content 1fr min-content`,
      },
    },
    header: {
      gridRow: "1 / 2",
      gridColumn: "1 / 4",

      height: HEADER_HEIGHT_DESKTOP,

      [breakpoints.down("md")]: {
        gridColumn: "1 / 4",
      },

      [breakpoints.down("sm")]: {
        height: HEADER_HEIGHT_MOBILE,
      },
    },
    banner: {
      gridRow: "2 / 3",
      gridColumn: "1 / 3",

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: palette.colors.background1,

      [breakpoints.down("md")]: {
        gridColumn: "1 / 4",
      },
    },
    leftSide: {
      paddingTop: 30,
      paddingLeft: 30,
      gridRow: "3 / 4",
      gridColumn: "1 / 2",

      "& > *": {
        [breakpoints.down("md")]: {
          position: "relative",
        },
      },

      [breakpoints.up("md")]: {
        width: LEFT_SIDE_WIDTH,
      },

      [breakpoints.down("md")]: {
        padding: 20,
        paddingBottom: 0,
        backgroundColor: palette.colors.background3,
        zIndex: 10,
        gridColumn: "1 / 4",
      },
    },
    rightSide: {
      gridRow: "2 / 4",
      gridColumn: "3 / 4",
      width: RIGHT_SIDE_WIDTH,
      height: `calc(100vh - ${HEADER_HEIGHT_DESKTOP}px)`,

      [breakpoints.down("md")]: {
        gridRow: "5 / 6",
        gridColumn: "1 / 4",

        width: "auto",
        height: 0,
      },
    },
    rightSideContent: {
      overflowY: "auto",

      width: RIGHT_SIDE_WIDTH,

      height: `calc(100vh - ${HEADER_HEIGHT_DESKTOP}px)`,
      display: "flex",
      flexDirection: "column",

      borderLeft: `1px solid ${palette.colors.stroke}`,

      [breakpoints.down("md")]: {
        height: 0,
      },
    },
    blur: {
      filter: "blur(10px)",
    },
  }
})

const MainLayoutHeader: React.FC = ({ children }) => {
  const classes = useStyles()

  return <Box className={classes.header}>{children}</Box>
}

const MainLayoutBanner: React.FC = ({ children }) => {
  const classes = useStyles()

  return <Box className={classes.banner}>{children}</Box>
}

const MainLayoutLeftSide: React.FC = ({ children }) => {
  const classes = useStyles()

  return <Box className={classes.leftSide}>{children}</Box>
}

type MainLayoutContentProps = BoxProps

const useContentStyles = makeStyles<Theme>(({ breakpoints }) => ({
  content: {
    gridRow: "3 / 4",
    gridColumn: "2 / 3",
    padding: 30,
    overflowY: "auto",

    display: "flex",
    flexDirection: "column",

    [breakpoints.up("md")]: {
      paddingRight: 30,
    },
    [breakpoints.down("md")]: {
      maxWidth: "100vw",
      gridRow: "4 / 5",
      gridColumn: "1 / 4",
      padding: 20,
    },
    [breakpoints.down("sm")]: {
      padding: 0,
      paddingTop: 30,
    },
  },
}))

const MainLayoutContent: React.FC<MainLayoutContentProps> = ({
  children,
  className,
  ...rest
}) => {
  const classes = useContentStyles()

  return (
    <Box className={clsx(classes.content, className)} {...rest}>
      {children}
    </Box>
  )
}

const MainLayoutRightSide: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.rightSide}>
      <Box className={classes.rightSideContent}>{children}</Box>
    </Box>
  )
}

const MainLayoutBase: React.FC<{ blur?: boolean }> = ({ children, blur }) => {
  const classes = useStyles()

  return (
    <main
      className={clsx(classes.root, {
        [classes.blur]: blur,
      })}
    >
      {children}
    </main>
  )
}

export const MainLayout = Object.assign(MainLayoutBase, {
  Header: MainLayoutHeader,
  Banner: MainLayoutBanner,
  LeftSide: MainLayoutLeftSide,
  Content: MainLayoutContent,
  RightSide: MainLayoutRightSide,
})
