import React from "react"

interface ITransactionSellSvg {
  className?: string
}

const TransactionSellSvg = ({ className }: ITransactionSellSvg) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill="url(#paint0_radial_6227_42597)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63371 8.63371C8.81199 8.45543 9.10105 8.45543 9.27933 8.63371L15.3663 14.7207C15.5446 14.899 15.5446 15.188 15.3663 15.3663C15.188 15.5446 14.899 15.5446 14.7207 15.3663L8.63371 9.27933C8.45543 9.10105 8.45543 8.812 8.63371 8.63371Z"
        fill="#0A1C2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 15.0435C8.5 14.7913 8.70439 14.587 8.95652 14.587L14.587 14.587L14.587 8.95652C14.587 8.70439 14.7913 8.5 15.0435 8.5C15.2956 8.5 15.5 8.70439 15.5 8.95652L15.5 15.0435C15.5 15.2956 15.2956 15.5 15.0435 15.5L8.95652 15.5C8.70439 15.5 8.5 15.2956 8.5 15.0435Z"
        fill="#0A1C2C"
      />
      <defs>
        <radialGradient
          id="paint0_radial_6227_42597"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.6 3.67021) rotate(46.6371) scale(27.9632 25.4247)"
        >
          <stop stopColor="#FFB5B5" />
          <stop offset="0.526042" stopColor="#F15858" />
          <stop offset="1" stopColor="#FF2626" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default TransactionSellSvg
