import React, { useState } from "react"
import {
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
  Theme,
  alpha,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { BigNumber } from "bignumber.js"
import { utils } from "ethers"

import { Series } from "../../../Contexts/SirenMarketsContext"
import OptionRowItem from "./OptionRowItem"
import { Details, HStack, Typography } from "../../common"
import { useLanguageContext } from "../../../Contexts/LanguageContext"

const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  popoverPaper: {
    border: `1px solid ${palette.colors.stroke}`,
    backgroundColor: alpha(palette.colors.background2, 0.7),
    boxShadow: palette.shadows.tooltip,
    borderRadius: 4,
    paddingLeft: spacing(2.5),
    paddingTop: spacing(2),
    paddingRight: spacing(2.5),
    paddingBottom: spacing(2),
    backdropFilter: "blur(10px)",
  },
  currentExchangeRow: {
    padding: 0,
    overflow: "hidden",
    borderRightColor: "transparent",
    borderLeftColor: "transparent",
    "& > td": {
      overflow: "hidden",
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  currentExchange: {
    color: palette.colors.textButton,
    position: "relative",
    backgroundColor: palette.secondary.main,
    borderRadius: 20,
    padding: "0 20px",
    height: 28,
    zIndex: 1,

    "&:before": {
      content: "''",
      backgroundColor: palette.secondary.main,
      display: "block",
      position: "absolute",
      top: "50%",
      left: "50%",
      height: 1,
      zIndex: -1,
      width: "100vw",
      transform: "translate(-50%,-50%)",
    },
  },
}))

const OptionsList: React.FC<{
  allSeries: Series[]
  selectedSeriesId?: string
  selectOption(id: string | number): void
}> = ({ allSeries, selectOption, selectedSeriesId }) => {
  const classes = useStyles()
  const { breakpoints } = useTheme()

  const mobile = useMediaQuery(breakpoints.down("sm"))

  const { formatMoney } = useLanguageContext()

  const getOptionBreakEven = (o: Series) => {
    return o.type === "Call"
      ? utils.commify(
          new BigNumber(o.premium)
            .multipliedBy(o.paymentPerUnderlying)
            .plus(o.strike)
            .decimalPlaces(2)
            .toString(),
        )
      : utils.commify(
          new BigNumber(o.strike)
            .minus(o.premium * o.paymentPerUnderlying)
            .decimalPlaces(2)
            .toString(),
        )
  }

  const currentExchange =
    allSeries[0] && allSeries[0].paymentPerUnderlying
      ? new BigNumber(allSeries[0].paymentPerUnderlying)
          .decimalPlaces(2)
          .toNumber()
      : NaN

  const [greeksPopoverAnchorEl, setGreeksPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null)

  const [greeks, setGreeks] = useState<
    | {
        impliedVolatility?: number
        delta?: number
        gamma?: number
        theta?: number
        vega?: number
        rho?: number
      }
    | undefined
  >()

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    seriesId: string,
  ) => {
    setGreeksPopoverAnchorEl(event.currentTarget)

    const selectedSeries = allSeries.find((s) => s.seriesId === seriesId)
    setGreeks({
      ...selectedSeries?.greeks,
      impliedVolatility: selectedSeries?.impliedVolatility || 0,
    })
  }

  const handleClose = () => {
    setGreeksPopoverAnchorEl(null)
  }

  const greeksPopoverOpen = Boolean(greeksPopoverAnchorEl)

  const renderedSeries = allSeries
    .sort((a, b) =>
      a.strike > b.strike
        ? 1
        : a.strike === b.strike
        ? a.expiration > b.expiration
          ? 1
          : -1
        : -1,
    )
    .filter((item) => item.strike !== currentExchange)

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Strike</TableCell>
              {mobile ? (
                <TableCell align="left">Premium / Break Even</TableCell>
              ) : (
                <>
                  <TableCell align="right">Premium</TableCell>
                  <TableCell align="right">Break Even</TableCell>
                </>
              )}
              <TableCell align={mobile ? "center" : "right"}>Greeks</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {renderedSeries.map((series, index) => {
              const shouldRenderBefore =
                currentExchange <= series.strike && index === 0

              const shouldRenderAfter =
                (currentExchange >= series.strike &&
                  index === renderedSeries.length - 1) ||
                (currentExchange >= series.strike &&
                  currentExchange <= renderedSeries[index + 1].strike)

              const currentExchangeRow = (
                <TableRow className={classes.currentExchangeRow}>
                  <TableCell colSpan={!mobile ? 6 : 4} align="center">
                    <HStack
                      className={classes.currentExchange}
                      space={0.5}
                      inline
                      alignItems="center"
                      my="1px"
                    >
                      <Typography weight="medium">Current Price</Typography>
                      <Typography weight="bold">
                        {formatMoney(currentExchange)}
                      </Typography>
                    </HStack>
                  </TableCell>
                </TableRow>
              )

              return (
                <React.Fragment key={series.strike}>
                  {!isNaN(currentExchange) &&
                    shouldRenderBefore &&
                    currentExchangeRow}
                  <OptionRowItem
                    key={series.seriesId}
                    series={series}
                    selectOption={selectOption}
                    breakeven={getOptionBreakEven(series)}
                    showGreeks={handleClick}
                    isSelected={series.seriesId === selectedSeriesId}
                  />
                  {!isNaN(currentExchange) &&
                    shouldRenderAfter &&
                    currentExchangeRow}
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Popover
        id={greeksPopoverOpen ? "simple-popover" : undefined}
        open={greeksPopoverOpen}
        anchorEl={greeksPopoverAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        PaperProps={{
          className: classes.popoverPaper,
        }}
      >
        <Details minWidth={80}>
          <Details.Row
            label="IV:"
            value={new BigNumber(greeks?.impliedVolatility || 0)
              .decimalPlaces(4)
              .toString()}
          />
          <Details.Row label="Delta:" value={greeks?.delta || 0} />
          <Details.Row label="Gamma:" value={greeks?.gamma || 0} />
          <Details.Row label="Theta:" value={greeks?.theta || 0} />
          <Details.Row label="Vega:" value={greeks?.vega || 0} />
        </Details>
      </Popover>
    </>
  )
}

export default OptionsList
