import React from "react"

interface IChevronsUpSvg {
  className?: string
}

const ChevronsUpSvg = ({ className }: IChevronsUpSvg) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.30026 7.7L6.70026 10.3C6.38359 10.6167 6.31292 10.979 6.48826 11.387C6.66292 11.7957 6.97526 12 7.42526 12H12.5753C13.0253 12 13.3376 11.7957 13.5123 11.387C13.6876 10.979 13.6169 10.6167 13.3003 10.3L10.7003 7.7C10.6003 7.6 10.4919 7.525 10.3753 7.475C10.2586 7.425 10.1336 7.4 10.0003 7.4C9.86692 7.4 9.74192 7.425 9.62526 7.475C9.50859 7.525 9.40026 7.6 9.30026 7.7Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ChevronsUpSvg
