export const CoinInfoLinks: { [key: string]: string } = {
  WBTC: "https://coingecko.com/en/coins/wrapped-bitcoin/",
  USDC: "https://coingecko.com/en/coins/usd-coin/",
  BAT: "https://coingecko.com/en/coins/basic-attention-token/",
  YFI: "https://coingecko.com/en/coins/yearn-finance",
  UNI: "https://coingecko.com/en/coins/uniswap",
  SUSHI: "https://coingecko.com/en/coins/sushi",
  WETH: "https://coingecko.com/en/coins/weth",
  DPI: "https://coingecko.com/en/coins/defipulse-index",
  KNC: "https://coingecko.com/en/coins/kyber-network-crystal",
  WMATIC: "https://coingecko.com/en/coins/wmatic"
}