import React from "react"

interface IArrowDownSvg {
  className?: string
}

const ArrowDownSvg = ({ className }: IArrowDownSvg) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 16.2071C7.68342 16.5976 8.31658 16.5976 8.70711 16.2071L15.7071 9.20711C16.0976 8.81658 16.0976 8.18342 15.7071 7.79289C15.3166 7.40237 14.6834 7.40237 14.2929 7.79289L9 13.0858L9 1.5C9 0.947714 8.55228 0.5 8 0.5C7.44771 0.5 7 0.947715 7 1.5L7 13.0858L1.70711 7.79289C1.31658 7.40237 0.683417 7.40237 0.292893 7.79289C-0.0976314 8.18342 -0.0976313 8.81658 0.292894 9.20711L7.29289 16.2071Z"
      />
    </svg>
  )
}

export default ArrowDownSvg
