import { useWeb3 } from "@chainsafe/web3-context"
import { Button } from "@mui/material"
import React, { FC, PropsWithChildren } from "react"

import { useSirenConfig } from "../../Contexts/SirenConfigContext"

export const CheckNetworkButton: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { isWalletNetworkValid, selectedChainId } = useSirenConfig()

  const { switchNetwork } = useWeb3()

  if (isWalletNetworkValid) {
    return <>{children}</>
  }

  return (
    <Button
      onClick={() => switchNetwork(selectedChainId)}
      variant="primary"
      fullWidth
    >
      Switch Network
    </Button>
  )
}
