import { useApolloClient } from "@apollo/client"
import { useWeb3 } from "@chainsafe/web3-context"
import * as Sentry from "@sentry/react"
import {
  AmmDataProvider__factory,
  MinterAmm__factory,
  PriceOracle__factory,
  SeriesDeployer__factory,
  SimpleToken__factory,
} from "@sirenmarkets/sdk"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { BigNumber } from "bignumber.js"
import dayjs from "dayjs"
import { constants, providers } from "ethers"
import Greeks from "greeks"
import { useCallback } from "react"

import { QueryKey, SLIPPAGE } from "../../constants"
import { seriesQuery } from "../graphQueries"
import {
  GetAllSeries,
  GetAllSeries_expirations,
  GetAllSeries_seriesAmms,
} from "../types/GetAllSeries"
import { REFETCH_TIMEOUT, STRIKE_PRICE_DECIMALS } from "./constants"
import { useSirenMarketsContext } from "./SirenMarketsContext"
import { Series } from "./types"
import { useAddresses } from "./useAddresses"

const invalidSeries: Array<string> = []

const DEFAULT_AMM_ONCHAIN_DATA = {
  premium: 0,
  exchange: 0,
  annualizedVolatilityFactor: 0,
}

const fetchAmmData = async (
  readProvider: providers.Provider | undefined,
  seriesAmm: GetAllSeries_seriesAmms,
  state: string,
  priceOracleAddress: string | undefined,
) => {
  if (
    !seriesAmm ||
    !readProvider ||
    !priceOracleAddress ||
    !seriesAmm.amm?.id ||
    state !== "open"
  ) {
    return DEFAULT_AMM_ONCHAIN_DATA
  }

  const ammContract = MinterAmm__factory.connect(
    seriesAmm.amm?.id,
    readProvider,
  )

  const priceOracleContract = PriceOracle__factory.connect(
    priceOracleAddress,
    readProvider,
  )
  try {
    const price = new BigNumber(
      (
        await ammContract.getPriceForSeries(seriesAmm.series.seriesId)
      ).toString(),
    )
      .shiftedBy(-18)
      .decimalPlaces(4)

    const exchange = new BigNumber(
      (
        await priceOracleContract.getCurrentPrice(
          seriesAmm.series.underlyingToken.id,
          seriesAmm.series.priceToken.id,
        )
      ).toString(),
    ).shiftedBy(-STRIKE_PRICE_DECIMALS)

    const annualizedVolatilityFactor = new BigNumber(
      (await ammContract.getVolatility(seriesAmm.series.seriesId)).toString(),
    ).shiftedBy(-18)

    return {
      premium: price.toNumber(),
      exchange: exchange.toNumber(),
      annualizedVolatilityFactor: annualizedVolatilityFactor.toNumber(),
    }
  } catch (error) {
    return DEFAULT_AMM_ONCHAIN_DATA
  }
}

export const useSeries = () => {
  const { provider, readProvider } = useSirenMarketsContext()

  const { address } = useWeb3()

  const sirenGraphClient = useApolloClient()

  const queryClient = useQueryClient()

  const {
    ammDataProviderAddress,
    priceOracleAddress,
    seriesControllerAddress,
    erc1155ControllerAddress,
    seriesDeployerAddress,
  } = useAddresses()

  const processDeployedSeries = useCallback(
    async (seriesAmm: GetAllSeries_seriesAmms): Promise<Series> => {
      const expiration = dayjs(seriesAmm.series.expirationDate * 1000, {
        utc: true,
      }).toDate()

      const state: "open" | "expired" | "closed" = dayjs(expiration).isAfter(
        dayjs(),
      )
        ? "open"
        : dayjs(expiration).add(180, "day").isAfter(dayjs())
        ? "expired"
        : "closed"

      const pair = `${seriesAmm.series.underlyingToken.symbol} / ${seriesAmm.series.priceToken.symbol}`

      const seriesType = seriesAmm.series.isPutOption ? "Put" : "Call"
      const strike = new BigNumber(seriesAmm.series.strikePrice)
        .shiftedBy(-STRIKE_PRICE_DECIMALS)
        .toNumber()

      const { premium, exchange, annualizedVolatilityFactor } =
        await fetchAmmData(readProvider, seriesAmm, state, priceOracleAddress)

      const openInterest = new BigNumber(seriesAmm.series.bToken.totalSupply)
        .shiftedBy(-seriesAmm.series.bToken.decimals)
        .decimalPlaces(4)
        .toNumber()

      const yearsToExpiration = dayjs(expiration).diff(dayjs(), "year", true)
      const greeksValues = {
        delta: new BigNumber(
          Greeks.getDelta(
            exchange,
            strike,
            yearsToExpiration,
            annualizedVolatilityFactor,
            0,
            seriesType.toLowerCase(),
          ),
        )
          .decimalPlaces(4)
          .toNumber(),
        gamma: new BigNumber(
          Greeks.getGamma(
            exchange,
            strike,
            yearsToExpiration,
            annualizedVolatilityFactor,
            0,
          ),
        )
          .decimalPlaces(5)
          .toNumber(),
        vega: new BigNumber(
          Greeks.getVega(
            exchange,
            strike,
            yearsToExpiration,
            annualizedVolatilityFactor,
            0,
          ),
        )
          .decimalPlaces(4)
          .toNumber(),
        theta: new BigNumber(
          Greeks.getTheta(
            exchange,
            strike,
            yearsToExpiration,
            annualizedVolatilityFactor,
            0,
            seriesType.toLowerCase(),
          ),
        )
          .decimalPlaces(4)
          .toNumber(),
        rho: new BigNumber(
          Greeks.getRho(
            exchange,
            strike,
            yearsToExpiration,
            annualizedVolatilityFactor,
            0,
            seriesType.toLowerCase(),
          ),
        )
          .decimalPlaces(4)
          .toNumber(),
      }

      return {
        seriesId: seriesAmm.series.id,
        seriesIndex: seriesAmm.series.seriesId,
        seriesControllerAddress: seriesControllerAddress || "0x",
        erc1155ControllerAddress: erc1155ControllerAddress || "0x",
        type: seriesType,
        ammAddress: seriesAmm.amm?.id || "0x",
        ammDataProviderAddress: ammDataProviderAddress || "0x",
        seriesStyle: "European",
        bTokenDecimals: seriesAmm.series.bToken.decimals,
        bTokenIndex: seriesAmm.series.bToken.index,
        wTokenDecimals: seriesAmm.series.wToken.decimals,
        wTokenIndex: seriesAmm.series.wToken.index,
        expiration,
        collateralTokenAddress: seriesAmm.series.collateralToken.id,
        collateralTokenSymbol: seriesAmm.series.collateralToken.symbol,
        collateralTokenDecimals: seriesAmm.series.collateralToken.decimals,
        collateralTokenName: seriesAmm.series.collateralToken.name,
        underlyingTokenAddress: seriesAmm.series.underlyingToken.id,
        underlyingTokenSymbol: seriesAmm.series.underlyingToken.symbol,
        underlyingTokenDecimals: seriesAmm.series.underlyingToken.decimals,
        underlyingTokenName: seriesAmm.series.underlyingToken.name,
        priceTokenAddress: seriesAmm.series.priceToken.id,
        priceTokenSymbol: seriesAmm.series.priceToken.symbol,
        priceTokenDecimals: seriesAmm.series.priceToken.decimals,
        priceTokenName: seriesAmm.series.priceToken.name,
        openInterest,
        premium: premium,
        paymentPerUnderlying: exchange,
        pair,
        strike,
        status: state,
        greeks: greeksValues,
        impliedVolatility: annualizedVolatilityFactor,
        priceOracleAddress: priceOracleAddress || "0x",
        seriesDeployed: true,
      }
    },
    [
      ammDataProviderAddress,
      erc1155ControllerAddress,
      priceOracleAddress,
      readProvider,
      seriesControllerAddress,
    ],
  )

  const processNewSeries = useCallback(
    async (allDeployedSeries: Series[], expirations: number[]) => {
      if (!readProvider || !seriesDeployerAddress) return []

      const seriesDeployerContract = SeriesDeployer__factory.connect(
        seriesDeployerAddress,
        readProvider,
      )
      const underlyingTokens = [
        ...new Set(
          allDeployedSeries.map((series) => series.underlyingTokenAddress),
        ),
      ]

      const newSeries = await Promise.all(
        underlyingTokens.map(async (ut) => {
          const [minPercent, maxPercent, incrementRaw] =
            await seriesDeployerContract.allowedStrikeRanges(ut)

          const sampleSeries = allDeployedSeries.find(
            (s) => s.underlyingTokenAddress === ut,
          )

          if (incrementRaw.eq(0) || !priceOracleAddress || !sampleSeries)
            return []

          const currentPrice =
            sampleSeries.paymentPerUnderlying ||
            new BigNumber(
              (
                await PriceOracle__factory.connect(
                  priceOracleAddress,
                  readProvider,
                ).getCurrentPrice(ut, sampleSeries.priceTokenAddress)
              ).toString(),
            )
              .shiftedBy(-STRIKE_PRICE_DECIMALS)
              .toNumber()

          const minStrike = new BigNumber(currentPrice)
            .multipliedBy(new BigNumber(minPercent.toString()).dividedBy(100))
            .decimalPlaces(0)
          const maxStrike = new BigNumber(currentPrice)
            .multipliedBy(new BigNumber(maxPercent.toString()).dividedBy(100))
            .toNumber()

          let newSeriesData: Array<{
            underlyingToken: string
            strike: number
            expiration: number
            type: "Call" | "Put"
          }> = []

          const increment = new BigNumber(incrementRaw.toString())
            .shiftedBy(-STRIKE_PRICE_DECIMALS)
            .toNumber()

          let startingStrikePrice = minStrike.toNumber()

          while (!new BigNumber(startingStrikePrice).modulo(increment).eq(0)) {
            if (increment > 1) {
              startingStrikePrice = startingStrikePrice + 1
            } else {
              startingStrikePrice = startingStrikePrice + increment * 0.1
            }
          }

          expirations.forEach((expiration) => {
            let possibleStrike = startingStrikePrice

            while (possibleStrike < maxStrike) {
              if (
                !allDeployedSeries.find(
                  // eslint-disable-next-line no-loop-func
                  (s) =>
                    s.underlyingTokenAddress === ut &&
                    s.strike === possibleStrike &&
                    s.type === "Call" &&
                    dayjs(s.expiration).unix() === expiration,
                )
              ) {
                newSeriesData.push({
                  underlyingToken: ut,
                  strike: possibleStrike,
                  expiration,
                  type: "Call",
                })
              }
              if (
                !allDeployedSeries.find(
                  // eslint-disable-next-line no-loop-func
                  (s) =>
                    s.underlyingTokenAddress === ut &&
                    s.strike === possibleStrike &&
                    s.type === "Put" &&
                    dayjs(s.expiration).unix() === expiration,
                )
              ) {
                newSeriesData.push({
                  underlyingToken: ut,
                  strike: possibleStrike,
                  expiration,
                  type: "Put",
                })
              }

              possibleStrike = new BigNumber(possibleStrike)
                .plus(increment)
                .toNumber()
            }
          })

          return newSeriesData.map(async (nsd) => {
            const series = allDeployedSeries.find(
              (s) =>
                s.underlyingTokenAddress === nsd.underlyingToken &&
                s.type === nsd.type,
            )

            if (!series) return undefined

            const ammDataProviderContract = AmmDataProvider__factory.connect(
              series.ammDataProviderAddress,
              readProvider,
            )

            const minterAmm = MinterAmm__factory.connect(
              series.ammAddress,
              readProvider,
            )

            try {
              series.impliedVolatility = new BigNumber(
                await (await minterAmm.getBaselineVolatility()).toString(),
              )
                .shiftedBy(-18)
                .toNumber()

              const premium = new BigNumber(
                (
                  await ammDataProviderContract.getPriceForSeries(
                    {
                      expirationDate: nsd.expiration,
                      isPutOption: nsd.type === "Put",
                      strikePrice: new BigNumber(nsd.strike)
                        .shiftedBy(STRIKE_PRICE_DECIMALS)
                        .decimalPlaces(0)
                        .toString(10),
                      tokens: {
                        collateralToken: series.collateralTokenAddress,
                        priceToken: series.priceTokenAddress,
                        underlyingToken: series.underlyingTokenAddress,
                      },
                    },
                    new BigNumber(series.impliedVolatility)
                      .shiftedBy(18)
                      .decimalPlaces(0)
                      .toString(10),
                  )
                ).toString(),
              )
                .shiftedBy(-18)
                .decimalPlaces(4)
                .toNumber()

              const newSeriesIndex = `${dayjs().unix()}${Math.floor(
                Math.random() * 1000,
              )}`

              const yearsToExpiration = dayjs
                .unix(nsd.expiration)
                .diff(dayjs(), "year", true)
              const greeksValues = {
                delta: new BigNumber(
                  Greeks.getDelta(
                    currentPrice,
                    nsd.strike,
                    yearsToExpiration,
                    series.impliedVolatility,
                    0,
                    series.type.toLowerCase(),
                  ),
                )
                  .decimalPlaces(4)
                  .toNumber(),
                gamma: new BigNumber(
                  Greeks.getGamma(
                    currentPrice,
                    nsd.strike,
                    yearsToExpiration,
                    series.impliedVolatility,
                    0,
                  ),
                )
                  .decimalPlaces(5)
                  .toNumber(),
                vega: new BigNumber(
                  Greeks.getVega(
                    currentPrice,
                    nsd.strike,
                    yearsToExpiration,
                    series.impliedVolatility,
                    0,
                  ),
                )
                  .decimalPlaces(4)
                  .toNumber(),
                theta: new BigNumber(
                  Greeks.getTheta(
                    currentPrice,
                    nsd.strike,
                    yearsToExpiration,
                    series.impliedVolatility,
                    0,
                    series.type.toLowerCase(),
                  ),
                )
                  .decimalPlaces(4)
                  .toNumber(),
                rho: new BigNumber(
                  Greeks.getRho(
                    currentPrice,
                    nsd.strike,
                    yearsToExpiration,
                    series.impliedVolatility,
                    0,
                    series.type.toLowerCase(),
                  ),
                )
                  .decimalPlaces(4)
                  .toNumber(),
              }

              return {
                ...series,
                seriesId: `${series.seriesControllerAddress}-${newSeriesIndex}`,
                seriesIndex: newSeriesIndex,
                expiration: dayjs.unix(nsd.expiration).toDate(),
                openInterest: 0,
                premium,
                strike: nsd.strike,
                status: "open",
                seriesDeployed: false,
                paymentPerUnderlying:
                  series.paymentPerUnderlying || currentPrice,
                greeks: greeksValues,
              }
            } catch (error) {
              console.error("error fetching premium for new series")
              console.error(error)
            }
          })
        }),
      )

      return (await Promise.all(newSeries)).flat()
    },
    [seriesDeployerAddress, priceOracleAddress, readProvider],
  )

  const processAllSeries = useCallback(
    async (
      allDeployedSeriesData: GetAllSeries_seriesAmms[],
      allExpirations: GetAllSeries_expirations[],
    ): Promise<Series[]> => {
      const allDeployedSeries = await Promise.all(
        allDeployedSeriesData
          .filter((s) => !invalidSeries.includes(s.series.id) && s.amm)
          .map((series) => processDeployedSeries(series)),
      )

      const allNewSeries = (
        await Promise.all(
          await processNewSeries(
            allDeployedSeries,
            allExpirations
              .map((exp) => Number(exp.id))
              .filter((exp) => exp > dayjs().unix()),
          ),
        )
      ).filter((ns): ns is Series => !!ns)

      return Promise.resolve([...allDeployedSeries, ...allNewSeries])
    },
    [processDeployedSeries, processNewSeries],
  )

  const { data: seriesData } = useQuery([QueryKey.SeriesData], () =>
    sirenGraphClient
      .query<GetAllSeries>({
        query: seriesQuery,
        fetchPolicy: "network-only",
      })
      .then((data) => ({ ...data.data, cacheKey: Math.random() })),
  )

  const seriesOnchainQueryEnabled = Boolean(
    readProvider &&
      ammDataProviderAddress &&
      priceOracleAddress &&
      seriesControllerAddress &&
      erc1155ControllerAddress &&
      seriesData,
  )

  const { data: series = [], isFetched: seriesFetched } = useQuery(
    [QueryKey.Series, seriesData?.cacheKey],
    () =>
      processAllSeries(
        seriesData!.seriesAmms.filter((s) => s.amm !== null), // TODO: Remove this filter when the contracts are fixed
        seriesData!.expirations,
      ),
    {
      enabled: seriesOnchainQueryEnabled,
    },
  )

  const quoteBuyOption = useCallback(
    async (
      quantity: BigNumber,
      seriesId: string,
    ): Promise<BigNumber | void> => {
      if (!provider) return

      const selectedSeries = series.find((m) => m.seriesId === seriesId)

      if (!selectedSeries) return

      const signer = provider.getSigner()
      const ammDataProviderContract = AmmDataProvider__factory.connect(
        selectedSeries.ammDataProviderAddress,
        signer,
      )
      if (selectedSeries.seriesDeployed) {
        try {
          const result =
            await ammDataProviderContract.bTokenGetCollateralInView(
              selectedSeries.ammAddress,
              selectedSeries.seriesIndex,
              new BigNumber(quantity)
                .shiftedBy(selectedSeries.bTokenDecimals)
                .decimalPlaces(0)
                .toString(10),
            )

          const collateralRequired = new BigNumber(result.toString()).shiftedBy(
            -selectedSeries.collateralTokenDecimals,
          )

          return collateralRequired
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          const result =
            await ammDataProviderContract.bTokenGetCollateralInForNewSeries(
              {
                expirationDate: dayjs(selectedSeries.expiration).unix(),
                isPutOption: selectedSeries.type === "Put",
                strikePrice: new BigNumber(selectedSeries.strike)
                  .shiftedBy(STRIKE_PRICE_DECIMALS)
                  .toString(10),
                tokens: {
                  underlyingToken: selectedSeries.underlyingTokenAddress,
                  priceToken: selectedSeries.priceTokenAddress,
                  collateralToken: selectedSeries.collateralTokenAddress,
                },
              },
              selectedSeries.ammAddress,
              new BigNumber(quantity)
                .shiftedBy(selectedSeries.bTokenDecimals)
                .decimalPlaces(0)
                .toString(10),
            )

          const collateralRequired = new BigNumber(result.toString()).shiftedBy(
            -selectedSeries.collateralTokenDecimals,
          )

          return collateralRequired
        } catch (error) {
          console.log(error)
        }
      }
    },
    [provider, series],
  )

  const quoteSellOption = useCallback(
    async (
      quantity: BigNumber,
      seriesId: string,
    ): Promise<BigNumber | void> => {
      if (!provider) throw new Error()
      const selectedSeries = series.find((m) => m.seriesId === seriesId)
      if (!selectedSeries) return
      const signer = provider.getSigner()
      const ammDataProviderContract = AmmDataProvider__factory.connect(
        selectedSeries.ammDataProviderAddress,
        signer,
      )

      try {
        const result = await ammDataProviderContract.bTokenGetCollateralOutView(
          selectedSeries.ammAddress,
          selectedSeries.seriesIndex,
          quantity
            .shiftedBy(selectedSeries.bTokenDecimals)
            .decimalPlaces(0)
            .toString(10),
        )

        const collateralToReceive = new BigNumber(result.toString()).shiftedBy(
          -selectedSeries.collateralTokenDecimals,
        )

        return collateralToReceive
      } catch (error) {
        console.log(error)
      }
    },
    [series, provider],
  )

  const buyOption = useCallback(
    async (
      seriesId: string,
      quantity: BigNumber,
      premiumEstimate: BigNumber,
      paymentTokenAddress: string,
    ) => {
      if (!provider || !address) return

      const selectedSeries = series.find((m) => m.seriesId === seriesId)

      if (!selectedSeries) return

      const signer = provider.getSigner()

      try {
        if (paymentTokenAddress === selectedSeries.collateralTokenAddress) {
          const collateralAmount = new BigNumber(premiumEstimate)
            .multipliedBy(1 + SLIPPAGE)
            .shiftedBy(selectedSeries.collateralTokenDecimals)
            .decimalPlaces(0)

          const collateralTokenContract = SimpleToken__factory.connect(
            selectedSeries.collateralTokenAddress,
            signer,
          )

          const quantityScaled = quantity.shiftedBy(
            selectedSeries.bTokenDecimals,
          )

          if (selectedSeries.seriesDeployed) {
            const currentAllowance = new BigNumber(
              (
                await collateralTokenContract.allowance(
                  address,
                  selectedSeries.ammAddress,
                )
              ).toString(),
            )

            if (collateralAmount.gt(currentAllowance)) {
              await (
                await collateralTokenContract.approve(
                  selectedSeries.ammAddress,
                  constants.MaxUint256,
                )
              ).wait()
            }
            const ammContract = MinterAmm__factory.connect(
              selectedSeries.ammAddress,
              signer,
            )
            const estimatedGas = await ammContract.estimateGas.bTokenBuy(
              selectedSeries.seriesIndex,
              quantityScaled.toString(10),
              collateralAmount.toString(10),
            )

            await (
              await ammContract.bTokenBuy(
                selectedSeries.seriesIndex,
                quantityScaled.toString(10),
                collateralAmount.toString(10),
                {
                  gasLimit: estimatedGas.mul(12).div(10),
                },
              )
            ).wait(1)
          } else {
            if (!seriesDeployerAddress) return
            const currentAllowance = new BigNumber(
              (
                await collateralTokenContract.allowance(
                  address,
                  seriesDeployerAddress,
                )
              ).toString(),
            )

            if (collateralAmount.gt(currentAllowance)) {
              await (
                await collateralTokenContract.approve(
                  seriesDeployerAddress,
                  constants.MaxUint256,
                )
              ).wait()
            }
            const seriesDeployerContract = SeriesDeployer__factory.connect(
              seriesDeployerAddress,
              signer,
            )

            const estimatedGas =
              await seriesDeployerContract.estimateGas.autoCreateSeriesAndBuy(
                selectedSeries.ammAddress,
                new BigNumber(selectedSeries.strike)
                  .shiftedBy(STRIKE_PRICE_DECIMALS)
                  .decimalPlaces(0)
                  .toString(10),
                dayjs(selectedSeries.expiration).unix(),
                selectedSeries.type === "Put",
                quantityScaled.toString(10),
                collateralAmount.toString(10),
              )

            await (
              await seriesDeployerContract.autoCreateSeriesAndBuy(
                selectedSeries.ammAddress,
                new BigNumber(selectedSeries.strike)
                  .shiftedBy(STRIKE_PRICE_DECIMALS)
                  .decimalPlaces(0)
                  .toString(10),
                dayjs(selectedSeries.expiration).unix(),
                selectedSeries.type === "Put",
                quantityScaled.toString(10),
                collateralAmount.toString(10),
                {
                  gasLimit: estimatedGas.mul(12).div(10),
                },
              )
            ).wait(1)
          }
        }

        setTimeout(() => {
          queryClient.invalidateQueries([QueryKey.PositionsData])
        }, REFETCH_TIMEOUT)
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
        throw error
      }
    },
    [series, provider, address, seriesDeployerAddress, queryClient],
  )

  return {
    series,
    seriesFetched,

    quoteBuyOption,
    quoteSellOption,
    buyOption,
  }
}
