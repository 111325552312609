import { useLocalStorage } from "@chainsafe/browser-storage-hooks"
import { useWeb3 } from "@chainsafe/web3-context"
import { useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React, { ReactNode, useCallback, useEffect, useState } from "react"

import { usePermissions } from "../Contexts/SirenMarketsContext"
import AppDrawer from "./AppDrawer"
import AppHeader from "./AppHeader"
import { MainLayout } from "./common"
import MaintenanceDialog from "./MaintenanceDialog"
import OnboardingModal from "./OnboardingModal"
import { RestrictionBanner } from "./RestrictionBanner"

const storageKey = "si.onboarding"

export const AppWrapper: React.FC<{ children: ReactNode | ReactNode[] }> = ({
  children,
}) => {
  const { address, resetOnboard } = useWeb3()

  const { canUseLocalStorage, localStorageGet, localStorageSet } =
    useLocalStorage()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [onboardingOpen, setOnboardingOpen] = useState(false)

  const { canTradeOptions, permissionsFetched } = usePermissions()

  const handleDrawerOpen = useCallback(() => {
    setDrawerOpen(true)
  }, [setDrawerOpen])

  const handleDrawerClose = useCallback(() => {
    setDrawerOpen(false)
  }, [setDrawerOpen])

  const handleDisconnect = useCallback(() => {
    resetOnboard()
  }, [resetOnboard])

  useEffect(() => {
    if (canUseLocalStorage && address) {
      const cache = localStorageGet(storageKey)

      if (!!!cache) {
        setOnboardingOpen(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canUseLocalStorage, address])

  const closeOnboarding = useCallback(() => {
    localStorageSet(storageKey, "rusks")
    setOnboardingOpen(false)
  }, [localStorageSet])

  const { breakpoints } = useTheme()
  const desktop = useMediaQuery(breakpoints.up("md"))

  useEffect(() => {
    if (drawerOpen && desktop) {
      handleDrawerClose()
    }
  }, [desktop, drawerOpen, handleDrawerClose])

  const maintenanceMode =
    process.env.REACT_APP_MAINTENANCE_MODE === "true" ? true : false

  return (
    <>
      <MainLayout blur={drawerOpen || onboardingOpen}>
        <MainLayout.Header>
          <AppHeader handleDrawerOpen={handleDrawerOpen} />
        </MainLayout.Header>

        {!canTradeOptions && permissionsFetched && (
          <MainLayout.Banner>
            <RestrictionBanner />
          </MainLayout.Banner>
        )}

        {!maintenanceMode && children}
      </MainLayout>

      {maintenanceMode && <MaintenanceDialog />}

      <OnboardingModal open={onboardingOpen} handleClose={closeOnboarding} />

      <AppDrawer
        address={address}
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        handleDisconnect={handleDisconnect}
      />
    </>
  )
}
