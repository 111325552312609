import React, { useState } from "react"
import {
  Button,
  Dialog,
  IconButton,
  useMediaQuery,
  useTheme,
  Theme,
  SvgIcon,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import ArrowRightSvg from "./icons/ArrowRightSvg"
import { useSwipeable } from "react-swipeable"
import { TabContext, TabPanel } from "@mui/lab"
import clsx from "clsx"
import { Close } from "@mui/icons-material"
import { HStack, Stack, Typography, VStack } from "./common"

const useStyles = makeStyles<Theme>(({ breakpoints, spacing, palette }) => ({
  inner: {
    margin: spacing(3),
  },
  close: {
    [breakpoints.down("sm")]: {
      display: "none",
    },
    [breakpoints.up("md")]: {
      position: "absolute",
      top: spacing(2),
      right: spacing(2),
    },
  },
  heading: {
    marginTop: spacing(3),
    marginBottom: spacing(6),
  },
  tabs: {
    [breakpoints.down("sm")]: {
      cursor: "pointer",
    },
    "& img": {
      maxWidth: "300px",
      width: "100%",
      marginBottom: spacing(4),
    },
  },
  slides: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "& > *": {
      [breakpoints.up("md")]: {
        width: `calc(50% - 80px)`,
        margin: "0 40px",
        maxWidth: 300,
      },
    },
  },
  panel: {
    [breakpoints.down("sm")]: {
      borderRadius: 20,
      overflow: "hidden",
      border: `1px solid ${palette.colors.stroke}`,
      textAlign: "center",
      backgroundColor: palette.colors.background0,
      "& p": {
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
    },
  },
  learnMore: {
    color: palette.colors.green,
    textDecoration: "none",

    "& svg": {
      height: 20,
      width: 20,
    },

    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bodyText: {
    marginTop: spacing(1.5),
    marginBottom: spacing(2),
  },
  dot: {
    display: "block",
    borderRadius: "100%",
    opacity: 0.4,
    backgroundColor: palette.colors.textPrimary,
    height: spacing(1),
    width: spacing(1),
    cursor: "pointer",
    margin: `0 ${spacing(0.5)}`,
    "&.active": {
      opacity: 1,
    },
  },
  gotItButton: {
    minWidth: 230,
  },
  image: {
    marginBottom: spacing(4),
  },
}))

interface OnboardingModalProps {
  open: boolean
  handleClose: () => void
}

const OnboardingModal: React.FC<OnboardingModalProps> = ({
  open,
  handleClose,
}) => {
  const classes = useStyles()
  const [value, setValue] = useState<string>("0")

  const { breakpoints } = useTheme()
  const desktop = useMediaQuery(breakpoints.up("md"))
  const mobile = useMediaQuery(breakpoints.down("sm"))

  const handlers = useSwipeable({
    onSwipedLeft: () => setValue("1"),
    onSwipedRight: () => setValue("0"),
  })

  const Traders = () => (
    <div className={classes.panel}>
      <img className={classes.image} src="/traders.png" alt="traders panel" />
      <Typography variant="h2">For Traders</Typography>
      <Typography
        className={classes.bodyText}
        color="textSecondary"
        size="extra-small"
      >
        Hedge, apply strategies to potentially limit downside risk and utilize
        other complex techniques or combinations to actively trade regardless of
        market directions
      </Typography>
      <a
        className={classes.learnMore}
        href="https://docs.siren.xyz/faq/what-are-options"
        rel="noopener noreferrer"
        target="_blank"
      >
        <HStack alignItems="center" space={1}>
          <span>Learn more</span>
          <SvgIcon component={ArrowRightSvg} />
        </HStack>
      </a>
    </div>
  )

  const Providers = () => (
    <div className={classes.panel}>
      <img
        className={classes.image}
        src="/lp-providers.png"
        alt="lp-providers panel"
      />
      <Typography variant="h2">For Liquidity Providers</Typography>
      <Typography
        className={classes.bodyText}
        color="textSecondary"
        size="extra-small"
      >
        Provide your funds to be involved in options market formation, make
        money on collecting fees, become a passive option writer in any of the
        ERC-20 tokens we offer.
      </Typography>
      <a
        className={classes.learnMore}
        href="https://docs.siren.xyz/faq/what-is-a-liquidity-provider"
        rel="noopener noreferrer"
        target="_blank"
      >
        <HStack alignItems="center" space={1}>
          <span>Learn more</span>
          <SvgIcon component={ArrowRightSvg} />
        </HStack>
      </a>
    </div>
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={!desktop}
      maxWidth={desktop && "lg"}
    >
      <VStack
        {...handlers}
        alignItems="center"
        className={classes.inner}
        space={mobile ? 3 : 6}
      >
        <Typography
          className={classes.heading}
          component="p"
          variant="h1"
          align="center"
        >
          First time on Siren?
        </Typography>
        {desktop ? (
          <>
            <IconButton
              className={classes.close}
              onClick={handleClose}
              size="large"
            >
              <Close fontSize="large" />
            </IconButton>

            <section className={classes.slides}>
              <Traders />
              <Providers />
            </section>

            <Button
              onClick={handleClose}
              variant="primary"
              className={classes.gotItButton}
            >
              Ok, got it
            </Button>
          </>
        ) : (
          <>
            <TabContext value={`${value}`}>
              <TabPanel className={classes.tabs} value="0">
                <Traders />
              </TabPanel>
              <TabPanel className={classes.tabs} value="1">
                <Providers />
              </TabPanel>
            </TabContext>

            <HStack space={0} alignItems="center" justifyContent="center">
              <span
                onClick={() => setValue("0")}
                className={clsx(classes.dot, {
                  active: value === "0",
                })}
              ></span>
              <span
                onClick={() => setValue("1")}
                className={clsx(classes.dot, {
                  active: value === "1",
                })}
              ></span>
            </HStack>

            <Stack
              direction={mobile ? "vertical" : "horizontal"}
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Button onClick={handleClose} variant="primary" fullWidth>
                Ok, got it
              </Button>
              <Button
                variant="secondary"
                onClick={() =>
                  window.open(
                    "https://docs.siren.xyz/faq/what-are-options",
                    "_blank",
                  )
                }
                fullWidth
              >
                Learn more
              </Button>
            </Stack>
          </>
        )}
      </VStack>
    </Dialog>
  )
}

export default OnboardingModal
