import { makeStyles } from "@mui/styles"
import { SvgIcon, Theme } from "@mui/material"
import React from "react"

import { HStack } from "./Stack"
import EqualsSvg from "../icons/EqualsSvg"

const useStyles = makeStyles<Theme>(({ palette }) => ({
  icon: {
    fill: palette.colors.background2,
  },
}))

export const EqualsSign: React.VFC = () => {
  const classes = useStyles()

  return (
    <HStack justifyContent="center">
      <SvgIcon component={EqualsSvg} className={classes.icon} />
    </HStack>
  )
}
