import { gql } from "apollo-boost"

const accountBalanceQuery = gql`
  query GetAccountBalance($id: String!) {
    erc1155AccountBalances(where: { account: $id }) {
      amount
      token {
        id
        index
        decimals
        type
        series {
          id
          seriesId
        }
      }
    }
    positions(where: { account: $id }) {
      id
      costBasis
      seriesId
    }
  }
`

const seriesQuery = gql`
  query GetAllSeries {
    seriesAmms(first: 1000) {
      series {
        id
        seriesId
        createdBlock
        createdTimestamp
        createdTransaction
        collateralToken {
          id
          decimals
          name
          symbol
        }
        priceToken {
          id
          decimals
          name
          symbol
        }
        underlyingToken {
          id
          decimals
          name
          symbol
        }
        seriesName
        isPutOption
        expirationDate
        strikePrice
        exerciseFeeBasisPoints
        closeFeeBasisPoints
        claimFeeBasisPoints
        wToken {
          id
          index
          totalSupply
          decimals
        }
        bToken {
          id
          index
          totalSupply
          decimals
        }
      }
      amm {
        id
      }
    }
    expirations {
      id
    }
  }
`

const liquidityPoolsQuery = gql`
  query GetLiquidityPools(
    $last30Days: BigInt!
    $upgradeTimestamp: BigInt!
    $recentEventsTimestamp: BigInt!
  ) {
    amms {
      id
      createdTimestamp
      collateralToken {
        id
        decimals
        symbol
        name
      }
      priceToken {
        id
        decimals
        symbol
        name
      }
      underlyingToken {
        id
        decimals
        symbol
        name
      }
      lpToken {
        id
        decimals
        totalSupply
      }
      valueThirtyDaysAgo: poolValueSnapshots(
        where: { createdTimestamp_lt: $last30Days, poolValue_not: 0 }
        orderBy: createdTimestamp
        orderDirection: desc
        first: 1
      ) {
        poolValue
        lpTokenSupply
      }
      valueAfterUpgrade: poolValueSnapshots(
        where: { createdTimestamp_lt: $upgradeTimestamp, poolValue_not: 0 }
        orderBy: createdTimestamp
        orderDirection: desc
        first: 1
      ) {
        poolValue
        lpTokenSupply
      }
      currentValue: poolValueSnapshots(
        orderBy: createdTimestamp
        orderDirection: desc
        first: 1
      ) {
        poolValue
        lpTokenSupply
      }
      series(first: 1000) {
        series {
          id
          expirationDate
          strikePrice
          wToken {
            index
            decimals
          }
          bToken {
            index
            decimals
          }
        }
      }
      recentTokenEvents: ammTokenEvents(
        where: { timestamp_gt: $recentEventsTimestamp }
      ) {
        eventType
        tokenAmount
        timestamp
        collateralAmount
        ... on BTokenBought {
          series {
            id
            seriesName
            strikePrice
            expirationDate
          }
        }
      }
    }
  }
`

const accountPoolActivity = gql`
  query GetPoolActivityForAccount($accountId: String!, $ammId: String!) {
    ammTokenEvents(where: { account: $accountId, amm: $ammId }) {
      eventType
      collateralAmount
      tokenAmount
      timestamp
      account {
        id
      }
      amm {
        id
      }
    }
  }
`

const ammTokenEvents = gql`
  query AmmTokenEvents($where: AmmTokenEvent_filter, $skip: Int) {
    ammTokenEvents(
      where: $where
      skip: $skip
      orderBy: timestamp
      orderDirection: desc
    ) {
      eventType
      collateralAmount
      tokenAmount
      timestamp
      transaction
      account {
        id
      }
      amm {
        id
      }
      ... on BTokenBought {
        series {
          id
          seriesName
          strikePrice
        }
      }
      ... on BTokenSold {
        series {
          id
          seriesName
          strikePrice
        }
      }
      ... on WTokenSold {
        series {
          id
          seriesName
          strikePrice
        }
      }
    }
  }
`

const priceOracleQuery = gql`
  query GetPriceOracle {
    oracleSettings(first: 1) {
      priceOracleAddress
    }
  }
`

const lockedExpirationPoolsQuery = gql`
  query LockedExpirationPools($id: ID!) {
    account(id: $id) {
      lockedExpirationPools {
        id
        lockedWTokens
        expirationDate
        availableCollateral
        amm {
          id
          collateralToken {
            id
            symbol
            decimals
          }
          underlyingToken {
            id
            symbol
            decimals
          }
        }
      }
    }
  }
`

const poolValueSnapshotsQuery = gql`
  query PoolValueSnapshots($where: PoolValueSnapshot_filter) {
    poolValueSnapshots(where: $where, first: 1000) {
      amm {
        id
      }
      lpTokenSupply
      poolValue
      createdTimestamp
    }
  }
`

export {
  seriesQuery,
  accountBalanceQuery,
  liquidityPoolsQuery,
  accountPoolActivity,
  ammTokenEvents,
  priceOracleQuery,
  lockedExpirationPoolsQuery,
  poolValueSnapshotsQuery,
}
