import { useWeb3 } from "@chainsafe/web3-context"
import React from "react"

import { Series } from "../../../Contexts/SirenMarketsContext"
import { Side, SkeletonGrid, Typography } from "../../common"
import { GetStartedSideContent } from "../../GetStartedSideContent"
import TradeOptionBuySide from "./TradeOptionBuySide"

const TradeOptionSide: React.FC<{
  series?: Series
  clearSelection(): void
}> = ({ series, clearSelection }) => {
  const { wallet } = useWeb3()

  return (
    <Side open={!!series} onClose={clearSelection}>
      {wallet === undefined ? (
        <GetStartedSideContent description="Connect your wallet to make your first siren trade" />
      ) : !series ? (
        <Side.Content space={3}>
          <Typography variant="h3">Select an option to continue</Typography>
          <Typography size="small" color="textSecondary">
            To make a trade, select an option from the table on the left
          </Typography>

          <SkeletonGrid columns={6} animation={false}>
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
          </SkeletonGrid>
        </Side.Content>
      ) : (
        <TradeOptionBuySide series={series} clearSelection={clearSelection} />
      )}
    </Side>
  )
}

export default TradeOptionSide
