import React from "react"
import { ReactTypeformEmbed } from "react-typeform-embed"

export const AirdropPage: React.FC<{}> = () => (
  <ReactTypeformEmbed
    url="https://qbdd25iw3vo.typeform.com/to/TDbRKvs5"
    hideHeaders={true}
    hideFooter={true}
  />
)
