import { Button, useMediaQuery, useTheme, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React from "react"
import { useWeb3 } from "@chainsafe/web3-context"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom"

import { useSirenMarketsContext } from "../../../Contexts/SirenMarketsContext"
import PortfolioSvg from "../../../Components/icons/PortfolioSvg"
import PositionList from "./PositionList"
import PortfolioPositionSide from "./PortfolioPositionSide"
import {
  MainLayout,
  VStack,
  HStack,
  Typography,
  NoData,
  SideSkeleton,
} from "../../common"
import PortfolioLiquidityTable from "./PortfolioLiquidityTable"
import PortfolioLiquiditySide from "./PortfolioLiquiditySide"

const useStyles = makeStyles<Theme>(({ spacing, breakpoints, palette }) => ({
  portfolioIcon: {
    fill: palette.colors.primary,
    height: 33,
    width: 28,
  },
  connectWalletBackdrop: {
    position: "fixed",
    height: "100%",
    width: "calc(100% - 24px)",
    "& > img": {
      width: "calc(100% - 24px)",
    },
    [breakpoints.down("sm")]: {
      width: "100%",
      "& > img": {
        width: "100%",
      },
    },
    zIndex: 0,
    "&:after": {
      position: "fixed",
      left: 0,
      bottom: 0,
      height: "50vh",
      width: "100vw",
      content: "''",
      background: palette.themeColor(
        "linear-gradient(180deg, rgba(9, 24, 37, 0) 0%, #091825 37.37%)",
        "linear-gradient(180deg, rgba(9, 24, 37, 0) 0%, #EEF1F2 37.37%)",
      ),
    },
  },
  connectWalletDialog: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    "& > *": {
      marginBottom: spacing(4),
    },
  },
}))

export const PortfolioPage: React.FC<{}> = () => {
  const { isReady } = useWeb3()
  const { handleSelectWalletConnect } = useSirenMarketsContext()

  const navigate = useNavigate()
  const location = useLocation()

  const classes = useStyles()

  const { breakpoints } = useTheme()
  const desktop = useMediaQuery(breakpoints.up("md"))
  const mobile = useMediaQuery(breakpoints.down("sm"))

  return (
    <>
      <MainLayout.Content>
        <VStack space={2} flex={1}>
          <HStack space={2} alignItems="center" px={mobile ? 2 : 0}>
            <PortfolioSvg className={classes.portfolioIcon} />
            <Typography variant="h1">Portfolio</Typography>
          </HStack>

          <HStack justifyContent="space-between" px={mobile ? 2 : 0}>
            <ToggleButtonGroup
              value={
                location.pathname.includes("/portfolio/liquidity")
                  ? "/portfolio/liquidity"
                  : "/portfolio/positions"
              }
              onChange={(_, value) => {
                navigate(value)
              }}
              exclusive
            >
              <ToggleButton value="/portfolio/positions" size="large">
                Positions
              </ToggleButton>
              <ToggleButton value="/portfolio/liquidity" size="large">
                Liquidity
              </ToggleButton>
            </ToggleButtonGroup>
          </HStack>

          {isReady ? (
            <>
              <Routes>
                <Route path="/positions" element={<PositionList />}>
                  <Route path="/positions/:id" element={<PositionList />} />
                </Route>
                <Route path="/liquidity" element={<PortfolioLiquidityTable />}>
                  <Route
                    path="/liquidity/:id"
                    element={<PortfolioLiquidityTable />}
                  />
                </Route>

                <Route
                  path="*"
                  element={<Navigate to="/portfolio/positions" />}
                />
              </Routes>
            </>
          ) : (
            <>
              <NoData
                title="Lets get started"
                subtitle="Connect your wallet to make your first siren trade"
                px={mobile ? 2 : 0}
              >
                <Button
                  variant="primary"
                  size={desktop ? "large" : "medium"}
                  onClick={handleSelectWalletConnect}
                  fullWidth={desktop}
                  style={{ maxWidth: 365 }}
                >
                  Connect Wallet
                </Button>
              </NoData>
            </>
          )}
        </VStack>
      </MainLayout.Content>

      <MainLayout.RightSide>
        {isReady ? (
          <Routes>
            <Route path="/positions" element={<PortfolioPositionSide />}>
              <Route
                path="/positions/:id"
                element={<PortfolioPositionSide />}
              />
            </Route>
            <Route path="/liquidity" element={<PortfolioLiquiditySide />}>
              <Route
                path="/liquidity/:id"
                element={<PortfolioLiquiditySide />}
              />
            </Route>
          </Routes>
        ) : (
          <SideSkeleton />
        )}
      </MainLayout.RightSide>
    </>
  )
}
