import { useQuery } from "@tanstack/react-query"
import { QueryKey } from "../../constants"

const PERMISSONS_URL = "https://dfh4j89egpa6u.cloudfront.net/permissions.json"

type Permissions = {
  tradeAllowed: boolean
}

const fetchPermissions = (): Promise<Permissions> =>
  fetch(PERMISSONS_URL).then((res) => res.json())

export const usePermissions = () => {
  const { data: permissions, isFetched: permissionsFetched } = useQuery(
    [QueryKey.Permissions],
    () => fetchPermissions(),
    {
      refetchOnWindowFocus: false,
    },
  )

  const canTradeOptions = permissions?.tradeAllowed ?? false

  return { canTradeOptions, permissionsFetched }
}
