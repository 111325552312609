import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client"
import { useWeb3 } from "@chainsafe/web3-context"
import React, { useMemo, useState } from "react"
import config, { SirenChainConfig, SirenConfig } from "../config"

export type SirenConfigContextType = {
  activeConfig: SirenChainConfig
  selectedChainId: keyof SirenConfig
  setSelectedChainId: (chainId: keyof SirenConfig) => void
  isWalletNetworkValid: boolean
}

type SirenConfigProps = {
  children: React.ReactNode | React.ReactNode[]
}

const SirenConfigContext = React.createContext<
  SirenConfigContextType | undefined
>(undefined)

const storedNetwork = localStorage.getItem("si.preferredNetwork")

const SirenConfigContextProvider = ({ children }: SirenConfigProps) => {
  const { network, isReady } = useWeb3()

  const [selectedChainId, setSelectedChainId] = useState<keyof SirenConfig>(
    storedNetwork && Object.keys(config).includes(storedNetwork)
      ? Number(storedNetwork)
      : Number(Object.keys(config)[0]),
  )

  const client = useMemo(
    () =>
      new ApolloClient({
        uri: selectedChainId
          ? config[selectedChainId].subgraphUrl
          : config[Number(Object.keys(config)[0])].subgraphUrl,
        cache: new InMemoryCache({
          typePolicies: {
            Query: {
              fields: {
                ammTokenEvents: {
                  keyArgs: ["where"],
                  merge(existing = [], incoming) {
                    return [...existing, ...incoming]
                  },
                },
              },
            },
          },
        }),
      }),
    [selectedChainId],
  )

  return (
    <ApolloProvider client={client}>
      <SirenConfigContext.Provider
        value={{
          activeConfig: useMemo(
            () => config[selectedChainId],
            [selectedChainId],
          ),
          selectedChainId,
          setSelectedChainId: (value) => {
            setSelectedChainId(value)
            localStorage.setItem("si.preferredNetwork", value.toString())
          },
          isWalletNetworkValid: useMemo(
            () => isReady && network === selectedChainId,
            [isReady, network, selectedChainId],
          ),
        }}
      >
        {children}
      </SirenConfigContext.Provider>
    </ApolloProvider>
  )
}

function useSirenConfig() {
  const context = React.useContext(SirenConfigContext)
  if (context === undefined) {
    throw new Error(
      "useNetworkSwitch must be used within a NetworkSwitchContext",
    )
  }
  return context
}

export { SirenConfigContextProvider, useSirenConfig }
