import React, { VFC } from "react"
import { AvatarProps, Avatar, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"

import { ReactComponent as COLOR_1INCH } from "../../media/tokens/COLOR-1INCH.svg"
import { ReactComponent as COLOR_AAVE } from "../../media/tokens/COLOR-AAVE.svg"
import { ReactComponent as COLOR_COMP } from "../../media/tokens/COLOR-COMP.svg"
import { ReactComponent as COLOR_DPI } from "../../media/tokens/COLOR-DPI.svg"
import { ReactComponent as COLOR_WETH } from "../../media/tokens/COLOR-WETH.svg"
import { ReactComponent as COLOR_KNC } from "../../media/tokens/COLOR-KNC.svg"
import { ReactComponent as COLOR_LINK } from "../../media/tokens/COLOR-LINK.svg"
import { ReactComponent as COLOR_WMATIC } from "../../media/tokens/COLOR-WMATIC.svg"
import { ReactComponent as COLOR_REN } from "../../media/tokens/COLOR-REN.svg"
import { ReactComponent as COLOR_SNX } from "../../media/tokens/COLOR-SNX.svg"
import { ReactComponent as COLOR_SUSHI } from "../../media/tokens/COLOR-SUSHI.svg"
import { ReactComponent as COLOR_UNI } from "../../media/tokens/COLOR-UNI.svg"
import { ReactComponent as COLOR_USDC } from "../../media/tokens/COLOR-USDC.svg"
import { ReactComponent as COLOR_WBTC } from "../../media/tokens/COLOR-WBTC.svg"
import { ReactComponent as COLOR_YFI } from "../../media/tokens/COLOR-YFI.svg"
import { ReactComponent as COLOR_DAI } from "../../media/tokens/COLOR-DAI.svg"
import { ReactComponent as DARK_1INCH } from "../../media/tokens/DARK-1INCH.svg"
import { ReactComponent as DARK_AAVE } from "../../media/tokens/DARK-AAVE.svg"
import { ReactComponent as DARK_COMP } from "../../media/tokens/DARK-COMP.svg"
import { ReactComponent as DARK_DPI } from "../../media/tokens/DARK-DPI.svg"
import { ReactComponent as DARK_WETH } from "../../media/tokens/DARK-WETH.svg"
import { ReactComponent as DARK_KNC } from "../../media/tokens/DARK-KNC.svg"
import { ReactComponent as DARK_LINK } from "../../media/tokens/DARK-LINK.svg"
import { ReactComponent as DARK_WMATIC } from "../../media/tokens/DARK-WMATIC.svg"
import { ReactComponent as DARK_REN } from "../../media/tokens/DARK-REN.svg"
import { ReactComponent as DARK_SNX } from "../../media/tokens/DARK-SNX.svg"
import { ReactComponent as DARK_SUSHI } from "../../media/tokens/DARK-SUSHI.svg"
import { ReactComponent as DARK_UNI } from "../../media/tokens/DARK-UNI.svg"
import { ReactComponent as DARK_USDC } from "../../media/tokens/DARK-USDC.svg"
import { ReactComponent as DARK_WBTC } from "../../media/tokens/DARK-WBTC.svg"
import { ReactComponent as DARK_YFI } from "../../media/tokens/DARK-YFI.svg"
import { ReactComponent as LIGHT_1INCH } from "../../media/tokens/LIGHT-1INCH.svg"
import { ReactComponent as LIGHT_AAVE } from "../../media/tokens/LIGHT-AAVE.svg"
import { ReactComponent as LIGHT_COMP } from "../../media/tokens/LIGHT-COMP.svg"
import { ReactComponent as LIGHT_DPI } from "../../media/tokens/LIGHT-DPI.svg"
import { ReactComponent as LIGHT_WETH } from "../../media/tokens/LIGHT-WETH.svg"
import { ReactComponent as LIGHT_KNC } from "../../media/tokens/LIGHT-KNC.svg"
import { ReactComponent as LIGHT_LINK } from "../../media/tokens/LIGHT-LINK.svg"
import { ReactComponent as LIGHT_WMATIC } from "../../media/tokens/LIGHT-WMATIC.svg"
import { ReactComponent as LIGHT_REN } from "../../media/tokens/LIGHT-REN.svg"
import { ReactComponent as LIGHT_SNX } from "../../media/tokens/LIGHT-SNX.svg"
import { ReactComponent as LIGHT_SUSHI } from "../../media/tokens/LIGHT-SUSHI.svg"
import { ReactComponent as LIGHT_UNI } from "../../media/tokens/LIGHT-UNI.svg"
import { ReactComponent as LIGHT_USDC } from "../../media/tokens/LIGHT-USDC.svg"
import { ReactComponent as LIGHT_WBTC } from "../../media/tokens/LIGHT-WBTC.svg"
import { ReactComponent as LIGHT_YFI } from "../../media/tokens/LIGHT-YFI.svg"
import { useMedia } from "../../Hooks/useMedia"

export enum SupportedAssets {
  IINCH = "1INCH",
  AAVE = "AAVE",
  COMP = "COMP",
  DPI = "DPI",
  WETH = "WETH",
  KNC = "KNC",
  LINK = "LINK",
  WMATIC = "WMATIC",
  REN = "REN",
  SNX = "SNX",
  SUSHI = "SUSHI",
  UNI = "UNI",
  USDC = "USDC",
  WBTC = "WBTC",
  YFI = "YFI",

  DAI = "DAI",
}

export enum TokenAvatarStyle {
  Color = "COLOR",
  Dark = "DARK",
  Light = "LIGHT",
}

const ASSETS_ICONS = {
  [TokenAvatarStyle.Color]: {
    [SupportedAssets.IINCH]: COLOR_1INCH,
    [SupportedAssets.AAVE]: COLOR_AAVE,
    [SupportedAssets.COMP]: COLOR_COMP,
    [SupportedAssets.DPI]: COLOR_DPI,
    [SupportedAssets.WETH]: COLOR_WETH,
    [SupportedAssets.KNC]: COLOR_KNC,
    [SupportedAssets.LINK]: COLOR_LINK,
    [SupportedAssets.WMATIC]: COLOR_WMATIC,
    [SupportedAssets.REN]: COLOR_REN,
    [SupportedAssets.SNX]: COLOR_SNX,
    [SupportedAssets.SUSHI]: COLOR_SUSHI,
    [SupportedAssets.UNI]: COLOR_UNI,
    [SupportedAssets.USDC]: COLOR_USDC,
    [SupportedAssets.WBTC]: COLOR_WBTC,
    [SupportedAssets.YFI]: COLOR_YFI,

    [SupportedAssets.DAI]: COLOR_DAI,
  },

  [TokenAvatarStyle.Dark]: {
    [SupportedAssets.IINCH]: DARK_1INCH,
    [SupportedAssets.AAVE]: DARK_AAVE,
    [SupportedAssets.COMP]: DARK_COMP,
    [SupportedAssets.DPI]: DARK_DPI,
    [SupportedAssets.WETH]: DARK_WETH,
    [SupportedAssets.KNC]: DARK_KNC,
    [SupportedAssets.LINK]: DARK_LINK,
    [SupportedAssets.WMATIC]: DARK_WMATIC,
    [SupportedAssets.REN]: DARK_REN,
    [SupportedAssets.SNX]: DARK_SNX,
    [SupportedAssets.SUSHI]: DARK_SUSHI,
    [SupportedAssets.UNI]: DARK_UNI,
    [SupportedAssets.USDC]: DARK_USDC,
    [SupportedAssets.WBTC]: DARK_WBTC,
    [SupportedAssets.YFI]: DARK_YFI,

    [SupportedAssets.DAI]: COLOR_DAI,
  },

  [TokenAvatarStyle.Light]: {
    [SupportedAssets.IINCH]: LIGHT_1INCH,
    [SupportedAssets.AAVE]: LIGHT_AAVE,
    [SupportedAssets.COMP]: LIGHT_COMP,
    [SupportedAssets.DPI]: LIGHT_DPI,
    [SupportedAssets.WETH]: LIGHT_WETH,
    [SupportedAssets.KNC]: LIGHT_KNC,
    [SupportedAssets.LINK]: LIGHT_LINK,
    [SupportedAssets.WMATIC]: LIGHT_WMATIC,
    [SupportedAssets.REN]: LIGHT_REN,
    [SupportedAssets.SNX]: LIGHT_SNX,
    [SupportedAssets.SUSHI]: LIGHT_SUSHI,
    [SupportedAssets.UNI]: LIGHT_UNI,
    [SupportedAssets.USDC]: LIGHT_USDC,
    [SupportedAssets.WBTC]: LIGHT_WBTC,
    [SupportedAssets.YFI]: LIGHT_YFI,

    [SupportedAssets.DAI]: COLOR_DAI,
  },
}

type TokenAvatarSize = 16 | 20 | 24 | 36 | 40 | 44 | 72

type TokenAvatarProps = {
  tokenSymbol: string
  tokenStyle?: TokenAvatarStyle
  size?:
    | TokenAvatarSize
    | [TokenAvatarSize, TokenAvatarSize]
    | [TokenAvatarSize, TokenAvatarSize, TokenAvatarSize]
} & AvatarProps

const useStyles = makeStyles<Theme, { size?: number }>(() => ({
  root: ({ size }) =>
    size
      ? {
          width: size,
          height: size,

          "& svg": {
            width: "100%",
            height: "100%",
          },
        }
      : {
          "& svg": {
            width: "100%",
            height: "100%",
          },
        },
}))

export const TokenAvatar: VFC<TokenAvatarProps> = ({
  className,
  tokenSymbol,
  tokenStyle = TokenAvatarStyle.Color,
  size,
  ...rest
}) => {
  const { mediaSwitch } = useMedia()

  const classes = useStyles({
    size: Array.isArray(size) ? mediaSwitch(size[0], size[1], size[2]) : size,
  })

  const assetIcon = ASSETS_ICONS[tokenStyle][tokenSymbol as SupportedAssets]

  if (!assetIcon) {
    return null
  }

  return (
    <Avatar className={clsx(className, classes.root)} {...rest}>
      {React.createElement(assetIcon)}
    </Avatar>
  )
}
