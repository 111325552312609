import React from "react"
import { Grid, Typography, Dialog, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Build } from "@mui/icons-material"

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  root: {
    padding: spacing(4),
    textAlign: "center",
  },
  dialog: {
    overflow: "hidden",
  },
  icon: {
    fontSize: 80,
  },
}))

const MaintenanceDialog: React.FC = () => {
  const classes = useStyles()

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <Grid
        container
        direction="column"
        spacing={4}
        alignContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Build color="primary" className={classes.icon} />
        <br />
        <Typography variant="subtitle1" paragraph>
          The app is currently undergoing maintenance.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          We will be back in action shortly.
        </Typography>
      </Grid>
    </Dialog>
  )
}

export default MaintenanceDialog
