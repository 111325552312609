import { Button, SvgIcon, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React from "react"

import TransactionFailedSvg from "../icons/TransactionFailedSvg"
import { Side, Typography } from "./"

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  icon: {
    marginTop: spacing(10),
    marginBottom: spacing(10),
    marginLeft: "auto",
    marginRight: "auto",

    width: 156,
    height: 156,

    "& > image": {
      transform: "translate(-21px,-21px)",
    },
  },
}))

export const TxFailure: React.FC<{
  clearSelection(): void
}> = ({ clearSelection }) => {
  const classes = useStyles()

  return (
    <>
      <Side.Content>
        <Typography variant="h3">Transaction got stuck</Typography>
        <Typography paragraph size="small" color="textSecondary">
          Something got lost in the matrix, please try again.
        </Typography>
        <Typography paragraph size="small" color="textSecondary">
          If issues still persist please contact us via support@sirenmarkets.com
        </Typography>

        <SvgIcon
          className={classes.icon}
          component={TransactionFailedSvg}
          htmlColor="#E9F2FF"
          viewBox="0 0 156 156"
        />
      </Side.Content>
      <Side.Action>
        <Button onClick={clearSelection} variant="primary" fullWidth>
          Try again
        </Button>
      </Side.Action>
    </>
  )
}
