import { useApolloClient } from "@apollo/client"
import { useWeb3 } from "@chainsafe/web3-context"
import { WTokenVault__factory } from "@sirenmarkets/sdk"
import BigNumber from "bignumber.js"
import dayjs from "dayjs"
import { ethers } from "ethers"
import { groupBy } from "lodash"
import { useCallback } from "react"
import { useQuery } from "@tanstack/react-query"

import { QueryKey } from "../../constants"
import { lockedExpirationPoolsQuery } from "../graphQueries"
import {
  LockedExpirationPools,
  LockedExpirationPools_account_lockedExpirationPools,
} from "../types/LockedExpirationPools"
import { useSirenMarketsContext } from "./SirenMarketsContext"
import { GroupedLockedLiquidity, LockedLiquidity, PositionType } from "./types"
import { useAddresses } from "./useAddresses"

const getActualExpirationDates = (items: LockedLiquidity[]) =>
  items.map((item) => item.expirationDate)

const getMaturityDate = (items: LockedLiquidity[]) => {
  const maxExpirationDate = Math.max(
    ...getActualExpirationDates(items).map((item) => parseInt(item)),
  )
  return dayjs.unix(maxExpirationDate).toDate()
}

const getCashOutValueForNonExpired = (items: LockedLiquidity[]) =>
  items
    .filter((item) => parseInt(item.expirationDate) * 1000 > Date.now())
    .reduce((result, item) => result + item.availableForCashOut, 0)

const getCashOutValueForExpired = (items: LockedLiquidity[]) =>
  items
    .filter((item) => parseInt(item.expirationDate) * 1000 <= Date.now())
    .reduce((result, item) => result + item.availableForCashOut, 0)

export const useLockedLiquidity = () => {
  const { address } = useWeb3()

  const { readProvider } = useSirenMarketsContext()

  const sirenGraphClient = useApolloClient()

  const { wTokenVaultAddress } = useAddresses()

  const lockedLiquidityEnabled = Boolean(
    readProvider && wTokenVaultAddress && address,
  )

  const processItem = useCallback(
    async (
      item: LockedExpirationPools_account_lockedExpirationPools,
      address: string,
      wTokenVaultAddress: string,
      readProvider: ethers.providers.Provider,
    ) => {
      try {
        const wTokenVault = WTokenVault__factory.connect(
          wTokenVaultAddress,
          readProvider,
        )

        const expirationDate = item.expirationDate
        const decimals = item.amm.collateralToken.decimals

        const lockedValue = new BigNumber(
          (
            await wTokenVault.getLockedValue(item.amm.id, expirationDate)
          ).toString(),
        )

        const lpSharesSupply = new BigNumber(
          (
            await wTokenVault.lpSharesSupply(item.amm.id, expirationDate)
          ).toString(),
        )

        const lpShares = new BigNumber(
          (
            await wTokenVault.lpShares(item.amm.id, expirationDate, address)
          ).toString(),
        )

        const redeemedCollateral = new BigNumber(
          (
            await wTokenVault.redeemedCollateral(
              item.amm.id,
              expirationDate,
              address,
            )
          ).toString(),
        )

        const redeemableCollateral = new BigNumber(
          (
            await wTokenVault.callStatic.getRedeemableCollateral(
              item.amm.id,
              expirationDate,
            )
          ).toString(),
        )

        const estimatedValue =
          lpSharesSupply.toNumber() !== 0
            ? lockedValue
                .plus(redeemableCollateral)
                .multipliedBy(lpShares)
                .div(lpSharesSupply)
                .minus(redeemedCollateral)
            : new BigNumber(0)

        const lockedCollateral =
          lpSharesSupply.toNumber() !== 0
            ? lockedValue.multipliedBy(lpShares).div(lpSharesSupply)
            : new BigNumber(0)

        const availableForCashOut =
          lpSharesSupply.toNumber() !== 0
            ? redeemableCollateral
                .multipliedBy(lpShares)
                .div(lpSharesSupply)
                .minus(redeemedCollateral)
            : new BigNumber(0)

        const lockedLiquidity: LockedLiquidity = {
          id: item.id,
          collateralSymbol: item.amm.collateralToken.symbol,
          underlyingSymbol: item.amm.underlyingToken.symbol,
          estimatedValue: new BigNumber(estimatedValue.toNumber())
            .shiftedBy(-decimals)
            .toNumber(),
          lockedCollateral: new BigNumber(lockedCollateral.toNumber())
            .shiftedBy(-decimals)
            .toNumber(),
          availableForCashOut: new BigNumber(availableForCashOut.toNumber())
            .shiftedBy(-decimals)
            .toNumber(),
          positionType:
            item.amm.collateralToken.id === item.amm.underlyingToken.id
              ? PositionType.Call
              : PositionType.Put,
          ammId: item.amm.id,
          expirationDate: item.expirationDate,
          lockedWTokens: new BigNumber(item.lockedWTokens)
            .shiftedBy(-decimals)
            .toNumber(),
        }

        return lockedLiquidity
      } catch (e) {
        console.log(e)
      }

      return null
    },
    [],
  )

  const processData = useCallback(
    async (
      data: LockedExpirationPools,
      address: string,
      wTokenVaultAddress: string,
      readProvider: ethers.providers.Provider,
    ) => {
      let result = await Promise.all(
        (data.account?.lockedExpirationPools ?? []).map((item) =>
          processItem(item, address, wTokenVaultAddress, readProvider),
        ),
      )

      const groupedLockedLiquidity: GroupedLockedLiquidity[] = Object.values(
        groupBy(
          result.filter((item) => item !== null) as LockedLiquidity[],
          (item) => item.ammId + item?.positionType,
        ),
      )
        .map((group) =>
          group.slice(1).reduce<GroupedLockedLiquidity>(
            (result, item) => ({
              ...result,
              estimatedValue: result.estimatedValue + item.estimatedValue,
              lockedCollateral: result.lockedCollateral + item.lockedCollateral,
            }),
            {
              ...group[0],
              expirationDates: getActualExpirationDates(group),
              maturityDate: getMaturityDate(group),
              lockedCollateral:
                group[0].lockedCollateral + getCashOutValueForNonExpired(group),
              availableForCashOut: getCashOutValueForExpired(group),
            },
          ),
        )
        .filter(
          (g) => !(g.availableForCashOut === 0 && g.lockedCollateral === 0),
        )

      return groupedLockedLiquidity
    },
    [processItem],
  )

  const { data: lockedLiquidity = [], isFetched: lockedLiquidityFetched } =
    useQuery(
      [QueryKey.LockedLiquidity],
      async () => {
        const { data: lockedExpirationPools } =
          await sirenGraphClient.query<LockedExpirationPools>({
            query: lockedExpirationPoolsQuery,
            variables: {
              id: address,
            },
            fetchPolicy: "network-only",
          })

        return processData(
          lockedExpirationPools,
          address!,
          wTokenVaultAddress!,
          readProvider!,
        )
      },
      {
        enabled: lockedLiquidityEnabled,
      },
    )

  return {
    lockedLiquidity,
    lockedLiquidityFetched,
  }
}
