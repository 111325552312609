import React from "react"

interface ITransactionDepositSvg {
  className?: string
}

const TransactionDepositSvg = ({ className }: ITransactionDepositSvg) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill="url(#paint0_radial_6227_42595)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3536 7.64645C16.5488 7.84171 16.5488 8.15829 16.3536 8.35355L10.8536 13.8536C10.6583 14.0488 10.3417 14.0488 10.1464 13.8536C9.95119 13.6583 9.95119 13.3417 10.1464 13.1464L15.6464 7.64645C15.8417 7.45118 16.1583 7.45118 16.3536 7.64645Z"
        fill="#0A1C2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3536 7.64645C16.4894 7.78226 16.5354 7.98388 16.4719 8.16517L12.9719 18.1652C12.9042 18.3587 12.7252 18.4912 12.5204 18.4996C12.3155 18.5079 12.1264 18.3904 12.0431 18.2031L10.1212 13.8788L5.79693 11.9569C5.60959 11.8736 5.49206 11.6845 5.50042 11.4796C5.50878 11.2748 5.64133 11.0958 5.83483 11.0281L15.8348 7.52807C16.0161 7.46462 16.2177 7.51063 16.3536 7.64645ZM7.35554 11.5553L10.7031 13.0431C10.8162 13.0934 10.9066 13.1838 10.9569 13.2969L12.4447 16.6445L15.185 8.81498L7.35554 11.5553Z"
        fill="#0A1C2C"
      />
      <defs>
        <radialGradient
          id="paint0_radial_6227_42595"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.6 3.67021) rotate(46.6371) scale(27.9632 25.4247)"
        >
          <stop stopColor="#ACE0FF" />
          <stop offset="0.526042" stopColor="#109CF3" />
          <stop offset="1" stopColor="#0075BD" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default TransactionDepositSvg
