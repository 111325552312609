import React from "react"

interface IBackSvg {
  className?: string
}

const BackSvg = ({ className }: IBackSvg) => {
  return (
    <svg
      className={className}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30" r="29" stroke="currentColor" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8248 22.6583C31.2804 22.2026 31.2804 21.4639 30.8248 21.0083C30.3692 20.5527 29.6305 20.5527 29.1749 21.0083L21.0082 29.175C20.5526 29.6306 20.5526 30.3693 21.0082 30.8249L29.1749 38.9916C29.6305 39.4472 30.3692 39.4472 30.8248 38.9916C31.2804 38.536 31.2804 37.7973 30.8248 37.3417L24.6498 31.1666H38.1665C38.8108 31.1666 39.3332 30.6443 39.3332 30C39.3332 29.3556 38.8108 28.8333 38.1665 28.8333H24.6498L30.8248 22.6583Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default BackSvg
