import { useQuery } from "@apollo/client"
import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import BigNumber from "bignumber.js"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { first } from "lodash"

import { ammTokenEvents } from "../../../../Contexts/graphQueries"
import { useLanguageContext } from "../../../../Contexts/LanguageContext"
import { LiquidityPool } from "../../../../Contexts/SirenMarketsContext"
import {
  AmmTokenEvents,
  AmmTokenEventsVariables,
  AmmTokenEvents_ammTokenEvents_BTokenBought,
  AmmTokenEvents_ammTokenEvents_BTokenSold,
  AmmTokenEvents_ammTokenEvents_WTokenSold,
} from "../../../../Contexts/types/AmmTokenEvents"
import { AmmTokenEventType } from "../../../../types/graphql-global-types"
import { HStack, Typography, VStack } from "../../../common"
import TransactionBuySvg from "../../../icons/TransactionBuySvg"
import TransactionSellSvg from "../../../icons/TransactionSellSvg"
import TransactioWithdrawSvg from "../../../icons/TransactionWithdrawSvg"
import TransactionDepositSvg from "../../../icons/TransactionDepositSvg"
import { STRIKE_PRICE_DECIMALS } from "../../../../Contexts/SirenMarketsContext"
import ExternalLinkSvg from "../../../icons/ExternalLinkSvg"
import { useSirenConfig } from "../../../../Contexts/SirenConfigContext"
import { SirenChainConfig } from "../../../../config"
import { useMedia } from "../../../../Hooks/useMedia"

const ACTION_BY_EVENT_TYPE = {
  [AmmTokenEventType.LpTokenBurn]: {
    title: "Withdraw",
    icon: TransactioWithdrawSvg,
  },
  [AmmTokenEventType.LpTokenMint]: {
    title: "Deposit",
    icon: TransactionDepositSvg,
  },
  [AmmTokenEventType.BTokenBought]: {
    title: "Buy",
    icon: TransactionBuySvg,
  },
  [AmmTokenEventType.BTokenSold]: {
    title: "Sell",
    icon: TransactionSellSvg,
  },
  [AmmTokenEventType.WTokenSold]: {
    title: "Sell",
    icon: TransactionSellSvg,
  },
}

const SHOWED_EVENT_TYPES = [
  AmmTokenEventType.BTokenBought,
  AmmTokenEventType.BTokenSold,
  AmmTokenEventType.WTokenSold,
]

const PER_PAGE = 10

type Transaction =
  | AmmTokenEvents_ammTokenEvents_BTokenBought
  | AmmTokenEvents_ammTokenEvents_BTokenSold
  | AmmTokenEvents_ammTokenEvents_WTokenSold

const getBlockExplorerLink = (config: SirenChainConfig, event: Transaction) => {
  const blockExplorerUrl = first(config?.addChainParams?.blockExplorerUrls)

  if (!blockExplorerUrl) {
    return undefined
  }

  return `${blockExplorerUrl}/tx/${event.transaction}`
}

const PoolRecentTransactions: React.FC<{ pool: LiquidityPool }> = ({
  pool,
}) => {
  const { formatShortLocaleDate, formatLocalTime } = useLanguageContext()

  const { activeConfig } = useSirenConfig()

  const [page, setPage] = useState(0)

  const media = useMedia()

  const { data: poolActivity, fetchMore } = useQuery<
    AmmTokenEvents,
    AmmTokenEventsVariables
  >(ammTokenEvents, {
    variables: {
      where: {
        amm: pool?.address as string,
      },
    },
    skip: !Boolean(pool),
  })

  const filteredRows = useMemo(
    () =>
      (poolActivity?.ammTokenEvents ?? []).filter((item) =>
        SHOWED_EVENT_TYPES.includes(item.eventType[0]!),
      ) as Transaction[],
    [poolActivity],
  )

  const rows = useMemo(
    () => filteredRows.slice(page * PER_PAGE, (page + 1) * PER_PAGE),
    [page, filteredRows],
  )

  const handlePageChange = useCallback(
    (_, value) => {
      setPage(value)
    },
    [setPage],
  )

  useEffect(() => {
    if (page > 0 && rows.length < PER_PAGE) {
      fetchMore({
        variables: {
          skip: poolActivity?.ammTokenEvents.length,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows])

  if (!pool || !poolActivity) {
    return null
  }

  return (
    <VStack>
      <Typography variant="h2">Recent transactions</Typography>

      <Box mx={media.mobile ? -4 : 0}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Date &amp; Time</TableCell>
                <TableCell>Contract</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((event, index) => {
                const blockExplorerLink = getBlockExplorerLink(
                  activeConfig,
                  event,
                )

                return (
                  <TableRow
                    key={event.account.id + event.timestamp + index}
                    onClick={() => {
                      if (blockExplorerLink) {
                        window.open(blockExplorerLink)
                      }
                    }}
                  >
                    <TableCell>
                      {event.eventType[0] && (
                        <HStack space={1}>
                          <SvgIcon
                            component={
                              ACTION_BY_EVENT_TYPE[event.eventType[0]].icon
                            }
                          />
                          <Typography size="extra-small">
                            {ACTION_BY_EVENT_TYPE[event.eventType[0]].title}
                          </Typography>
                        </HStack>
                      )}
                    </TableCell>
                    <TableCell>
                      <VStack space={0}>
                        <Typography
                          size="extra-small"
                          color="textSecondary"
                          weight="regular"
                        >
                          {formatShortLocaleDate(
                            new Date(Number(event.timestamp) * 1000),
                          )}
                        </Typography>
                        <Typography
                          size="x-tiny"
                          weight="regular"
                          color="textSecondary"
                        >
                          {formatLocalTime(
                            new Date(Number(event.timestamp) * 1000),
                          )}
                        </Typography>
                      </VStack>
                    </TableCell>
                    <TableCell>
                      <VStack space={0}>
                        <Typography
                          size="extra-small"
                          color="textSecondary"
                          weight="regular"
                        >
                          {event.series.seriesName}
                        </Typography>
                        <Typography
                          size="x-tiny"
                          weight="regular"
                          color="textSecondary"
                        >
                          {`Strike $${new BigNumber(event.series.strikePrice)
                            .shiftedBy(-STRIKE_PRICE_DECIMALS)
                            .decimalPlaces(4)
                            .toString()}`}
                        </Typography>
                      </VStack>
                    </TableCell>
                    <TableCell>
                      <Typography size="extra-small" weight="regular">
                        {new BigNumber(event.tokenAmount)
                          .shiftedBy(-pool.underlyingTokenDecimals)
                          .decimalPlaces(4)
                          .toString()}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <SvgIcon component={ExternalLinkSvg} fontSize="small" />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <TablePagination
        component="div"
        page={page}
        count={-1}
        labelDisplayedRows={() => ""}
        rowsPerPage={10}
        rowsPerPageOptions={[]}
        onPageChange={handlePageChange}
      />
    </VStack>
  )
}

export default PoolRecentTransactions
