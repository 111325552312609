import React, { useEffect, FC } from "react"
import { useLocation } from "react-router-dom"

export const ScrollToTop: FC = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [pathname])

  return <>{children}</>
}
