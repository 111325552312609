import { MenuItem, OutlinedInput, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React from "react"

import { HStack, VStack } from "./Stack"
import { TokenAvatar } from "./TokenAvatar"
import { Select } from "./Select"
import { Typography } from "./Typography"

const useStyles = makeStyles<Theme>(({ palette }) => ({
  input: {
    pointerEvents: "none",
    borderTopRightRadius: "0 !important",
    borderBottomRightRadius: "0 !important",

    "& .MuiOutlinedInput-notchedOutline": {
      borderRight: 0,
    },

    backgroundColor: palette.colors.background0,
  },

  select: {
    borderBottomLeftRadius: "0 !important",
    borderTopLeftRadius: "0 !important",
    minWidth: 162,
    maxWidth: 162,
  },
}))

export const NumericOutputWithTokenSelector: React.FC<{
  selectedTokenAddress: string
  tokens: Array<{ address: string; symbol: string }>
  value: string
  errorMessage?: string
  label?: string | React.ReactNode
  onSetSelectedToken: (newToken: string) => void
  className?: string
}> = ({
  value,
  errorMessage,
  onSetSelectedToken,
  selectedTokenAddress,
  tokens,
  label,
}) => {
  const classes = useStyles()
  const hasError = !!errorMessage

  return (
    <VStack space={1}>
      <HStack space={0}>
        <OutlinedInput
          value={value}
          className={classes.input}
          readOnly
          error={hasError}
          size="large"
        />
        <Select
          variant="outlined"
          value={selectedTokenAddress}
          onChange={(e) => onSetSelectedToken(e.target.value as string)}
          fullWidth
          className={classes.select}
          error={hasError}
          size="large"
        >
          {tokens.map((t) => (
            <MenuItem key={t.address} value={t.address}>
              <HStack alignItems="center" space={2}>
                <TokenAvatar
                  className={classes.tokenIcon}
                  tokenSymbol={t.symbol}
                  size={20}
                />

                <span>{t.symbol}</span>
              </HStack>
            </MenuItem>
          ))}
        </Select>
      </HStack>

      <HStack>
        {!!label && !hasError && (
          <Typography size="tiny" color="textSecondary" align="left">
            {label}
          </Typography>
        )}
        {hasError && (
          <Typography size="tiny" color="error" align="left">
            {errorMessage}
          </Typography>
        )}
      </HStack>
    </VStack>
  )
}
