import { alpha, Button, Collapse, SvgIcon, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { FC } from "react"

import { HStack, Typography } from "../common"
import BridgeSvg from "../icons/BridgeSvg"

import { LiFiDialog } from "./LiFiDialog"

const useStyles = makeStyles<Theme>(({ palette }) => ({
  root: {
    color: palette.colors.greenLight,
    backgroundColor: alpha(palette.colors.green, 0.06),
    height: 40,

    "&:hover": {
      color: palette.colors.textButton,
      backgroundColor: palette.colors.green,
    },

    "&:active": {
      color: palette.colors.greenDarker,
    },
  },
  icon: {
    width: 16,
    height: 16,
  },
}))

interface LiFiBridgeButtonProps {
  balance: number
  amount: number
  symbol?: string
  toToken: string
}

export const LiFiBridgeButton: FC<LiFiBridgeButtonProps> = ({
  symbol,
  balance,
  amount,
  toToken,
}) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const hidden = amount <= balance || !symbol
  const text = `Bridge ${symbol}`

  return (
    <>
      <Collapse in={!hidden} timeout={500}>
        <Button className={classes.root} onClick={handleClick} fullWidth>
          <HStack alignItems="center" space={1}>
            <SvgIcon
              className={classes.icon}
              viewBox="0 0 17 16"
              component={BridgeSvg}
            />
            <Typography size="extra-small">{text}</Typography>
          </HStack>
        </Button>
      </Collapse>

      <LiFiDialog toToken={toToken} open={open} onClose={handleClose} />
    </>
  )
}
