import { BigNumber } from "bignumber.js"
import { providers } from "ethers"

export enum PositionType {
  Call = "Call",
  Put = "Put",
}

export type Series = {
  seriesId: string // In the form `SeriesControllerAddress-SeriesIndex` This maps to series.id from Subgraph response
  seriesControllerAddress: string
  seriesIndex: string // This maps to `Series.seriesId` from the subgraph response
  ammAddress: string
  ammDataProviderAddress: string
  priceOracleAddress: string
  erc1155ControllerAddress: string
  seriesStyle: "American" | "European"
  collateralTokenAddress: string
  collateralTokenSymbol: string
  collateralTokenName: string
  collateralTokenDecimals: number
  priceTokenAddress: string
  priceTokenSymbol: string
  priceTokenName: string
  priceTokenDecimals: number
  underlyingTokenAddress: string
  underlyingTokenSymbol: string
  underlyingTokenName: string
  underlyingTokenDecimals: number
  wTokenIndex: string
  wTokenDecimals: number
  bTokenIndex: string
  bTokenDecimals: number
  pair: string
  type: "Call" | "Put"
  expiration: Date
  strike: number
  openInterest: number
  paymentPerUnderlying: number
  premium: number
  status: "open" | "expired" | "closed"
  greeks: {
    delta: number
    gamma: number
    vega: number
    theta: number
    rho: number
  }
  impliedVolatility: number
  seriesDeployed: boolean
}

export type Position = Series & {
  bTokenBalance: BigNumber
  wTokenBalance: BigNumber
  settlementValue?: BigNumber
  expiryStatus?: "ITM" | "ATM" | "OTM"
  unrealizedGain?: BigNumber
  collateralPerOptionToken?: BigNumber
}

export type PoolTokenBalance = {
  name: string
  tokenType: "bToken" | "wToken"
  amount: BigNumber
  tokenIndex: string
  strikePrice: BigNumber
  decimals?: number
  expiration: number
}

export type LiquidityPool = {
  address: string
  ammDataProviderAddress?: string
  createdAt: Date
  name: string
  userBalance?: BigNumber
  lpTokenAddress: string
  lpTokenDecimals: number
  lpTokenSupply: number
  collateralTokenAddress: string
  collateralTokenSymbol: string
  collateralTokenName: string
  collateralTokenDecimals: number
  priceTokenAddress: string
  priceTokenSymbol: string
  priceTokenName: string
  priceTokenDecimals: number
  underlyingTokenAddress: string
  underlyingTokenSymbol: string
  underlyingTokenName: string
  underlyingTokenDecimals: number
  poolValueLocked: number
  exchangeRate: number
  poolType: "Call" | "Put"
  yieldSinceInception: number
  yieldLastMonth: number
  utilization: number
  poolOptionBalances: Array<PoolTokenBalance>
  poolCollateralTokenBalance: BigNumber
  isActive: boolean
  currentLpTokenValue: number
  projectedYield: number
}

export type SirenPool = {
  siDailyRewards: number
}

export type SirenPoolsResponse = Record<string, SirenPool>

export type SirenRewardsResponse = {
  claimedRewards: string
  claimableRewards: string
  claimMessage?: { r: string; s: string; v: number }
  recipient?: { amount: string; nonce: string; wallet: string }
  totalRewards: string
}

export type SirenRewards = {
  claimedRewards: number
  claimableRewards: number
  claimMessage?: { r: string; s: string; v: number }
  recipient?: { amount: string; nonce: string; wallet: string }
  totalRewards: number
}

export type Pool2Type = "Uni" | "Sushi" | "SushiExtendable"

export type LockedLiquidity = {
  id: string
  collateralSymbol: string
  underlyingSymbol: string
  estimatedValue: number
  lockedCollateral: number
  availableForCashOut: number
  positionType: PositionType
  ammId: string
  expirationDate: string
  lockedWTokens: number
}

export type GroupedLockedLiquidity = Omit<LockedLiquidity, "expirationDate"> & {
  maturityDate: Date
  expirationDates: string[]
}

export type Addresses = {
  ammDataProviderAddress?: string
  priceOracleAddress?: string
  seriesControllerAddress?: string
  erc1155ControllerAddress?: string
  wTokenVaultAddress?: string
  lightswap1155Address?: string
  seriesDeployerAddress?: string
}

export type SirenMarketsContextType = {
  isConnecting: boolean
  handleSelectWalletConnect(): Promise<void>
  readProvider?: providers.Provider
  provider?: providers.Web3Provider
}
