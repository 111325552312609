import { makeStyles } from "@mui/styles"
import { Theme } from "@mui/material"
import React, { ReactNode } from "react"

import { VStack, VStackProps } from "./Stack"
import { Typography } from "./Typography"
import { SkeletonGrid } from "./SkeletonGrid"

const useStyles = makeStyles<Theme>(() => ({
  root: {
    position: "relative",
  },
  content: {
    position: "absolute",
    left: "50%",
    top: 75,
    transform: "translate(-50%, 0)",
    textAlign: "center",
  },
}))

type NoDataProps = {
  title: ReactNode
  subtitle?: ReactNode
} & VStackProps

export const NoData: React.FC<NoDataProps> = ({
  title,
  subtitle,
  children,

  ...rest
}) => {
  const classes = useStyles()

  return (
    <VStack className={classes.root} alignItems="center" flex={1} {...rest}>
      <SkeletonGrid columns={6} animation={false}>
        <SkeletonGrid.Item height={5} />
        <SkeletonGrid.Item height={5} />
        <SkeletonGrid.Item height={5} />
        <SkeletonGrid.Item height={5} />
        <SkeletonGrid.Item height={5} />
        <SkeletonGrid.Item height={5} />
        <SkeletonGrid.Item start={1} end={7} height={8} />
        <SkeletonGrid.Item start={1} end={7} height={8} />
        <SkeletonGrid.Item start={1} end={7} height={8} />
        <SkeletonGrid.Item start={1} end={7} height={8} />
      </SkeletonGrid>

      <VStack className={classes.content} alignItems="center">
        <Typography variant="h1">{title}</Typography>
        {subtitle && (
          <Typography size="medium" color="textSecondary" align="center">
            {subtitle}
          </Typography>
        )}
        {children}
      </VStack>
    </VStack>
  )
}
