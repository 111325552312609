import React from "react"
import { Typography, TypographyProps } from "./Typography"

type NumberTypographyProps = {
  value: number
} & Omit<TypographyProps, "color">

export const NumberTypography: React.FC<NumberTypographyProps> = ({
  value,
  children,
  ...rest
}) => {
  const color =
    value === 0 ? undefined : value > 0 ? "colors.green" : "colors.red"

  return (
    <Typography color={color} {...rest}>
      {children ?? value}
    </Typography>
  )
}
