import { useCallback } from "react"
import { PaletteType } from "../theme"
import { useLocalStorage } from "./useLocalStorage"

const LOCALSTORAGE_KEY = "paletteType"

const usePalleteType = () => {
  const { value: paletteType, setValue: setPaletteType } =
    useLocalStorage<PaletteType>(LOCALSTORAGE_KEY, PaletteType.Dark)

  const togglePalleteType = useCallback(
    () =>
      setPaletteType(
        paletteType === PaletteType.Dark ? PaletteType.Light : PaletteType.Dark,
      ),
    [setPaletteType, paletteType],
  )

  return { paletteType, togglePalleteType }
}

export { usePalleteType }
