import { IconButton, IconButtonProps, SvgIcon, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { ReactNode } from "react"

import InfoIcon from "../icons/InfoSvg"

import { Popover } from "./Popover"
import { HStack } from "./Stack"
import { Typography } from "./Typography"

type InfoPopoverIconProps = {
  isOpen: boolean
} & IconButtonProps

const useIconStyles = makeStyles<Theme, { isOpen: boolean }>(({ palette }) => ({
  root: ({ isOpen }) => ({
    padding: 0,
    width: 20,
    height: 20,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: -4,
    marginRight: -4,

    backgroundColor: isOpen ? palette.colors.background2 : "transparent",

    "&:hover": {
      backgroundColor: palette.colors.background1,
    },

    "&:active": {
      backgroundColor: palette.colors.background2,
    },

    "& svg": {
      height: 14,
      width: 14,
      fill: palette.colors.textSecondary,
    },
  }),
}))

const InfoPopoverIcon = ({ isOpen, onClick }: InfoPopoverIconProps) => {
  const classes = useIconStyles({ isOpen })

  return (
    <IconButton className={classes.root} onClick={onClick} size="large">
      <SvgIcon component={InfoIcon} viewBox="0 0 22 22" fontSize="small" />
    </IconButton>
  )
}

type InfoPopoverProps = {
  children: ReactNode
  text: string
}

const useStyles = makeStyles<Theme>(({ palette }) => ({
  root: {
    display: "inline-flex",
  },
}))

export const InfoPopover = ({ text, children }: InfoPopoverProps) => {
  const classes = useStyles({})

  return (
    <Popover content={<Typography size="extra-small">{text}</Typography>}>
      {({ open, isOpen }) => (
        <HStack className={classes.root} space={0.5} alignItems="center">
          {children}

          <InfoPopoverIcon onClick={open} isOpen={isOpen} />
        </HStack>
      )}
    </Popover>
  )
}
