import { useCallback, useEffect, useState } from "react"

const useLocalStorage = <T = string>(name: string, defaultValue: T | null) => {
  const [value, setValue] = useState(localStorage.getItem(name) ?? defaultValue)

  const updateValue = useCallback(
    (nextValue: T | null) => {
      if (nextValue === null) {
        localStorage.removeItem(name)
      } else {
        localStorage.setItem(name, String(nextValue))
      }

      const event = new StorageEvent("storage", {
        key: name,
        newValue: nextValue as string | null,
      })

      document.createEvent("Event")

      event.initEvent("storage", true, true)

      window.dispatchEvent(event)
    },
    [name],
  )

  const handleChange = useCallback(
    (e: StorageEvent) => {
      if (e.key === name) {
        setValue(e.newValue)
      }
    },
    [setValue, name],
  )

  useEffect(() => {
    window.addEventListener("storage", handleChange)

    return () => {
      window.removeEventListener("storage", handleChange)
    }
  }, [handleChange])

  return { value: value as T, setValue: updateValue }
}

export { useLocalStorage }
