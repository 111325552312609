import React, { useCallback, useMemo, useRef, useState } from "react"
import { alpha, Theme, useTheme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"

import { useContainerDimensions } from "../../../helpers/useContainerDimensions"
import { useLanguageContext } from "../../../Contexts/LanguageContext"
import { Details, NumberTypography, Typography } from "../../common"

const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  root: {
    maxWidth: "100%",
    backgroundColor: palette.colors.background2,
    padding: "20px",
    marginTop: 20,
  },
  blocker: {
    cursor: "pointer",
  },
  chartArea: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  markers: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: `0 ${spacing(2)} 0 0`,
  },
  svgRoot: {
    width: "100%",
    "& > *": {
      transitionDuration: "200ms",
    },
    "& > circle": {
      transitionProperty: "fill",
    },
  },
  hoverDot: {},
}))

interface ISvgSettings {
  x: number
  y: number
  strokeWidth: number
  circleRadius: number
}

interface ISvgElements {
  lossPath: string
  profitPath: string
  profitBackgroundPath: string
  lossBackgroundPath: string
  breakevenCoords: {
    x: number
    y: number
  }
  strikeCoords: {
    x: number
    y: number
  }
}

interface IArea {
  x: {
    min: number
    max: number
    size: number
  }
  y: {
    min: number
    max: number
    size: number
  }
}

interface IHoverCoords {
  x: number
  y: number
  above: boolean
  active: boolean
  highlightedPrice: number
}

const PnLChart: React.FC<{
  type: "Call" | "Put"
  currentPrice: number
  breakeven: number
  strikePrice: number
  premium: number
  contractSize: number
  className?: string
}> = ({
  type,
  currentPrice,
  breakeven,
  strikePrice,
  premium,
  className,
  contractSize,
}) => {
  const { palette } = useTheme()
  const classes = useStyles()

  const graphElementRef = useRef(null)
  const { width } = useContainerDimensions(graphElementRef)

  const { formatMoney } = useLanguageContext()

  const svgSettings: ISvgSettings = useMemo(
    () => ({
      x: width,
      y: 140,
      strokeWidth: 2,
      circleRadius: 4,
    }),
    [width],
  )

  const area: IArea | undefined = useMemo(() => {
    if (!svgSettings) return
    let newArea = {
      x: {
        min: 0,
        max: 0,
        size: 0,
      },
      y: {
        min: 0,
        max: 0,
        size: 0,
      },
    }

    newArea.x.max = svgSettings.x - svgSettings.circleRadius
    newArea.x.min = svgSettings.circleRadius

    newArea.y.max = svgSettings.y - svgSettings.circleRadius
    newArea.y.min = svgSettings.circleRadius

    newArea.x.size = svgSettings.x
    newArea.y.size = svgSettings.y
    return newArea
  }, [svgSettings])

  const svgElements: ISvgElements = useMemo(() => {
    const newSvgElements: ISvgElements = {
      lossBackgroundPath: "",
      lossPath: "",
      profitBackgroundPath: "",
      profitPath: "",
      breakevenCoords: {
        x: 0,
        y: 0,
      },
      strikeCoords: {
        x: 0,
        y: 0,
      },
    }
    if (!area) return newSvgElements
    if (!svgSettings) return newSvgElements

    if (type === "Call") {
      const start = `${area.x.min}, ${area.y.max}`

      const strikeCoords = `${area.x.size / 3},${area.y.max}`

      newSvgElements.strikeCoords = {
        x: area.x.size / 3,
        y: area.y.max,
      }

      const breakevenCoords = `${
        (area.x.size / 3) * 2 - svgSettings.circleRadius / 2
      },${svgSettings.y / 2}`
      newSvgElements.breakevenCoords = {
        x: (area.x.size / 3) * 2 - svgSettings.circleRadius / 2,
        y: svgSettings.y / 2,
      }

      const lossPath = `M${start} L${strikeCoords} L${breakevenCoords}`
      newSvgElements.lossPath = lossPath

      const profitMaxCoord = `${area.x.max}, ${area.y.min}`
      const profitPath = `M${breakevenCoords} L${profitMaxCoord}`
      newSvgElements.profitPath = profitPath

      const profitBackgroundPath = `M${profitMaxCoord} L${area.x.max},${
        svgSettings.y / 2
      } L${breakevenCoords} Z`

      newSvgElements.profitBackgroundPath = profitBackgroundPath

      const lossBackgroundPath = `M${start} L${area.x.min},${
        svgSettings.y / 2
      } L${breakevenCoords} L${strikeCoords} Z`

      newSvgElements.lossBackgroundPath = lossBackgroundPath
    } else {
      const start = `${area.x.min}, ${area.y.min}`

      const breakevenX = ((area.x.size / 3) * 2 - area.x.min) / 2 + area.x.min

      const breakevenCoords = `${breakevenX},${svgSettings.y / 2}`

      newSvgElements.breakevenCoords = {
        x: breakevenX,
        y: svgSettings.y / 2,
      }

      const profitPath = `M${start} L${breakevenCoords}`
      newSvgElements.profitPath = profitPath

      const strikeCoords = `${(area.x.size / 3) * 2},${area.y.max}`

      newSvgElements.strikeCoords = {
        x: (area.x.size / 3) * 2,
        y: area.y.max,
      }

      const end = `${area.x.max},${area.y.max}`

      const lossPath = `M${breakevenCoords} L${strikeCoords} L${end}`
      newSvgElements.lossPath = lossPath

      const profitBackgroundPath = `M${start} L${breakevenCoords} L${
        area.x.min
      },${svgSettings.y / 2} Z`

      newSvgElements.profitBackgroundPath = profitBackgroundPath

      const lossBackgroundPath = `M${breakevenCoords} L${strikeCoords} L${end} L${
        area.x.max
      },${svgSettings.y / 2} Z`

      newSvgElements.lossBackgroundPath = lossBackgroundPath
    }
    return newSvgElements
  }, [area, svgSettings, type])

  // TODO: useMemo
  const [hoverCoords, setHoverCoords] = useState<IHoverCoords>({
    x: 0,
    y: 0,
    above: false,
    active: false,
    highlightedPrice: currentPrice,
  })

  const onMouseMove = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!area) return
      // @ts-ignore
      var bounds = event.target.getBoundingClientRect()
      var fetchedX = event.clientX - bounds.left
      // var fetchedY = event.clientY - bounds.top;
      if (fetchedX < area.x.min) {
        fetchedX = area.x.min
      } else if (fetchedX > area.x.max) {
        fetchedX = area.x.max
      }

      let newHoverCoords = {
        ...hoverCoords,
      }

      if (type === "Call") {
        // Handle OTM Call
        if (fetchedX <= area.x.size / 3) {
          newHoverCoords = {
            ...newHoverCoords,
            x: fetchedX,
            y: area.y.max,
            above: false,
            active: true,
          }
          const start = 0
          const end = area.x.size / 3

          const totalDistanceX = end - start
          const traveled = fetchedX - start
          const traveledPercentage = traveled / totalDistanceX

          const segment = breakeven - strikePrice
          const priceStart = Math.min(strikePrice - segment, currentPrice * 0.9)
          const priceEnd = strikePrice
          const priceRange = priceEnd - priceStart

          newHoverCoords.highlightedPrice =
            priceStart + priceRange * traveledPercentage
        } else if (fetchedX === area.x.max) {
          // Handle infinity for Calls
          newHoverCoords = {
            ...newHoverCoords,
            x: fetchedX,
            y: area.y.min,
            above: true,
            active: true,
          }
          newHoverCoords.highlightedPrice =
            breakeven + (breakeven - strikePrice) * 10
        } else if (
          fetchedX > area.x.size / 3 &&
          fetchedX < (area.x.size / 3) * 2
        ) {
          // Handle below break even Calls
          const start = area.x.size / 3
          const end = (area.x.size / 3) * 2

          const totalDistanceX = end - start
          const traveled = fetchedX - start
          const traveledPercentage = traveled / totalDistanceX

          const modSize = area.y.max - area.y.min
          const adjustY = area.y.max - (modSize * traveledPercentage) / 2

          const priceStart = strikePrice
          const priceEnd = breakeven
          const priceRange = priceEnd - priceStart

          newHoverCoords.highlightedPrice =
            priceStart + priceRange * traveledPercentage

          newHoverCoords = {
            ...newHoverCoords,
            x: fetchedX,
            y: adjustY,
            above: false,
            active: true,
          }
        } else {
          // Handle above break even Calls
          const start = (area.x.size / 3) * 2
          const end = area.x.max

          const totalDistanceX = end - start
          const traveled = fetchedX - start
          const traveledPercentage = traveled / totalDistanceX

          const modSize = area.y.max - area.y.min
          const adjustY =
            area.y.max - (modSize * traveledPercentage) / 2 - modSize / 2

          const segment = breakeven - strikePrice
          const priceStart = breakeven
          const priceEnd = breakeven + segment * 9
          const priceRange = priceEnd - priceStart

          newHoverCoords.highlightedPrice =
            priceStart + priceRange * traveledPercentage

          newHoverCoords = {
            ...newHoverCoords,
            x: fetchedX,
            y: adjustY,
            above: true,
            active: true,
          }
        }
        setHoverCoords(newHoverCoords)
      } else {
        // Handle Puts
        if (fetchedX <= area.x.size / 3) {
          // Handle below breakeven Puts
          const start = area.x.min
          const end = area.x.size / 3

          const totalDistanceX = end - start
          const traveled = fetchedX - start
          const traveledPercentage = traveled / totalDistanceX
          const modSize = area.y.max - area.y.min
          const adjustY =
            area.y.max -
            (modSize - modSize * traveledPercentage) / 2 -
            modSize / 2

          newHoverCoords = {
            ...newHoverCoords,
            x: fetchedX,
            y: adjustY,
            above: newHoverCoords.highlightedPrice < breakeven,
            active: true,
          }
          const segment = strikePrice - breakeven
          const priceStart = Math.max(breakeven - segment * 9, 0)
          const priceEnd = breakeven
          const priceRange = priceStart - priceEnd

          newHoverCoords.highlightedPrice =
            priceStart - priceRange * traveledPercentage
        } else if (
          fetchedX >= area.x.size / 3 &&
          fetchedX < (area.x.size / 3) * 2
        ) {
          // Handle below strike Puts
          const start = area.x.min / 3
          const end = (area.x.size / 3) * 2

          const totalDistanceX = end - start
          const traveled = fetchedX - start
          const traveledPercentage = traveled / totalDistanceX
          const modSize = area.y.max - area.y.min
          const adjustY = area.y.max - (modSize - modSize * traveledPercentage)

          newHoverCoords = {
            ...newHoverCoords,
            x: fetchedX,
            y: adjustY,
            above: newHoverCoords.highlightedPrice < breakeven,
            active: true,
          }
          const priceStart = breakeven
          const priceEnd = strikePrice
          const priceRange = priceStart - priceEnd

          newHoverCoords.highlightedPrice =
            priceStart - priceRange * traveledPercentage
        } else {
          const start = (area.x.size / 3) * 2
          const end = area.x.size

          const totalDistanceX = end - start
          const traveled = fetchedX - start
          const traveledPercentage = traveled / totalDistanceX

          newHoverCoords = {
            ...newHoverCoords,
            x: fetchedX,
            y: area.y.max,
            above: false,
            active: true,
          }

          const segment = strikePrice - breakeven
          const priceStart = strikePrice
          const priceEnd = Math.max(strikePrice + segment, currentPrice * 1.1)
          const priceRange = priceStart - priceEnd

          newHoverCoords.highlightedPrice =
            priceStart - priceRange * traveledPercentage
        }

        setHoverCoords(newHoverCoords)
      }
    },
    [area, type, hoverCoords, currentPrice, breakeven, strikePrice],
  )

  const onMouseLeave = useCallback(() => {
    setHoverCoords({
      ...hoverCoords,
      highlightedPrice: currentPrice,
      above:
        type === "Call" ? currentPrice > breakeven : currentPrice < breakeven,
      active: false,
    })
  }, [hoverCoords, currentPrice, breakeven, type])

  const pnLValue = useMemo(() => {
    if (hoverCoords.active) {
      if (type === "Call") {
        if (hoverCoords.highlightedPrice < strikePrice) {
          return -premium * contractSize
        }
        return (
          (hoverCoords.highlightedPrice - strikePrice - premium) * contractSize
        )
      } else {
        if (hoverCoords.highlightedPrice > strikePrice) {
          return -premium * contractSize
        }
        return (
          (strikePrice - hoverCoords.highlightedPrice - premium) * contractSize
        )
      }
    } else {
      if (type === "Call") {
        if (currentPrice < strikePrice) {
          return -premium * contractSize
        }
        return (currentPrice - strikePrice - premium) * contractSize
      } else {
        if (currentPrice > strikePrice) {
          return -premium * contractSize
        }
        return (strikePrice - currentPrice - premium) * contractSize
      }
    }
  }, [premium, strikePrice, type, currentPrice, hoverCoords, contractSize])

  return (
    <div className={clsx(classes.root, className)}>
      <Details>
        <Details.Row
          label="Asset Price"
          value={
            hoverCoords.active
              ? hoverCoords.highlightedPrice ===
                breakeven + (breakeven - strikePrice)
                ? "unlimited"
                : formatMoney(hoverCoords.highlightedPrice)
              : formatMoney(hoverCoords.highlightedPrice)
          }
        />

        <Details.Row label="Expected PnL">
          <NumberTypography
            size="extra-small"
            weight="bold"
            value={hoverCoords.above ? 1 : -1}
          >
            {type === "Call" &&
            hoverCoords.highlightedPrice ===
              breakeven + (breakeven - strikePrice)
              ? "unlimited"
              : formatMoney(pnLValue || 0)}
          </NumberTypography>
        </Details.Row>
      </Details>
      <div className={classes.chartArea}>
        <div className={classes.markers}>
          <Typography size="extra-small" weight="bold">
            0
          </Typography>
        </div>
        <div
          ref={graphElementRef}
          style={{
            height: `${svgSettings ? svgSettings.y : 1}px`,
            width: `100%`,
            position: "relative",
          }}
        >
          <div
            className={classes.blocker}
            style={{
              height: `${svgSettings ? svgSettings.y : 1}px`,
              width: `${svgSettings ? `${svgSettings.x}px` : "100%"}`,
              position: "absolute",
              top: 0,
              left: 0,
            }}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          />
          {area && svgSettings && (
            <svg
              className={classes.svgRoot}
              height={svgSettings.y}
              width={svgSettings.x}
              viewBox={`0 0 ${svgSettings.x} ${svgSettings.y}`}
            >
              <path
                style={{
                  stroke: "transparent",
                  strokeWidth: 0,
                  fill: alpha(palette.colors.green, 0.1),
                }}
                d={svgElements.profitBackgroundPath}
              />
              <path
                style={{
                  stroke: "transparent",
                  strokeWidth: 0,
                  fill: alpha(palette.colors.red, 0.1),
                }}
                d={svgElements.lossBackgroundPath}
              />
              <line
                x1={hoverCoords.x}
                y1="0"
                x2={hoverCoords.x}
                y2={svgSettings.y}
                style={{
                  stroke: "rgb(255,255,255)",
                  strokeWidth: hoverCoords.active ? 1 : 0,
                  strokeDasharray: 3,
                }}
              />
              <path
                style={{
                  stroke: palette.colors.red,
                  strokeWidth: svgSettings.strokeWidth,
                  fill: "none",
                }}
                d={svgElements.lossPath}
              />
              <path
                style={{
                  stroke: palette.colors.green,
                  strokeWidth: svgSettings.strokeWidth,
                  fill: "none",
                }}
                d={svgElements.profitPath}
              />
              <circle
                cx={svgElements.breakevenCoords.x}
                cy={svgElements.breakevenCoords.y}
                r={svgSettings.circleRadius}
                stroke="transparent"
                fill="white"
              />
              <circle
                cx={svgElements.strikeCoords.x}
                cy={svgElements.strikeCoords.y}
                r={svgSettings.circleRadius}
                stroke="transparent"
                fill={alpha(palette.colors.red, 0.1)}
              />
              <circle
                className={clsx(classes.hoverDot, "hover-dot")}
                cx={hoverCoords.x}
                cy={hoverCoords.y}
                r={hoverCoords.active ? svgSettings.circleRadius : 0}
                fill={
                  hoverCoords.above ? palette.colors.green : palette.colors.red
                }
              />
            </svg>
          )}
        </div>
      </div>
    </div>
  )
}

export default PnLChart
