import {
  Divider,
  Link,
  Skeleton,
  SvgIcon,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useMemo } from "react"
import BigNumber from "bignumber.js"
import { useWeb3 } from "@chainsafe/web3-context"

import {
  Details,
  HStack,
  InfoPopover,
  MainLayout,
  Typography,
  VStack,
} from "../../../common"
import { ReactComponent as CardsViewIcon } from "../../../../media/CardsView.svg"
import { ReactComponent as TableViewIcon } from "../../../../media/TableView.svg"
import { EarnCards } from "./EarnCards"
import { EarnList } from "./EarnList"
import { useMedia } from "../../../../Hooks/useMedia"
import { useLayoutType, LayoutType } from "../../../../Hooks/useLayoutType"

import { useLanguageContext } from "../../../../Contexts/LanguageContext"
import CashOutSvg from "../../../icons/CashOutSvg"
import {
  useSirenRewards,
  usePools,
} from "../../../../Contexts/SirenMarketsContext"

const useStyles = makeStyles<Theme>(({ palette }) => ({
  title1: {
    background: palette.colors.earnBackgroundTitle1,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  },

  title2: {
    background: palette.colors.earnBackgroundTitle2,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  },

  toggleButton: {
    padding: 0,
    width: 40,

    "& svg": {
      width: 20,
      height: 20,
    },
  },
}))

export const EarnPage = () => {
  const classes = useStyles()

  const { layoutType, setLayoutType } = useLayoutType()

  const { isReady } = useWeb3()
  const { mobile } = useMedia()

  const { sirenRewards, sirenRewardsFetched } = useSirenRewards()
  const { pools, poolsOnchainFetched } = usePools()
  const { formatMoney, formatCrypto } = useLanguageContext()

  const activePools = useMemo(
    () =>
      pools
        .filter((p) => p.isActive)
        .sort((a, b) => b.yieldSinceInception - a.yieldSinceInception),
    [pools],
  )

  const totalTvl = useMemo(
    () =>
      new BigNumber(
        activePools.reduce(
          (totalTVL, pool) =>
            pool.collateralTokenSymbol.toLowerCase() === "usdc"
              ? (totalTVL += pool.poolValueLocked)
              : (totalTVL += pool.poolValueLocked * pool.exchangeRate),
          0,
        ),
      ).toNumber(),
    [activePools],
  )

  const cashOutLink = (
    <Link
      variant="action"
      underline="none"
      {...(sirenRewards.claimableRewards <= 0
        ? { component: "button", disabled: true }
        : {
            href: "https://v1.sirenmarkets.com/pool",
            target: "_blank",
            rel: "noreferrer noopener",
          })}
    >
      <HStack alignItems="center" space={0.5}>
        <SvgIcon component={CashOutSvg} fontSize="small" />
        <Typography size="extra-small" weight="medium">
          Cash out
        </Typography>
      </HStack>
    </Link>
  )

  const isShowingClaim =
    isReady &&
    sirenRewardsFetched &&
    sirenRewards.totalRewards > 0 &&
    sirenRewards.claimableRewards > 0

  return (
    <MainLayout.Content className={classes.root} alignItems="center">
      <VStack
        width="100%"
        maxWidth="1140px"
        justifySelf="center"
        space={mobile ? 4 : 7.5}
        mt={mobile ? 0 : 6}
        px={mobile ? 2 : 0}
      >
        <Typography
          variant={mobile ? "h4" : "h2"}
          color="colors.textSecondary"
          mb="16px !important"
        >
          Supply liquidity to options trading pools.
        </Typography>
        <Typography variant="display2">
          Earn yield from{" "}
          <span className={classes.title1}>
            option {mobile && <br />}
            premiums
          </span>
          {!mobile && <br />}
          <span>{mobile && " "}and</span>{" "}
          <span className={classes.title2}>trading fees.</span>
        </Typography>

        {mobile && (
          <Details variant="bordered">
            <Details.Row label="TVL" value={formatMoney(totalTvl)} />

            {isShowingClaim && (
              <>
                <Details.Row>
                  <InfoPopover text="Total amount of rewards earned">
                    <Typography size="extra-small" color="textSecondary">
                      Rewards earned
                    </Typography>
                  </InfoPopover>

                  <Typography size="extra-small" weight="bold">
                    {formatCrypto(sirenRewards.totalRewards, "SI")}
                  </Typography>
                </Details.Row>
                <Details.Row label="Available to Claim">
                  <HStack alignItems="center" space={1}>
                    <Typography size="small" weight="bold">
                      {formatCrypto(sirenRewards.claimableRewards, "SI")}
                    </Typography>

                    {cashOutLink}
                  </HStack>
                </Details.Row>
              </>
            )}
          </Details>
        )}

        <HStack justifyContent="space-between">
          {mobile ? (
            <div />
          ) : (
            <HStack>
              <VStack space={0.5}>
                <Typography size="extra-small" color="colors.textSecondary">
                  TVL
                </Typography>
                <Typography variant="h2">
                  {poolsOnchainFetched ? (
                    formatMoney(totalTvl)
                  ) : (
                    <Skeleton variant="text" />
                  )}
                </Typography>
              </VStack>

              {isShowingClaim && (
                <>
                  <Divider orientation="vertical" />

                  <VStack space={0.5}>
                    <Typography size="extra-small" color="colors.textSecondary">
                      Rewards Earned
                    </Typography>
                    <Typography variant="h2">
                      {sirenRewardsFetched ? (
                        formatCrypto(sirenRewards.totalRewards, "SI")
                      ) : (
                        <Skeleton variant="text" />
                      )}
                    </Typography>
                  </VStack>

                  <Divider orientation="vertical" />

                  <VStack space={0.5}>
                    <Typography size="extra-small" color="colors.textSecondary">
                      Available to Claim
                    </Typography>

                    <HStack alignItems="center" space={1}>
                      <Typography variant="h2">
                        {sirenRewardsFetched ? (
                          formatCrypto(sirenRewards.claimableRewards, "SI")
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </Typography>

                      {cashOutLink}
                    </HStack>
                  </VStack>
                </>
              )}
            </HStack>
          )}

          <ToggleButtonGroup
            value={layoutType}
            onChange={(_, value: LayoutType) => value && setLayoutType(value)}
            exclusive
          >
            <ToggleButton
              className={classes.toggleButton}
              value={LayoutType.Cards}
            >
              <SvgIcon viewBox="0 0 20 20" component={CardsViewIcon} />
            </ToggleButton>
            <ToggleButton
              className={classes.toggleButton}
              value={LayoutType.Table}
            >
              <SvgIcon viewBox="0 0 20 20" component={TableViewIcon} />
            </ToggleButton>
          </ToggleButtonGroup>
        </HStack>

        {layoutType === LayoutType.Cards ? <EarnCards /> : <EarnList />}
      </VStack>
    </MainLayout.Content>
  )
}
