import { useWeb3 } from "@chainsafe/web3-context"
import { Close, ContentCopy } from "@mui/icons-material"
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  SvgIcon,
  Theme,
  useMediaQuery,
} from "@mui/material"
import { alpha, useTheme } from "@mui/material/styles"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"
import React, { useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useSirenMarketsContext } from "../Contexts/SirenMarketsContext"
import { formatAddress } from "../helpers/ethAddressFormatting"
import { HStack, Typography, VStack } from "./common"
import ConnectWalletSvg from "./icons/ConnectWalletSvg"
import DiscordLogoSvg from "./icons/DiscordLogoSvg"
import HelpSvg from "./icons/HelpSvg"
import PoolSvg from "./icons/PoolSvg"
import PortfolioSvg from "./icons/PortfolioSvg"
import TradeSvg from "./icons/TradeSvg"
import { ThemeSwitcher } from "./ThemeSwitcher"

const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  navItemText: {
    fontSize: 16,
    fontWeight: 500,
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    paddingTop: 20,
    paddingRight: 30,
  },
  closeIcon: {
    color: palette.colors.icon,
  },
  drawerOpen: {
    width: "100vw",
    borderLeft: "none",
  },
  drawerPaper: {
    backgroundColor: alpha(palette.colors.background3, 0.7),
    alignItems: "center",
    textAlign: "center",
    color: palette.colors.textTertiary,
    padding: 0,
  },
  drawerNavItem: {
    background: "transparent",
    border: "none",

    "&:hover": {
      background: "transparent",
    },

    "& > div > svg": {
      fill: palette.colors.textTertiary,
    },
  },
  drawerNavItemSelected: {
    border: "none !important",
    backgroundColor: `transparent !important`,
    color: palette.colors.primary,
    "& > div > svg": {
      fill: palette.colors.primary,
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: palette.colors.primary,
      "& > div > svg": {
        fill: palette.colors.primary,
      },
    },
  },
  drawerDivider: {
    width: "100%",
    marginTop: spacing(4),
    marginBottom: spacing(4),
  },
  navListItemIcon: {
    minWidth: 0,
    paddingRight: spacing(1.5),
  },
  support: {
    color: palette.colors.support,
  },
  menuList: {
    backgroundColor: palette.colors.background4,
    border: `1px solid ${palette.colors.stroke}`,
    padding: 0,
    borderRadius: 10,
    overflow: "hidden",
    minWidth: 260,
  },
  menuItemWallet: {
    borderRadius: 0,
    backgroundColor: palette.colors.background2,
  },
}))

const AppWrapper = ({
  address,
  drawerOpen,
  handleDrawerClose,
  handleDisconnect,
}: {
  address?: string
  drawerOpen: boolean
  handleDrawerClose: VoidFunction
  handleDisconnect: VoidFunction
}) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { pathname } = useLocation()
  const { isReady } = useWeb3()

  const { handleSelectWalletConnect } = useSirenMarketsContext()

  const handleNavigate = (path: string) => {
    navigate(path)
    !desktop && handleDrawerClose()
  }

  const { breakpoints } = useTheme()

  const desktop = useMediaQuery(breakpoints.up("md"))

  const handleCopyAddress = useCallback(() => {
    if (address) {
      navigator.clipboard.writeText(address)
    }
  }, [address])

  return (
    <Drawer
      className={clsx({
        [classes.drawerOpen]: drawerOpen,
      })}
      variant="persistent"
      anchor="right"
      open={drawerOpen}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: drawerOpen,
        }),
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose} size="small">
          <Close className={classes.closeIcon} />
        </IconButton>
      </div>
      <List>
        <ListItem
          classes={{
            root: classes.drawerNavItem,
            selected: classes.drawerNavItemSelected,
          }}
          selected={pathname === "/earn"}
          onClick={() => handleNavigate("/earn")}
        >
          <ListItemIcon className={classes.navListItemIcon}>
            <SvgIcon component={PoolSvg} viewBox="0 0 16 19" />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.navItemText} color="inherit">
              Earn
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem
          selected={pathname.includes("/trade")}
          onClick={() => handleNavigate("/trade")}
          classes={{
            root: classes.drawerNavItem,
            selected: classes.drawerNavItemSelected,
          }}
        >
          <ListItemIcon className={classes.navListItemIcon}>
            <SvgIcon component={TradeSvg} viewBox="0 0 20 12" />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.navItemText} color="inherit">
              Trade
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem
          classes={{
            root: classes.drawerNavItem,
            selected: classes.drawerNavItemSelected,
          }}
          selected={pathname.includes("/portfolio")}
          onClick={() => handleNavigate("/portfolio")}
        >
          <ListItemIcon className={classes.navListItemIcon}>
            <SvgIcon component={PortfolioSvg} viewBox="0 0 20 18" />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.navItemText} color="inherit">
              Portfolio
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem
          className={classes.drawerNavItem}
          onClick={() => window.open("https://docs.siren.xyz", "blank")}
        >
          <ListItemIcon className={classes.navListItemIcon}>
            <SvgIcon component={HelpSvg} viewBox="0 0 20 20" />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.navItemText} color="inherit">
              Docs
            </Typography>
          </ListItemText>
        </ListItem>

        <ListItem
          className={clsx(classes.drawerNavItem, classes.support)}
          onClick={() => window.open("https://discord.gg/3j5pr4qWq8")}
        >
          <ListItemIcon
            className={clsx(classes.navListItemIcon, classes.support)}
          >
            <SvgIcon component={DiscordLogoSvg} viewBox="0 0 22 16" />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.navItemText} color="inherit">
              Support
            </Typography>
          </ListItemText>
        </ListItem>

        <ListItem className={classes.drawerNavItem}>
          <Box ml={-1.5}>
            <ThemeSwitcher />
          </Box>
        </ListItem>
      </List>

      <Divider className={classes.drawerDivider} />

      {isReady ? (
        <MenuList className={classes.menuList}>
          <MenuItem className={classes.menuItemWallet}>
            <HStack color="colors.green" alignItems="center" flex={1}>
              <SvgIcon component={ConnectWalletSvg} viewBox="0 0 22 20" />
              <Typography size="medium" weight="medium" color="textPrimary">
                {!!address && formatAddress(address)}
              </Typography>
            </HStack>
          </MenuItem>
          <MenuItem onClick={handleCopyAddress}>
            <HStack alignItems="center" justifyContent="space-between" flex={1}>
              <Typography size="medium" weight="medium" color="textPrimary">
                Copy Address
              </Typography>
              <SvgIcon component={ContentCopy} />
            </HStack>
          </MenuItem>
          <MenuItem onClick={handleDisconnect}>
            <HStack alignItems="center" justifyContent="space-between" flex={1}>
              <Typography size="medium" weight="medium" color="error">
                Disconnect
              </Typography>
              <SvgIcon component={Close} color="error" />
            </HStack>
          </MenuItem>
        </MenuList>
      ) : (
        <VStack px={4}>
          <Typography variant="h2" color="textPrimary">
            Let’s get started 👋
          </Typography>
          <Typography size="small" color="textSecondary">
            Connect your wallet to make your first siren trade
          </Typography>
          <Button variant="primary" onClick={handleSelectWalletConnect}>
            Connect Wallet
          </Button>
        </VStack>
      )}

      <Typography mt="auto" mb={2}>
        © 2022 Siren Markets
      </Typography>
    </Drawer>
  )
}

export default AppWrapper
