import React from "react"

interface ISunSvg {
  className?: string
}

const SunSvg = ({ className }: ISunSvg) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.33333C6.52724 5.33333 5.33333 6.52724 5.33333 8C5.33333 9.47276 6.52724 10.6667 8 10.6667C9.47276 10.6667 10.6667 9.47276 10.6667 8C10.6667 6.52724 9.47276 5.33333 8 5.33333ZM4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C8.36819 0 8.66667 0.298477 8.66667 0.666667V2C8.66667 2.36819 8.36819 2.66667 8 2.66667C7.63181 2.66667 7.33333 2.36819 7.33333 2V0.666667C7.33333 0.298477 7.63181 0 8 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13.3333C8.36819 13.3333 8.66667 13.6318 8.66667 14V15.3333C8.66667 15.7015 8.36819 16 8 16C7.63181 16 7.33333 15.7015 7.33333 15.3333V14C7.33333 13.6318 7.63181 13.3333 8 13.3333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.34193 2.34193C2.60228 2.08158 3.02439 2.08158 3.28474 2.34193L4.2314 3.2886C4.49175 3.54895 4.49175 3.97106 4.2314 4.2314C3.97106 4.49175 3.54895 4.49175 3.2886 4.2314L2.34193 3.28474C2.08158 3.02439 2.08158 2.60228 2.34193 2.34193Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7686 11.7686C12.0289 11.5082 12.4511 11.5082 12.7114 11.7686L13.6581 12.7153C13.9184 12.9756 13.9184 13.3977 13.6581 13.6581C13.3977 13.9184 12.9756 13.9184 12.7153 13.6581L11.7686 12.7114C11.5082 12.4511 11.5082 12.0289 11.7686 11.7686Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8C0 7.63181 0.298477 7.33333 0.666667 7.33333H2C2.36819 7.33333 2.66667 7.63181 2.66667 8C2.66667 8.36819 2.36819 8.66667 2 8.66667H0.666667C0.298477 8.66667 0 8.36819 0 8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 8C13.3333 7.63181 13.6318 7.33333 14 7.33333H15.3333C15.7015 7.33333 16 7.63181 16 8C16 8.36819 15.7015 8.66667 15.3333 8.66667H14C13.6318 8.66667 13.3333 8.36819 13.3333 8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.2314 11.7686C4.49175 12.0289 4.49175 12.4511 4.2314 12.7114L3.28474 13.6581C3.02439 13.9184 2.60228 13.9184 2.34193 13.6581C2.08158 13.3977 2.08158 12.9756 2.34193 12.7153L3.2886 11.7686C3.54895 11.5082 3.97106 11.5082 4.2314 11.7686Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.658 2.34175C13.9183 2.6021 13.9183 3.02421 13.658 3.28456L12.7113 4.23122C12.451 4.49157 12.0289 4.49157 11.7685 4.23122C11.5082 3.97087 11.5082 3.54876 11.7685 3.28841L12.7152 2.34175C12.9755 2.0814 13.3976 2.0814 13.658 2.34175Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SunSvg
