import { useMediaQuery, useTheme } from "@mui/material"
import { useCallback } from "react"

export const useMedia = () => {
  const { breakpoints } = useTheme()

  const largeDesktop = useMediaQuery(breakpoints.up("lg"))
  const desktop = useMediaQuery(breakpoints.up("md"))
  const mobile = useMediaQuery(breakpoints.down("sm"))
  const tablet = useMediaQuery(breakpoints.down("md"))

  const mediaSwitch: <T>(
    desktopValue: T,
    mobileValue: T,
    tabletValue?: T,
  ) => T = useCallback(
    (desktopValue, mobileValue, tabletValue) => {
      if (desktop) {
        return desktopValue
      }

      if (mobile) {
        return mobileValue
      }

      return tabletValue ?? desktopValue
    },
    [desktop, mobile],
  )

  return { largeDesktop, desktop, mobile, tablet, mediaSwitch }
}
