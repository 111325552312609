import { Button } from "@mui/material"

import { find } from "lodash"
import React, { useCallback, useMemo, useState, VFC } from "react"
import { useNavigate, useParams } from "react-router-dom"

import {
  useLockedLiquidity,
  usePools,
} from "../../../Contexts/SirenMarketsContext"

import {
  CheckNetworkButton,
  Details,
  HStack,
  Side,
  SkeletonGrid,
  TokenAvatar,
  TxFailure,
  TxInProgress,
  TxSuccess,
  Typography,
} from "../../common"

enum TxStatus {
  Init = "init",
  InProgress = "inProgress",
  Success = "success",
  Error = "error",
}

const PortfolioLiquiditySide: VFC = () => {
  const navigate = useNavigate()

  const { id } = useParams<{ id?: string }>()

  const { lockedLiquidity } = useLockedLiquidity()
  const { withdrawLockedCollateral } = usePools()

  const [txStatus, setTxStatus] = useState<TxStatus>(TxStatus.Init)

  const currentLockedLiquidity = useMemo(
    () => find(lockedLiquidity, { id }),
    [lockedLiquidity, id],
  )

  const onSubmit = useCallback(async () => {
    if (!currentLockedLiquidity) {
      setTxStatus(TxStatus.Error)

      return
    }

    setTxStatus(TxStatus.InProgress)

    try {
      await withdrawLockedCollateral(
        currentLockedLiquidity.expirationDates,
        currentLockedLiquidity.ammId,
      )
    } catch (error) {
      console.log(error)
      setTxStatus(TxStatus.Error)

      return
    }

    setTxStatus(TxStatus.Success)
  }, [setTxStatus, currentLockedLiquidity, withdrawLockedCollateral])

  const onClose = useCallback(() => {
    navigate("/portfolio/liquidity", { replace: true })

    setTxStatus(TxStatus.Init)
  }, [navigate])

  return (
    <Side open={!!id} onClose={onClose}>
      {id === undefined || !currentLockedLiquidity ? (
        <Side.Content space={3}>
          <Typography variant="h3">Select an asset</Typography>
          <Typography size="small" color="textSecondary">
            To request a cash-out, select an asset from the table on the left
          </Typography>
          <SkeletonGrid columns={6} animation={false}>
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
          </SkeletonGrid>
        </Side.Content>
      ) : txStatus === TxStatus.Init ? (
        <>
          <Side.Content space={3}>
            <Typography variant="h3">Cash-out</Typography>

            <Details mb={2} variant="filled">
              <Details.Row label="Asset">
                <HStack space={1} alignItems="center">
                  <TokenAvatar
                    tokenSymbol={currentLockedLiquidity.collateralSymbol}
                    size={20}
                  />

                  <Typography size="small" weight="bold">
                    {currentLockedLiquidity.collateralSymbol}
                  </Typography>
                </HStack>
              </Details.Row>

              <Details.Row
                label="Available for cash-out"
                value={currentLockedLiquidity.availableForCashOut.toFixed(4)}
              />
            </Details>
          </Side.Content>
          <Side.Action>
            <CheckNetworkButton>
              <Button
                variant="primary"
                onClick={onSubmit}
                disabled={currentLockedLiquidity.availableForCashOut === 0}
                fullWidth
              >
                Cash-out
              </Button>
            </CheckNetworkButton>
          </Side.Action>
        </>
      ) : txStatus === TxStatus.InProgress ? (
        <TxInProgress message="Cashing out in process" />
      ) : txStatus === TxStatus.Success ? (
        <TxSuccess
          message="Assets has been cash-out to your wallet"
          buttonMessage="Close"
          onConfirm={onClose}
        >
          <Details mb={2}>
            <Details.Row label="Asset">
              <HStack space={1} alignItems="center">
                <TokenAvatar
                  tokenSymbol={currentLockedLiquidity.collateralSymbol}
                  size={20}
                />

                <Typography size="small" weight="bold">
                  {currentLockedLiquidity.collateralSymbol}
                </Typography>
              </HStack>
            </Details.Row>

            <Details.Row label="Amount">
              <Typography size="small" weight="bold">
                {currentLockedLiquidity.availableForCashOut.toFixed(4)}
              </Typography>
            </Details.Row>
          </Details>
        </TxSuccess>
      ) : (
        <TxFailure clearSelection={onClose} />
      )}
    </Side>
  )
}

export default PortfolioLiquiditySide
