import { useMemo, useState } from "react"
import { useQuery } from "@tanstack/react-query"

import { ChartPeriod, DAYS_BY_CHART_PERIOD } from "../constants"

export type ChartDataResponse = {
  prices: Array<number[]>
  market_caps: Array<number[]>
  total_volumes: Array<number[]>
}

const COINS_BY_TOKEN: { [key: string]: string } = {
  WBTC: "wrapped-bitcoin",
  USDC: "usd-coin",
  BAT: "basic-attention-token",
  YFI: "yearn-finance",
  UNI: "uniswap",
  SUSHI: "sushi",
  WETH: "weth",
  DPI: "defipulse-index",
  KNC: "kyber-network-crystal",
  WMATIC: "wmatic",
}

export const useChartData = (token: string) => {
  const [period, setPeriod] = useState<ChartPeriod>(ChartPeriod["24H"])

  const coin = COINS_BY_TOKEN[token]
  const enabled = typeof coin === "string"

  const { isLoading, error, data } = useQuery<ChartDataResponse>(
    ["ChartData", coin, period],
    () =>
      fetch(
        `https://api.coingecko.com/api/v3/coins/${coin}/market_chart?vs_currency=usd&days=${DAYS_BY_CHART_PERIOD[period]}`,
      ).then((res) => res.json()),
    {
      enabled,
    },
  )

  const chartData = useMemo(
    () =>
      data
        ? data.prices.map(([timestamp, value]) => ({
            time: timestamp / 1000,
            value,
          }))
        : undefined,
    [data],
  )

  return {
    isLoading,
    error,
    data: chartData,
    setPeriod,
    period,
    enabled,
  }
}
