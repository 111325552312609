import { AddChainParams } from "@chainsafe/web3-context/dist/context/Web3Context"

type SirenChainConfig = {
  networkName: string
  rpcUrl: string
  subgraphUrl: string
  addressesProvider: string
  sirenExchangeAddress?: string
  erc20Tokens?: string[]
  addChainParams?: Omit<AddChainParams, "chainId" | "chainName">
  icon: string
}

type SirenConfig = {
  [chainId: number]: SirenChainConfig
}

const infuraId = process.env.REACT_APP_INFURA_KEY

const config: SirenConfig = {
  137: {
    networkName: "Polygon",
    rpcUrl: `https://polygon-mainnet.infura.io/v3/${infuraId}`,
    subgraphUrl:
      "https://api.thegraph.com/subgraphs/name/sirenmarkets/protocol-v2-matic",
    addressesProvider: "0xeca3e062a073daff795bdae97c14d602931d4233",
    sirenExchangeAddress: "0x18675d28c44f75210a31805b5130072e9b228aed",
    erc20Tokens: [
      "0xda537104d6a5edd53c6fbba9a898708e465260b6", // YFI
      "0x2791bca1f2de4661ed88a30c99a7a9449aa84174", // USDC
      "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270", // WMATIC
      "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619", // WETH
      "0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a", // SUSHI
      "0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c", // KNC
      "0xb33eaad8d922b1083446dc23f610c2567fb5180f", // UNI
      "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6", // WBTC
      "0x85955046df4668e1dd369d2de9f3aeb98dd2a369", // DPI
      "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063", // DAI
    ],
    addChainParams: {
      rpcUrls: ["https://polygon-rpc.com"],
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      blockExplorerUrls: ["https://polygonscan.com"],
    },
    icon: "WMATIC",
  },
  80001: {
    networkName: "Mumbai",
    rpcUrl: `https://polygon-mumbai.infura.io/v3/${infuraId}`,
    subgraphUrl:
      "https://api.thegraph.com/subgraphs/name/sirenmarkets/protocol-v2-mumbai",
    addressesProvider: "0x264c7e8fdd91acfaefafd3da677e7d7881795d2a",
    sirenExchangeAddress: "0x490a954da31e6743aae34d515545f0c2cd00f3b1",
    erc20Tokens: [
      "0x78fd7068d0ddd7d70c334d8624f2e42cb86c7b45", // WETH
      "0x57191aaa545ed69412dfb750e99808f1b40a7110", // USDC
      "0x05b773ccd189ed4904a8e7c432ce6cb99d540fd5", // WBTC
    ],
    addChainParams: {
      rpcUrls: ["https://rpc-mumbai.matic.today"],
      nativeCurrency: {
        name: "Mumbai MATIC",
        symbol: "mMATIC",
        decimals: 18,
      },
      blockExplorerUrls: ["https://mumbai.polygonscan.com"],
    },
    icon: "WMATIC",
  },
}

const disabledChainIds =
  process.env.REACT_APP_DIASABLED_CHAINIDS?.split(",").map((c) => Number(c)) ||
  []

export type { SirenConfig, SirenChainConfig }
export default config
export { disabledChainIds }
