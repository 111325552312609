import React from "react"
import { createRoot } from "react-dom/client"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import TagManager from "react-gtm-module"

const gtmId = process.env.REACT_APP_GTM_ID

if (!!gtmId) {
  const tagManagerArgs = {
    gtmId: gtmId,
  }

  TagManager.initialize(tagManagerArgs)
}

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
