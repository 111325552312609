import React from "react"
import { Route, Routes } from "react-router-dom"

import { EarnPage } from "./root/EarnPage"
import { EarnDetailsPage } from "./details/EarnDetailsPage"

export const EarnPages = () => (
  <Routes>
    <Route path="/" element={<EarnPage />} />
    <Route path="/:address" element={<EarnDetailsPage />} />
  </Routes>
)
