import React from "react"

import { PaletteType } from "../theme"
import {
  FullLogoDarkSvg,
  FullLogoLightSvg,
  LogoDarkSvg,
  LogoLightSvg,
} from "./logos"

type LogoProps = {
  paletteType: PaletteType
  isFull: boolean
}

export const Logo = ({ paletteType, isFull }: LogoProps) => {
  if (isFull) {
    return paletteType === PaletteType.Light ? (
      <FullLogoLightSvg />
    ) : (
      <FullLogoDarkSvg />
    )
  }

  return paletteType === PaletteType.Light ? <LogoLightSvg /> : <LogoDarkSvg />
}
