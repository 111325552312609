import { useWeb3 } from "@chainsafe/web3-context"
import { Close } from "@mui/icons-material"
import {
  Button,
  IconButton,
  SvgIcon,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { BigNumber } from "bignumber.js"
import { utils } from "ethers"
import { debounce } from "lodash"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useLanguageContext } from "../../../Contexts/LanguageContext"
import {
  Series,
  usePermissions,
  useSeries,
} from "../../../Contexts/SirenMarketsContext"
import { numericRegex } from "../../../helpers/numericRegex"
import ChevronsDownSvg from "../../icons/ChevronsDownSvg"
import ChevronsUpSvg from "../../icons/ChevronsUpSvg"
import PnLChart from "./PnLChart"

import {
  CheckNetworkButton,
  Details,
  EqualsSign,
  HStack,
  InfoPopover,
  LiFiBridgeButton,
  NumericInput,
  Side,
  TxFailure,
  TxInProgress,
  TxSuccess,
  Typography,
  VStack,
} from "../../common"

const useStyles = makeStyles<Theme>(({ palette, breakpoints, spacing }) => ({
  closeButton: {
    right: "20px",
    width: 32,
    height: 32,
    position: "absolute",
    borderRadius: 16,
    backgroundColor: palette.colors.background2,
    padding: 0,
  },
  caption: {
    paddingLeft: 40,
    paddingRight: 40,

    [breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  pnlChart: {
    marginTop: 0,
    marginBottom: spacing(3),
    maxWidth: "unset",
    left: "50%",
    position: "relative",
    transform: "translate(-50%)",
    width: `calc(100% + ${spacing(8)})`, // Paper margins with borders
  },
  exclamationIcon: {
    marginLeft: 8,
    fill: palette.common.white,
  },
  expandIcon: {
    padding: 0,

    "& svg": {
      fill: palette.colors.textSecondary,
    },
  },
  breakEven: {
    cursor: "pointer",
    color: palette.colors.textSecondary,
    userSelect: "none",

    "&:hover": {
      color: palette.colors.textPrimary,
    },

    "&:active": {
      color: palette.colors.textSecondary,
    },
  },

  breakEvenText: {
    position: "relative",
    display: "inline-flex",

    "&:after": {
      content: '" "',
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: 0,
      borderBottom: `1px dashed`,
    },
  },
}))

const BuyOptionDialog: React.FC<{ series: Series; clearSelection(): void }> = ({
  series,
  clearSelection,
}) => {
  const navigate = useNavigate()
  const { formatShortLocaleDate } = useLanguageContext()

  const classes = useStyles()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { tokens } = useWeb3()
  const [contractSize, setContractSize] = useState("1")
  const [collateralAmount, setCollateralAmount] = useState(new BigNumber(0))
  const [premiumAmount, setPremiumAmount] = useState(new BigNumber(0))
  const [breakEven, setBreakEven] = useState(new BigNumber(0))
  const [priceImpact, setPriceImpact] = useState(new BigNumber(0))
  const { quoteBuyOption, buyOption } = useSeries()

  const [txStatus, setTxStatus] = useState<
    "init" | "inProgress" | "success" | "error"
  >("init")
  const [isQuoting, setIsQuoting] = useState(false)

  const [showGraph, setShowGraph] = useState(true)

  const { canTradeOptions, permissionsFetched } = usePermissions()

  const selectedPaymentToken = series.collateralTokenAddress

  const paymentTokenBalance =
    tokens[selectedPaymentToken]?.balanceBN || new BigNumber(0)

  const getQuote = useMemo(
    () =>
      debounce(
        async (quoteSize: BigNumber, series: Series) => {
          if (quoteSize.gt(0)) {
            setIsQuoting(true)

            const premiumInCollateralTokens = await quoteBuyOption(
              quoteSize,
              series.seriesId,
            )

            if (!premiumInCollateralTokens) {
              return
            }

            setCollateralAmount(premiumInCollateralTokens)
            setPremiumAmount(premiumInCollateralTokens)

            const newBreakEven = premiumInCollateralTokens
              ? series.type === "Call"
                ? premiumInCollateralTokens
                    .multipliedBy(series.paymentPerUnderlying)
                    .dividedBy(quoteSize)
                    .plus(series.strike)
                : new BigNumber(series.strike).minus(
                    premiumInCollateralTokens.dividedBy(quoteSize),
                  )
              : series.type === "Call"
              ? new BigNumber(series.premium)
                  .multipliedBy(series.paymentPerUnderlying)
                  .plus(series.strike)
              : new BigNumber(series.strike).minus(
                  series.premium / series.paymentPerUnderlying,
                )

            const newPriceImpact = premiumInCollateralTokens
              ? series.type === "Call"
                ? premiumInCollateralTokens
                    .dividedBy(quoteSize)
                    .minus(series.premium)
                    .dividedBy(series.premium)
                    .multipliedBy(100)
                    .decimalPlaces(2)
                : premiumInCollateralTokens
                    .dividedBy(series.paymentPerUnderlying)
                    .dividedBy(quoteSize)
                    .minus(series.premium)
                    .dividedBy(series.premium)
                    .multipliedBy(100)
                    .decimalPlaces(2)
              : new BigNumber(0)

            setBreakEven(newBreakEven)
            setPriceImpact(
              newPriceImpact.gt(0) ? newPriceImpact : new BigNumber(0),
            )
            setIsQuoting(false)
            inputRef.current && inputRef.current.focus()
          } else {
            setPremiumAmount(new BigNumber(0))
            setBreakEven(
              series.type === "Call"
                ? new BigNumber(series.premium)
                    .multipliedBy(series.paymentPerUnderlying)
                    .plus(series.strike)
                : new BigNumber(series.strike).minus(
                    series.premium / series.paymentPerUnderlying,
                  ),
            )
            setPriceImpact(new BigNumber(0))
          }
        },
        500,
        { trailing: true },
      ),
    [quoteBuyOption],
  )

  useEffect(() => {
    if (
      contractSize &&
      contractSize !== "" &&
      numericRegex.test(contractSize)
    ) {
      getQuote(new BigNumber(contractSize), series)
    }
  }, [contractSize, series, getQuote])

  useEffect(() => {
    if (txStatus === "success") {
      setTxStatus("init")
    }
    // eslint-disable-next-line
  }, [series])

  const handleBuyOption = async () => {
    setTxStatus("inProgress")
    try {
      await buyOption(
        series.seriesId,
        new BigNumber(contractSize),
        premiumAmount,
        selectedPaymentToken,
      )
      setTxStatus("success")
    } catch (error) {
      console.log(error)
      setTxStatus("error")
    }
  }

  const minTradeSize = new BigNumber(1000)
    .shiftedBy(-series.collateralTokenDecimals)
    .toNumber()
  const { breakpoints } = useTheme()
  const desktop = useMediaQuery(breakpoints.up("md"))

  if (txStatus === "inProgress") {
    return <TxInProgress message="Minting your option contracts..." />
  } else if (txStatus === "success") {
    return (
      <TxSuccess
        message="Your option trade has been added to your portfolio."
        buttonMessage="View position"
        twitterMessage="Another options trade in the deep sea with @sirenprotocol 🧜 Unleash the leveraged #DeFi power!"
        onConfirm={() => navigate("/portfolio/positions")}
      />
    )
  } else if (txStatus === "error") {
    return <TxFailure clearSelection={clearSelection} />
  }

  const balance = utils.commify(paymentTokenBalance.decimalPlaces(4).toString())

  const selectedPaymentTokenSymbol = tokens[selectedPaymentToken]?.symbol

  return (
    <>
      <Side.Content space={0}>
        {txStatus === "init" && (
          <VStack>
            <Typography variant="h3">Buying a {series.type} Option</Typography>

            <Details>
              <Details.Row
                label="Strike Price"
                value={`$${utils.commify(series.strike)}`}
              />
              <Details.Row
                label="Expiration"
                value={formatShortLocaleDate(series.expiration)}
              />
              <Details.Row>
                <InfoPopover text="American vs European">
                  <Typography size="extra-small" color="textSecondary">
                    Style
                  </Typography>
                </InfoPopover>

                <Typography size="extra-small" weight="bold">
                  {series.seriesStyle}
                </Typography>
              </Details.Row>
            </Details>

            <VStack space={1}>
              <NumericInput
                type="number"
                min={0}
                value={contractSize}
                onChange={setContractSize}
                label="# of Contracts"
                errorMessage={
                  new BigNumber(premiumAmount).gt(paymentTokenBalance)
                    ? "Not enough tokens"
                    : undefined
                }
                ref={inputRef}
                size="large"
              />

              <LiFiBridgeButton
                balance={paymentTokenBalance.toNumber()}
                amount={premiumAmount.toNumber()}
                symbol={selectedPaymentTokenSymbol}
                toToken={selectedPaymentToken}
              />

              <EqualsSign />

              <NumericInput
                value={premiumAmount.decimalPlaces(4).toString()}
                label={`${selectedPaymentTokenSymbol} Required (Available: ${balance})`}
                readOnly
              />
            </VStack>

            <Details>
              <Details.Row>
                <InfoPopover text="Increase in price due to order size">
                  <Typography size="extra-small" color="textSecondary">
                    Price Impact
                  </Typography>
                </InfoPopover>

                <Typography size="extra-small" weight="bold">
                  {priceImpact.toString()}%
                </Typography>
              </Details.Row>
              <Details.Row>
                <InfoPopover text="The price of the underlying asset at which this position will become profitable at expiration">
                  <HStack
                    className={classes.breakEven}
                    onClick={() => setShowGraph(!showGraph)}
                    space={0}
                    alignItems="center"
                  >
                    <Typography
                      className={classes.breakEvenText}
                      size="extra-small"
                    >
                      Break Even
                    </Typography>

                    <SvgIcon
                      component={showGraph ? ChevronsDownSvg : ChevronsUpSvg}
                    />
                  </HStack>
                </InfoPopover>

                <Typography size="extra-small" weight="bold">
                  ${utils.commify(breakEven.decimalPlaces(2).toString())}
                </Typography>
              </Details.Row>
            </Details>

            {showGraph && (
              <PnLChart
                className={classes.pnlChart}
                currentPrice={series.paymentPerUnderlying}
                type={series.type}
                breakeven={breakEven.toNumber()}
                premium={
                  series.type === "Call"
                    ? collateralAmount
                        .multipliedBy(series.paymentPerUnderlying)
                        .dividedBy(contractSize || 1)
                        .toNumber()
                    : collateralAmount.dividedBy(contractSize || 1).toNumber()
                }
                strikePrice={series.strike}
                contractSize={Number(contractSize || 1)}
              />
            )}

            {!desktop && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  clearSelection()
                }}
                className={classes.closeButton}
                size="large"
              >
                <Close />
              </IconButton>
            )}
          </VStack>
        )}
      </Side.Content>

      <Side.Action>
        <CheckNetworkButton>
          {canTradeOptions || !permissionsFetched ? (
            <Button
              variant="primary"
              onClick={handleBuyOption}
              disabled={
                isQuoting ||
                premiumAmount.gt(paymentTokenBalance) ||
                contractSize === "0" ||
                contractSize === "" ||
                !numericRegex.test(contractSize) ||
                new BigNumber(contractSize).lt(minTradeSize) ||
                !permissionsFetched
              }
              fullWidth
            >
              Buy {series.type}
            </Button>
          ) : (
            <VStack>
              <Typography size="tiny" align="center">
                Trading restricted in your current location. Users located in
                the US and UK are not allowed to trade.
              </Typography>
              <Button variant="primary" fullWidth disabled>
                Trading restricted...
              </Button>
            </VStack>
          )}
        </CheckNetworkButton>
      </Side.Action>
    </>
  )
}

export default BuyOptionDialog
