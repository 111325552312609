import { Button, SvgIcon, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Twitter } from "@mui/icons-material"
import React from "react"

import TransactionCompleteSvg from "../icons/TransactionCompleteSvg"
import { Side, VStack, Typography } from "./"

const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  successIcon: {
    marginTop: spacing(10),
    marginBottom: `${spacing(10)} !important`,
    marginLeft: "auto",
    marginRight: "auto",

    width: 156,
    height: 156,
    color: palette.colors.green,
  },
  twitterButton: {
    background: palette.colors.background2,
    border: "none",
  },
  twitterIcon: {
    fill: palette.colors.twitter,
  },
}))

export const TxSuccess: React.FC<{
  message?: string
  buttonMessage?: string
  twitterMessage?: string
  onConfirm?: () => void
}> = ({ message, buttonMessage, twitterMessage, onConfirm, children }) => {
  const classes = useStyles()

  return (
    <>
      <Side.Content>
        <Typography variant="h3">Transaction complete!</Typography>
        <Typography size="small" color="textSecondary">
          {message}
        </Typography>

        <SvgIcon
          className={classes.successIcon}
          component={TransactionCompleteSvg}
          viewBox="0 0 156 156"
          width="156px"
          height="156px"
        />

        {children}
      </Side.Content>
      <Side.Action>
        <VStack>
          {twitterMessage && (
            <Button
              className={classes.twitterButton}
              startIcon={<Twitter className={classes.twitterIcon} />}
              component="a"
              href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                twitterMessage,
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              Share a tweet
            </Button>
          )}
          {buttonMessage && (
            <Button
              onClick={() => {
                onConfirm && onConfirm()
              }}
              variant="primary"
            >
              {buttonMessage}
            </Button>
          )}
        </VStack>
      </Side.Action>
    </>
  )
}
