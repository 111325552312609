import { Button, ButtonProps, Theme, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { makeStyles } from "@mui/styles"
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import React from "react"
import clsx from "clsx"

type HeaderButtonColor = "primary" | "green"

type HeaderButtonProps = {
  hasMenu?: boolean
  open?: boolean
  color: HeaderButtonColor
} & Omit<ButtonProps, "endIcon" | "color">

const useStyles = makeStyles<Theme, Pick<HeaderButtonProps, "open" | "color">>(
  ({ breakpoints, palette }) => ({
    root: {
      fontWeight: 500,
      height: 40,
      minWidth: "initial",
      padding: "5px 20px",
      borderRadius: 20,
      whiteSpace: "nowrap",
      border: ({ open, color }) =>
        `1px solid ${open ? palette.colors[color] : palette.colors.stroke}`,

      backgroundColor: palette.colors.background4,

      "&:hover": {
        backgroundColor: palette.colors.background4,
        border: ({ color }) => `1px solid ${palette.colors[color]}`,
      },

      [breakpoints.only("md")]: {
        padding: "5px 10px",
      },
      "& svg": {
        fontSize: 20,
      },
      "& .MuiButton-endIcon": {
        color: palette.colors.icon,
      },
    },
  }),
)

export const HeaderButton = ({
  className,
  startIcon,
  children,
  hasMenu,
  open,
  color = "primary",
  ...props
}: HeaderButtonProps) => {
  const { breakpoints } = useTheme()

  const classes = useStyles({ open, color })

  const mdOnly = useMediaQuery(breakpoints.only("md"))

  const endIcon = open ? (
    <ArrowDropUp fontSize="small" />
  ) : (
    <ArrowDropDown fontSize="small" />
  )

  return (
    <Button
      className={clsx(classes.root, className)}
      startIcon={mdOnly ? undefined : startIcon}
      endIcon={hasMenu ? endIcon : undefined}
      {...props}
    >
      {mdOnly ? startIcon : children}
    </Button>
  )
}
