import React from "react"

interface IArrowUpSvg {
  className?: string
}

const ArrowUpSvg = ({ className }: IArrowUpSvg) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70711 0.792893C8.31658 0.402369 7.68342 0.402369 7.29289 0.792893L0.292893 7.79289C-0.0976311 8.18342 -0.0976311 8.81658 0.292893 9.20711C0.683417 9.59763 1.31658 9.59763 1.70711 9.20711L7 3.91421V15.5C7 16.0523 7.44772 16.5 8 16.5C8.55229 16.5 9 16.0523 9 15.5V3.91421L14.2929 9.20711C14.6834 9.59763 15.3166 9.59763 15.7071 9.20711C16.0976 8.81658 16.0976 8.18342 15.7071 7.79289L8.70711 0.792893Z"
      />
    </svg>
  )
}

export default ArrowUpSvg
