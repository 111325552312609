import {
  Typography as MUITypography,
  TypographyProps as MUITypographyProps,
  Theme,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"
import React from "react"

export const Weight = {
  Bold: "bold",
  Medium: "medium",
  Regular: "regular",
} as const

type WeightType = typeof Weight[keyof typeof Weight]

export const Size = {
  Large: "large",
  Medium: "medium",
  Small: "small",
  ExtraSmall: "extra-small",
  Tiny: "tiny",
  XTiny: "x-tiny",
  Inherit: "inherit",
} as const

type SizeType = typeof Size[keyof typeof Size]

export const Variant = {
  Display1: "display1",
  Display2: "display2",
  H1: "h1",
  H2: "h2",
  H3: "h3",
  H4: "h4",
  H5: "h5",
  H6: "h6",
  Body: "body",
} as const

type VariantType = typeof Variant[keyof typeof Variant]

export type TypographyProps = Omit<MUITypographyProps, "variant"> & {
  size?: SizeType
  weight?: WeightType
  component?: React.ElementType
} & (
    | { variant?: Exclude<VariantType, "body"> }
    | {
        variant?: Extract<VariantType, "body">
        size?: SizeType
      }
  )

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  [Variant.Display1]: {
    fontFamily: "ClashDisplay",
    fontSize: 60,
    fontWeight: 600,
    lineHeight: "120%",
  },

  [Variant.Display2]: {
    fontFamily: "ClashDisplay",
    fontSize: 48,
    fontWeight: 600,
    lineHeight: "120%",

    [breakpoints.down("md")]: {
      fontSize: 34,
    },

    [breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },

  [Variant.H1]: {
    fontFamily: "ClashDisplay",
    fontSize: 34,
    fontWeight: 600,
    lineHeight: "120%",

    [breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  [Variant.H2]: {
    fontFamily: "ClashDisplay",
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "120%",

    [breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  [Variant.H3]: {
    fontFamily: "ClashDisplay",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "120%",

    [breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  [Variant.H4]: {
    fontFamily: "ClashDisplay",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "120%",

    [breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  [Variant.H5]: {
    fontFamily: "ClashDisplay",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "120%",

    [breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  [Variant.H6]: {
    fontFamily: "ClashDisplay",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "120%",

    [breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  [Variant.Body + Size.Large]: {
    fontSize: 24,
    lineHeight: "140%",
  },
  [Variant.Body + Size.Medium]: {
    fontSize: 18,
    lineHeight: "140%",
  },
  [Variant.Body + Size.Small]: {
    fontSize: 16,
    lineHeight: "140%",
  },
  [Variant.Body + Size.ExtraSmall]: {
    fontSize: 14,
    lineHeight: "140%",
  },
  [Variant.Body + Size.Tiny]: {
    fontSize: 12,
    lineHeight: "140%",
  },
  [Variant.Body + Size.XTiny]: {
    fontSize: 10,
    lineHeight: "140%",
  },
  [Variant.Body + Size.Inherit]: {
    fontSize: "inherit",
    lineHeight: "140%",
  },

  [Weight.Bold]: {
    fontWeight: 700,
  },

  [Weight.Medium]: {
    fontWeight: 500,
  },

  [Weight.Regular]: {
    fontWeight: 400,
  },
}))

export const Typography = ({
  className,
  variant = Variant.Body,
  size,
  weight,
  ...props
}: TypographyProps) => {
  const classes = useStyles(props)

  return (
    <MUITypography
      variant="inherit"
      className={clsx([
        className,
        classes[
          variant +
            ((variant === Variant.Body ? size ?? Size.Inherit : size) ?? "")
        ],
        weight && classes[weight],
      ])}
      {...props}
    />
  )
}
