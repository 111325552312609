import React from "react"

interface IChevronsRightSvg {
  className?: string
}

const ChevronsRightSvg = ({ className }: IChevronsRightSvg) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2441 5.24408C10.5695 4.91864 11.0972 4.91864 11.4226 5.24408L15.5893 9.41074C15.9147 9.73618 15.9147 10.2638 15.5893 10.5893L11.4226 14.7559C11.0972 15.0814 10.5695 15.0814 10.2441 14.7559C9.91864 14.4305 9.91864 13.9028 10.2441 13.5774L13.8215 10L10.2441 6.42259C9.91864 6.09715 9.91864 5.56951 10.2441 5.24408Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.41083 5.24408C4.73626 4.91864 5.2639 4.91864 5.58934 5.24408L9.756 9.41074C10.0814 9.73618 10.0814 10.2638 9.756 10.5893L5.58934 14.7559C5.2639 15.0814 4.73626 15.0814 4.41083 14.7559C4.08539 14.4305 4.08539 13.9028 4.41083 13.5774L7.98824 10L4.41083 6.42259C4.08539 6.09715 4.08539 5.56951 4.41083 5.24408Z"
      />
    </svg>
  )
}

export default ChevronsRightSvg
