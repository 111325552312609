import { Box, Theme, Button } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { range } from "lodash"
import React, { useMemo, useState } from "react"

import { usePools } from "../../../../Contexts/SirenMarketsContext"
import { VStack } from "../../../common"
import { CardSkeleton } from "./CardSkeleton"
import { EarnCard } from "./EarnCard"

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: 30,
    width: "100%",

    [breakpoints.down("sm")]: {
      gridGap: 10,
    },
  },
}))

export const EarnCards = () => {
  const classes = useStyles()

  const [inactivePoolsShown, setInactivePoolsShown] = useState(false)

  const { pools, poolsFetched, poolsOnchainFetched } = usePools()

  const activePools = useMemo(
    () =>
      pools
        .filter((p) => (inactivePoolsShown ? true : p.isActive))
        .sort((a, b) => b.projectedYield - a.projectedYield),
    [pools, inactivePoolsShown],
  )

  return (
    <VStack space={6} alignItems="center">
      <Box className={classes.root}>
        {poolsFetched
          ? activePools.map((pool) => (
              <EarnCard
                key={pool.address}
                pool={pool}
                poolsOnchainFetched={poolsOnchainFetched}
              />
            ))
          : range(4).map((i) => <CardSkeleton key={i} />)}
      </Box>

      {pools.length > activePools.length && (
        <Button
          variant="secondary"
          size="large"
          onClick={() => setInactivePoolsShown((value) => !value)}
        >
          {inactivePoolsShown ? "Hide" : "Show"} inactive pools
        </Button>
      )}
    </VStack>
  )
}
