/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AmmTokenEventType {
  BTokenBought = "BTokenBought",
  BTokenSold = "BTokenSold",
  LpTokenBurn = "LpTokenBurn",
  LpTokenMint = "LpTokenMint",
  WTokenSold = "WTokenSold",
}

export enum ERC1155TokenType {
  B_TOKEN = "B_TOKEN",
  W_TOKEN = "W_TOKEN",
}

export interface AmmTokenEvent_filter {
  id?: string | null
  id_not?: string | null
  id_gt?: string | null
  id_lt?: string | null
  id_gte?: string | null
  id_lte?: string | null
  id_in?: string[] | null
  id_not_in?: string[] | null
  account?: string | null
  account_not?: string | null
  account_gt?: string | null
  account_lt?: string | null
  account_gte?: string | null
  account_lte?: string | null
  account_in?: string[] | null
  account_not_in?: string[] | null
  account_contains?: string | null
  account_contains_nocase?: string | null
  account_not_contains?: string | null
  account_not_contains_nocase?: string | null
  account_starts_with?: string | null
  account_starts_with_nocase?: string | null
  account_not_starts_with?: string | null
  account_not_starts_with_nocase?: string | null
  account_ends_with?: string | null
  account_ends_with_nocase?: string | null
  account_not_ends_with?: string | null
  account_not_ends_with_nocase?: string | null
  collateralAmount?: any | null
  collateralAmount_not?: any | null
  collateralAmount_gt?: any | null
  collateralAmount_lt?: any | null
  collateralAmount_gte?: any | null
  collateralAmount_lte?: any | null
  collateralAmount_in?: any[] | null
  collateralAmount_not_in?: any[] | null
  tokenAmount?: any | null
  tokenAmount_not?: any | null
  tokenAmount_gt?: any | null
  tokenAmount_lt?: any | null
  tokenAmount_gte?: any | null
  tokenAmount_lte?: any | null
  tokenAmount_in?: any[] | null
  tokenAmount_not_in?: any[] | null
  eventType?: AmmTokenEventType[] | null
  eventType_not?: AmmTokenEventType[] | null
  eventType_contains?: AmmTokenEventType[] | null
  eventType_contains_nocase?: AmmTokenEventType[] | null
  eventType_not_contains?: AmmTokenEventType[] | null
  eventType_not_contains_nocase?: AmmTokenEventType[] | null
  amm?: string | null
  amm_not?: string | null
  amm_gt?: string | null
  amm_lt?: string | null
  amm_gte?: string | null
  amm_lte?: string | null
  amm_in?: string[] | null
  amm_not_in?: string[] | null
  amm_contains?: string | null
  amm_contains_nocase?: string | null
  amm_not_contains?: string | null
  amm_not_contains_nocase?: string | null
  amm_starts_with?: string | null
  amm_starts_with_nocase?: string | null
  amm_not_starts_with?: string | null
  amm_not_starts_with_nocase?: string | null
  amm_ends_with?: string | null
  amm_ends_with_nocase?: string | null
  amm_not_ends_with?: string | null
  amm_not_ends_with_nocase?: string | null
  block?: any | null
  block_not?: any | null
  block_gt?: any | null
  block_lt?: any | null
  block_gte?: any | null
  block_lte?: any | null
  block_in?: any[] | null
  block_not_in?: any[] | null
  timestamp?: any | null
  timestamp_not?: any | null
  timestamp_gt?: any | null
  timestamp_lt?: any | null
  timestamp_gte?: any | null
  timestamp_lte?: any | null
  timestamp_in?: any[] | null
  timestamp_not_in?: any[] | null
  poolValueSnapshot?: string | null
  poolValueSnapshot_not?: string | null
  poolValueSnapshot_gt?: string | null
  poolValueSnapshot_lt?: string | null
  poolValueSnapshot_gte?: string | null
  poolValueSnapshot_lte?: string | null
  poolValueSnapshot_in?: string[] | null
  poolValueSnapshot_not_in?: string[] | null
  poolValueSnapshot_contains?: string | null
  poolValueSnapshot_contains_nocase?: string | null
  poolValueSnapshot_not_contains?: string | null
  poolValueSnapshot_not_contains_nocase?: string | null
  poolValueSnapshot_starts_with?: string | null
  poolValueSnapshot_starts_with_nocase?: string | null
  poolValueSnapshot_not_starts_with?: string | null
  poolValueSnapshot_not_starts_with_nocase?: string | null
  poolValueSnapshot_ends_with?: string | null
  poolValueSnapshot_ends_with_nocase?: string | null
  poolValueSnapshot_not_ends_with?: string | null
  poolValueSnapshot_not_ends_with_nocase?: string | null
  transaction?: string | null
  transaction_not?: string | null
  transaction_gt?: string | null
  transaction_lt?: string | null
  transaction_gte?: string | null
  transaction_lte?: string | null
  transaction_in?: string[] | null
  transaction_not_in?: string[] | null
  transaction_contains?: string | null
  transaction_contains_nocase?: string | null
  transaction_not_contains?: string | null
  transaction_not_contains_nocase?: string | null
  transaction_starts_with?: string | null
  transaction_starts_with_nocase?: string | null
  transaction_not_starts_with?: string | null
  transaction_not_starts_with_nocase?: string | null
  transaction_ends_with?: string | null
  transaction_ends_with_nocase?: string | null
  transaction_not_ends_with?: string | null
  transaction_not_ends_with_nocase?: string | null
  _change_block?: BlockChangedFilter | null
}

export interface BlockChangedFilter {
  number_gte: number
}

export interface PoolValueSnapshot_filter {
  id?: string | null
  id_not?: string | null
  id_gt?: string | null
  id_lt?: string | null
  id_gte?: string | null
  id_lte?: string | null
  id_in?: string[] | null
  id_not_in?: string[] | null
  createdBlock?: any | null
  createdBlock_not?: any | null
  createdBlock_gt?: any | null
  createdBlock_lt?: any | null
  createdBlock_gte?: any | null
  createdBlock_lte?: any | null
  createdBlock_in?: any[] | null
  createdBlock_not_in?: any[] | null
  createdTimestamp?: any | null
  createdTimestamp_not?: any | null
  createdTimestamp_gt?: any | null
  createdTimestamp_lt?: any | null
  createdTimestamp_gte?: any | null
  createdTimestamp_lte?: any | null
  createdTimestamp_in?: any[] | null
  createdTimestamp_not_in?: any[] | null
  createdTransaction?: any | null
  createdTransaction_not?: any | null
  createdTransaction_in?: any[] | null
  createdTransaction_not_in?: any[] | null
  createdTransaction_contains?: any | null
  createdTransaction_not_contains?: any | null
  amm?: string | null
  amm_not?: string | null
  amm_gt?: string | null
  amm_lt?: string | null
  amm_gte?: string | null
  amm_lte?: string | null
  amm_in?: string[] | null
  amm_not_in?: string[] | null
  amm_contains?: string | null
  amm_contains_nocase?: string | null
  amm_not_contains?: string | null
  amm_not_contains_nocase?: string | null
  amm_starts_with?: string | null
  amm_starts_with_nocase?: string | null
  amm_not_starts_with?: string | null
  amm_not_starts_with_nocase?: string | null
  amm_ends_with?: string | null
  amm_ends_with_nocase?: string | null
  amm_not_ends_with?: string | null
  amm_not_ends_with_nocase?: string | null
  poolValue?: any | null
  poolValue_not?: any | null
  poolValue_gt?: any | null
  poolValue_lt?: any | null
  poolValue_gte?: any | null
  poolValue_lte?: any | null
  poolValue_in?: any[] | null
  poolValue_not_in?: any[] | null
  lpTokenSupply?: any | null
  lpTokenSupply_not?: any | null
  lpTokenSupply_gt?: any | null
  lpTokenSupply_lt?: any | null
  lpTokenSupply_gte?: any | null
  lpTokenSupply_lte?: any | null
  lpTokenSupply_in?: any[] | null
  lpTokenSupply_not_in?: any[] | null
  _change_block?: BlockChangedFilter | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================
