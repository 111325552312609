import React from "react"

interface ITransactionWithdrawSvg {
  className?: string
}

const TransactionWithdrawSvg = ({ className }: ITransactionWithdrawSvg) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill="url(#paint0_radial_6227_42598)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.64645 13.1464C7.84171 12.9512 8.15829 12.9512 8.35355 13.1464L10.5 15.2929L12.6464 13.1464C12.8417 12.9512 13.1583 12.9512 13.3536 13.1464C13.5488 13.3417 13.5488 13.6583 13.3536 13.8536L10.8536 16.3536C10.6583 16.5488 10.3417 16.5488 10.1464 16.3536L7.64645 13.8536C7.45118 13.6583 7.45118 13.3417 7.64645 13.1464Z"
        fill="#0A1C2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7322 8.23223C11.2011 7.76339 11.837 7.5 12.5 7.5H16C16.2761 7.5 16.5 7.72386 16.5 8C16.5 8.27614 16.2761 8.5 16 8.5H12.5C12.1022 8.5 11.7206 8.65804 11.4393 8.93934C11.158 9.22064 11 9.60218 11 10V16C11 16.2761 10.7761 16.5 10.5 16.5C10.2239 16.5 10 16.2761 10 16V10C10 9.33696 10.2634 8.70107 10.7322 8.23223Z"
        fill="#0A1C2C"
      />
      <defs>
        <radialGradient
          id="paint0_radial_6227_42598"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.6 3.67021) rotate(46.6371) scale(27.9632 25.4247)"
        >
          <stop stopColor="#E4D8FF" />
          <stop offset="0.526042" stopColor="#A57BFF" />
          <stop offset="1" stopColor="#8044FF" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default TransactionWithdrawSvg
