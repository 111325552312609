import React from "react"

interface IEqualsSvg {
  className?: string
}

const EqualsSvg = ({ className }: IEqualsSvg) => {
  return (
    <svg
      className={className}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" />
      <rect x="8" y="11" width="12" height="2" rx="1" fill="#8491A3" />
      <rect x="8" y="15" width="12" height="2" rx="1" fill="#8491A3" />
    </svg>
  )
}

export default EqualsSvg
