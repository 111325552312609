import { useWeb3 } from "@chainsafe/web3-context"
import { Close, ContentCopy } from "@mui/icons-material"
import {
  Menu,
  MenuItem,
  SvgIcon,
  Theme,
  useMediaQuery,
  alpha,
  useTheme,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useCallback } from "react"
import { useSirenMarketsContext } from "../Contexts/SirenMarketsContext"
import { formatAddress } from "../helpers/ethAddressFormatting"
import { usePopover } from "../Hooks/usePopover"
import { HeaderButton, HStack, Typography } from "./common"
import ConnectWalletSvg from "./icons/ConnectWalletSvg"

const useStyles = makeStyles<Theme>(({ palette }) => ({
  menu: {
    transform: "translate(0, 10px) !important",
    backgroundColor: palette.important(palette.colors.background4),
    border: "none",
  },
  menuItem: {
    "&:hover": {
      backgroundColor: palette.colors.background3,
    },

    "&:active": {
      backgroundColor: alpha(palette.colors.primary, 0.1),
    },

    "&.Mui-selected": {
      backgroundColor: palette.colors.background3,

      "&:hover": {
        backgroundColor: palette.colors.background3,
      },
    },

    "& + .MuiMenuItem-root": {
      marginTop: 5,
    },
  },
  menuItemDanger: {
    "&:hover": {
      backgroundColor: alpha(palette.colors.red, 0.1),
    },

    "&:active": {
      backgroundColor: alpha(palette.colors.red, 0.2),
    },

    "&.Mui-selected": {
      backgroundColor: alpha(palette.colors.red, 0.1),

      "&:hover": {
        backgroundColor: alpha(palette.colors.red, 0.1),
      },
    },

    "& + .MuiMenuItem-root": {
      marginTop: 5,
    },
  },
  button: {
    fontWeight: 700,
    color: palette.colors.green,
  },
}))

const MAINTENANCE_MODE =
  process.env.REACT_APP_MAINTENANCE_MODE === "true" ? true : false

export const AppHeaderWalletMenu = () => {
  const classes = useStyles()
  const { anchorEl, open, handleClose, handleOpen } = usePopover()
  const { breakpoints } = useTheme()

  const desktop = useMediaQuery(breakpoints.up("md"))

  const { isReady, address, resetOnboard } = useWeb3()
  const { handleSelectWalletConnect } = useSirenMarketsContext()

  const handleCopyAddress = useCallback(() => {
    if (address) {
      navigator.clipboard.writeText(address)
    }

    handleClose()
  }, [address, handleClose])

  const handleDisconnect = useCallback(() => {
    resetOnboard()

    handleClose()
  }, [resetOnboard, handleClose])

  return (
    <>
      {!isReady && (
        <HeaderButton
          className={classes.button}
          color="green"
          onClick={handleSelectWalletConnect}
          disabled={MAINTENANCE_MODE}
          startIcon={
            <SvgIcon component={ConnectWalletSvg} viewBox="0 0 22 20" />
          }
          hasMenu={false}
        >
          Connect Wallet
        </HeaderButton>
      )}
      {isReady && desktop && (
        <>
          <HeaderButton
            className={classes.button}
            color="green"
            onClick={handleOpen}
            startIcon={
              <SvgIcon component={ConnectWalletSvg} viewBox="0 0 22 20" />
            }
            open={open}
            hasMenu
          >
            <Typography variant="body" size="extra-small" color="textSecondary">
              {!!address && formatAddress(address)}
            </Typography>
          </HeaderButton>
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PopoverClasses={{
              paper: classes.menu,
            }}
          >
            <MenuItem className={classes.menuItem} onClick={handleCopyAddress}>
              <HStack
                alignItems="center"
                justifyContent="space-between"
                flex={1}
              >
                <Typography variant="body" size="medium" weight="medium">
                  Copy Address
                </Typography>
                <SvgIcon
                  component={ContentCopy}
                  style={{ width: 18, height: 18 }}
                />
              </HStack>
            </MenuItem>
            <MenuItem
              className={classes.menuItemDanger}
              onClick={handleDisconnect}
            >
              <HStack
                alignItems="center"
                justifyContent="space-between"
                flex={1}
              >
                <Typography
                  variant="body"
                  size="medium"
                  weight="medium"
                  color="error"
                >
                  Disconnect
                </Typography>
                <SvgIcon
                  component={Close}
                  color="error"
                  style={{ width: 18, height: 18 }}
                />
              </HStack>
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  )
}
