import React from "react"
import { Route, Routes, Navigate } from "react-router-dom"

import { ErrorPage, ScrollToTop } from "./common"
import { AppWrapper } from "./AppWrapper"

import { EarnPages } from "./routes/earn"
import { TradePage } from "./routes/trade"
import { PortfolioPage } from "./routes/portfolio"
import { AirdropPage } from "./routes/airdrop"
import { DirectBuyPage } from "./routes/direct-buy"

export const AppRoutes = () => (
  <ScrollToTop>
    <Routes>
      <Route
        path="/403"
        element={
          <ErrorPage code={403} text="Sorry, you’re not allowed in here." />
        }
      />
      <Route
        path="/404"
        element={<ErrorPage code={404} text="Oops! You’ve lost the road." />}
      />
      <Route
        path="/500"
        element={
          <ErrorPage
            code={500}
            text="Sorry, unexpected error. We are working on it."
          />
        }
      />
      <Route
        path="*"
        element={
          <AppWrapper>
            <Routes>
              <Route path="/" element={<Navigate to="/trade" replace />} />
              <Route path="/pools" element={<Navigate to="/earn" />} />

              <Route path="/earn/*" element={<EarnPages />} />
              <Route path="/trade" element={<TradePage />} />
              <Route path="/trade/:pair" element={<TradePage />} />
              <Route path="/portfolio/*" element={<PortfolioPage />} />
              <Route path="/airdrop" element={<AirdropPage />} />
              <Route path="/direct-buy" element={<DirectBuyPage />} />

              <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
          </AppWrapper>
        }
      />
    </Routes>
  </ScrollToTop>
)
