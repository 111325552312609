import React from "react"

interface IChevronsDownSvg {
  className?: string
}

const ChevronsDownSvg = ({ className }: IChevronsDownSvg) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.42526 7.99961C6.97526 7.99961 6.66292 8.20394 6.48826 8.61261C6.31292 9.02061 6.38359 9.38294 6.70026 9.69961L9.30026 12.2996C9.40026 12.3996 9.50859 12.4746 9.62526 12.5246C9.74192 12.5746 9.86692 12.5996 10.0003 12.5996C10.1336 12.5996 10.2586 12.5746 10.3753 12.5246C10.4919 12.4746 10.6003 12.3996 10.7003 12.2996L13.3003 9.69961C13.6169 9.38294 13.6876 9.02061 13.5123 8.61261C13.3376 8.20394 13.0253 7.99961 12.5753 7.99961H7.42526Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ChevronsDownSvg
