import { ethers } from "ethers"
import {
  AddressesProvider__factory,
  SeriesController__factory,
} from "@sirenmarkets/sdk"
import { useQuery } from "@tanstack/react-query"

import { QueryKey } from "../../constants"
import { useSirenConfig } from "../SirenConfigContext"
import { Addresses } from "./types"
import { useSirenMarketsContext } from "./SirenMarketsContext"

const fetchAddresses = async (
  addressesProvider: string,
  provider: ethers.providers.Provider,
): Promise<Addresses> => {
  const addressesProviderContract = AddressesProvider__factory.connect(
    addressesProvider,
    provider,
  )

  const [
    ammDataProviderAddress,
    priceOracleAddress,
    seriesControllerAddress,
    wTokenVaultAddress,
    lightswap1155Address,
    seriesDeployerAddress,
  ] = await Promise.all([
    addressesProviderContract.getAmmDataProvider(),
    addressesProviderContract.getPriceOracle(),
    addressesProviderContract.getSeriesController(),
    addressesProviderContract.getWTokenVault(),
    addressesProviderContract.getAirswapLight(),
    addressesProviderContract.getSeriesDeployer(),
  ])

  const seriesControllerContract = SeriesController__factory.connect(
    seriesControllerAddress,
    provider,
  )

  const erc1155ControllerAddress =
    await seriesControllerContract.erc1155Controller()

  return {
    ammDataProviderAddress,
    priceOracleAddress,
    seriesControllerAddress,
    erc1155ControllerAddress,
    wTokenVaultAddress,
    lightswap1155Address,
    seriesDeployerAddress,
  }
}

export const useAddresses = () => {
  const { readProvider } = useSirenMarketsContext()

  const { activeConfig } = useSirenConfig()

  const { data: addresses = {} } = useQuery(
    [QueryKey.Addresses],
    () => fetchAddresses(activeConfig.addressesProvider, readProvider!),
    {
      enabled: Boolean(readProvider),
    },
  )

  return addresses
}
