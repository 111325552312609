import React, { useCallback, useMemo } from "react"
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  SvgIcon,
} from "@mui/material"
import { startCase } from "lodash"
import dayjs from "dayjs"
import { BigNumber } from "bignumber.js"

import { useLanguageContext } from "../../../Contexts/LanguageContext"

import { useSirenConfig } from "../../../Contexts/SirenConfigContext"
import {
  HStack,
  InfoPopover,
  NoData,
  TokenAvatar,
  Typography,
  VStack,
  FixedBottomPanel,
  TableSkeleton,
} from "../../common"
import ArrowSouthWestSvg from "../../icons/ArrowSouthWestSvg"
import { useMedia } from "../../../Hooks/useMedia"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { usePositions } from "../../../Contexts/SirenMarketsContext"

const PositionList: React.FC<{}> = () => {
  const navigate = useNavigate()
  const { formatShortLocaleDate, formatMoney } = useLanguageContext()

  const { id: selectedId } = useParams<{ id?: string }>()

  const { positions, positionsFetched } = usePositions()

  const filteredPositions = useMemo(
    () =>
      positions.filter(
        (p) =>
          p.status === "open" ||
          p.wTokenBalance.gt(0) ||
          (p.status === "expired" &&
            p.bTokenBalance.gt(0) &&
            p.expiryStatus === "ITM"),
      ),
    [positions],
  )

  const handleSelectRow = useCallback(
    (rowId: string) => {
      navigate(generatePath("/portfolio/positions/:id", { id: rowId }), {
        replace: true,
      })
    },
    [navigate],
  )

  const { isWalletNetworkValid } = useSirenConfig()

  const { desktop, mobile, mediaSwitch } = useMedia()

  const totalPnL = filteredPositions.reduce(
    (sum, p) => sum.plus(p.unrealizedGain || 0),
    new BigNumber(0),
  )

  const sortedPositions = useMemo(
    () =>
      filteredPositions // First sort by exp, then by strike price
        .sort((a, b) =>
          dayjs(a.expiration).unix() < dayjs(b.expiration).unix()
            ? -1
            : dayjs(a.expiration).unix() === dayjs(b.expiration).unix()
            ? a.strike < b.strike
              ? -1
              : 1
            : 1,
        ),
    [filteredPositions],
  )

  if (!positionsFetched) {
    return <TableSkeleton columns={mobile ? 3 : 7} />
  }

  if (sortedPositions.length === 0) {
    return (
      <NoData
        title="No positions yet"
        subtitle="Start trading to add new positions in portfolio"
      >
        {isWalletNetworkValid && (
          <Button
            variant="primary"
            size={desktop ? "large" : "medium"}
            onClick={() => navigate("/trade")}
            fullWidth
            style={{ maxWidth: 365 }}
          >
            Start Trading
          </Button>
        )}
      </NoData>
    )
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {mediaSwitch("Asset", "Asset/Strike", "Asset")}
              </TableCell>
              <TableCell>
                <InfoPopover text="All expirations happen at 08:00:00 GMT.">
                  <span>{mediaSwitch("Expiration", "Expiration", "Exp.")}</span>
                </InfoPopover>
              </TableCell>

              {!mobile && (
                <>
                  <TableCell>Status</TableCell>
                  <TableCell align="right">Strike</TableCell>
                </>
              )}

              <TableCell align="right">
                {mediaSwitch("# contracts", "# contr./Unr. PnL", "# contr.")}
              </TableCell>

              {!mobile && (
                <>
                  <TableCell align="right">
                    {mediaSwitch(
                      "Unrealized PnL",
                      "Unrealized PnL",
                      "Unr. PnL",
                    )}
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedPositions.map((p) => {
              return (
                <TableRow
                  hover
                  key={p.seriesId}
                  selected={p.seriesId === selectedId}
                  onClick={() => handleSelectRow(p.seriesId)}
                >
                  <TableCell>
                    <HStack
                      space={1}
                      alignItems={mobile ? "inherit" : "center"}
                    >
                      <TokenAvatar
                        tokenSymbol={p.underlyingTokenSymbol}
                        size={20}
                      />

                      <VStack space={0.5}>
                        <Typography weight="bold">
                          {p.underlyingTokenSymbol} {p.type}
                        </Typography>

                        {mobile && (
                          <Typography>{formatMoney(p.strike)}</Typography>
                        )}
                      </VStack>
                    </HStack>
                  </TableCell>
                  <TableCell>
                    <VStack space={0.5}>
                      <Typography
                        color={
                          p.seriesId === selectedId
                            ? "textPrimary"
                            : "textSecondary"
                        }
                      >
                        {formatShortLocaleDate(p.expiration)}
                      </Typography>

                      {mobile && (
                        <Typography
                          color={
                            p.seriesId === selectedId
                              ? "textPrimary"
                              : "textSecondary"
                          }
                        >
                          {startCase(p.status)}
                        </Typography>
                      )}
                    </VStack>
                  </TableCell>

                  {!mobile && (
                    <>
                      <TableCell>
                        <Typography
                          color={
                            p.seriesId === selectedId
                              ? "textPrimary"
                              : "textSecondary"
                          }
                        >
                          {startCase(p.status)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography weight="bold">
                          {formatMoney(p.strike)}
                        </Typography>
                      </TableCell>
                    </>
                  )}

                  <TableCell align="right">
                    <VStack space={0.5}>
                      <Typography
                        color={
                          p.seriesId === selectedId
                            ? "textPrimary"
                            : "textSecondary"
                        }
                      >
                        {p.bTokenBalance.decimalPlaces(4).toString()}
                      </Typography>

                      {mobile && (
                        <Typography
                          color={
                            p.unrealizedGain?.lt(0)
                              ? "colors.red"
                              : "colors.green"
                          }
                        >
                          {formatMoney(
                            p.unrealizedGain?.decimalPlaces(2).toNumber() ?? 0,
                            {
                              signDisplay: "exceptZero",
                            },
                          )}
                        </Typography>
                      )}
                    </VStack>
                  </TableCell>

                  {!mobile && (
                    <>
                      <TableCell align="right">
                        <Typography
                          color={
                            p.unrealizedGain?.lt(0)
                              ? "colors.red"
                              : "colors.green"
                          }
                        >
                          {formatMoney(
                            p.unrealizedGain?.decimalPlaces(2).toNumber() ?? 0,
                            {
                              signDisplay: "exceptZero",
                            },
                          )}
                        </Typography>
                      </TableCell>

                      <TableCell align="right">
                        <HStack
                          space={0.5}
                          alignItems="center"
                          color="colors.green"
                          inline
                        >
                          {p.status === "open" && (
                            <SvgIcon
                              component={ArrowSouthWestSvg}
                              fontSize="inherit"
                            />
                          )}
                          <Typography weight="medium" noWrap>
                            {p.status === "open" ? "Sell" : "Exercise"}
                          </Typography>
                        </HStack>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              )
            })}

            <TableRow>
              <TableCell colSpan={mobile ? 1 : 4} />
              <TableCell align="right">
                <Typography weight="bold" size="small">
                  Total PnL
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  weight="bold"
                  size="small"
                  color={totalPnL?.lt(0) ? "colors.red" : "colors.green"}
                >
                  {formatMoney(totalPnL.decimalPlaces(2).toNumber(), {
                    signDisplay: "exceptZero",
                  })}
                </Typography>
              </TableCell>
              {!mobile && <TableCell />}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {!desktop && (
        <FixedBottomPanel>
          <Typography size="small" weight="medium" align="center">
            <Typography
              size="small"
              weight="medium"
              color="primary"
              component="span"
              paragraph={false}
            >
              To excercise or sell a position
            </Typography>
            , select a position from the table
          </Typography>
        </FixedBottomPanel>
      )}
    </>
  )
}

export default PositionList
