import React from "react"
import { makeStyles } from "@mui/styles"
import { List, ListItem, ListItemText, Theme } from "@mui/material"
import SimpleBar from "simplebar-react"

import { TokenAvatar, HStack } from "../../common"
import { useNavigate } from "react-router-dom"

const useStyles = makeStyles<Theme>(({ palette, breakpoints }) => ({
  scrollbar: {
    width: "100%",
    maxHeight: "100vh",

    [breakpoints.down("sm")]: {
      height: "100%",
      width: "calc(100vw - 40px)",
    },
  },
  list: {
    width: "100%",
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 5,
    [breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 8,
    },
    backgroundColor: palette.colors.background4,
  },
  listItem: {
    cursor: "pointer",
    padding: "0 12px",
    height: 46,

    [breakpoints.up("md")]: {
      border: "2px solid transparent",

      "&:hover": {
        border: `2px solid ${palette.colors.primary}`,
      },
    },

    "&:first-of-type": {
      borderRadius: "5px 5px 0px 0px",
    },
    "&:last-of-type": {
      borderRadius: "0px 0px 5px 5px",
    },

    [breakpoints.down("md")]: {
      "&:first-of-type": {
        borderRadius: "5px 0px 0px 5px",
      },
      "&:last-of-type": {
        borderRadius: "0px 5px 5px 0px",
      },
    },

    [breakpoints.down("sm")]: {
      height: 36,
    },
  },
}))

const AssetPairSelector: React.FC<{
  pairs: string[]
  selectedPair: string
}> = ({ pairs, selectedPair }) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <SimpleBar className={classes.scrollbar}>
      <List className={classes.list}>
        {pairs.map((p) => {
          return (
            <ListItem
              key={p}
              onClick={() => navigate(`/trade/${p.split(" / ").join("-")}`)}
              selected={selectedPair === p}
              className={classes.listItem}
            >
              <HStack space={1} alignItems="center">
                <TokenAvatar
                  tokenSymbol={p.split(" / ")[0]}
                  size={[24, 16, 24]}
                />
                <ListItemText
                  primary={p.split(" / ")[0]}
                  primaryTypographyProps={{
                    variant: "h5",
                  }}
                />
              </HStack>
            </ListItem>
          )
        })}
      </List>
    </SimpleBar>
  )
}

export default AssetPairSelector
