import React from "react"
import { Box, BoxProps } from "@mui/material"
import { Theme } from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import clsx from "clsx"

export type VStackProps = Omit<BoxProps, "display" | "flexDirection"> & {
  space?: number
  inline?: boolean
}

const useVStackStyles = makeStyles<Theme, VStackProps>(({ spacing }) => ({
  root: {
    "& > *:not(:last-child)": {
      marginBottom: ({ space }) => spacing(space ?? 2),
    },
  },
}))

export const VStack: React.FC<VStackProps> = ({
  children,
  space,
  className,
  inline,
  ...rest
}) => {
  const classes = useVStackStyles({ space })

  return (
    <Box
      className={clsx(classes.root, className)}
      display={inline ? "inline-flex" : "flex"}
      flexDirection="column"
      {...rest}
    >
      {children}
    </Box>
  )
}

export type HStackProps = Omit<BoxProps, "display" | "flexDirection"> & {
  space?: number
  inline?: boolean
}

const useHStackStyles = makeStyles<Theme, HStackProps>(({ spacing }) => ({
  root: {
    "& > *:not(:last-child)": {
      marginRight: ({ space }) => spacing(space ?? 2),
    },
  },
}))

export const HStack: React.FC<HStackProps> = ({
  children,
  space,
  className,
  inline,
  ...rest
}) => {
  const classes = useHStackStyles({ space })

  return (
    <Box
      className={clsx(classes.root, className)}
      display={inline ? "inline-flex" : "flex"}
      flexDirection="row"
      {...rest}
    >
      {children}
    </Box>
  )
}

export type StackProps = Omit<BoxProps, "display" | "flexDirection"> & {
  direction: "horizontal" | "vertical"
  space?: number
}

export const Stack: React.FC<StackProps> = ({ direction, ...rest }) =>
  direction === "horizontal" ? <HStack {...rest} /> : <VStack {...rest} />
