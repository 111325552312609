import { Button, Theme, useTheme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React from "react"
import { Link } from "react-router-dom"
import { VStack } from "./Stack"
import { Typography } from "./Typography"

const useStyles = makeStyles<Theme>(({ palette, breakpoints }) => ({
  root: {
    height: "100vh",
    position: "relative",
  },

  errorText: {
    background: palette.colors.errorPageTitle,
    fontSize: 128,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    marginBottom: 12,
  },

  button: {
    width: 240,
    marginTop: 60,
  },

  cube: {
    position: "absolute",
    left: 40,
    transform: "translate(0, -170px)",

    [breakpoints.down("sm")]: {
      display: "none",
    },
  },

  circle: {
    position: "absolute",
    right: 40,
    transform: "translate(0, 200px)",

    [breakpoints.down("sm")]: {
      display: "none",
    },
  },

  orbs1: {
    position: "absolute",
    left: 40,
    transform: "translate(0, 140px)",
    mixBlendMode: palette.themeColor("lighten", "difference"),

    [breakpoints.down("sm")]: {
      display: "none",
    },
  },

  orbs2: {
    position: "absolute",
    right: 40,
    mixBlendMode: palette.themeColor("lighten", "difference"),

    [breakpoints.down("sm")]: {
      display: "none",
    },
  },

  blur: {
    position: "absolute",
    width: "100%",
    left: "50%",
    top: 0,
    transform: "translate(-50%, 0)",
    opacity: 0.7,
    mixBlendMode: palette.themeColor("lighten", "normal"),
  },

  wave: {
    position: "absolute",
    width: "100%",
    left: "50%",
    bottom: 0,
    transform: "translate(-50%, 0)",
    mixBlendMode: palette.themeColor("lighten", "difference"),
  },

  bgWrapper: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    left: 0,
    top: 0,
    overflow: "hidden",
  },

  wrapper: {
    background: palette.colors.background3,
  },
}))

type ErrorPageProps = {
  code: number
  text: string
}

export const ErrorPage = ({ code, text }: ErrorPageProps) => {
  const classes = useStyles()

  const { palette } = useTheme()

  return (
    <div className={classes.wrapper}>
      <div className={classes.bgWrapper}>
        <img
          className={classes.blur}
          alt=""
          src={palette.themeColor(
            "/images/dark-blur.svg",
            "/images/light-blur.svg",
          )}
        />

        <img
          className={classes.wave}
          alt=""
          src={palette.themeColor(
            "/images/dark-wave2.png",
            "/images/light-wave2.png",
          )}
        />
      </div>

      <VStack
        className={classes.root}
        alignItems="center"
        justifyContent="center"
        space={0}
        flex={1}
      >
        <Typography className={classes.errorText} variant="h1">
          {code}
        </Typography>
        <Typography size="large" color="textSecondary">
          {text}
        </Typography>
        <Button
          className={classes.button}
          component={Link}
          to="/"
          variant="primary"
          size="large"
        >
          Back home
        </Button>

        <img
          className={classes.cube}
          alt=""
          src={palette.themeColor(
            "/images/dark-cube.png",
            "/images/light-cube.png",
          )}
        />
        <img
          className={classes.circle}
          alt=""
          src={palette.themeColor(
            "/images/dark-circle.png",
            "/images/light-circle.png",
          )}
        />
        <img className={classes.orbs1} alt="" src="/images/light-orbs1.png" />
        <img className={classes.orbs2} alt="" src="/images/light-orbs2.png" />
      </VStack>
    </div>
  )
}
