import {
  Box,
  Link,
  Paper,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
  Theme,
  SvgIcon,
} from "@mui/material"
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import BigNumber from "bignumber.js"
import { utils } from "ethers"
import React, { useCallback, useState } from "react"
import { Series } from "../../../Contexts/SirenMarketsContext"
import { CoinInfoLinks } from "../../../helpers/CoinInfoLinks"
import { useChartData } from "../../../Hooks/useChartData"
import { ChartPeriod } from "../../../constants"
import { HStack, VStack, Chart, TokenAvatar, Typography } from "../../common"
import ExternalLinkSvg from "../../icons/ExternalLinkSvg"

const useStyles = makeStyles<Theme>(({ spacing, palette, breakpoints }) => ({
  root: {
    padding: spacing(4),
    overflow: "hidden",
    background: palette.colors.background2,

    [breakpoints.down("sm")]: {
      borderRadius: 0,
      padding: spacing(3),
    },
  },
  title: {
    fontWeight: 700,

    [breakpoints.down("sm")]: {
      maxWidth: 180,
    },
  },
  chart: {
    height: 150,
  },
  tabs: {
    minHeight: 0,
    alignItems: "center",
    border: `1px solid ${palette.colors.background1}`,
    borderRadius: 5,
  },
  tab: {
    height: 30,
    minHeight: 0,
    minWidth: "0 !important",
    background: "transparent",
    padding: "0 16px",
    fontSize: 14,
    fontWeight: 400,
    border: "1px solid transparent",

    "&:first-child": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },

    "&:last-child": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },

    "&:hover": {
      border: `1px solid ${palette.colors.primary}`,
      color: palette.colors.textPrimary,
    },

    "&.Mui-selected": {
      backgroundColor: palette.colors.background1,
      border: "1px solid transparent",
    },
  },
  price: {
    position: "absolute",
    top: "100%",
    left: 0,
    color: palette.colors.primary,
  },
}))

type Props = {
  series: Series
}

const TradeOptionsHeader = ({ series }: Props) => {
  const classes = useStyles()

  const [chartEnabled, setChartEnabled] = useState(true)

  const { data, period, setPeriod, enabled } = useChartData(
    series.underlyingTokenSymbol,
  )

  const { breakpoints } = useTheme()

  const mobile = useMediaQuery(breakpoints.down("sm"))

  const toggleChart = useCallback(() => {
    setChartEnabled((value) => !value)
  }, [setChartEnabled])

  return (
    <VStack>
      <HStack
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        px={mobile ? 2 : 0}
      >
        <HStack alignItems="center" marginBottom={4}>
          <TokenAvatar tokenSymbol={series.underlyingTokenSymbol} size={36} />
          <Box position="relative">
            <Typography
              size="medium"
              className={classes.title}
              title={series.underlyingTokenName}
              noWrap
            >
              {series.underlyingTokenName}
            </Typography>

            {mobile && (
              <Typography size="extra-small" color="textSecondary" noWrap>
                {series.pair}
              </Typography>
            )}

            <Typography variant="h2" className={classes.price}>
              $
              {utils.commify(
                new BigNumber(series.paymentPerUnderlying)
                  .decimalPlaces(2)
                  .toNumber(),
              )}
            </Typography>
          </Box>

          {!mobile && (
            <Typography size="extra-small" color="textSecondary" noWrap>
              {series.pair}
            </Typography>
          )}
        </HStack>

        {enabled && (
          <Link
            variant="action"
            component="button"
            onClick={toggleChart}
            underline="none"
          >
            <HStack alignItems="center" space={1}>
              <span>{chartEnabled ? "Hide Chart" : "Show Chart"}</span>

              <SvgIcon
                fontSize="small"
                component={chartEnabled ? ArrowDropUp : ArrowDropDown}
              />
            </HStack>
          </Link>
        )}
      </HStack>

      {chartEnabled && enabled && (
        <Paper className={classes.root} elevation={0}>
          <VStack space={2}>
            <HStack alignItems="center" justifyContent="space-between">
              <Tabs
                value={period}
                onChange={(_, value) => setPeriod(value)}
                indicatorColor="primary"
                textColor="primary"
                className={classes.tabs}
              >
                {Object.values(ChartPeriod).map((value) => (
                  <Tab
                    key={value}
                    className={classes.tab}
                    label={value.toUpperCase()}
                    value={value}
                  />
                ))}
              </Tabs>

              {!mobile && (
                <Link
                  href={CoinInfoLinks[series.collateralTokenSymbol]}
                  underline="none"
                  target="_blank"
                  rel="noreferrer noopener"
                  color="colors.green"
                  fontSize={10}
                >
                  <HStack space={0.5}>
                    <span>See the details on CoinGecko</span>
                    <SvgIcon component={ExternalLinkSvg} fontSize="small" />
                  </HStack>
                </Link>
              )}
            </HStack>

            <Chart
              className={classes.chart}
              data={data ?? []}
              period={period}
              loading={!data}
              background="background2"
            />

            {mobile && (
              <Link
                href={CoinInfoLinks[series.collateralTokenSymbol]}
                underline="none"
                target="_blank"
                rel="noreferrer noopener"
                color="colors.green"
                fontSize={10}
              >
                <HStack space={0.5}>
                  <span>See the details on CoinGecko</span>
                  <SvgIcon component={ExternalLinkSvg} fontSize="small" />
                </HStack>
              </Link>
            )}
          </VStack>
        </Paper>
      )}
    </VStack>
  )
}
export default TradeOptionsHeader
