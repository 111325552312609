import { Paper, Theme } from "@mui/material"
import React, { useMemo } from "react"
import { rangeRight, sortBy, uniqBy } from "lodash"
import BigNumber from "bignumber.js"
import { useQuery } from "@apollo/client"
import { makeStyles } from "@mui/styles"

import { poolValueSnapshotsQuery } from "../../../../Contexts/graphQueries"
import {
  PoolValueSnapshots,
  PoolValueSnapshotsVariables,
  PoolValueSnapshots_poolValueSnapshots,
} from "../../../../Contexts/types/PoolValueSnapshots"
import { LiquidityPool } from "../../../../Contexts/SirenMarketsContext"
import { Typography, VStack, Chart, ChartValueFormat } from "../../../common"
import { ChartPeriod, UPGRADE_TIMESTAMP } from "../../../../constants"
import { useLanguageContext } from "../../../../Contexts/LanguageContext"
import { BarPrice } from "lightweight-charts"
import dayjs from "dayjs"

const useStyles = makeStyles<Theme>(({ palette, spacing, breakpoints }) => ({
  backIcon: {
    height: 60,
    width: 60,
  },
  detailsRow: {
    borderBottom: `1px solid ${palette.colors.stroke}`,
    height: 40,
  },
  icon: {
    width: 12,
    fill: palette.colors.green,
  },
  chartPaper: {
    padding: 0,

    [breakpoints.down("sm")]: {
      marginLeft: -32,
      marginRight: -32,
      borderRadius: 0,
    },
  },
  chart: {
    width: "100%",
    height: 250,
    borderRadius: 8,
    overflow: "hidden",
  },
  tabs: {
    minHeight: 0,
    height: 36,
    alignItems: "center",
  },
  tab: {
    minHeight: 0,
    minWidth: "0 !important",
    background: "transparent",
    padding: 0,
    fontSize: 14,
    fontWeight: 400,
    "&:not(:last-child)": {
      marginRight: spacing(3),
    },
  },
}))

const calcLpTokenValue = (
  snapshot: PoolValueSnapshots_poolValueSnapshots,
  pool: LiquidityPool,
) => {
  const poolValue = new BigNumber(snapshot.poolValue).shiftedBy(
    -pool.lpTokenDecimals,
  )
  const lpTokenSupply = new BigNumber(snapshot.lpTokenSupply).shiftedBy(
    -pool.lpTokenDecimals,
  )

  return poolValue.div(lpTokenSupply)
}

const calcPerfomanceChartData = (
  snapshots: PoolValueSnapshots_poolValueSnapshots[],
  pool: LiquidityPool,
) => {
  let lpTokenValues = snapshots
    .filter((item) => parseFloat(item.poolValue) !== 0)
    .map((snapshot) => ({
      value: calcLpTokenValue(snapshot, pool),
      time: Number(snapshot.createdTimestamp),
    }))

  const initialLpTokenValue = lpTokenValues[0]?.value

  if (!initialLpTokenValue) {
    return []
  }

  lpTokenValues = [
    ...lpTokenValues,
    {
      value: new BigNumber(pool.currentLpTokenValue),
      time: dayjs().unix(),
    },
  ]

  return lpTokenValues.map((item) => ({
    value: item.value
      .minus(initialLpTokenValue)
      .div(initialLpTokenValue)
      .multipliedBy(100)
      .decimalPlaces(2)
      .toNumber(),
    time: item.time,
  }))
}

const EMPTY_CHART_VALUES = [60, 80, 70, 80, 90, 70, 80]

const generateEmptyChartData = () => {
  const today = dayjs()

  return rangeRight(7).map((i, index) => ({
    time: today.subtract(i, "day").unix(),
    value: EMPTY_CHART_VALUES[index],
  }))
}

const PoolHistoricals: React.FC<{ pool: LiquidityPool }> = ({ pool }) => {
  const classes = useStyles()
  const { formatDate, formatPercents } = useLanguageContext()

  const { data: poolValueSnapshots } = useQuery<
    PoolValueSnapshots,
    PoolValueSnapshotsVariables
  >(poolValueSnapshotsQuery, {
    variables: {
      where: {
        amm: pool!.address,
        createdTimestamp_gte: UPGRADE_TIMESTAMP,
      },
    },
    skip: !pool?.address,
  })

  const { chartData, isEmpty } = useMemo(() => {
    const result = calcPerfomanceChartData(
      sortBy(
        uniqBy(
          poolValueSnapshots?.poolValueSnapshots ?? [],
          "createdTimestamp",
        ),
        "createdTimestamp",
      ),
      pool,
    )

    const isEmpty = result.length === 0

    return {
      chartData: isEmpty ? generateEmptyChartData() : result,
      isEmpty,
    }
  }, [poolValueSnapshots, pool])

  return (
    <VStack space={2}>
      <Typography variant="h2">Historicals</Typography>

      {/* <HStack justifyContent="space-between">
        <ToggleButtonGroup value="Perfomance">
          <ToggleButton value="Perfomance">Perfomance</ToggleButton>
        </ToggleButtonGroup>
      </HStack> */}

      <Paper elevation={0} className={classes.chartPaper}>
        <VStack p={4} space={1}>
          <Typography size="small" weight="bold">
            Perfomance
          </Typography>

          <Typography size="extra-small" color="textSecondary">
            {formatDate(new Date(), {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </Typography>

          <Chart
            className={classes.chart}
            data={chartData}
            period={ChartPeriod.Max}
            valueFormat={ChartValueFormat.Percent}
            loading={!poolValueSnapshots}
            options={{
              timeScale: {
                visible: true,
              },
            }}
            seriesOptions={{
              priceFormat: {
                type: "custom",
                formatter: (value: BarPrice) => formatPercents(value),
              },
            }}
            empty={isEmpty}
            background="background0"
          />
        </VStack>
      </Paper>
    </VStack>
  )
}

export default PoolHistoricals
