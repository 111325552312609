import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import {
  Box,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import React, { useMemo, useState } from "react"

import { usePools } from "../../../../Contexts/SirenMarketsContext"
import { useMedia } from "../../../../Hooks/useMedia"
import { HStack, InfoPopover, TableSkeleton } from "../../../common"
import { EarnListRow } from "./EarnListRow"

export const EarnList = () => {
  const [selectedPoolId, setSelectedPoolId] = useState<string>()
  const [inactivePoolsShown, setInactivePoolsShown] = useState(false)

  const { mobile } = useMedia()

  const { pools } = usePools()

  const activePools = useMemo(
    () =>
      pools
        .filter((p) => (inactivePoolsShown ? true : p.isActive))
        .sort((a, b) => b.projectedYield - a.projectedYield),
    [pools, inactivePoolsShown],
  )

  if (activePools.length === 0) {
    return <TableSkeleton columns={mobile ? 3 : 4} />
  }

  return (
    <Box mx={mobile ? -2 : 0}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {mobile ? (
                <TableCell>
                  <InfoPopover text="Total value of all assets in the pool (in collateral asset)">
                    <span>Asset/Pool Value</span>
                  </InfoPopover>
                </TableCell>
              ) : (
                <TableCell>Asset</TableCell>
              )}
              {!mobile && (
                <TableCell align="right">
                  <InfoPopover text="Total value of all assets in the pool (in collateral asset)">
                    <span>Pool Value</span>
                  </InfoPopover>
                </TableCell>
              )}
              <TableCell align="right">
                <InfoPopover text="APY from options writing and SI rewards">
                  <span>Pool APY</span>
                </InfoPopover>
              </TableCell>
              <TableCell align="right">
                <InfoPopover text="Number of LP Tokens of that pool that you currently hold">
                  <span>My LP Tokens</span>
                </InfoPopover>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activePools?.length > 0 &&
              activePools.map((pool) => (
                <EarnListRow
                  key={pool.address}
                  pool={pool}
                  selected={pool.address === selectedPoolId}
                  onClick={(address) => setSelectedPoolId(address)}
                />
              ))}

            {pools.length > activePools.length && (
              <TableRow>
                <TableCell colSpan={4} height={44}>
                  <Link
                    variant="action"
                    underline="none"
                    component="button"
                    onClick={() => setInactivePoolsShown((value) => !value)}
                  >
                    <HStack space={0.5} alignItems="center" inline>
                      <span>
                        {inactivePoolsShown ? "Hide" : "Show"} inactive pools
                      </span>
                      <SvgIcon
                        component={
                          inactivePoolsShown ? ArrowDropDown : ArrowDropUp
                        }
                        fontSize="inherit"
                      />
                    </HStack>
                  </Link>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
