import React from "react"

interface ITransactionBuySvg {
  className?: string
}

const TransactionBuySvg = ({ className }: ITransactionBuySvg) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill="url(#paint0_radial_6235_36773)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63371 15.3663C8.45543 15.188 8.45543 14.899 8.63371 14.7207L14.7207 8.63371C14.899 8.45543 15.188 8.45543 15.3663 8.63371C15.5446 8.812 15.5446 9.10105 15.3663 9.27933L9.27933 15.3663C9.10105 15.5446 8.812 15.5446 8.63371 15.3663Z"
        fill="#0A1C2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0435 15.5C14.7913 15.5 14.587 15.2956 14.587 15.0435L14.587 9.41304L8.95652 9.41304C8.70439 9.41304 8.5 9.20865 8.5 8.95652C8.5 8.70439 8.70439 8.5 8.95652 8.5L15.0435 8.5C15.2956 8.5 15.5 8.70439 15.5 8.95652L15.5 15.0435C15.5 15.2956 15.2956 15.5 15.0435 15.5Z"
        fill="#0A1C2C"
      />
      <defs>
        <radialGradient
          id="paint0_radial_6235_36773"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.6 3.67021) rotate(46.6371) scale(27.9632 25.4247)"
        >
          <stop stopColor="#D5FFF2" />
          <stop offset="0.526042" stopColor="#48E8B8" />
          <stop offset="1" stopColor="#0FBF8B" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default TransactionBuySvg
