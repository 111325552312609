import { useWeb3 } from "@chainsafe/web3-context"
import { Close } from "@mui/icons-material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import BigNumber from "bignumber.js"
import dayjs from "dayjs"
import { constants } from "ethers"
import React, { useCallback, useEffect, useMemo, useState } from "react"

import ShoppingCartSvg from "../../../Components/icons/ShoppingCartSvg"
import { useLanguageContext } from "../../../Contexts/LanguageContext"
import { createSignedOrder, LightOrder } from "../../../helpers/lightswapHelper"

import { useAddresses, useSeries } from "../../../Contexts/SirenMarketsContext"
import { HStack, MainLayout, NumericInput, Select, VStack } from "../../common"
import { useSearchParams } from "react-router-dom"

const useStyles = makeStyles<Theme>(({ palette, breakpoints }) => ({
  root: {
    position: "relative",
    overflow: "hidden",
    paddingTop: 80,

    [breakpoints.down("md")]: {
      paddingTop: 40,
    },

    [breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  paper: {
    position: "relative",
    padding: 40,
    maxWidth: 750,
    zIndex: 1,

    backgroundColor: palette.colors.background0,

    [breakpoints.down("md")]: {
      maxWidth: 680,
    },

    [breakpoints.down("sm")]: {
      borderRadius: 0,
      maxWidth: "none",
      height: "100%",
    },
  },
  icon: {
    color: palette.colors.primary,
    height: 33,
    width: 28,
  },
  toggleButtonGroup: {
    [breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  orbs1: {
    position: "absolute",
    left: -32,
    bottom: 24,
    transform: "translate(-100%, 0)",

    [breakpoints.down("md")]: {
      bottom: 0,
      transform: "translate(0, 100%)",
      left: 0,
    },

    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
  orbs2: {
    position: "absolute",
    right: -48,
    top: -135,
    transform: "translate(100%, 0)",

    [breakpoints.down("md")]: {
      display: "none",
    },
  },
  wave: {
    position: "absolute",
    width: "100%",
    left: "50%",
    top: 515,
    transform: "translate(-50%, -80%)",
    mixBlendMode: "lighten",

    [breakpoints.down("md")]: {
      top: 765,
    },

    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))

export const DirectBuyPage: React.FC<{}> = () => {
  const classes = useStyles()

  const { tokens, address, provider, network } = useWeb3()
  const { series, seriesFetched } = useSeries()
  const { selectedLocale } = useLanguageContext()
  const { lightswap1155Address } = useAddresses()
  const [params] = useSearchParams()

  const [selectedSeriesId, setSelectedSeriesId] = useState<string | undefined>(
    undefined,
  )

  const [quantity, setQuantity] = useState<string>(
    params.get("quantity") || "0",
  )
  const [price, setPrice] = useState<string>(params.get("price") || "0")
  const [order, setOrder] = useState<LightOrder | undefined>()
  const [copyCodeDialogOpen, setCopyCodeDialogOpen] = useState(false)

  const selectedSeries = useMemo(
    () =>
      selectedSeriesId
        ? series.find((s) => s.seriesId === selectedSeriesId)
        : undefined,
    [series, selectedSeriesId],
  )

  const offerAmount = useMemo(
    () => Number(price) * Number(quantity),
    [price, quantity],
  )

  const [optionType, setOptionType] = useState<"Call" | "Put">(
    // @ts-ignore
    params.get("type") || "Call",
  )

  const handleChangeOptionType = useCallback(
    (e: React.MouseEvent<HTMLElement>, newValue: "Call" | "Put" | null) => {
      newValue !== null && setOptionType(newValue)
    },
    [],
  )

  const [selectedExpiryDate, setSelectedExpiryDate] = useState<
    Number | undefined
  >()

  const expiryDates = [
    ...new Set(
      series
        .filter(
          (s) =>
            s.underlyingTokenSymbol.toLowerCase() === "weth" &&
            s.status === "open" &&
            s.type === optionType &&
            s.seriesDeployed,
        )
        .map((s) => dayjs(s.expiration).unix())
        .sort((a, b) => a - b),
    ),
  ]

  const filteredSeries = useMemo(
    () =>
      series.filter(
        (s) =>
          s.underlyingTokenSymbol.toLowerCase() === "weth" &&
          s.status === "open" &&
          s.type === optionType &&
          dayjs(s.expiration).unix() === selectedExpiryDate &&
          s.seriesDeployed,
      ),
    [optionType, selectedExpiryDate, series],
  )

  const createOrder = useCallback(
    async (quantity: string, offerAmount: number) => {
      if (
        !selectedSeries ||
        !tokens ||
        !tokens[selectedSeries.collateralTokenAddress] ||
        !address ||
        !lightswap1155Address ||
        !provider ||
        !network
      )
        return

      const token = tokens[selectedSeries.collateralTokenAddress]
      const allowance = new BigNumber(
        token.allowance
          ? (await token.allowance(address, lightswap1155Address)).toString()
          : "0",
      ).shiftedBy(-selectedSeries.collateralTokenDecimals)

      if (allowance.lt(new BigNumber(offerAmount))) {
        token.approve &&
          (await (
            await token.approve(lightswap1155Address, constants.MaxUint256)
          ).wait())
      }
      const order = await createSignedOrder(
        {
          signerTokenAddress: selectedSeries.collateralTokenAddress,
          signerAmount: new BigNumber(offerAmount).shiftedBy(
            selectedSeries.collateralTokenDecimals,
          ),
          senderAmount: new BigNumber(quantity).shiftedBy(
            selectedSeries.bTokenDecimals,
          ),
          senderAddress: selectedSeries.ammAddress,
          senderTokenAddress: selectedSeries.erc1155ControllerAddress,
          tokenIndex: selectedSeries.bTokenIndex,
        },
        provider.getSigner(),
        network,
        selectedSeries.seriesIndex,
        lightswap1155Address,
      )
      setOrder(order)

      setCopyCodeDialogOpen(true)
    },
    [selectedSeries, tokens, address, provider, network, lightswap1155Address],
  )

  const handleCopyCodeDialogClose = useCallback(() => {
    setCopyCodeDialogOpen(false)
  }, [setCopyCodeDialogOpen])

  const handleCopyCode = useCallback(() => {
    if (order) {
      navigator.clipboard.writeText(JSON.stringify(order))
    }
  }, [order])

  useEffect(() => {
    if (selectedSeriesId) return
    if (series.length === 0) return

    const expiration = Number(params.get("expiration"))
    const strikePrice = Number(params.get("strikePrice"))

    if (!expiration || !strikePrice) return

    const querySelectedSeries = series.find(
      (s) =>
        s.underlyingTokenSymbol.toLowerCase() === "weth" &&
        dayjs(s.expiration).unix() === expiration &&
        s.strike === strikePrice &&
        s.type === optionType,
    )

    if (!querySelectedSeries) return

    setSelectedSeriesId(querySelectedSeries?.seriesId)
  }, [optionType, params, selectedSeriesId, series])

  useEffect(() => {
    selectedSeries &&
      setSelectedExpiryDate(dayjs(selectedSeries.expiration).unix())
  }, [selectedSeries])

  const disabled = !seriesFetched || series.length === 0

  return (
    <MainLayout.Content
      display="flex"
      alignItems="center"
      className={classes.root}
    >
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid xs={12} sm={6} item>
            <HStack space={2} alignItems="center">
              <ShoppingCartSvg className={classes.icon} />
              <Typography variant="h3">Direct Buy</Typography>
            </HStack>
          </Grid>
          <Grid xs={12} sm={6} item container justifyContent="flex-end">
            <ToggleButtonGroup
              className={classes.toggleButtonGroup}
              value={optionType}
              exclusive
              onChange={handleChangeOptionType}
            >
              <ToggleButton value="Call">Call</ToggleButton>
              <ToggleButton value="Put">Put</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid xs={12} sm={6} item>
            <VStack space={1}>
              <Select
                variant="outlined"
                size="large"
                value={selectedExpiryDate || ""}
                onChange={(e) =>
                  setSelectedExpiryDate(e.target.value as number)
                }
                disabled={disabled}
                fullWidth
                displayEmpty
              >
                <MenuItem disabled value="">
                  {seriesFetched
                    ? "Select an expiry date"
                    : "Loading expiry dates..."}
                </MenuItem>
                {expiryDates
                  .sort((a, b) => a - b)
                  .map((d) => (
                    <MenuItem value={d} key={d}>
                      {dayjs.unix(d).format("YYYY-MM-DD")}
                    </MenuItem>
                  ))}
              </Select>
              <Typography variant="caption" color="textSecondary">
                Expiry date
              </Typography>
            </VStack>
          </Grid>
          <Grid xs={12} sm={6} item>
            <VStack space={1}>
              <Select
                variant="outlined"
                size="large"
                value={selectedSeriesId || ""}
                onChange={(e) => setSelectedSeriesId(String(e.target.value))}
                disabled={!selectedExpiryDate || disabled}
                fullWidth
                displayEmpty
              >
                <MenuItem disabled value="">
                  {seriesFetched ? "Select a series" : "Loading series..."}
                </MenuItem>
                {filteredSeries.map((s) => (
                  <MenuItem value={s.seriesId} key={s.seriesId}>{`${dayjs(
                    s.expiration,
                  ).format("YYYY-MM-DD")} ${s.underlyingTokenSymbol} ${
                    s.type
                  } ${s.strike}`}</MenuItem>
                ))}
              </Select>
              <Typography variant="caption" color="textSecondary">
                Series
              </Typography>
            </VStack>
          </Grid>
          <Grid xs={12} sm={6} item>
            <NumericInput
              type="number"
              min={0}
              value={quantity}
              onChange={setQuantity}
              label="# of Contracts"
              disabled={!selectedSeries}
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <NumericInput
              type="number"
              min={0}
              value={price}
              onChange={setPrice}
              label={`Price per options (${
                selectedSeries?.collateralTokenSymbol || ""
              })`}
              disabled={!selectedSeries}
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <VStack space={1}>
              <Typography variant="caption" color="textSecondary">
                Order total
              </Typography>
              <Typography variant="h1" title={String(offerAmount)} noWrap>
                {offerAmount.toLocaleString(selectedLocale, {
                  maximumFractionDigits: 8,
                })}
              </Typography>
            </VStack>
          </Grid>
          <Grid xs={12} sm={6} item>
            {selectedSeries &&
              tokens[selectedSeries.collateralTokenAddress]?.balanceBN.lt(
                new BigNumber(offerAmount),
              ) && (
                <Typography textAlign="center" color="red">
                  Not enough{" "}
                  {tokens[selectedSeries.collateralTokenAddress].symbol}
                </Typography>
              )}
            <Button
              onClick={() => createOrder(quantity || "0", offerAmount || 0)}
              variant="primary"
              size="large"
              disabled={
                !selectedSeries ||
                Number(quantity) <= 0 ||
                Number(offerAmount) <= 0 ||
                tokens[selectedSeries.collateralTokenAddress]?.balanceBN.lt(
                  new BigNumber(offerAmount),
                )
              }
              fullWidth
            >
              Sign order
            </Button>
          </Grid>
        </Grid>

        <img className={classes.orbs1} alt="" src="/images/orbs1.png" />
        <img className={classes.orbs2} alt="" src="/images/orbs2.png" />
      </Paper>

      <img className={classes.wave} alt="" src="/images/wave1.png" />

      <Dialog
        open={copyCodeDialogOpen}
        onClose={handleCopyCodeDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <HStack justifyContent="space-between" alignItems="center">
            <Typography variant="h3">Copy JSON Code</Typography>
            <IconButton
              aria-label="Close dialog"
              onClick={handleCopyCodeDialogClose}
              size="small"
            >
              <Close />
            </IconButton>
          </HStack>
        </DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            defaultValue={order ? JSON.stringify(order) : ""}
            disabled
            fullWidth
            multiline
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button variant="primary" size="small" onClick={handleCopyCode}>
            Copy code
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayout.Content>
  )
}
