import { Button, useMediaQuery, useTheme } from "@mui/material"
import React, { useEffect, useState, useRef, useMemo } from "react"
import { LiquidityPool } from "../../../../Contexts/SirenMarketsContext"
import { useWeb3 } from "@chainsafe/web3-context"
import { utils } from "ethers"
import { debounce } from "lodash"
import BigNumber from "bignumber.js"

import { numericRegex } from "../../../../helpers/numericRegex"
import {
  EqualsSign,
  Details,
  Side,
  VStack,
  NumericInput,
  TxInProgress,
  TxSuccess,
  TxFailure,
  LiFiBridgeButton,
  CheckNetworkButton,
} from "../../../common"
import { usePools } from "../../../../Contexts/SirenMarketsContext"

const ProvideLiquidityDialog: React.FC<{
  pool: LiquidityPool
  clearSelection(): void
}> = ({ pool, clearSelection }) => {
  const { tokens } = useWeb3()

  const { breakpoints } = useTheme()
  const desktop = useMediaQuery(breakpoints.up("md"))

  const inputRef = useRef<HTMLInputElement | null>(null)
  const tokenBalance =
    tokens[pool.collateralTokenAddress]?.balanceBN || new BigNumber(0)

  const [collateralAmount, setCollateralAmount] = useState(
    BigNumber.min(0, tokenBalance).toString(),
  )
  const [expectedLPTokens, setExpectedLPTokens] = useState(0)
  const { quoteProvideCapital, provideCapital } = usePools()
  const [txStatus, setTxStatus] = useState<
    "init" | "inProgress" | "success" | "error"
  >("init")
  const [isQuoting, setIsQuoting] = useState(false)

  const getQuote = useMemo(
    () =>
      debounce(
        async (quoteAmount: BigNumber) => {
          setIsQuoting(true)

          const result = await quoteProvideCapital(quoteAmount, pool.address)

          result && setExpectedLPTokens(result)

          setIsQuoting(false)

          inputRef.current && inputRef.current.focus()
        },
        500,
        { trailing: true },
      ),
    [quoteProvideCapital, pool.address],
  )

  useEffect(() => {
    setCollateralAmount("0")
    // eslint-disable-next-line
  }, [pool])

  useEffect(() => {
    collateralAmount &&
      collateralAmount !== "" &&
      numericRegex.test(collateralAmount) &&
      getQuote(new BigNumber(collateralAmount))
  }, [collateralAmount, pool, getQuote])

  const handleProvideCapital = async () => {
    setTxStatus("inProgress")
    try {
      await provideCapital(
        new BigNumber(collateralAmount),
        expectedLPTokens,
        pool.address,
      )
      setTxStatus("success")
    } catch (error) {
      console.log(error)
      setTxStatus("error")
    }
  }

  if (txStatus === "inProgress") {
    return <TxInProgress message="Adding your liquidity to the pool..." />
  } else if (txStatus === "success") {
    return (
      <TxSuccess
        buttonMessage="Close"
        message="Your liquidity has been added to the pool."
        onConfirm={clearSelection}
      />
    )
  } else if (txStatus === "error") {
    return <TxFailure clearSelection={clearSelection} />
  }

  return (
    <>
      <Side.Content>
        <Details mb={2}>
          {!desktop && <Details.Row label="Pool" value={pool.name} />}
          <Details.Row
            label="Available Balance"
            value={utils.commify(
              new BigNumber(tokenBalance).decimalPlaces(4).toString(),
            )}
          />
        </Details>

        <VStack space={1}>
          <NumericInput
            min={0}
            max={tokenBalance}
            value={collateralAmount}
            label={pool.collateralTokenSymbol}
            onChange={setCollateralAmount}
            ref={inputRef}
            errorMessage={
              new BigNumber(collateralAmount).gt(tokenBalance)
                ? "Not enough tokens"
                : undefined
            }
          />

          <LiFiBridgeButton
            balance={tokenBalance.toNumber()}
            amount={Number(collateralAmount)}
            symbol={pool.collateralTokenSymbol}
            toToken={pool.collateralTokenAddress}
          />

          <EqualsSign />

          <NumericInput
            value={expectedLPTokens.toString()}
            label="LP Tokens"
            disabled
          />
        </VStack>
      </Side.Content>

      <Side.Action>
        <CheckNetworkButton>
          <Button
            variant="primary"
            onClick={handleProvideCapital}
            fullWidth
            disabled={
              collateralAmount === "" ||
              collateralAmount === "0" ||
              !numericRegex.test(collateralAmount) ||
              new BigNumber(collateralAmount).lte(0) ||
              new BigNumber(collateralAmount).gt(tokenBalance) ||
              isQuoting
            }
          >
            Provide
          </Button>
        </CheckNetworkButton>
      </Side.Action>
    </>
  )
}

export default ProvideLiquidityDialog
