import { useQuery } from "@apollo/client"
import { useWeb3 } from "@chainsafe/web3-context"
import {
  useMediaQuery,
  useTheme,
  Theme,
  Paper,
  SvgIcon,
  Link,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import BigNumber from "bignumber.js"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { utils } from "ethers"
import { cloneDeep, first } from "lodash"
import React, { useMemo } from "react"
import { Link as RouterLink } from "react-router-dom"

import { accountPoolActivity } from "../../../../Contexts/graphQueries"
import { useLanguageContext } from "../../../../Contexts/LanguageContext"
import { LiquidityPool } from "../../../../Contexts/SirenMarketsContext"
import {
  GetPoolActivityForAccount,
  GetPoolActivityForAccountVariables,
} from "../../../../Contexts/types/GetPoolActivityForAccount"
import {
  Details,
  HStack,
  NumberTypography,
  Stack,
  TokenAvatar,
  Typography,
  VStack,
} from "../../../common"
import BackSvg from "../../../icons/BackSvg"
import ExternalLinkSvg from "../../../icons/ExternalLinkSvg"
import { useSirenConfig } from "../../../../Contexts/SirenConfigContext"
import { SirenChainConfig } from "../../../../config"

import PoolHistoricals from "./PoolHistoricals"
import PoolStructure from "./PoolStructure"
import PoolRecentTransactions from "./PoolRecentTransactions"

dayjs.extend(relativeTime)

const useStyles = makeStyles<Theme>(({ palette, breakpoints }) => ({
  backIcon: {
    width: 60,
    height: 60,
    color: palette.colors.primary,

    [breakpoints.down("sm")]: {
      height: 32,
      width: 32,
    },
  },
  icon: {
    width: 12,
    fill: palette.colors.green,
  },
  avatar: {
    width: 32,
    height: 32,
  },
  paper: {
    padding: 0,

    [breakpoints.down("sm")]: {
      marginLeft: -32,
      marginRight: -32,
      borderRadius: 0,
    },
  },
}))

const calcMyReturn = (
  poolActivityForAccount: GetPoolActivityForAccount,
  pool: LiquidityPool,
) => {
  const allBuys = poolActivityForAccount.ammTokenEvents
    .sort((a, b) => a.timestamp - b.timestamp)
    .filter((te) => te.eventType[0] === "LpTokenMint")
    .map((te) => ({
      tokenAmount: new BigNumber(te.tokenAmount).shiftedBy(
        -pool.lpTokenDecimals,
      ),
      collateralAmount: new BigNumber(te.collateralAmount).shiftedBy(
        -pool.collateralTokenDecimals,
      ),
    }))

  let relevantBuys: Array<{
    tokenAmount: BigNumber
    collateralAmount: BigNumber
  }> = []
  let remainingBalance = pool.userBalance || new BigNumber(0)
  let i = 0
  while (remainingBalance.gt(0) && i < allBuys.length) {
    if (allBuys[i].tokenAmount.lte(remainingBalance)) {
      relevantBuys.push(allBuys[i])
      remainingBalance = remainingBalance.minus(allBuys[i].tokenAmount)
    } else {
      const collateralAmount = allBuys[i].collateralAmount
        .div(allBuys[i].tokenAmount)
        .multipliedBy(remainingBalance)
      relevantBuys.push({
        tokenAmount: remainingBalance,
        collateralAmount,
      })
      remainingBalance = new BigNumber(0)
    }
    i++
  }
  const summed = relevantBuys.reduce(
    (acc, buy) => {
      acc.collateralAmount = acc.collateralAmount.plus(buy.collateralAmount)
      acc.tokenAmount = acc.tokenAmount.plus(buy.tokenAmount)
      return acc
    },
    {
      collateralAmount: new BigNumber(0),
      tokenAmount: new BigNumber(0),
    },
  )
  const averageCost = summed.collateralAmount.div(summed.tokenAmount)
  const result = new BigNumber(pool.currentLpTokenValue)
    .div(averageCost)
    .minus(1)
    .multipliedBy(100)

  return result.decimalPlaces(2).toString()
}

const getBlockExplorerLink = (config: SirenChainConfig, address: string) => {
  const blockExplorerUrl = first(config?.addChainParams?.blockExplorerUrls)

  if (!blockExplorerUrl) {
    return undefined
  }

  return `${blockExplorerUrl}/address/${address}`
}

export const EarnDetails: React.FC<{ pool: LiquidityPool }> = ({ pool }) => {
  const { formatPercents } = useLanguageContext()

  const { address } = useWeb3()

  const { activeConfig } = useSirenConfig()

  const classes = useStyles()
  const { breakpoints } = useTheme()

  const desktop = useMediaQuery(breakpoints.up("md"))
  const mobile = useMediaQuery(breakpoints.down("sm"))

  const skip = !(address && pool && pool.userBalance && pool.userBalance.gt(0))

  const { data: poolActivityForAccount } = useQuery<
    GetPoolActivityForAccount,
    GetPoolActivityForAccountVariables
  >(accountPoolActivity, {
    variables: {
      accountId: address?.toLowerCase() as string,
      ammId: pool?.address as string,
    },
    skip,
  })

  const myReturn = useMemo(
    () =>
      poolActivityForAccount && pool
        ? calcMyReturn(cloneDeep(poolActivityForAccount), pool)
        : 0,
    [pool, poolActivityForAccount],
  )

  if (!pool) {
    return null
  }

  return (
    <>
      <Stack
        direction={mobile ? "vertical" : "horizontal"}
        space={2}
        paddingX={mobile ? 4 : 0}
      >
        {desktop && (
          <RouterLink to="/earn">
            <BackSvg className={classes.backIcon} />
          </RouterLink>
        )}

        <VStack space={4} flex={1} pr={desktop ? 9.5 : 0}>
          <HStack>
            {!desktop && (
              <RouterLink to="/earn">
                <BackSvg className={classes.backIcon} />
              </RouterLink>
            )}

            <VStack space={1} overflow="hidden" flex={1}>
              <Typography
                variant="body"
                weight="bold"
                size="medium"
                color="textSecondary"
              >
                Earn
              </Typography>
              <HStack alignItems="center">
                <TokenAvatar
                  className={classes.avatar}
                  tokenSymbol={pool?.collateralTokenSymbol}
                />

                <Typography variant="h1" noWrap>
                  {`${pool.underlyingTokenName} ${
                    pool.poolType === "Call" ? "Calls" : "Puts"
                  }`}
                </Typography>

                <Link
                  href={getBlockExplorerLink(activeConfig, pool.address)}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                  marginLeft="auto"
                >
                  <HStack space={0.5} alignItems="center" color="colors.green">
                    <Typography size="extra-small" weight="medium" noWrap>
                      Etherscan
                    </Typography>
                    <SvgIcon component={ExternalLinkSvg} fontSize="inherit" />
                  </HStack>
                </Link>
              </HStack>
            </VStack>
          </HStack>

          <Paper className={classes.paper} elevation={0}>
            <VStack px={4} py={3}>
              <Typography variant="h4">Strategy</Typography>
              <Typography size="small" color="textSecondary">
                This strategy earns yield from systematically selling weekly
                options. Options are automatically sold from the pool at a
                strike price corresponding to 0.1 Delta value in order to
                balance the low probability of exercise with higher premiums. If
                options expire out-of-the-money, LPs keep the entire premium
                that is reinvested during the next week sale (auto-compounding).
                If options expire in-the-money LPs keep the premiums paid minus
                the difference between the current asset price and the option
                strike price.
              </Typography>
              <Typography variant="h4">Risks</Typography>
              <Typography size="small" color="textSecondary">
                The collateral in the pool is used to underwrite options. If
                options expire in-the-money, LPs lose a portion of the
                collateral equivalent to the difference between the current
                asset price and the option strike price for each option contract
                sold from the pool.
              </Typography>
            </VStack>
          </Paper>

          <VStack space={0}>
            <Typography variant="h2">Overview</Typography>

            <Details variant="bordered" mt={2}>
              <Details.Row
                label="Pool Value"
                value={`${utils.commify(
                  new BigNumber(pool.poolValueLocked)
                    .decimalPlaces(4)
                    .toString(),
                )} ${pool.collateralTokenSymbol}`}
              />
              <Details.Row
                label={`Pool APY since inception (${dayjs(pool.createdAt).toNow(
                  true,
                )})`}
                value={formatPercents(pool.yieldSinceInception)}
              />
              <Details.Row
                label="Pool APY last 30 days"
                value={formatPercents(pool.yieldLastMonth)}
              />
              <Details.Row
                label="Pool Utilization"
                value={formatPercents(pool.utilization)}
              />
            </Details>
            {pool.userBalance?.gt(0) && poolActivityForAccount && (
              <Details variant="bordered" mt={3}>
                <Details.Row
                  label="My LP tokens"
                  value={pool.userBalance.toString()}
                />
                <Details.Row label="My Return">
                  <NumberTypography
                    size="small"
                    weight="bold"
                    value={Number(myReturn)}
                  >
                    {formatPercents(myReturn)}
                  </NumberTypography>
                </Details.Row>
              </Details>
            )}
          </VStack>
          <PoolHistoricals pool={pool} />
          <PoolStructure pool={pool} />
          <PoolRecentTransactions pool={pool} />
        </VStack>
      </Stack>
    </>
  )
}
