import {
  createTheme as createMuiTheme,
  adaptV4Theme,
  ThemeOptions,
} from "@mui/material"
import { keyframes, alpha } from "@mui/material/styles"
import type {} from "@mui/lab/themeAugmentation"
import { merge } from "lodash"

import {
  PaletteColors,
  DarkPalette,
  LightPalette,
  DarkShadows,
  LightShadows,
  AssetsPalette,
} from "./pallete"

declare module "@mui/material/styles" {
  interface Palette {
    colors: PaletteColors

    shadows: {
      tooltip: string
    }

    themeColor: <T>(dark: T, light: any) => T
    important: (value: string | number) => string
  }

  interface PaletteOptions {
    colors: PaletteColors

    shadows: {
      tooltip: string
    }

    themeColor: <T>(dark: T, light: any) => T
    important: (value: string | number) => string
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    action: true
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true
    secondary: true
    action: true
    support: true
  }
}

declare module "@mui/material/InputBase" {
  interface InputBasePropsSizeOverrides {
    large: true
  }
}

export enum PaletteType {
  Dark = "dark",
  Light = "light",
}

const BREAKPOINTS = {
  values: {
    xs: 0,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1920,
  },
}

const important = (value: string | number) => `${value} !important`

export const createTheme = (paletteType: PaletteType) => {
  const themeColor = <T>(dark: T, light: any) =>
    paletteType === PaletteType.Dark ? dark : light

  let theme = createMuiTheme({
    breakpoints: BREAKPOINTS,
  })

  const themeOptions: ThemeOptions = adaptV4Theme({
    breakpoints: BREAKPOINTS,

    palette: {
      mode: paletteType,

      themeColor,
      important,

      // Our colors

      colors: {
        primary: themeColor(DarkPalette.Primary, LightPalette.Primary),
        primaryLight: themeColor(
          DarkPalette.PrimaryLight,
          LightPalette.PrimaryLight,
        ),
        primaryDark: themeColor(
          DarkPalette.PrimaryDark,
          LightPalette.PrimaryDark,
        ),
        primaryGradient0: themeColor(
          DarkPalette.PrimaryGradient0,
          LightPalette.PrimaryGradient0,
        ),
        primaryGradient1: themeColor(
          DarkPalette.PrimaryGradient1,
          LightPalette.PrimaryGradient1,
        ),
        primaryGradient2: themeColor(
          DarkPalette.PrimaryGradient2,
          LightPalette.PrimaryGradient2,
        ),
        primaryGradient3: themeColor(
          DarkPalette.PrimaryGradient3,
          LightPalette.PrimaryGradient3,
        ),
        primaryGradient4: themeColor(
          DarkPalette.PrimaryGradient4,
          LightPalette.PrimaryGradient4,
        ),
        primaryGradient5: themeColor(
          DarkPalette.PrimaryGradient5,
          LightPalette.PrimaryGradient5,
        ),
        background0: themeColor(
          DarkPalette.Background0,
          LightPalette.Background0,
        ),
        background0Gradient: themeColor(
          DarkPalette.Background0Gradient,
          LightPalette.Background0Gradient,
        ),
        background0Fade: themeColor(
          DarkPalette.Background0Fade,
          LightPalette.Background0Fade,
        ),
        background1: themeColor(
          DarkPalette.Background1,
          LightPalette.Background1,
        ),
        background2: themeColor(
          DarkPalette.Background2,
          LightPalette.Background2,
        ),
        background3: themeColor(
          DarkPalette.Background3,
          LightPalette.Background3,
        ),
        background4: themeColor(
          DarkPalette.Background4,
          LightPalette.Background4,
        ),
        background4Fade: themeColor(
          DarkPalette.Background4Fade,
          LightPalette.Background4Fade,
        ),
        textPrimary: themeColor(
          DarkPalette.TextPrimary,
          LightPalette.TextPrimary,
        ),
        textSecondary: themeColor(
          DarkPalette.TextSecondary,
          LightPalette.TextSecondary,
        ),
        textTertiary: themeColor(
          DarkPalette.TextTertiary,
          LightPalette.TextTertiary,
        ),
        textButton: themeColor(DarkPalette.TextButton, LightPalette.TextButton),
        textDisabled: themeColor(
          DarkPalette.TextDisabled,
          LightPalette.TextDisabled,
        ),
        textLoading: themeColor(
          DarkPalette.TextLoading,
          LightPalette.TextLoading,
        ),
        red: themeColor(DarkPalette.Red, LightPalette.Red),
        redLight: themeColor(DarkPalette.RedLight, LightPalette.RedLight),
        green: themeColor(DarkPalette.Green, LightPalette.Green),
        greenLight: themeColor(DarkPalette.GreenLight, LightPalette.GreenLight),
        greenDark: themeColor(DarkPalette.GreenDark, LightPalette.GreenDark),
        greenDarker: themeColor(
          DarkPalette.GreenDarker,
          LightPalette.GreenDarker,
        ),
        yellow: themeColor(DarkPalette.Yellow, LightPalette.Yellow),
        logo1: themeColor(DarkPalette.Logo1, LightPalette.Logo1),
        logo2: themeColor(DarkPalette.Logo2, LightPalette.Logo2),
        icon: themeColor(DarkPalette.Icon, LightPalette.Icon),
        scrollbar: themeColor(DarkPalette.Scrollbar, LightPalette.Scrollbar),
        toggle: themeColor(DarkPalette.Toggle, LightPalette.Toggle),
        stroke: themeColor(DarkPalette.Stroke, LightPalette.Stroke),
        skeleton: themeColor(DarkPalette.Skeleton, LightPalette.Skeleton),
        twitter: themeColor(DarkPalette.Twitter, LightPalette.Twitter),
        support: themeColor(DarkPalette.Support, LightPalette.Support),
        earnBackgroundTitle1: themeColor(
          DarkPalette.EarnBackgroundTitle1,
          LightPalette.EarnBackgroundTitle1,
        ),
        earnBackgroundTitle2: themeColor(
          DarkPalette.EarnBackgroundTitle2,
          LightPalette.EarnBackgroundTitle2,
        ),
        errorPageTitle: themeColor(
          DarkPalette.ErrorPageTitle,
          LightPalette.ErrorPageTitle,
        ),

        WETH: AssetsPalette.WETH,
        WETHGradient: AssetsPalette.WETHGradient,
        WMATIC: AssetsPalette.WMATIC,
        WMATICGradient: AssetsPalette.WMATICGradient,
        USDC: AssetsPalette.USDC,
        USDCGradient: AssetsPalette.USDCGradient,
        DPI: AssetsPalette.DPI,
        DPIGradient: AssetsPalette.DPIGradient,
        YFI: AssetsPalette.YFI,
        YFIGradient: AssetsPalette.YFIGradient,
        SUSHI: AssetsPalette.SUSHI,
        SUSHIGradient: AssetsPalette.SUSHIGradient,
        KNC: AssetsPalette.KNC,
        KNCGradient: AssetsPalette.KNCGradient,
        UNI: AssetsPalette.UNI,
        UNIGradient: AssetsPalette.UNIGradient,
        WBTC: AssetsPalette.WBTC,
        WBTCGradient: AssetsPalette.WBTCGradient,
      },

      shadows: {
        tooltip: themeColor(DarkShadows.Tooltip, LightShadows.Tooltip),
      },

      // MUI colors

      primary: {
        main: themeColor(DarkPalette.Primary, LightPalette.Primary),
      },

      secondary: {
        main: themeColor(DarkPalette.Green, LightPalette.Green),
      },

      text: {
        primary: themeColor(DarkPalette.TextPrimary, LightPalette.TextPrimary),
        secondary: themeColor(
          DarkPalette.TextSecondary,
          LightPalette.TextSecondary,
        ),
      },

      background: {
        default: themeColor(DarkPalette.Background3, LightPalette.Background3),
        paper: themeColor(DarkPalette.Background0, LightPalette.Background0),
      },

      error: {
        main: themeColor(DarkPalette.Red, LightPalette.Red),
      },

      success: {
        main: themeColor(DarkPalette.Green, LightPalette.Green),
      },

      info: {
        main: themeColor(DarkPalette.Background2, LightPalette.Background2),
      },
    },

    typography: {
      fontFamily: "DM Sans",

      h1: {
        fontSize: 34,
        fontWeight: 700,

        [theme.breakpoints.down("md")]: {
          fontSize: 24,
        },
      },

      h2: {
        fontSize: 24,
        fontWeight: 700,
        [theme.breakpoints.down("md")]: {
          fontSize: 20,
        },
      },

      h3: {
        fontSize: 20,
        fontWeight: 700,
        [theme.breakpoints.down("md")]: {
          fontSize: 18,
        },
      },

      h4: {
        fontSize: 18,
        fontWeight: 700,
        [theme.breakpoints.down("md")]: {
          fontSize: 16,
        },
      },

      h5: {
        fontSize: 16,
        fontWeight: 700,
        [theme.breakpoints.down("md")]: {
          fontSize: 14,
        },
      },

      h6: {
        fontSize: 14,
        fontWeight: 700,
        [theme.breakpoints.down("md")]: {
          fontSize: 12,
        },
      },

      body1: {
        fontSize: 14,
      },

      body2: {
        fontSize: 18,
      },

      subtitle1: {
        fontSize: 16,
      },

      subtitle2: {
        fontSize: 14,
      },

      caption: {
        fontSize: 12,
      },

      button: {
        fontSize: 16,
        fontWeight: 700,
        textTransform: "none",
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [
            {
              fontFamily: "Inter",
              fontStyle: "normal",
              fontDisplay: "swap",
              fontWeight: 400,
            },
          ],
        },
      },
      MuiAppBar: {
        colorDefault: {
          backgroundColor: themeColor(
            DarkPalette.Background2,
            LightPalette.Background2,
          ),
          borderBottom: `1px solid ${themeColor(
            DarkPalette.Stroke,
            LightPalette.Stroke,
          )}`,
          backgroundImage: "none",
          boxShadow: "none",
        },
      },
      MuiPaper: {
        root: {
          padding: 8,
          paddingBottom: 16,
          backgroundImage: "none",

          "@media (min-width:600px)": {
            padding: 0,
          },
        },
        rounded: {
          borderRadius: 8,
        },
      },
      MuiDialog: {
        paper: {
          background: themeColor(
            DarkPalette.Background0,
            LightPalette.Background0,
          ),
        },
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.01)",
          backdropFilter: "blur(10px)",
        },
      },

      MuiTableContainer: {
        root: {
          border: `1px solid ${themeColor(
            DarkPalette.Stroke,
            LightPalette.Stroke,
          )}`,
          borderBottom: 0,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,

          [theme.breakpoints.down("sm")]: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderLeft: 0,
            borderRight: 0,
          },
        },
      },
      MuiTable: {
        stickyHeader: {
          borderCollapse: "collapse",
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: "none",
          paddingTop: 8,
          paddingBottom: 8,

          fontSize: 14,
          lineHeight: "16.8px",

          [theme.breakpoints.down("sm")]: {
            fontSize: 12,

            paddingLeft: 8,
            paddingRight: 8,
          },
        },
        head: {
          fontWeight: 500,
          paddingTop: 0,
          paddingBottom: 0,
          height: 44,
        },
        sizeSmall: {
          padding: "2px 0px",
          "&:last-child": {
            paddingRight: 0,
          },
        },
      },
      MuiTableRow: {
        root: {
          backgroundColor: themeColor(
            DarkPalette.Background3,
            LightPalette.Background0,
          ),
          borderTop: `1px solid ${themeColor(
            DarkPalette.Stroke,
            LightPalette.Stroke,
          )}`,
          cursor: "pointer",

          "&.Mui-selected": {
            border: important(
              `1px solid ${themeColor(
                DarkPalette.Primary,
                LightPalette.Primary,
              )}`,
            ),
            backgroundColor: themeColor(
              alpha(DarkPalette.Primary, 0.1),
              alpha(LightPalette.Primary, 0.07),
            ),
            "&:hover": {
              backgroundColor: important(
                themeColor(
                  alpha(DarkPalette.Primary, 0.1),
                  alpha(LightPalette.Primary, 0.1),
                ),
              ),
            },
          },

          "&:hover": {
            backgroundColor: important(
              themeColor(
                alpha(DarkPalette.Primary, 0.05),
                alpha(LightPalette.Primary, 0.05),
              ),
            ),
          },
          "&:last-of-type": {
            borderBottom: `1px solid ${themeColor(
              DarkPalette.Stroke,
              LightPalette.Stroke,
            )}`,

            "&.Mui-selected": {
              borderBottom: `1px solid ${themeColor(
                DarkPalette.Primary,
                LightPalette.Stroke,
              )}`,
            },
          },
        },
        head: {
          backgroundColor: themeColor(
            DarkPalette.Background2,
            LightPalette.Background2,
          ),
          cursor: "auto",

          border: `1px solid ${themeColor(
            DarkPalette.Background2,
            LightPalette.Background2,
          )}`,

          "&:hover": {
            backgroundColor: important(
              themeColor(DarkPalette.Background2, LightPalette.Background2),
            ),
          },

          "&:first-of-type": {
            borderTop: "none !important",
            borderBottom: `1px solid ${themeColor(
              DarkPalette.Stroke,
              LightPalette.Stroke,
            )}`,
          },

          "&:last-of-type": {
            borderBottom: "none !important",
          },
        },
      },
      MuiSvgIcon: {
        fontSizeSmall: {
          fontSize: `0.8rem`,
        },
      },
      MuiTab: {
        root: {
          textTransform: "uppercase",
          padding: `12px 30px`,
          color: themeColor(
            DarkPalette.TextTertiary,
            LightPalette.TextTertiary,
          ),
          background: themeColor(
            DarkPalette.Background3,
            LightPalette.Background3,
          ),

          "&.Mui-selected": {
            background: "transparent",
            color: themeColor(DarkPalette.Primary, LightPalette.Primary),
          },
        },
      },
      MuiListItem: {
        root: {
          backgroundColor: themeColor(
            DarkPalette.Background4,
            LightPalette.Background4,
          ),

          "&:hover": {
            backgroundColor: themeColor(
              DarkPalette.Background2,
              LightPalette.Background2,
            ),
          },

          "&.Mui-selected": {
            backgroundColor: themeColor(
              DarkPalette.Background0,
              LightPalette.Background1,
            ),

            pointerEvents: "none",

            color: themeColor(DarkPalette.Primary, LightPalette.Primary),

            "& svg": {
              fill: themeColor(DarkPalette.Primary, LightPalette.Primary),
            },
          },
        },
      },
      MuiRadio: {
        root: {
          "svg:first-of-type": {
            fill: themeColor(DarkPalette.Stroke, LightPalette.Stroke),
          },

          "svg:last-of-type": {
            fill: themeColor(DarkPalette.Green, LightPalette.Green),
          },

          "&:hover": {
            background: "transparent",

            "svg:first-of-type": {
              fill: themeColor(DarkPalette.Green, LightPalette.Green),
            },
          },
          padding: 0,
        },
      },
      MuiFormControlLabel: {
        root: {
          margin: 0,
        },
        label: {
          marginLeft: 8,
        },
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiUseMediaQuery: {
        noSsr: true,
      },
    },
  })

  const primaryButtonLoading = keyframes`
    0% {
      background-position-x: 0;

    }

    100% {
      background-position-x: -200%;
    }
  `

  theme = createMuiTheme(
    merge(themeOptions, {
      components: {
        MuiLink: {
          styleOverrides: {
            root: {
              cursor: "pointer",
              userSelect: "none",
            },
            button: {
              "&[disabled]": {
                cursor: "default",
                pointerEvents: "none",
              },
            },
          },

          variants: [
            {
              props: { variant: "action" },
              style: {
                color: themeColor(DarkPalette.Green, LightPalette.Green),
                fontSize: 14,
                fontWeight: 500,

                "&:hover": {
                  color: themeColor(
                    DarkPalette.GreenLight,
                    LightPalette.GreenLight,
                  ),
                },

                "&[disabled]": {
                  color: themeColor(
                    DarkPalette.GreenDarker,
                    LightPalette.GreenDarker,
                  ),
                },
              },
            },
          ],
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontSize: 16,
              fontWeight: 700,
            },
            sizeLarge: {
              height: 60,
              borderRadius: 10,
            },
            sizeMedium: {
              height: 50,
              borderRadius: 8,
            },
            sizeSmall: {
              fontSize: 14,
              height: 40,
              borderRadius: 6,
            },
          },
          variants: [
            {
              props: { variant: "primary" },
              style: {
                background: themeColor(
                  DarkPalette.PrimaryGradient0,
                  LightPalette.PrimaryGradient0,
                ),
                color: themeColor(
                  DarkPalette.TextButton,
                  LightPalette.TextButton,
                ),

                "&:hover": {
                  background: themeColor(
                    DarkPalette.PrimaryGradient1,
                    LightPalette.PrimaryGradient1,
                  ),
                },

                "&:active": {
                  background: themeColor(
                    DarkPalette.PrimaryGradient2,
                    LightPalette.PrimaryGradient2,
                  ),
                },

                "&.Mui-disabled": {
                  background: themeColor(
                    DarkPalette.PrimaryGradient4,
                    LightPalette.PrimaryGradient4,
                  ),

                  color: themeColor(
                    DarkPalette.TextDisabled,
                    LightPalette.TextDisabled,
                  ),
                },
              },
            },
            {
              props: { variant: "secondary" },
              style: {
                background: "transparent",
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: themeColor(
                  DarkPalette.Primary,
                  LightPalette.Primary,
                ),
                color: themeColor(DarkPalette.Primary, LightPalette.Primary),

                "&:hover": {
                  borderColor: themeColor(
                    DarkPalette.PrimaryLight,
                    LightPalette.PrimaryLight,
                  ),
                  color: themeColor(
                    DarkPalette.PrimaryLight,
                    LightPalette.PrimaryLight,
                  ),
                  background: "transparent",
                },

                "&:active": {
                  borderColor: themeColor(
                    DarkPalette.PrimaryDark,
                    LightPalette.PrimaryDark,
                  ),
                  color: themeColor(
                    DarkPalette.PrimaryDark,
                    LightPalette.PrimaryDark,
                  ),
                },

                "&.Mui-disabled": {
                  borderColor: themeColor(
                    alpha(DarkPalette.Primary, 0.4),
                    alpha(LightPalette.Primary, 0.4),
                  ),
                  color: themeColor(
                    alpha(DarkPalette.Primary, 0.4),
                    alpha(LightPalette.Primary, 0.4),
                  ),
                },
              },
            },
            {
              props: { variant: "action" },
              style: {
                fontSize: 14,
                height: 30,
                borderRadius: 6,

                background: "transparent",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "transparent",
                color: themeColor(DarkPalette.Green, LightPalette.Green),

                "&:hover": {
                  background: alpha(
                    themeColor(DarkPalette.Green, LightPalette.Green),
                    0.1,
                  ),
                },

                "&:active": {
                  background: alpha(
                    themeColor(DarkPalette.Green, LightPalette.Green),
                    0.1,
                  ),
                },

                "&.active": {
                  background: themeColor(DarkPalette.Green, LightPalette.Green),
                  color: themeColor(
                    DarkPalette.TextButton,
                    LightPalette.TextButton,
                  ),
                },

                "& .MuiSvgIcon-root": {
                  fontSize: important("inherit"),
                },

                "&.Mui-disabled": {
                  borderColor: "transparent",
                  color: themeColor(
                    DarkPalette.GreenDarker,
                    LightPalette.GreenDarker,
                  ),
                },
              },
            },

            {
              props: { variant: "support" },
              style: {
                borderRadius: 20,
                fontWeight: 500,

                background: "transparent",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "transparent",
                color: themeColor(
                  DarkPalette.TextSecondary,
                  LightPalette.TextSecondary,
                ),

                "&:hover": {
                  borderColor: "transparent",
                  background: themeColor(
                    DarkPalette.Background3,
                    LightPalette.Background0,
                  ),
                  color: themeColor(DarkPalette.Support, LightPalette.Support),
                },

                "&:active": {
                  borderColor: "transparent",
                  background: themeColor(
                    DarkPalette.Background3,
                    LightPalette.Background0,
                  ),
                  color: themeColor(DarkPalette.Support, LightPalette.Support),
                },

                "& .MuiSvgIcon-root": {
                  color: themeColor(DarkPalette.Support, LightPalette.Support),
                },
              },
            },
          ],
        },
        MuiLoadingButton: {
          variants: [
            {
              props: { variant: "primary" },

              style: {
                "& .MuiLoadingButton-loadingIndicator": {
                  display: "none",
                },

                "&.MuiLoadingButton-loading": {
                  background: themeColor(
                    DarkPalette.PrimaryGradient3,
                    LightPalette.PrimaryGradient3,
                  ),
                  backgroundRepeat: "repeat-x",
                  backgroundSize: "200% 200%",
                  animation: `${primaryButtonLoading} 1000ms linear infinite`,
                  color: themeColor(
                    DarkPalette.TextLoading,
                    LightPalette.TextLoading,
                  ),
                },
              },
            },
            {
              props: { variant: "secondary" },
              style: {
                "& .MuiLoadingButton-loadingIndicator": {
                  display: "none",
                },

                "&.MuiLoadingButton-loading": {
                  color: themeColor(
                    alpha(DarkPalette.Primary, 0.4),
                    alpha(LightPalette.Primary, 0.4),
                  ),
                },
              },
            },
            {
              props: { variant: "action" },
              style: {
                "& .MuiButton-startIcon": {
                  display: "none",
                },

                "& .MuiLoadingButton-loadingIndicator": {
                  position: "relative",
                  marginRight: 8,
                  marginLeft: -4,
                },

                "&.Mui-disabled": {
                  borderColor: themeColor(
                    DarkPalette.Green,
                    LightPalette.Green,
                  ),
                  color: themeColor(DarkPalette.Green, LightPalette.Green),
                },
              },
            },
          ],
        },

        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: `2px solid ${themeColor(
                  DarkPalette.Primary,
                  LightPalette.Primary,
                )}`,
              },

              "&.Mui-Disabled": {
                backgroundColor: important(
                  themeColor(DarkPalette.Background0, LightPalette.Background0),
                ),
              },

              "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                border: important(
                  `2px solid ${themeColor(DarkPalette.Red, LightPalette.Red)}`,
                ),
              },

              "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                border: `2px solid ${themeColor(
                  DarkPalette.Stroke,
                  LightPalette.Stroke,
                )}`,
              },
            },
            notchedOutline: {
              border: `2px solid ${themeColor(
                DarkPalette.Stroke,
                LightPalette.Stroke,
              )}`,
            },
          },
        },

        MuiSelect: {
          styleOverrides: {
            root: {
              fontSize: 16,
              fontWeight: 500,

              height: 50,
              borderRadius: 6,

              backgroundColor: important(
                themeColor(DarkPalette.Background2, LightPalette.Background2),
              ),

              "&:active": {
                backgroundColor: important(
                  themeColor(DarkPalette.Background3, LightPalette.Background3),
                ),
              },
            },
          },

          variants: [
            {
              props: { size: "large" },
              style: {
                height: 60,
                borderRadius: 10,
              },
            },
            {
              props: { size: "small" },
              style: {
                height: 40,
                borderRadius: 6,
              },
            },
          ],
        },

        MuiInputBase: {
          styleOverrides: {
            root: {
              fontSize: 16,
              fontWeight: 700,
              width: "100%",

              height: 50,
              borderRadius: important("10px"),

              backgroundColor: themeColor(
                DarkPalette.Background2,
                LightPalette.Background2,
              ),

              "&.Mui-disabled": {
                backgroundColor: important(
                  themeColor(DarkPalette.Background0, LightPalette.Background0),
                ),
                pointerEvents: "none",
              },

              "&.MuiInputBase-adornedEnd": {
                paddingRight: 5,
              },

              "& .MuiInputAdornment-root": {
                zIndex: 1,
              },

              "& .MuiInputBase-input": {
                paddingLeft: 24,
                paddingRight: 24,
              },
            },

            input: {
              "&:focus": {
                outline: "none",
              },
              "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                MozAppearance: "none",
                margin: 0,
              },
              "&[type=number]": {
                WebkitAppearance: "textfield",
                MozAppearance: "textfield",
              },
              "&.Mui-disabled": {
                color: themeColor(
                  DarkPalette.TextDisabled,
                  LightPalette.TextDisabled,
                ),
                WebkitTextFillColor: themeColor(
                  DarkPalette.TextDisabled,
                  LightPalette.TextDisabled,
                ),
              },
              "&[readonly]": {
                color: important(
                  themeColor(DarkPalette.TextPrimary, LightPalette.TextPrimary),
                ),
                WebkitTextFillColor: important(
                  themeColor(DarkPalette.TextPrimary, LightPalette.TextPrimary),
                ),
              },
            },
          },

          variants: [
            {
              props: { size: "large" },
              style: {
                height: 60,

                "&.MuiInputBase-adornedEnd": {
                  paddingRight: 10,
                },
              },
            },
            {
              props: { size: "small" },
              style: {
                height: 40,

                "&.MuiInputBase-adornedEnd": {
                  paddingRight: 0,
                },
              },
            },
          ],
        },

        MuiPopover: {
          styleOverrides: {
            paper: {
              boxShadow: themeColor(
                DarkShadows.Dropdown,
                LightShadows.Dropdown,
              ),
            },
          },
        },

        MuiMenu: {
          styleOverrides: {
            paper: {
              padding: important("0 8px"),
              backgroundColor: important(
                themeColor(DarkPalette.Background2, LightPalette.Background2),
              ),
              border: `2px solid ${themeColor(
                DarkPalette.Stroke,
                LightPalette.Stroke,
              )}`,
              borderRadius: important("6px"),
            },
          },
        },

        MuiMenuItem: {
          styleOverrides: {
            root: {
              height: 42,
              fontSize: 16,
              fontWeight: 500,
              padding: "10px 16px",
              borderRadius: 4,

              "&:hover": {
                backgroundColor: themeColor(
                  DarkPalette.Background1,
                  LightPalette.Background1,
                ),
              },

              "&:active": {
                backgroundColor: themeColor(
                  DarkPalette.Background3,
                  LightPalette.Background3,
                ),
              },

              "&.Mui-selected": {
                backgroundColor: themeColor(
                  DarkPalette.Background3,
                  LightPalette.Background3,
                ),

                "&:hover": {
                  backgroundColor: themeColor(
                    DarkPalette.Background3,
                    LightPalette.Background3,
                  ),
                },
              },

              [theme.breakpoints.down("sm")]: {
                height: 40,
              },
            },
          },
        },

        MuiToggleButton: {
          styleOverrides: {
            root: {
              color: themeColor(
                DarkPalette.TextTertiary,
                LightPalette.TextTertiary,
              ),
              background: "transparent",

              border: important(
                `2px solid ${themeColor(
                  DarkPalette.Background1,
                  LightPalette.Background1,
                )}`,
              ),

              marginLeft: important(0),

              "&:hover": {
                color: themeColor(
                  DarkPalette.TextPrimary,
                  LightPalette.TextPrimary,
                ),

                border: important(
                  `2px solid ${themeColor(
                    DarkPalette.Primary,
                    LightPalette.Primary,
                  )}`,
                ),

                background: "transparent",
              },

              "&.Mui-selected": {
                color: themeColor(DarkPalette.Primary, LightPalette.Primary),
                background: themeColor(
                  DarkPalette.Background1,
                  LightPalette.Background1,
                ),
                pointerEvents: "none",
              },

              "&.Mui-disabled": {
                color: alpha(
                  themeColor(
                    DarkPalette.TextTertiary,
                    LightPalette.TextTertiary,
                  ),
                  0.2,
                ),

                borderColor: themeColor(
                  DarkPalette.Background2,
                  LightPalette.Background2,
                ),

                background: themeColor(
                  DarkPalette.Background2,
                  LightPalette.Background2,
                ),
              },
            },

            sizeLarge: {
              height: 50,
              paddingLeft: 55,
              paddingRight: 55,

              [theme.breakpoints.down("sm")]: {
                height: 40,
                paddingLeft: 24,
                paddingRight: 24,
              },
            },

            sizeMedium: {
              height: 40,
              paddingLeft: 40,
              paddingRight: 40,
            },
          },
        },

        MuiSkeleton: {
          styleOverrides: {
            root: {
              background: alpha(
                themeColor(
                  DarkPalette.TextSecondary,
                  LightPalette.TextSecondary,
                ),
                0.1,
              ),
            },
            text: {
              transform: "none",
              minWidth: "1rem",
            },
          },
        },

        MuiTabs: {
          styleOverrides: {
            indicator: {
              display: "none",
            },
          },
        },

        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: themeColor(
                alpha(DarkPalette.Background2, 0.7),
                alpha(LightPalette.Background2, 0.7),
              ),
              border: `1px solid ${themeColor(
                DarkPalette.Stroke,
                LightPalette.Stroke,
              )}`,
              color: themeColor(
                DarkPalette.TextPrimary,
                LightPalette.TextPrimary,
              ),
              fontSize: 14,
              fontWeight: 500,
              padding: "12px 20px",

              backdropFilter: "blur(4px)",
              boxShadow: themeColor(DarkShadows.Tooltip, LightShadows.Tooltip),
            },
          },
        },

        MuiSwitch: {
          styleOverrides: {
            root: {
              borderRadius: 20,

              "&:hover .MuiSwitch-track": {
                backgroundColor: themeColor(
                  DarkPalette.Background3,
                  LightPalette.PrimaryDark,
                ),
              },
            },
            thumb: {
              backgroundColor: themeColor(
                DarkPalette.Toggle,
                LightPalette.Toggle,
              ),
              zIndex: 2,
            },
            track: {
              backgroundColor: themeColor(
                DarkPalette.Background0,
                LightPalette.Background0,
              ),
              opacity: important(1),
              borderRadius: 12,
            },
            switchBase: {
              background: important("transparent"),
            },
          },
        },
      },
    } as ThemeOptions),
  )

  return theme
}
