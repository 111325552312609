import { Close } from "@mui/icons-material"
import {
  IconButton,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useLanguageContext } from "../../../Contexts/LanguageContext"
import { find } from "lodash"
import { useNavigate, useParams } from "react-router-dom"
import { Position, usePositions } from "../../../Contexts/SirenMarketsContext"
import { Side, SkeletonGrid, Typography } from "../../common"
import ExercisePositionDialog from "./ExercisePositionDialog"
import SellPositionDialog from "./SellPositionDialog"

const useStyles = makeStyles<Theme>(({ palette }) => ({
  closeButton: {
    top: "10px",
    right: "10px",
    position: "absolute",
    borderRadius: 16,
    backgroundColor: palette.colors.background2,
    padding: 0,
  },
}))

type PositionActions = "Exercise" | "Sell"

const getPositionActions = (
  selectedPosition?: Position,
): Array<PositionActions> => {
  if (!selectedPosition) return []

  let actions: Array<PositionActions> = []

  if (
    selectedPosition.status === "open" &&
    selectedPosition.bTokenBalance.gt(0)
  ) {
    actions.push("Sell")
  }

  if (
    selectedPosition.status === "expired" &&
    selectedPosition.bTokenBalance.gt(0) &&
    (selectedPosition.expiryStatus === "ITM" ||
      selectedPosition.expiryStatus === "ATM")
  ) {
    actions.push("Exercise")
  }

  return actions
}

const PortfolioPositionSide: React.FC<{}> = () => {
  const classes = useStyles()
  const { breakpoints } = useTheme()
  const desktop = useMediaQuery(breakpoints.up("md"))
  const [txType, setTxType] = useState<PositionActions | undefined>(undefined)
  const { formatShortLocaleDate } = useLanguageContext()

  const { positions } = usePositions()

  const { id } = useParams<{ id?: string }>()

  const currentPosition = useMemo(
    () => find(positions, { seriesId: id }),
    [positions, id],
  )

  const navigate = useNavigate()

  const onClose = useCallback(() => {
    navigate("/portfolio/positions", { replace: true })
  }, [navigate])

  const actions = getPositionActions(currentPosition)

  useEffect(() => {
    if (actions.length === 0) {
      setTxType(undefined)
    } else {
      if (!txType || !actions.includes(txType)) {
        setTxType(actions[0])
      }
    }
  }, [actions, txType])

  return (
    <Side open={!!currentPosition} onClose={onClose}>
      {!currentPosition ? (
        <Side.Content space={3}>
          <Typography variant="h3">Select a position to continue</Typography>
          <Typography size="small" color="textSecondary">
            To make a trade, select an option from the table on the left
          </Typography>
          <SkeletonGrid columns={6} animation={false}>
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
          </SkeletonGrid>
        </Side.Content>
      ) : !txType ? (
        <Side.Content space={3}>
          <Typography variant="h3">No actions available</Typography>
          <Typography size="small" color="textSecondary">
            You’ll be able to withdraw available wTokens after expiration on{" "}
            {formatShortLocaleDate(currentPosition.expiration)}
          </Typography>
        </Side.Content>
      ) : (
        <>
          <Tabs
            value={txType}
            onChange={(e, newValue) => {
              setTxType(newValue)
            }}
            variant="fullWidth"
          >
            {actions.map((a) => (
              <Tab key={a} label={a} value={a} />
            ))}
          </Tabs>
          {txType === "Exercise" ? (
            <ExercisePositionDialog
              position={currentPosition}
              clearSelection={onClose}
            />
          ) : (
            txType === "Sell" && (
              <SellPositionDialog
                position={currentPosition}
                clearSelection={onClose}
              />
            )
          )}
          {!desktop && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                onClose()
              }}
              className={classes.closeButton}
              size="large"
            >
              <Close />
            </IconButton>
          )}
        </>
      )}
    </Side>
  )
}

export default PortfolioPositionSide
