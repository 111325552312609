import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import BigNumber from "bignumber.js"
import { utils } from "ethers"
import React, { useCallback, useMemo, useState } from "react"
import { useLanguageContext } from "../../../../Contexts/LanguageContext"
import { LiquidityPool } from "../../../../Contexts/SirenMarketsContext"
import { useMedia } from "../../../../Hooks/useMedia"
import { HStack, TokenAvatar, Typography, VStack } from "../../../common"

const PER_PAGE = 5

const PoolRecentTransactions: React.FC<{ pool: LiquidityPool }> = ({
  pool,
}) => {
  const { formatShortLocaleDate, formatPercents, formatCrypto } =
    useLanguageContext()

  const [page, setPage] = useState(0)

  const media = useMedia()

  const handlePageChange = useCallback(
    (_, value) => {
      setPage(value)
    },
    [setPage],
  )

  const poolStructure = useMemo(
    () =>
      pool.poolOptionBalances
        .filter((item) => item.tokenType === "wToken" && item.amount.gt(0))
        .sort((a, b) => b.amount.comparedTo(a.amount)),
    [pool],
  )

  const rows = useMemo(
    () => poolStructure.slice(page * PER_PAGE, (page + 1) * PER_PAGE),
    [page, poolStructure],
  )

  const totalAmount = poolStructure.reduce(
    (result, item) => result.plus(item.amount),
    new BigNumber(0),
  )

  return (
    <VStack>
      <HStack alignItems="center" justifyContent="space-between">
        <Typography variant="h2">Pool Structure</Typography>

        <HStack
          space={1}
          px={1}
          py={0.5}
          borderRadius="6px"
          bgcolor="colors.darkBackground"
        >
          <Typography size="extra-small" weight="medium" color="textSecondary">
            Unlocked Collateral
          </Typography>

          <TokenAvatar tokenSymbol={pool?.collateralTokenSymbol} size={16} />

          <Typography size="extra-small" weight="medium">
            {formatCrypto(pool.poolCollateralTokenBalance.toNumber(), "")}
          </Typography>
        </HStack>
      </HStack>

      <Box mx={media.mobile ? -4 : 0}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Expiration</TableCell>
                <TableCell align="right">Strike</TableCell>
                <TableCell align="right"># of contracts</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={row.strikePrice.toString() + index}>
                  <TableCell>
                    <Typography
                      size="extra-small"
                      color="textSecondary"
                      weight="regular"
                    >
                      {formatShortLocaleDate(
                        new Date(Number(row.expiration) * 1000),
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography size="extra-small" weight="bold">
                      ${utils.commify(row.strikePrice.toNumber())}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <VStack space={0}>
                      <Typography
                        size="extra-small"
                        weight="regular"
                        color="colors.green"
                      >
                        {row.amount.decimalPlaces(4).toString()}
                      </Typography>

                      <Typography
                        size="x-tiny"
                        weight="regular"
                        color="textSecondary"
                      >
                        {formatPercents(
                          row.amount.div(totalAmount).toNumber(),
                          "ratio",
                        )}
                      </Typography>
                    </VStack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <TablePagination
        component="div"
        page={page}
        count={poolStructure.length}
        labelDisplayedRows={() => ""}
        rowsPerPage={PER_PAGE}
        rowsPerPageOptions={[]}
        onPageChange={handlePageChange}
      />
    </VStack>
  )
}

export default PoolRecentTransactions
