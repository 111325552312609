import { Close } from "@mui/icons-material"
import {
  Button,
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
  Theme,
  Typography,
} from "@mui/material"
import BigNumber from "bignumber.js"
import { makeStyles } from "@mui/styles"

import React, { useCallback } from "react"
import { numericRegex } from "../../helpers/numericRegex"
import { HStack, VStack } from "./Stack"

const useStyles = makeStyles<Theme, { readOnly?: boolean }>(({ palette }) => ({
  input: ({ readOnly }) => ({
    pointerEvents: readOnly ? "none" : undefined,

    backgroundColor: palette.colors.background0,
  }),
}))

export const NumericInput = React.forwardRef<
  HTMLInputElement | null,
  {
    value: string
    errorMessage?: string
    label?: string | React.ReactNode
    onChange?: (newValue: string) => void
    max?: BigNumber
    min?: number
    readOnly?: boolean
  } & Pick<OutlinedInputProps, "size" | "disabled" | "className" | "type">
>(
  (
    {
      value,
      disabled = false,
      errorMessage,
      onChange,
      min,
      max,
      label,
      size = "large",
      readOnly,
    },
    inputRef,
  ) => {
    const classes = useStyles({ readOnly })

    const hasError = !!errorMessage && Boolean(value)
    // onChange should only be called if the value is numeric or an empty string
    const handleChange = useCallback(
      (newValue: string) => {
        onChange &&
          (numericRegex.test(newValue) || newValue === "") &&
          onChange(newValue)
      },
      [onChange],
    )

    const handleSetMax = useCallback(() => {
      onChange && max && onChange(max.toString())
    }, [onChange, max])

    return (
      <VStack space={1}>
        <OutlinedInput
          className={classes.input}
          type="number"
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          disabled={disabled}
          ref={inputRef}
          inputProps={{ min, max: max?.toString() }}
          error={hasError}
          size={size}
          readOnly={readOnly}
          endAdornment={
            hasError ? (
              <InputAdornment
                position="end"
                onClick={() => onChange && onChange("")}
                style={{ cursor: "pointer" }}
              >
                <Close color="error" style={{ width: 40 }} />
              </InputAdornment>
            ) : !!max ? (
              <InputAdornment position="end">
                <Button
                  onClick={handleSetMax}
                  disabled={disabled}
                  variant="primary"
                  size="small"
                >
                  MAX
                </Button>
              </InputAdornment>
            ) : undefined
          }
        />

        <HStack justifyContent="space-between">
          {!!label && (
            <Typography
              variant="caption"
              align="left"
              component="h6"
              color="textSecondary"
            >
              {label}
            </Typography>
          )}

          {hasError && (
            <Typography
              variant="caption"
              color="error"
              align="left"
              component="h6"
            >
              {errorMessage}
            </Typography>
          )}
        </HStack>
      </VStack>
    )
  },
)
