import React from "react"
import { range } from "lodash"

import { SkeletonGrid } from "./SkeletonGrid"

type TableSkeletonProps = {
  columns: number
  rows?: number
}

export const TableSkeleton: React.VFC<TableSkeletonProps> = ({
  columns,
  rows = 4,
}) => (
  <SkeletonGrid columns={columns} animation="wave">
    {range(columns).map((i) => (
      <SkeletonGrid.Item key={i} start={i + 1} end={i + 2} height={5} />
    ))}

    {range(rows).map((i) => (
      <SkeletonGrid.Item key={i} start={1} end={columns + 1} height={8} />
    ))}
  </SkeletonGrid>
)
