import React from "react"

import { SkeletonGrid } from "./SkeletonGrid"
import { Side } from "./Side"

type SideSkeletonProps = {}

export const SideSkeleton: React.FC<SideSkeletonProps> = () => (
  <Side>
    <Side.Content>
      <SkeletonGrid columns={12} animation="wave">
        <SkeletonGrid.Item start={2} end={12} />

        <SkeletonGrid.Item start={1} end={7} />
        <SkeletonGrid.Item start={9} end={13} />

        <SkeletonGrid.Item start={1} end={7} />
        <SkeletonGrid.Item start={8} end={13} />

        <SkeletonGrid.Item start={1} end={6} />
        <SkeletonGrid.Item start={9} end={13} />

        <SkeletonGrid.Item start={1} end={7} />
        <SkeletonGrid.Item start={8} end={13} />

        <SkeletonGrid.Item start={1} end={13} height={10} />
        <SkeletonGrid.Item start={1} end={5} />

        <SkeletonGrid.Item start={1} end={13} height={10} />
        <SkeletonGrid.Item start={1} end={5} />

        <SkeletonGrid.Item start={2} end={10} height={6} />
        <SkeletonGrid.Item start={1} end={13} height={6} />
      </SkeletonGrid>
    </Side.Content>
  </Side>
)
