import { Button } from "@mui/material"
import React, { useCallback, useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import { usePools } from "../../../../Contexts/SirenMarketsContext"
import { useMedia } from "../../../../Hooks/useMedia"
import { FixedBottomPanel, MainLayout } from "../../../common"

import { EarnDetails } from "./EarnDetails"
import { EarnDetailsSide } from "./EarnDetailsSide"

export const EarnDetailsPage = () => {
  const { address } = useParams<{ address: string }>()

  const { desktop } = useMedia()

  const { pools } = usePools()

  const pool = useMemo(() => {
    if (!address || !pools || pools.length === 0) return undefined

    return pools.find((p) => p.address.toLowerCase() === address.toLowerCase())
  }, [address, pools])

  const [open, setOpen] = useState(false)

  const onOpen = useCallback(() => setOpen(true), [setOpen])
  const onClose = useCallback(() => setOpen(false), [setOpen])

  if (!pool) {
    return null
  }

  return (
    <>
      <MainLayout.Content>
        <EarnDetails pool={pool} />

        {!desktop && (
          <FixedBottomPanel>
            <Button fullWidth onClick={onOpen} variant="primary">
              Provide or withdraw from pool
            </Button>
          </FixedBottomPanel>
        )}
      </MainLayout.Content>

      <MainLayout.RightSide>
        <EarnDetailsSide open={open} onClose={onClose} pool={pool} />
      </MainLayout.RightSide>
    </>
  )
}
