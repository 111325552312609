import React from "react"
import {
  IconButton,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
  Theme,
  Button,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Add } from "@mui/icons-material"
import { utils } from "ethers"
import clsx from "clsx"

import { Series } from "../../../Contexts/SirenMarketsContext"
import InfoSvg from "../../icons/InfoSvg"

const useStyles = makeStyles<Theme>(() => ({
  infoIcon: {
    padding: 0,
    fontSize: "1rem",
  },
}))

const OptionRowItem: React.FC<{
  series: Series
  breakeven: string
  isSelected?: boolean
  selectOption(optionAddress: string): void
  showGreeks(event: React.MouseEvent<HTMLButtonElement>, seriesId: string): void
}> = ({ series, selectOption, showGreeks, isSelected = false, breakeven }) => {
  const classes = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    showGreeks(event, series.seriesId)
  }

  const { breakpoints } = useTheme()

  const mobile = useMediaQuery(breakpoints.down("sm"))

  return (
    <TableRow
      hover
      selected={isSelected}
      onClick={() => selectOption(series.seriesId)}
    >
      <TableCell align="left">
        <Typography variant="h6">${utils.commify(series.strike)}</Typography>
      </TableCell>

      {mobile ? (
        <TableCell align="left">
          <Typography variant="subtitle2" color="textSecondary" display="block">
            ${breakeven}
          </Typography>

          <Typography variant="subtitle2" color="textSecondary">
            {series.premium}
          </Typography>
        </TableCell>
      ) : (
        <>
          <TableCell align="right">
            <Typography variant="subtitle2" color="textSecondary">
              {series.premium}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography variant="subtitle2" color="textSecondary">
              ${breakeven}
            </Typography>
          </TableCell>
        </>
      )}

      <TableCell align={mobile ? "center" : "right"}>
        <IconButton
          onClick={handleClick}
          className={classes.infoIcon}
          size="large"
        >
          <SvgIcon
            component={InfoSvg}
            color="primary"
            viewBox="0 0 22 22"
            fontSize={mobile ? "inherit" : undefined}
          />
        </IconButton>
      </TableCell>

      <TableCell align="right">
        <Button
          className={clsx({ active: isSelected })}
          variant="action"
          startIcon={<Add />}
        >
          Buy
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default OptionRowItem
