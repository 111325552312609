export enum ChartPeriod {
  "24H" = "24H",
  "7d" = "7d",
  "30d" = "30d",
  "180d" = "180d",
  "1y" = "1y",
  "Max" = "Max",
}

export const DAYS_BY_CHART_PERIOD = {
  [ChartPeriod["24H"]]: 1,
  [ChartPeriod["7d"]]: 7,
  [ChartPeriod["30d"]]: 30,
  [ChartPeriod["180d"]]: 180,
  [ChartPeriod["1y"]]: 365,
  [ChartPeriod["Max"]]: "max",
}

export const UPGRADE_TIMESTAMP = 1645059600 // 2022-02-17 01:00 GMT

export enum QueryKey {
  Permissions = "Permissions",
  PoolsRewards = "PoolsRewards",
  Pools = "Pools",
  PoolsOnchainData = "PoolsOnchainData",
  Addresses = "Addresses",
  SeriesData = "SeriesData",
  Series = "Series",
  LockedLiquidity = "LockedLiquidity",
  PositionsData = "PositionsData",
  Positions = "Positions",
}

export const SLIPPAGE = 0.02
