import { useApolloClient } from "@apollo/client"
import { useWeb3 } from "@chainsafe/web3-context"
import * as Sentry from "@sentry/react"
import {
  ERC1155Controller__factory,
  MinterAmm__factory,
  SeriesController__factory,
} from "@sirenmarkets/sdk"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { BigNumber } from "bignumber.js"
import { useCallback } from "react"

import { QueryKey, SLIPPAGE } from "../../constants"
import { ERC1155TokenType } from "../../types/graphql-global-types"
import { accountBalanceQuery } from "../graphQueries"
import {
  GetAccountBalance,
  GetAccountBalanceVariables,
  GetAccountBalance_erc1155AccountBalances,
  GetAccountBalance_positions,
} from "../types/GetAccountBalance"
import { REFETCH_TIMEOUT, STRIKE_PRICE_DECIMALS } from "./constants"
import { Position, Series } from "./types"
import { useSeries } from "./useSeries"

export const usePositions = () => {
  const { tokens, provider, address } = useWeb3()

  const { series } = useSeries()

  const sirenGraphClient = useApolloClient()

  const queryClient = useQueryClient()

  const processBalance = useCallback(
    async (
      wTokenBalance: BigNumber,
      bTokenBalance: BigNumber,
      seriesId: string,
      allSeries: Series[],
      positionData?: GetAccountBalance_positions,
    ): Promise<Position> => {
      const seriesData = allSeries.find((s) => s.seriesId === seriesId)
      if (!seriesData || !provider) return Promise.reject("Invalid series")

      let settlementValue: BigNumber | undefined = undefined
      let expiryStatus: "ITM" | "ATM" | "OTM" | undefined = undefined
      let collateralPerOptionToken: BigNumber | undefined = undefined
      let unrealizedGain: BigNumber | undefined = undefined

      if (seriesData.status === "expired") {
        const seriesControllerContract = SeriesController__factory.connect(
          seriesData.seriesControllerAddress,
          provider,
        )

        settlementValue = new BigNumber(
          await (
            await seriesControllerContract.getSettlementPrice(
              seriesData.seriesIndex,
            )
          )[1].toString(),
        ).shiftedBy(-STRIKE_PRICE_DECIMALS)

        expiryStatus =
          seriesData.type === "Call"
            ? seriesData.strike > settlementValue?.toNumber()
              ? "OTM"
              : seriesData.strike === settlementValue?.toNumber()
              ? "ATM"
              : "ITM"
            : seriesData.strike > settlementValue?.toNumber()
            ? "ITM"
            : seriesData.strike === settlementValue?.toNumber()
            ? "ATM"
            : "OTM"

        if (expiryStatus === "ATM" || expiryStatus === "ITM") {
          collateralPerOptionToken = new BigNumber(
            //TODO: Handle exercise fee
            (
              await seriesControllerContract.getExerciseAmount(
                seriesData.seriesIndex,
                new BigNumber(1)
                  .shiftedBy(seriesData.bTokenDecimals)
                  .toString(10),
              )
            )[0].toString(),
          ).shiftedBy(-seriesData.collateralTokenDecimals)
        }
      }

      if (seriesData.status === "open" && bTokenBalance.gt(0)) {
        const costBasis = positionData?.costBasis || 0

        const currentPrice =
          seriesData.type === "Call"
            ? new BigNumber(seriesData.premium)
            : new BigNumber(seriesData.premium).multipliedBy(seriesData.strike)

        unrealizedGain = currentPrice
          .minus(costBasis)
          .multipliedBy(bTokenBalance)
          .multipliedBy(
            seriesData.type === "Call" ? seriesData.paymentPerUnderlying : 1,
          )
      }

      return {
        ...seriesData,
        bTokenBalance,
        wTokenBalance,
        settlementValue,
        expiryStatus,
        collateralPerOptionToken,
        unrealizedGain,
      }
    },
    [provider],
  )

  const processBalances = useCallback(
    async (
      balances: GetAccountBalance_erc1155AccountBalances[],
      allPositions: GetAccountBalance_positions[],
      allSeries: Series[],
    ): Promise<Position[]> => {
      const uniqueSeries = Array.from(
        new Set(
          balances.map((b) => b.token && b.token.series && b.token.series.id),
        ),
      )
      const uniqueSeriesPositions = uniqueSeries.map((um) => {
        const result = balances
          .filter((b) => b.token && b.token.series && b.token.series.id === um)
          .reduce(
            (ump, position) => {
              return position.token.type === ERC1155TokenType.W_TOKEN
                ? {
                    ...ump,
                    wTokenBalance: new BigNumber(position.amount)
                      .shiftedBy(-position.token.decimals)
                      .decimalPlaces(position.token.decimals),
                  }
                : position.token.type === ERC1155TokenType.B_TOKEN
                ? {
                    ...ump,
                    bTokenBalance: new BigNumber(position.amount)
                      .shiftedBy(-position.token.decimals)
                      .decimalPlaces(position.token.decimals),
                  }
                : {
                    ...ump,
                  }
            },
            {
              wTokenBalance: new BigNumber(0),
              bTokenBalance: new BigNumber(0),
              series: balances.filter(
                (b) => b.token && b.token.series && b.token.series.id === um,
              )[0].token.series,
            },
          )
        return result
      })
      const result = await Promise.all(
        uniqueSeriesPositions
          .filter((usp) => usp.series !== null)
          .map((usp) =>
            processBalance(
              usp.wTokenBalance,
              usp.bTokenBalance,
              usp.series.id,
              allSeries,
              allPositions.find((p) => p.seriesId === usp.series.seriesId),
            ),
          ),
      )
      return result.filter(
        (p) => p.bTokenBalance.gt(0) || p.wTokenBalance.gt(0),
      )
    },
    [processBalance],
  )

  const positionsDataEnabled = Boolean(address)

  const { data: positionsData } = useQuery(
    [QueryKey.PositionsData],
    () =>
      sirenGraphClient
        .query<GetAccountBalance, GetAccountBalanceVariables>({
          query: accountBalanceQuery,
          variables: { id: address!.toLowerCase() },
          fetchPolicy: "network-only",
        })
        .then((data) => ({ ...data.data, cacheKey: Math.random() })),
    {
      enabled: positionsDataEnabled,
    },
  )

  const positionsEnabled =
    positionsDataEnabled &&
    Boolean(provider && series.length > 0 && positionsData)

  const { data: positions = [], isFetched: positionsFetched } = useQuery(
    [QueryKey.Positions, positionsData?.cacheKey],
    () =>
      processBalances(
        positionsData!.erc1155AccountBalances,
        positionsData!.positions,
        series,
      ),
    {
      enabled: positionsEnabled,
      refetchInterval: 12000000,
    },
  )

  const sellOption = async (
    seriesId: string,
    quantity: BigNumber,
    estimateOut: BigNumber,
    outTokenAddress: string,
  ) => {
    if (!provider || !address) return
    const position = positions.find((p) => p.seriesId === seriesId)
    if (!position) return
    const signer = provider.getSigner()

    const erc1155ControllerContract = ERC1155Controller__factory.connect(
      position.erc1155ControllerAddress,
      signer,
    )

    const bTokenAmount = new BigNumber(quantity)
      .shiftedBy(position.bTokenDecimals)
      .decimalPlaces(0)

    const outAmountMin = new BigNumber(estimateOut)
      .multipliedBy(1 - SLIPPAGE)
      .shiftedBy(tokens[outTokenAddress].decimals)
      .decimalPlaces(0)

    try {
      if (outTokenAddress === position.collateralTokenAddress) {
        const isApprovedForAll =
          await erc1155ControllerContract.isApprovedForAll(
            address,
            position.ammAddress,
          )

        if (!isApprovedForAll) {
          await (
            await erc1155ControllerContract.setApprovalForAll(
              position.ammAddress,
              true,
            )
          ).wait()
        }
        const ammContract = MinterAmm__factory.connect(
          position.ammAddress,
          signer,
        )
        const estimatedGas = await ammContract.estimateGas.bTokenSell(
          position.seriesIndex,
          bTokenAmount.toString(10),
          outAmountMin.toString(10),
        )
        await (
          await ammContract.bTokenSell(
            position.seriesIndex,
            bTokenAmount.toString(10),
            outAmountMin.toString(10),
            {
              gasLimit: estimatedGas.mul(12).div(10),
            },
          )
        ).wait(1)
      }

      setTimeout(() => {
        queryClient.invalidateQueries([QueryKey.PositionsData])

        queryClient.invalidateQueries(
          [QueryKey.Series],
          {},
          {
            cancelRefetch: true,
          },
        )
      }, REFETCH_TIMEOUT)
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
      throw error
    }
  }

  const exerciseOption = async (seriesId: string) => {
    if (!provider || !address) return
    const position = positions.find((p) => p.seriesId === seriesId)
    if (!position) return

    const signer = provider.getSigner()

    const erc1155ControllerContract = ERC1155Controller__factory.connect(
      position.erc1155ControllerAddress,
      signer,
    )

    try {
      const isApprovedForAll = await erc1155ControllerContract.isApprovedForAll(
        address,
        position.seriesControllerAddress,
      )

      if (!isApprovedForAll) {
        await (
          await erc1155ControllerContract.setApprovalForAll(
            position.seriesControllerAddress,
            true,
          )
        ).wait()
      }

      const seriesControllerContract = SeriesController__factory.connect(
        position.seriesControllerAddress,
        signer,
      )

      const estimatedGas =
        await seriesControllerContract.estimateGas.exerciseOption(
          position.seriesIndex,
          position.bTokenBalance
            .shiftedBy(position.bTokenDecimals)
            .toString(10),
          true,
        )

      await (
        await seriesControllerContract.exerciseOption(
          position.seriesIndex,
          position.bTokenBalance
            .shiftedBy(position.bTokenDecimals)
            .toString(10),
          true,
          {
            gasLimit: estimatedGas.mul(12).div(10),
          },
        )
      ).wait(1)

      setTimeout(() => {
        queryClient.invalidateQueries([QueryKey.PositionsData])

        queryClient.invalidateQueries(
          [QueryKey.Series],
          {},
          {
            cancelRefetch: true,
          },
        )
      }, REFETCH_TIMEOUT)
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
      throw error
    }
  }

  return {
    positions,
    positionsFetched,
    sellOption,
    exerciseOption,
  }
}
