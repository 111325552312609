import {
  Box,
  Paper,
  Dialog,
  useMediaQuery,
  PaperProps,
  Theme,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { makeStyles } from "@mui/styles"
import React from "react"
import SimpleBar from "simplebar-react"
import { VStack, VStackProps } from "./Stack"

const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  sidePaper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,

    borderRadius: 0,
    padding: 0,

    backgroundImage: palette.themeColor(
      "url(/images/dark-side-background.png)",
      "url(/images/light-side-background.png)",
    ),
    backgroundRepeat: "no-repeat",
  },
  sideDialogPaper: {
    padding: 0,
  },
  sideContentWrapper: {
    display: "flex",
    flex: 1,
  },
  sideContent: {
    display: "flex",
    flex: 1,

    textAlign: "center",
    padding: spacing(4),
  },
  sideAction: {
    position: "sticky",
    bottom: 0,

    width: "100%",
    background: palette.colors.background0,
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(4),
    paddingRight: spacing(4),

    boxShadow: "0px -5px 12px rgba(0, 0, 0, 0.1)",
  },
}))

type SideProps = {
  open?: boolean
  onClose?: VoidFunction
  action?: React.ReactNode
} & PaperProps

const SideBase: React.FC<SideProps> = ({ children, open = false, onClose }) => {
  const classes = useStyles()

  const { breakpoints } = useTheme()

  const desktop = useMediaQuery(breakpoints.up("md"))

  if (!desktop) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        PaperProps={{ className: classes.sideDialogPaper }}
      >
        {children}
      </Dialog>
    )
  }

  return <Paper className={classes.sidePaper}>{children}</Paper>
}

const SideContent: React.FC<VStackProps> = ({ children, ...rest }) => {
  const classes = useStyles()

  return (
    <SimpleBar className={classes.sideContentWrapper}>
      <VStack className={classes.sideContent} {...rest}>
        {children}
      </VStack>
    </SimpleBar>
  )
}

const SideAction: React.FC = ({ children }) => {
  const classes = useStyles()

  return <Box className={classes.sideAction}>{children}</Box>
}

export const Side = Object.assign(SideBase, {
  Content: SideContent,
  Action: SideAction,
})
