import React from "react"

interface IArrowRightSvg {
  className?: string
}

const ArrowSouthWestSvg = ({ className }: IArrowRightSvg) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.17871 11.8213C4.417 12.0596 4.80334 12.0596 5.04162 11.8213L11.8213 5.04163C12.0596 4.80334 12.0596 4.417 11.8213 4.17871C11.583 3.94043 11.1967 3.94043 10.9584 4.17871L4.17871 10.9584C3.94043 11.1967 3.94043 11.583 4.17871 11.8213Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 11.3898C4 11.7268 4.27318 12 4.61017 12L11.3898 12C11.7268 12 12 11.7268 12 11.3898C12 11.0528 11.7268 10.7797 11.3898 10.7797L5.22034 10.7797L5.22034 4.61017C5.22034 4.27318 4.94716 4 4.61017 4C4.27318 4 4 4.27318 4 4.61017L4 11.3898Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowSouthWestSvg
