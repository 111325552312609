import { useWeb3 } from "@chainsafe/web3-context"
import { useQuery } from "@tanstack/react-query"
import { QueryKey } from "../../constants"

import { SirenRewardsResponse, SirenRewards } from "./types"

const REWARDS_API_URL = process.env.REACT_APP_SI_REWARDS_API

const fetchSirenRewards = (address: string): Promise<SirenRewardsResponse> =>
  fetch(`${REWARDS_API_URL}/?address=${address}`).then((res) => res.json())

const parseSirenRewards = (result: SirenRewardsResponse): SirenRewards => ({
  claimableRewards: Number.parseFloat(result.claimableRewards),
  claimedRewards: Number.parseFloat(result.claimedRewards),
  totalRewards: Number.parseFloat(result.totalRewards),
  claimMessage: result.claimMessage,
  recipient: result.recipient,
})

const DEFAULT_REWARDS: SirenRewards = {
  totalRewards: 0,
  claimedRewards: 0,
  claimableRewards: 0,
}

export const useSirenRewards = () => {
  const { address } = useWeb3()

  const {
    data: sirenRewards = DEFAULT_REWARDS,
    isFetched: sirenRewardsFetched,
  } = useQuery([QueryKey.PoolsRewards], () => fetchSirenRewards(address!), {
    enabled: Boolean(address && REWARDS_API_URL),
    select: parseSirenRewards,
    refetchInterval: 1200000,
    onError: () => {
      console.error("There was a problem fetching rewards")
    },
    refetchOnWindowFocus: false,
  })

  return { sirenRewards, sirenRewardsFetched }
}
