import React, { ReactNode } from "react"
import { alpha, Popover as MUIPopover, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"

import { usePopover } from "../../Hooks/usePopover"

type PopoverProps = {
  children({
    open,
    isOpen,
  }: {
    open: (event: React.MouseEvent<HTMLButtonElement>) => void
    isOpen: boolean
  }): ReactNode
  content: ReactNode
}

const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  root: {
    border: `1px solid ${palette.colors.stroke}`,
    backgroundColor: alpha(palette.colors.background2, 0.7),
    boxShadow: palette.shadows.tooltip,
    borderRadius: 4,
    paddingLeft: spacing(2.5),
    paddingTop: spacing(2),
    paddingRight: spacing(2.5),
    paddingBottom: spacing(2),
    backdropFilter: "blur(10px)",
  },
}))

export const Popover = ({ content, children }: PopoverProps) => {
  const classes = useStyles()

  const { anchorEl, open, handleClose, handleOpen } = usePopover()

  return (
    <>
      {children({ open: handleOpen, isOpen: open })}
      <MUIPopover
        id={open ? "simple-popover" : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          className: classes.root,
        }}
      >
        {content}
      </MUIPopover>
    </>
  )
}
