import {
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import React, { VFC, useCallback } from "react"
import { generatePath, useNavigate, useParams } from "react-router-dom"

import CashOutSvg from "../../../Components/icons/CashOutSvg"
import { useLanguageContext } from "../../../Contexts/LanguageContext"
import { useLockedLiquidity } from "../../../Contexts/SirenMarketsContext"
import { useMedia } from "../../../Hooks/useMedia"
import {
  HStack,
  InfoPopover,
  Typography,
  VStack,
  NoData,
  TokenAvatar,
  FixedBottomPanel,
  TableSkeleton,
} from "../../common"

const PortfolioLiquidityTable: VFC = () => {
  const { formatShortLocaleDate } = useLanguageContext()

  const { lockedLiquidity, lockedLiquidityFetched } = useLockedLiquidity()

  const navigate = useNavigate()
  const { id } = useParams<{ id?: string }>()

  const { mobile, tablet, desktop } = useMedia()

  const handleSelectRow = useCallback(
    (rowId) => {
      navigate(generatePath("/portfolio/liquidity/:id", { id: rowId }), {
        replace: true,
      })
    },
    [navigate],
  )

  if (!lockedLiquidityFetched) {
    return <TableSkeleton columns={mobile ? 4 : 6} />
  }

  if (lockedLiquidity.length === 0) {
    return (
      <NoData
        title="No locked liquidity"
        subtitle="Locked liquidity positions will be displayed here after you withdraw from a pool"
      />
    )
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{mobile ? "Asset/Est. Value" : "Asset"}</TableCell>
              <TableCell>
                <InfoPopover text="Maturity date is the last expiration date">
                  <span>Maturity date</span>
                </InfoPopover>
              </TableCell>
              {!mobile && <TableCell>Estimated value</TableCell>}
              <TableCell>
                {tablet ? "Lock. collateral" : "Locked collateral"}
              </TableCell>
              <TableCell>
                {tablet ? "Av. for cash-out" : "Available for cash-out"}
              </TableCell>
              {!mobile && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {lockedLiquidity.map((row) => (
              <TableRow
                key={row.id}
                selected={id === row.id}
                onClick={() => handleSelectRow(row.id)}
              >
                <TableCell>
                  <HStack space={1} alignItems={mobile ? "inherit" : "center"}>
                    <TokenAvatar tokenSymbol={row.collateralSymbol} size={20} />

                    <VStack space={0.5}>
                      <Typography size="extra-small" weight="bold" noWrap>
                        {row.underlyingSymbol} {row.positionType}
                      </Typography>

                      {mobile && (
                        <Typography
                          size="extra-small"
                          weight="regular"
                          color="textSecondary"
                        >
                          {row.estimatedValue.toFixed(4)} {row.collateralSymbol}
                        </Typography>
                      )}
                    </VStack>
                  </HStack>
                </TableCell>
                <TableCell>
                  <Typography
                    size="extra-small"
                    weight="regular"
                    color="textSecondary"
                  >
                    {formatShortLocaleDate(row.maturityDate)}
                  </Typography>
                </TableCell>

                {!mobile && (
                  <TableCell>
                    <Typography
                      size="extra-small"
                      weight="regular"
                      color="textSecondary"
                    >
                      {row.estimatedValue.toFixed(4)} {row.collateralSymbol}
                    </Typography>
                  </TableCell>
                )}

                <TableCell>
                  <Typography
                    size="extra-small"
                    weight="regular"
                    color="textSecondary"
                  >
                    {row.lockedCollateral.toFixed(4)} {row.collateralSymbol}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    size="extra-small"
                    weight="regular"
                    color="textSecondary"
                  >
                    {row.availableForCashOut.toFixed(4)} {row.collateralSymbol}
                  </Typography>
                </TableCell>

                {!mobile && (
                  <TableCell>
                    <HStack
                      space={0.5}
                      alignItems="center"
                      color="colors.green"
                    >
                      <SvgIcon component={CashOutSvg} fontSize="inherit" />
                      <Typography size="extra-small" weight="medium" noWrap>
                        Cash-out
                      </Typography>
                    </HStack>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!desktop && (
        <FixedBottomPanel>
          <Typography size="small" weight="medium" align="center">
            <Typography
              size="small"
              weight="medium"
              color="primary"
              component="span"
              paragraph={false}
            >
              To cash-out
            </Typography>
            , select an asset from the table
          </Typography>
        </FixedBottomPanel>
      )}
    </>
  )
}

export default PortfolioLiquidityTable
