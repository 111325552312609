import {
  Select as MUISelect,
  SelectProps as MUISelectProps,
  SvgIcon,
} from "@mui/material"
import React from "react"
import ChevronsDownSvg from "../icons/ChevronsDownSvg"

type SelectProps = MUISelectProps

const SelectIcon: MUISelectProps["IconComponent"] = (props) => (
  <SvgIcon component={ChevronsDownSvg} {...props} />
)

export const Select = ({ ...rest }: SelectProps) => {
  return <MUISelect IconComponent={SelectIcon} {...rest} />
}
