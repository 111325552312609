import { useWeb3 } from "@chainsafe/web3-context"
import { Menu as MenuIcon } from "@mui/icons-material"
import {
  AppBar,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Theme,
  Toolbar,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useMedia } from "../Hooks/useMedia"
import { usePalleteType } from "../Hooks/usePalleteType"
import { AppHeaderNetworkMenu } from "./AppHeaderNetworkMenu"
import { AppHeaderWalletMenu } from "./AppHeaderWalletMenu"
import { HStack, RIGHT_SIDE_WIDTH } from "./common"
import DiscordLogoSvg from "./icons/DiscordLogoSvg"
import HelpSvg from "./icons/HelpSvg"
import PoolSvg from "./icons/PoolSvg"
import PortfolioSvg from "./icons/PortfolioSvg"
import TradeSvg from "./icons/TradeSvg"
import { Logo } from "./Logo"
import { ThemeSwitcher } from "./ThemeSwitcher"

const useStyles = makeStyles<Theme>(
  ({ spacing, zIndex, breakpoints, palette }) => ({
    appBar: {
      zIndex: zIndex.drawer - 1,
      padding: 0,
      height: "100%",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: 30,
      paddingRight: 30,
      height: "100%",
    },
    logo: {
      display: "flex",
      cursor: "pointer",

      "& > svg": {
        width: 89,
      },

      [breakpoints.down("sm")]: {
        "& > svg": {
          width: 24,
        },
      },

      paddingRight: spacing(5),
    },
    navList: {
      display: "grid",
      gridTemplateColumns: "repeat(5, min-content)",
      gridGap: spacing(5),
      height: "100%",
      padding: 0,
      flex: 1,
      "& > *": {
        whiteSpace: "nowrap",
      },
      [breakpoints.down("md")]: {
        gridGap: spacing(4),
      },
    },
    navItem: {
      padding: 0,
      color: palette.colors.textTertiary,
      backgroundColor: "transparent",
      cursor: "pointer",
      border: "none",

      "& svg": {
        fill: palette.colors.textTertiary,
        transition: "none",
      },

      "&:hover": {
        color: palette.colors.primary,
        border: "none",

        "& svg": {
          fill: palette.colors.primary,
        },
      },
    },
    navItemSelected: {
      backgroundColor: "transparent !important",
      border: "none !important",

      color: `${palette.colors.primary} !important`,

      "&:hover": {
        color: `${palette.colors.primary} !important`,
      },

      position: "relative",

      "& svg": {
        fill: palette.colors.primary,
      },

      "&:after": {
        content: "''",
        bottom: -1,
        left: 0,
        position: "absolute",
        width: "100%",
        height: 3,
        background: palette.colors.primary,
      },
    },
    navItemText: {
      fontSize: 16,
      fontWeight: 500,
    },
    navListItemIcon: {
      minWidth: 0,
      paddingRight: spacing(1.5),
    },
    closeIcon: {
      minWidth: spacing(6),
      "& > svg": {
        fill: palette.error.main,
        height: 10.5,
        width: 10.5,
      },
    },
    menuContainer: {
      paddingLeft: 20,

      [breakpoints.up("lg")]: {
        width: RIGHT_SIDE_WIDTH - 31,
      },
    },
  }),
)

const AppHeader = ({
  handleDrawerOpen,
}: {
  handleDrawerOpen: VoidFunction
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isReady, network } = useWeb3()

  const { paletteType } = usePalleteType()

  const { largeDesktop, desktop, mobile } = useMedia()

  return (
    <AppBar className={classes.appBar} color="default" position="relative">
      <Toolbar className={classes.toolbar}>
        <div className={classes.logo} onClick={() => navigate("/trade")}>
          <Logo paletteType={paletteType} isFull={!mobile} />
        </div>

        {desktop && (
          <>
            <List className={classes.navList}>
              <ListItem
                selected={pathname.includes("/trade")}
                classes={{
                  root: classes.navItem,
                  selected: classes.navItemSelected,
                }}
                onClick={() => navigate("/trade")}
              >
                <ListItemIcon className={classes.navListItemIcon}>
                  <SvgIcon component={TradeSvg} viewBox="0 0 20 12" />
                </ListItemIcon>
                <ListItemText className={classes.navItemText} disableTypography>
                  Trade
                </ListItemText>
              </ListItem>
              <ListItem
                classes={{
                  root: classes.navItem,
                  selected: classes.navItemSelected,
                }}
                selected={pathname.includes("/earn")}
                onClick={() => navigate("/earn")}
              >
                <ListItemIcon className={classes.navListItemIcon}>
                  <SvgIcon component={PoolSvg} viewBox="0 0 16 19" />
                </ListItemIcon>
                <ListItemText className={classes.navItemText} disableTypography>
                  Earn
                </ListItemText>
              </ListItem>
              <ListItem
                classes={{
                  root: classes.navItem,
                  selected: classes.navItemSelected,
                }}
                selected={pathname.includes("/portfolio")}
                onClick={() => navigate("/portfolio")}
              >
                <ListItemIcon className={classes.navListItemIcon}>
                  <SvgIcon component={PortfolioSvg} viewBox="0 0 20 18" />
                </ListItemIcon>
                <ListItemText className={classes.navItemText} disableTypography>
                  Portfolio
                </ListItemText>
              </ListItem>
              <ListItem
                classes={{
                  root: classes.navItem,
                }}
                onClick={() => window.open("https://docs.siren.xyz", "blank")}
              >
                <ListItemIcon className={classes.navListItemIcon}>
                  <SvgIcon component={HelpSvg} viewBox="0 0 20 20" />
                </ListItemIcon>
                <ListItemText className={classes.navItemText} disableTypography>
                  Docs
                </ListItemText>
              </ListItem>
            </List>

            <HStack space={1} mr={2}>
              <ThemeSwitcher />

              <Button
                onClick={() => window.open("https://discord.gg/3j5pr4qWq8")}
                size="small"
                variant="support"
              >
                <HStack space={1} alignItems="center">
                  <SvgIcon component={DiscordLogoSvg} viewBox="0 0 22 16" />
                  {largeDesktop && <span>Support</span>}
                </HStack>
              </Button>
            </HStack>

            <Divider orientation="vertical" flexItem />
          </>
        )}

        <HStack className={classes.menuContainer} space={1}>
          {desktop && isReady && network && <AppHeaderNetworkMenu />}

          <AppHeaderWalletMenu />

          {!desktop && (
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              size="small"
            >
              <MenuIcon />
            </IconButton>
          )}
        </HStack>
      </Toolbar>
    </AppBar>
  )
}

export default AppHeader
