import { Theme } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { Skeleton } from "@mui/material"
import { SkeletonProps } from "@mui/lab"
import React from "react"

type SkeletonGridItemProps = SkeletonProps & {
  start?: number
  end?: number
  height?: number
}

const useStyles = makeStyles<Theme, SkeletonGridItemProps>(({ spacing }) => ({
  item: {
    borderRadius: spacing(1),
    height: (props) => spacing(props.height ?? 3),
    gridColumnStart: (props) => props.start,
    gridColumnEnd: (props) => props.end,
  },
}))

export const SkeletonGridItem = ({
  start,
  end,
  height,
  ...rest
}: SkeletonGridItemProps) => {
  const classes = useStyles({ start, end, height })

  return (
    <Skeleton
      variant="rectangular"
      width="100%"
      className={classes.item}
      {...rest}
    />
  )
}
