import { useWeb3 } from "@chainsafe/web3-context"
import { Check } from "@mui/icons-material"
import {
  alpha,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  Theme,
  useTheme,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import React from "react"
import config, { disabledChainIds } from "../config"
import { useSirenConfig } from "../Contexts/SirenConfigContext"
import { usePopover } from "../Hooks/usePopover"
import {
  HeaderButton,
  HStack,
  TokenAvatar,
  Typography,
  TokenAvatarStyle,
} from "./common"
import AlertIcon from "./icons/AlertSvg"

const useStyles = makeStyles<Theme>(({ palette }) => ({
  menu: {
    transform: "translate(0, 10px) !important",
    backgroundColor: palette.important(palette.colors.background4),
    border: "none",
  },
  menuItem: {
    "&:hover": {
      backgroundColor: palette.colors.background3,
    },

    "&:active": {
      backgroundColor: alpha(palette.colors.primary, 0.1),
    },

    "&.Mui-selected": {
      backgroundColor: palette.colors.background3,

      "&:hover": {
        backgroundColor: palette.colors.background3,
      },
    },

    "& + .MuiMenuItem-root": {
      marginTop: 5,
    },
  },
}))

export const AppHeaderNetworkMenu = () => {
  const classes = useStyles()
  const { palette } = useTheme()

  const { switchNetwork } = useWeb3()
  const {
    activeConfig,
    selectedChainId,
    setSelectedChainId,
    isWalletNetworkValid,
  } = useSirenConfig()

  const networks = Object.keys(config)
    .filter((chainId) => !disabledChainIds.includes(Number(chainId)))
    .map((chainId) => ({
      chainId: Number(chainId),
      name: config[Number(chainId)].networkName,
      icon: config[Number(chainId)].icon,
    }))

  const { anchorEl, open, handleClose, handleOpen } = usePopover()

  const isUnknownNetwork =
    !isWalletNetworkValid ||
    !networks.some((network) => network.chainId === selectedChainId)

  return (
    <>
      <HeaderButton
        color="primary"
        onClick={
          networks.length > 1 || isUnknownNetwork ? handleOpen : undefined
        }
        startIcon={
          isUnknownNetwork ? (
            <SvgIcon
              component={AlertIcon}
              color={"warning"}
              viewBox="0 0 19 20"
            />
          ) : (
            <TokenAvatar tokenSymbol="WMATIC" size={24} />
          )
        }
        hasMenu={networks.length > 1}
        open={open}
      >
        <Typography variant="body" size="extra-small" color="textSecondary">
          {isUnknownNetwork ? "Switch network" : activeConfig.networkName}
        </Typography>
      </HeaderButton>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PopoverClasses={{
          paper: classes.menu,
        }}
      >
        {isUnknownNetwork ? (
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              switchNetwork(networks[0].chainId)
              handleClose()
            }}
          >
            <ListItemText>Switch Wallet to {networks[0].name}</ListItemText>
          </MenuItem>
        ) : (
          networks.map((n) => (
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                setSelectedChainId(n.chainId)
                switchNetwork(n.chainId)
                handleClose()
              }}
              key={n.chainId}
              selected={n.chainId === selectedChainId}
            >
              <HStack alignItems="center">
                <TokenAvatar
                  tokenSymbol={n.icon}
                  tokenStyle={palette.themeColor(
                    TokenAvatarStyle.Light,
                    TokenAvatarStyle.Dark,
                  )}
                  size={24}
                />
                <Typography variant="body" size="medium" weight="medium">
                  {n.name}
                </Typography>
                {n.chainId === selectedChainId && isWalletNetworkValid && (
                  <SvgIcon component={Check} color="success" />
                )}
              </HStack>
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  )
}
