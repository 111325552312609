import { Button, SvgIcon, useMediaQuery, useTheme } from "@mui/material"
import React from "react"

import { useSirenMarketsContext } from "../Contexts/SirenMarketsContext"

import { Side, Typography } from "./common"
import CircleWalletDarkSvg from "./icons/CircleWalletDarkSvg"
import CircleWalletLightSvg from "./icons/CircleWalletLightSvg"

export const GetStartedSideContent: React.VFC<{
  description: string
}> = ({ description }) => {
  const { breakpoints, palette } = useTheme()
  const desktop = useMediaQuery(breakpoints.up("md"))

  const { handleSelectWalletConnect } = useSirenMarketsContext()

  return (
    <>
      <Side.Content space={3} alignItems="center">
        <Typography variant="h3">Lets get started</Typography>
        <Typography variant="body" size="small" color="textSecondary" pb={2}>
          {description}
        </Typography>

        <SvgIcon
          component={palette.themeColor(
            CircleWalletDarkSvg,
            CircleWalletLightSvg,
          )}
          viewBox="0 0 224 224"
          style={{ width: 224, height: 224 }}
        />
      </Side.Content>
      <Side.Action>
        <Button
          size={desktop ? "large" : "medium"}
          onClick={handleSelectWalletConnect}
          variant="primary"
          fullWidth
        >
          Connect Wallet
        </Button>
      </Side.Action>
    </>
  )
}
