import { alpha, Link, SvgIcon, TableCell, TableRow, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { BigNumber } from "bignumber.js"
import dayjs from "dayjs"
import { utils } from "ethers"
import { get } from "lodash"
import React, { FC, Fragment } from "react"
import { Link as RouterLink } from "react-router-dom"

import { useLanguageContext } from "../../../../Contexts/LanguageContext"
import { LiquidityPool } from "../../../../Contexts/SirenMarketsContext"
import { useMedia } from "../../../../Hooks/useMedia"
import {
  HStack,
  NumberTypography,
  TokenAvatar,
  Typography,
  VStack,
} from "../../../common"
import ChevronsRightSvg from "../../../icons/ChevronsRightSvg"

const useStyles = makeStyles<Theme, { assetToken: string }>(
  ({ breakpoints, palette, spacing }) => ({
    metrics: {
      paddingLeft: 32,

      [breakpoints.down("sm")]: {
        paddingLeft: 26,
      },
    },
    dotSpacer: {
      flex: "1 1 0",
      borderBottom: `1px dashed ${alpha(palette.colors.textTertiary, 0.3)}`,
      position: "relative",
      top: -5,
    },
    coreRow: ({ assetToken }) => {
      const assetColor = alpha(
        get(palette.colors, assetToken, palette.colors.WMATIC),
        0.1,
      )

      return {
        backgroundColor: assetColor,
        backgroundBlendMode: palette.themeColor("overlay", "multiply"),

        "&:hover": {
          backgroundImage: "none",
          backgroundBlendMode: "normal",
        },

        "&.Mui-selected": {
          backgroundImage: "none",
          backgroundBlendMode: "normal",
        },

        "&:not(:last-child)": {
          borderBottom: "none !important",
        },
      }
    },
    detailRow: {
      "&.Mui-selected": {
        borderTop: "none !important",
      },
    },
    viewDetailsIcon: {
      width: 20,
      height: 20,
    },
  }),
)

type EarnListRowProps = {
  pool: LiquidityPool
  selected: boolean
  onClick: (address: string) => void
}

export const EarnListRow: FC<EarnListRowProps> = ({
  pool,
  selected,
  onClick,
}) => {
  const { formatPercents, formatMoney } = useLanguageContext()
  const classes = useStyles({ assetToken: pool.collateralTokenSymbol })
  const { mobile, mediaSwitch } = useMedia()

  const poolValue = (
    <VStack space={0.5}>
      <Typography
        size={mediaSwitch("medium", "tiny", "extra-small")}
        weight={mediaSwitch("bold", "regular", "regular")}
      >
        {utils.commify(
          new BigNumber(pool.poolValueLocked).decimalPlaces(2).toString(),
        )}{" "}
        {pool.collateralTokenSymbol}
      </Typography>

      <Typography
        color="textSecondary"
        size={mediaSwitch("extra-small", "tiny")}
      >
        {formatMoney(
          Math.ceil(
            pool.collateralTokenSymbol.toLowerCase() === "usdc"
              ? pool.poolValueLocked
              : pool.poolValueLocked * pool.exchangeRate,
          ),
        )}
      </Typography>
    </VStack>
  )
  return (
    <Fragment key={pool.address}>
      <TableRow
        className={classes.coreRow}
        hover
        key={pool.address}
        selected={selected}
        onClick={() => onClick(pool.address)}
      >
        <TableCell height={mobile ? undefined : 70}>
          <HStack space={1} alignItems={mobile ? "inherit" : "center"}>
            <TokenAvatar
              tokenSymbol={pool.collateralTokenSymbol}
              size={[44, 20, 24]}
            />

            <VStack space={0.5}>
              <Typography
                size={mediaSwitch("medium", "tiny", "small")}
                weight="bold"
              >
                {`${pool.underlyingTokenSymbol} ${
                  pool.poolType === "Call" ? "Calls" : "Puts"
                }`}
              </Typography>
              {mobile && poolValue}
            </VStack>
          </HStack>
        </TableCell>
        {!mobile && <TableCell align="right">{poolValue}</TableCell>}
        <TableCell align="right">
          <NumberTypography
            value={Number(pool.projectedYield)}
            size={mediaSwitch("medium", "tiny", "extra-small")}
            weight={mediaSwitch("bold", "regular", "regular")}
          >
            {formatPercents(pool.projectedYield)}
          </NumberTypography>
        </TableCell>
        <TableCell align="right">
          <Typography>
            {pool.userBalance?.decimalPlaces(4).toString(10) || 0}
          </Typography>
        </TableCell>
      </TableRow>
      {selected && (
        <TableRow
          className={classes.detailRow}
          hover
          selected={selected}
          key={`details-${pool.address}`}
        >
          <TableCell colSpan={3}>
            <VStack className={classes.metrics} space={1} mb={1.5}>
              <HStack justifyContent="space-between" space={1}>
                <Typography>
                  {`Pool APY since inception (${dayjs(pool.createdAt).toNow(
                    true,
                  )}):`}
                </Typography>
                <div className={classes.dotSpacer} />
                <Typography color="colors.green">
                  {formatPercents(pool.yieldSinceInception)}
                </Typography>
              </HStack>
              <HStack justifyContent="space-between" space={1}>
                <Typography>Pool APY (last 30 days):</Typography>
                <div className={classes.dotSpacer} />
                <Typography color="colors.green">
                  {formatPercents(pool.yieldLastMonth)}
                </Typography>
              </HStack>
              <HStack justifyContent="space-between" space={1}>
                <Typography>Pool utilization:</Typography>
                <div className={classes.dotSpacer} />
                <Typography color="colors.green">
                  {formatPercents(pool.utilization)}
                </Typography>
              </HStack>
              {mobile && (
                <Link
                  variant="action"
                  underline="none"
                  component={RouterLink}
                  to={`/earn/${pool.address}`}
                  display="inline-flex"
                  alignSelf="end"
                >
                  <HStack alignItems="center" space={0.5}>
                    <Typography>View details</Typography>
                    <SvgIcon
                      component={ChevronsRightSvg}
                      className={classes.viewDetailsIcon}
                    />
                  </HStack>
                </Link>
              )}
            </VStack>
          </TableCell>
          {!mobile && (
            <TableCell
              colSpan={1}
              align="right"
              style={{ verticalAlign: "bottom" }}
            >
              <Link
                variant="action"
                underline="none"
                component={RouterLink}
                to={`/earn/${pool.address}`}
                display="inline-flex"
                mb={1.5}
              >
                <HStack alignItems="center" space={0.5}>
                  <Typography>View details</Typography>
                  <SvgIcon
                    component={ChevronsRightSvg}
                    className={classes.viewDetailsIcon}
                  />
                </HStack>
              </Link>
            </TableCell>
          )}
        </TableRow>
      )}
    </Fragment>
  )
}
