import { Box, Dialog, DialogProps, Theme } from "@mui/material"
import React, { FC, useEffect, useMemo } from "react"
import { LiFiWidget, useWidgetEvents, WidgetConfig } from "@lifi/widget"
import { makeStyles } from "@mui/styles"

import { useSirenConfig } from "../../Contexts/SirenConfigContext"
import { usePalleteType } from "../../Hooks/usePalleteType"

const useStyles = makeStyles<Theme>(() => ({
  root: {
    backdropFilter: "none",
  },
  paper: {
    borderRadius: 8,
  },
}))

interface LiFiDialogProps extends DialogProps {
  onClose: () => void
  toToken: string
}

export const LiFiDialog: FC<LiFiDialogProps> = ({ toToken, open, onClose }) => {
  const config = useSirenConfig()

  const { paletteType } = usePalleteType()

  const classes = useStyles()

  const widgetConfig: WidgetConfig = useMemo(
    () => ({
      toChain: config.selectedChainId,
      toToken,
      appearance: paletteType,
    }),
    [config.selectedChainId, toToken, paletteType],
  )

  const widgetEvents = useWidgetEvents()

  useEffect(() => {
    widgetEvents.on("routeExecutionCompleted", () => {
      onClose()
    })

    return () => {
      widgetEvents.off("routeExecutionCompleted")
    }
  }, [widgetEvents, onClose])

  return (
    <Dialog
      classes={{ root: classes.root, paper: classes.paper }}
      open={open}
      onClose={onClose}
      PaperComponent={Box}
    >
      <LiFiWidget config={widgetConfig} />
    </Dialog>
  )
}
