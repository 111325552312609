export type PaletteColors = {
  primary: string
  primaryLight: string
  primaryDark: string
  primaryGradient0: string
  primaryGradient1: string
  primaryGradient2: string
  primaryGradient3: string
  primaryGradient4: string
  primaryGradient5: string
  background0: string
  background0Gradient: string
  background0Fade: string
  background1: string
  background2: string
  background3: string
  background4: string
  background4Fade: string
  textPrimary: string
  textSecondary: string
  textTertiary: string
  textButton: string
  textDisabled: string
  textLoading: string
  red: string
  redLight: string
  green: string
  greenLight: string
  greenDark: string
  greenDarker: string
  yellow: string
  logo1: string
  logo2: string
  icon: string
  scrollbar: string
  toggle: string
  stroke: string
  skeleton: string
  twitter: string
  support: string
  earnBackgroundTitle1: string
  earnBackgroundTitle2: string
  errorPageTitle: string

  WETH: string
  WETHGradient: string
  WMATIC: string
  WMATICGradient: string
  USDC: string
  USDCGradient: string
  DPI: string
  DPIGradient: string
  YFI: string
  YFIGradient: string
  SUSHI: string
  SUSHIGradient: string
  KNC: string
  KNCGradient: string
  UNI: string
  UNIGradient: string
  WBTC: string
  WBTCGradient: string
}

export enum DarkPalette {
  Primary = "#A448FF",
  PrimaryLight = "#C86CFF",
  PrimaryDark = "#8438CD",

  PrimaryGradient0 = "linear-gradient(180deg, #E24BFC 0%, #8438CD 100%)",
  PrimaryGradient1 = "linear-gradient(180deg, #E24BFC 0%, #C86CFF 100%)",
  PrimaryGradient2 = "linear-gradient(128.61deg, #8438CD 26.35%, #A448FF 72.2%)",
  PrimaryGradient3 = "linear-gradient(270deg, rgba(255, 255, 255, 0) 22.38%, rgba(255, 255, 255, 0.28) 24.78%, rgba(255, 255, 255, 0.14) 100%), linear-gradient(180deg, #A448FF 0%, #8438CD 100%)",
  PrimaryGradient4 = "linear-gradient(180deg, rgba(226, 75, 252, 0.2) 0%, rgba(104, 28, 215, 0.2) 100%)",
  PrimaryGradient5 = "linear-gradient(248.09deg, #8438CD 0%, #C86CFF 100%)",

  Background0 = "#1C2028",
  Background0Gradient = "linear-gradient(180deg, #1C2028 0%, #1C2228 100%)",
  Background0Fade = "linear-gradient(180deg, rgba(28, 32, 40, 0) 0%, #1C2028 100%)",

  Background1 = "#191D24",
  Background2 = "#161A20",
  Background3 = "#13171D",
  Background4 = "#101419",
  Background4Fade = "linear-gradient(90deg, rgba(16, 20, 25, 0) 0%, #101419 57.86%)",

  TextPrimary = "#F6FAFF",
  TextSecondary = "#C1CFDD",
  TextTertiary = "#8491A3",
  TextButton = "#101419",
  TextDisabled = "rgba(255, 255, 255, 0.3)",
  TextLoading = "rgba(16, 20, 25, 0.5)",

  Red = "#FF4646",
  RedLight = "#FF7171",

  Green = "#94F83E",
  GreenGradient = "linear-gradient(0deg, rgba(148, 248, 62, 0.0001) -3.75%, rgba(148, 248, 62, 0.5) 93.32%)",
  GreenLight = "#98FF60",
  GreenDark = "#77C930",
  GreenDarker = "#2D4E10",

  Yellow = "#FFCC47",

  Logo1 = "#49A8E2",
  Logo2 = "#C4E1F6",

  Icon = "#FFFFFF",
  Scrollbar = "rgba(255, 255, 255, 0.7)",
  Toggle = "#FFFFFF",
  Stroke = "#2E323D",
  Skeleton = "linear-gradient(179.93deg, rgba(98, 113, 131, 0.1) 0.06%, rgba(98, 113, 131, 0) 99.94%)",

  Twitter = "#55ACEE",
  Support = "#5865F2",

  EarnBackgroundTitle1 = "linear-gradient(91.13deg, #1045E3 2.52%, #E24BFC 46.98%, #94F83E 99.53%)",
  EarnBackgroundTitle2 = "linear-gradient(270deg, #2AC4E6 0%, #94F83E 134.34%)",
  ErrorPageTitle = "linear-gradient(131.67deg, #103255 0%, #2358BE 24.47%, #E24BFC 40.19%, #94F83E 54.73%, #2AC4E6 72.01%)",
}

export enum DarkShadows {
  Tooltip = "0px 4px 30px rgba(0, 0, 0, 0.25)",
  Dropdown = "0px 10px 20px rgba(0, 0, 0, 0.2)",
}

export enum LightPalette {
  Primary = "#652EEB",
  PrimaryLight = "#8458EF",
  PrimaryDark = "#5F1FD7",

  PrimaryGradient0 = "linear-gradient(180deg, #9036F9 0%, #652EEB 100%)",
  PrimaryGradient1 = "linear-gradient(180deg, #852EEB 0%, #8458EF 100%)",
  PrimaryGradient2 = "linear-gradient(180deg, #5F1FD7 0%, #652EEB 100%)",
  PrimaryGradient3 = "linear-gradient(270deg, rgba(255, 255, 255, 0) 22.38%, rgba(255, 255, 255, 0.18) 24.78%, rgba(255, 255, 255, 0.12) 100%), linear-gradient(180deg, #9036F9 0%, #652EEB 100%)",
  PrimaryGradient4 = "linear-gradient(180deg, #DEC3FD 0%, #D1C0F9 100%)",
  PrimaryGradient5 = "linear-gradient(268.49deg, #9036F9 0%, #652EEB 100%)",

  Background0 = "#FFFFFF",
  Background0Gradient = "linear-gradient(180deg, #FFFFFF 0%, #F8F8FA 100%)",
  Background0Fade = "linear-gradient(180deg, rgba(255, 255, 255, 0.62) 0%, #FFFFFF 100%)",
  Background1 = "#F4F0FE",
  Background2 = "#FAFBFC",
  Background3 = "#F7F9FC",
  Background4 = "#FFFFFF",
  Background4Fade = "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 57.86%)",

  TextPrimary = "#13171D",
  TextSecondary = "#53565A",
  TextTertiary = "#86898B",
  TextButton = "#FFFFFF",
  TextDisabled = "rgba(50, 65, 82, 0.5)",
  TextLoading = "rgba(255, 255, 255, 0.5)",

  Red = "#FF4646",
  RedLight = "#FF7171",

  Green = "#3CB318",
  GreenGradient = "linear-gradient(0deg, rgba(60, 179, 24, 0) -3.75%, rgba(60, 179, 24, 0.8) 93.32%)",
  GreenLight = "#72D629",
  GreenDark = "#2A940F",
  GreenDarker = "#156105",

  Yellow = "#FFA51E",

  Logo1 = "#0AA1FF",
  Logo2 = "#465B71",

  Icon = "#76899C",
  Scrollbar = "rgba(15, 39, 61, 0.4)",
  Toggle = "#FFFFFF",
  Stroke = "#E5E7F4",
  Skeleton = "linear-gradient(179.93deg, rgba(167, 185, 207, 0.1) 0.06%, rgba(167, 185, 207, 0) 99.94%)",

  Twitter = "#55ACEE",
  Support = "#4655FE",

  EarnBackgroundTitle1 = "linear-gradient(91.13deg, #1045E3 2.52%, #E24BFC 46.98%, #72D629 99.53%)",
  EarnBackgroundTitle2 = "linear-gradient(270deg, #2AC4E6 0%, #72D629 134.34%)",
  ErrorPageTitle = "linear-gradient(131.67deg, #103255 0%, #2358BE 22.3%, #9036F9 44.21%, #3CB318 61.12%, #2AC4E6 84.29%)",
}

export enum LightShadows {
  Tooltip = "0px 4px 30px rgba(49, 63, 86, 0.12)",
  Dropdown = "0px 10px 20px rgba(0, 0, 0, 0.1)",
}

export enum AssetsPalette {
  WETH = "#B8C0FF",
  WETHGradient = "radial-gradient(80.17% 84.71% at 19.17% 15.29%, #FFFFFF 0%, #FFFFFF 0%, #E3E6FF 52.6%, #9398C7 100%)",

  WMATIC = "#9F73FF",
  WMATICGradient = "radial-gradient(80.17% 84.71% at 19.17% 15.29%, #E4D8FF 0%, #A57BFF 52.6%, #8044FF 100%)",

  USDC = "#2775ca",
  USDCGradient = "radial-gradient(80.17% 84.71% at 19.17% 15.29%, #ACE0FF 0%, #109CF3 52.6%, #2775ca 100%)",

  DPI = "#9F73FF",
  DPIGradient = "radial-gradient(80.17% 84.71% at 19.17% 15.29%, #E4D8FF 0%, #A57BFF 52.6%, #8044FF 100%)",

  YFI = "#0F9CF3",
  YFIGradient = "radial-gradient(80.17% 84.71% at 19.17% 15.29%, #ACE0FF 0%, #109CF3 52.6%, #0075BD 100%)",

  SUSHI = "#FF4E8D",
  SUSHIGradient = "radial-gradient(80.17% 84.71% at 19.17% 15.29%, #FFC0D7 0%, #FF639A 52.6%, #FF3980 100%)",

  KNC = "#31CB9D",
  KNCGradient = "radial-gradient(80.17% 84.71% at 19.17% 15.29%, #D5FFF2 0%, #48E8B8 52.6%, #0FBF8B 100%)",

  UNI = "#FF5AD1",
  UNIGradient = "radial-gradient(80.17% 84.71% at 19.17% 15.29%, #FFA8E7 0%, #FF75D8 52.6%, #E022AB 100%)",

  WBTC = "#FFA51E",
  WBTCGradient = "radial-gradient(80.17% 84.71% at 19.17% 15.29%, #FFEDBE 0%, #FFCC47 52.6%, #FFA51E 100%)",
}
