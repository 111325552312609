import {
  alpha,
  Box,
  Skeleton,
  SvgIcon,
  Theme,
  Tooltip,
  useTheme,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { get, startCase } from "lodash"
import React, { VFC } from "react"
import { Link } from "react-router-dom"
import clsx from "clsx"

import { LiquidityPool } from "../../../../Contexts/SirenMarketsContext"

import {
  HStack,
  TokenAvatar,
  TokenAvatarStyle,
  Typography,
  VStack,
} from "../../../common"

import { useLanguageContext } from "../../../../Contexts/LanguageContext"
import { useMedia } from "../../../../Hooks/useMedia"
import ArrowDownSvg from "../../../icons/ArrowDownSvg"
import ArrowUpSvg from "../../../icons/ArrowUpSvg"
import InfoIcon from "../../../icons/InfoSvg"

const useStyles = makeStyles<Theme, { assetToken: string }>(
  ({ palette, breakpoints }) => ({
    link: {
      textDecoration: "none",
      display: "flex",
      flex: 1,
    },
    root: ({ assetToken }) => ({
      position: "relative",
      padding: "35px 35px 40px 40px",
      borderRadius: 24,

      color: palette.colors.textPrimary,
      border: `2px solid ${alpha(
        get(palette.colors, assetToken, palette.colors.WMATIC),
        0.8,
      )}`,

      [breakpoints.down("sm")]: {
        padding: 16,
        border: `1px solid ${alpha(
          get(palette.colors, assetToken, palette.colors.WMATIC),
          0.8,
        )}`,
      },

      transition: "color 300ms ease-in-out",

      "& [data-text-color]": {
        transition: "color 300ms ease-in-out",
      },

      "&:after": {
        content: '""',
        position: "absolute",
        zIndex: -1,

        top: 2,
        left: 2,
        width: "calc(100% - 4px)",
        height: "calc(100% - 4px)",
        borderRadius: 20,
        opacity: 0,

        background: get(
          palette.colors,
          `${assetToken}Gradient`,
          palette.colors.WMATICGradient,
        ),
        transition: "opacity 300ms ease-in-out",

        boxShadow: `0px ${palette.themeColor(0, 10)}px 30px ${alpha(
          get(palette.colors, assetToken, palette.colors.WMATIC),
          0.5,
        )}, 0px 15px 120px ${alpha(
          get(palette.colors, assetToken, palette.colors.WMATIC),
          0.3,
        )}`,
      },

      "&:before": {
        content: '""',
        position: "absolute",
        zIndex: -1,

        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: 20,

        background: alpha(
          get(palette.colors, assetToken, palette.colors.WMATIC),
          palette.themeColor(0.5, 0.1),
        ),
        mixBlendMode: palette.themeColor("overlay", "multiply"),
      },

      "& [data-text-color='secondary']": {
        color: palette.colors.textSecondary,
      },

      "& [data-text-color='tetriary']": {
        color: palette.colors.textTertiary,
      },

      "&:hover": {
        color: palette.themeColor(
          palette.colors.textButton,
          palette.colors.textPrimary,
        ),

        borderColor: "transparent",

        "&:before": {
          top: -3,
          left: -3,
          width: "calc(100% + 6px)",
          height: "calc(100% + 6px)",
          mixBlendMode: "normal",
          background: "transparent",

          border: `2px solid ${alpha(
            get(palette.colors, assetToken, palette.colors.WMATIC),
            0.5,
          )}`,
          borderRadius: 24,
        },

        "&:after": {
          opacity: 1,
        },

        "& [data-text-color='secondary']": {
          color: palette.themeColor(
            palette.colors.textButton,
            palette.colors.textPrimary,
          ),
        },

        "& [data-text-color='tetriary']": {
          color: palette.themeColor(
            palette.colors.textButton,
            palette.colors.textPrimary,
          ),
          opacity: 0.8,
        },

        "& .avatar-main": {
          opacity: 0,
        },

        "& .avatar-hover": {
          opacity: 1,
        },
      },
    }),
    avatar: ({ assetToken }) => ({
      background: get(palette.colors, assetToken, palette.colors.WMATIC),
      transition: "opacity 300ms ease-in-out",

      position: "absolute",
      top: 0,
      left: 0,

      "&.avatar-hover": {
        opacity: 0,
      },
    }),
  }),
)

type EarnCardProps = {
  pool: LiquidityPool
  poolsOnchainFetched: boolean
}

export const EarnCard: VFC<EarnCardProps> = ({ pool, poolsOnchainFetched }) => {
  const classes = useStyles({ assetToken: pool!.collateralTokenSymbol })

  const { palette } = useTheme()

  const { formatPercents, formatMoney } = useLanguageContext()

  const { mobile, desktop } = useMedia()

  return (
    <Link className={classes.link} to={`/earn/${pool.address}`}>
      <VStack className={classes.root} justifyContent="space-between">
        <HStack justifyContent="space-between">
          <VStack space={0.5}>
            <Typography variant={mobile ? "h3" : "h1"} color="inherit">
              {pool?.underlyingTokenSymbol}
            </Typography>
            <Typography
              size={mobile ? "tiny" : "medium"}
              weight="bold"
              color="inherit"
            >
              <HStack alignItems="center" space={1}>
                <span>{startCase(pool.poolType)}s</span>

                <SvgIcon
                  component={
                    pool.poolType === "Call" ? ArrowUpSvg : ArrowDownSvg
                  }
                  fontSize="small"
                />
              </HStack>
            </Typography>
          </VStack>

          <Box
            position="relative"
            width={desktop ? "72px" : "40px"}
            height={desktop ? "72px" : "40px"}
          >
            <TokenAvatar
              className={clsx(classes.avatar, "avatar-main")}
              tokenSymbol={pool.collateralTokenSymbol}
              size={[72, 40, 40]}
            />

            <TokenAvatar
              className={clsx(classes.avatar, "avatar-hover")}
              tokenSymbol={pool.collateralTokenSymbol}
              tokenStyle={palette.themeColor(
                TokenAvatarStyle.Light,
                TokenAvatarStyle.Dark,
              )}
              size={[72, 40, 40]}
            />
          </Box>
        </HStack>

        <Typography
          size={mobile ? "x-tiny" : "small"}
          color="inherit"
          data-text-color="secondary"
        >
          {pool.poolType === "Call"
            ? `The vault earns yield on its ${pool?.collateralTokenSymbol} deposits via selling covered calls and trading fees`
            : `The vault earns yield on its ${pool?.collateralTokenSymbol} deposits via selling cash-secured puts and trading fees`}
        </Typography>

        <HStack justifyContent="space-between">
          <VStack space={0.5}>
            <Typography
              size={mobile ? "x-tiny" : "extra-small"}
              color="inherit"
              data-text-color="tetriary"
            >
              Total value locked
            </Typography>
            <Typography variant={mobile ? "h6" : "h1"} color="inherit">
              {poolsOnchainFetched ? (
                formatMoney(
                  Math.ceil(
                    pool.collateralTokenSymbol.toLowerCase() === "usdc"
                      ? pool.poolValueLocked
                      : pool.poolValueLocked * pool.exchangeRate,
                  ),
                  mobile
                    ? {
                        compactDisplay: "short",
                        notation: "compact",
                      }
                    : {
                        maximumFractionDigits: 0,
                      },
                )
              ) : (
                <Skeleton variant="text" />
              )}
            </Typography>
          </VStack>

          <VStack space={0.5} alignItems="flex-end">
            <HStack space={0.5} alignItems="center">
              <Typography
                size={mobile ? "x-tiny" : "extra-small"}
                color="inherit"
                data-text-color="tetriary"
              >
                APY
              </Typography>

              <Tooltip title="APY from last two weeks of premiums earned from options sold by the pool (not indicative of future returns).">
                <SvgIcon
                  component={InfoIcon}
                  viewBox="0 0 22 22"
                  fontSize="small"
                  data-text-color="tetriary"
                />
              </Tooltip>
            </HStack>

            <Typography variant={mobile ? "h6" : "h1"} color="inherit">
              {poolsOnchainFetched ? (
                formatPercents(pool.projectedYield, "raw", {
                  maximumSignificantDigits: 2,
                })
              ) : (
                <Skeleton variant="text" />
              )}
            </Typography>
          </VStack>
        </HStack>
      </VStack>
    </Link>
  )
}
