import { useApolloClient } from "@apollo/client"
import { useWeb3 } from "@chainsafe/web3-context"
import * as Sentry from "@sentry/react"
import {
  AmmDataProvider__factory,
  ERC1155Controller__factory,
  MinterAmm__factory,
  PriceOracle__factory,
  SimpleToken__factory,
} from "@sirenmarkets/sdk"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { BigNumber } from "bignumber.js"
import dayjs from "dayjs"
import { constants, providers, utils } from "ethers"
import { useCallback, useMemo } from "react"

import { QueryKey, SLIPPAGE } from "../../constants"
import { liquidityPoolsQuery } from "../graphQueries"
import { useLanguageContext } from "../LanguageContext"
import {
  GetLiquidityPools,
  GetLiquidityPoolsVariables,
  GetLiquidityPools_amms,
  GetLiquidityPools_amms_recentTokenEvents_BTokenBought,
} from "../types/GetLiquidityPools"

import { REFETCH_TIMEOUT, STRIKE_PRICE_DECIMALS } from "./constants"
import { useSirenMarketsContext } from "./SirenMarketsContext"
import { Addresses, LiquidityPool, PoolTokenBalance } from "./types"
import { useAddresses } from "./useAddresses"

// This contains a list of pools that should be overridden to `Inactive`
const overrideInactivePools: Array<string> = process.env
  .REACT_APP_OVERRIDE_INACTIVE_POOLS
  ? process.env.REACT_APP_OVERRIDE_INACTIVE_POOLS.split(",")
  : []

const last30Days = dayjs().subtract(30, "day").unix()
const recentEventsTimestamp = dayjs().subtract(2, "weeks").unix()
const upgradeTimestamp = 1645059600 // 2022-02-17 01:00 GMT
const poolActivityTimestamp = 1651708800 // 2022/05-05 00:00 GMT

const poolsWithoutActivityTimestamp = [
  "0x41667758d84c1a6c89e4422fce6453bb376ddb8d",
  "0xa42e6396c4a66c764d5e86f1ba6b68c3b6427f64",
  "0xb54dfba46af632e865c3cec53f4e631307fa22ef",
  "0x27ece594b45f19a5fbf6080c659344399cf10e61",
  "0x387d3a92122703d5e78547ae767c723aa7856e33",
]

type PoolOnchainData = {
  poolCollateralTokenBalance: BigNumber
  lpTokenBalance: BigNumber
  exchangeRate: number
  poolOptionBalances: Array<PoolTokenBalance>
  onchainPoolValue: BigNumber
}

const DEFAULT_POOL_ONCHAIN_DATA: PoolOnchainData = {
  poolCollateralTokenBalance: new BigNumber(0),
  lpTokenBalance: new BigNumber(0),
  exchangeRate: 1,
  poolOptionBalances: [],
  onchainPoolValue: new BigNumber(0),
}

const fetchLpTokenBalance = async (
  readProvider: providers.Provider,
  pool: GetLiquidityPools_amms,
  userAddress?: string,
) => {
  const lpTokenContract = SimpleToken__factory.connect(
    pool.lpToken.id,
    readProvider,
  )

  const lpTokenBalance = !!userAddress
    ? new BigNumber(
        (await lpTokenContract.balanceOf(userAddress)).toString(),
      ).shiftedBy(-pool.lpToken.decimals)
    : new BigNumber(0)

  return lpTokenBalance
}

const fetchExchangeRate = async (
  readProvider: providers.Provider,
  pool: GetLiquidityPools_amms,
  priceOracleAddress: string,
) => {
  const priceOracleContract = PriceOracle__factory.connect(
    priceOracleAddress,
    readProvider,
  )

  const exchangeRate = new BigNumber(
    (
      await priceOracleContract.getCurrentPrice(
        pool.underlyingToken.id,
        pool.priceToken.id,
      )
    ).toString(),
  )
    .shiftedBy(-STRIKE_PRICE_DECIMALS)
    .toNumber()

  return exchangeRate
}

const fetchPoolOnchainData = async (
  readProvider: providers.Provider | undefined,
  pool: GetLiquidityPools_amms,
  addresses: Addresses,
  userAddress: string | undefined,
  formatShortLocaleDate: (date: Date) => string,
): Promise<PoolOnchainData> => {
  const {
    ammDataProviderAddress,
    priceOracleAddress,
    erc1155ControllerAddress,
  } = addresses

  if (
    !readProvider ||
    !ammDataProviderAddress ||
    !priceOracleAddress ||
    !erc1155ControllerAddress
  ) {
    return DEFAULT_POOL_ONCHAIN_DATA
  }

  try {
    const collateralTokenContract = SimpleToken__factory.connect(
      pool.collateralToken.id,
      readProvider,
    )

    const ammDataProviderContract = AmmDataProvider__factory.connect(
      ammDataProviderAddress,
      readProvider,
    )

    const [
      lpTokenBalance,
      collateralBal,
      unclaimedCollateral,
      onchainPoolValue,
      exchangeRate,
    ] = await Promise.all([
      fetchLpTokenBalance(readProvider, pool, userAddress),
      collateralTokenContract.balanceOf(pool.id),
      ammDataProviderContract.getCollateralValueOfAllExpiredOptionTokensView(
        pool.id,
      ),
      ammDataProviderContract.getTotalPoolValueView(pool.id, true),
      fetchExchangeRate(readProvider, pool, priceOracleAddress),
    ])

    const poolCollateralTokenBalance = new BigNumber(collateralBal.toString())
      .plus(new BigNumber(unclaimedCollateral.toString()))
      .shiftedBy(-pool.collateralToken.decimals)

    const poolTokens: Array<PoolTokenBalance> = pool.series
      .filter((s) => s?.series.expirationDate > dayjs().unix())
      .flatMap((s) => [
        {
          name: `$${utils.commify(
            new BigNumber(s?.series.strikePrice)
              .shiftedBy(-STRIKE_PRICE_DECIMALS)
              .toString(),
          )} ${formatShortLocaleDate(
            dayjs.unix(s?.series.expirationDate).toDate(),
          )} bTokens`,
          tokenIndex: s?.series.bToken.index,
          tokenType: "bToken",
          strikePrice: new BigNumber(s?.series.strikePrice).shiftedBy(
            -STRIKE_PRICE_DECIMALS,
          ),
          amount: new BigNumber(0),
          decimals: s?.series.bToken.decimals,
          expiration: s?.series.expirationDate,
        },
        {
          name: `$${utils.commify(
            new BigNumber(s?.series.strikePrice)
              .shiftedBy(-STRIKE_PRICE_DECIMALS)
              .toString(),
          )} ${formatShortLocaleDate(
            dayjs.unix(s?.series.expirationDate).toDate(),
          )}  wTokens`,
          tokenIndex: s?.series.wToken.index,
          tokenType: "wToken",
          strikePrice: new BigNumber(s?.series.strikePrice).shiftedBy(
            -STRIKE_PRICE_DECIMALS,
          ),
          amount: new BigNumber(0),
          decimals: s?.series.wToken.decimals,
          expiration: s?.series.expirationDate,
        },
      ])

    const erc1155ControllerContract = ERC1155Controller__factory.connect(
      erc1155ControllerAddress,
      readProvider,
    )

    const bals = await erc1155ControllerContract.balanceOfBatch(
      poolTokens.map(() => pool.id),
      poolTokens.map((t) => t.tokenIndex),
    )

    const optionBalances: Array<PoolTokenBalance> = bals.map((balance, i) => ({
      ...poolTokens[i],
      amount: new BigNumber(balance.toString()).shiftedBy(
        -(poolTokens[i].decimals || 0),
      ),
    }))

    return {
      poolCollateralTokenBalance,
      exchangeRate,
      lpTokenBalance,
      poolOptionBalances: optionBalances,
      onchainPoolValue: new BigNumber(onchainPoolValue.toString()),
    }
  } catch (error) {
    console.log(error)
    console.log("error fetching chain data for pool: ", pool.id)
    return DEFAULT_POOL_ONCHAIN_DATA
  }
}

const processPool = (
  pool: GetLiquidityPools_amms,
  poolOnchainData: PoolOnchainData,
  ammDataProviderAddress: string | undefined,
): LiquidityPool => {
  const {
    poolCollateralTokenBalance,
    lpTokenBalance,
    exchangeRate,
    poolOptionBalances,
    onchainPoolValue,
  } = poolOnchainData

  const name =
    pool.collateralToken.id === pool.underlyingToken.id
      ? `${pool.underlyingToken.symbol} / ${pool.priceToken.symbol} Calls (${pool.collateralToken.symbol})`
      : `${pool.underlyingToken.symbol} / ${pool.priceToken.symbol} Puts (${pool.collateralToken.symbol})`

  const lpTokenSupply = new BigNumber(pool.lpToken.totalSupply)
    .shiftedBy(-pool.lpToken.decimals)
    .toNumber()

  const initialLpTokenValue = pool.valueAfterUpgrade[0]
    ? new BigNumber(pool.valueAfterUpgrade[0].poolValue)
        .div(pool.valueAfterUpgrade[0].lpTokenSupply)
        .toNumber()
    : 1

  const poolCreatedTimestamp =
    pool.createdTimestamp > upgradeTimestamp
      ? (pool.createdTimestamp as number)
      : poolsWithoutActivityTimestamp.includes(pool.id)
      ? upgradeTimestamp
      : poolActivityTimestamp

  const valueOfLpTokensThirtyDaysAgo =
    pool.valueThirtyDaysAgo[0] &&
    pool.valueThirtyDaysAgo[0].lpTokenSupply !== "0"
      ? new BigNumber(pool.valueThirtyDaysAgo[0].poolValue)
          .div(pool.valueThirtyDaysAgo[0].lpTokenSupply)
          .toNumber()
      : initialLpTokenValue

  const currentLpTokenValue =
    onchainPoolValue.div(pool.currentValue[0]?.lpTokenSupply).toNumber() ||
    initialLpTokenValue

  const poolType =
    pool.collateralToken.id === pool.underlyingToken.id ? "Call" : "Put"

  const notionalOptionsWritten = poolOptionBalances
    .filter((b) => b.tokenType === "wToken")
    .reduce(
      (acc, b) =>
        acc.plus(
          b.amount.multipliedBy(poolType === "Call" ? 1 : b.strikePrice),
        ),
      new BigNumber(0),
    )

  const poolValue = poolCollateralTokenBalance.plus(notionalOptionsWritten)

  const utilization = poolValue.gt(0)
    ? notionalOptionsWritten
        .div(poolValue)
        .multipliedBy(100)
        .decimalPlaces(2)
        .toNumber()
    : 0

  const isActive = !overrideInactivePools.includes(pool.id)

  const filteredEvents = (pool.recentTokenEvents ?? []).filter(
    (e) => e?.eventType[0] === "BTokenBought",
  ) as GetLiquidityPools_amms_recentTokenEvents_BTokenBought[]

  const projectedYieldData = filteredEvents.reduce(
    (sum, e) => {
      sum.collateralRequired += new BigNumber(e?.tokenAmount.toString())
        .shiftedBy(-pool.underlyingToken.decimals)
        .multipliedBy(
          poolType === "Call"
            ? 1
            : new BigNumber(e.series.strikePrice.toString()).shiftedBy(
                -STRIKE_PRICE_DECIMALS,
              ),
        )
        .toNumber()

      const weeksToExpiry = dayjs
        .unix(e.series.expirationDate)
        .diff(dayjs.unix(e.timestamp), "week", true)

      sum.premiumsReceived += new BigNumber(e?.collateralAmount.toString())
        .div(weeksToExpiry < 1 ? 1 : weeksToExpiry)
        .shiftedBy(-pool.collateralToken.decimals)
        .toNumber()

      return sum
    },
    {
      premiumsReceived: 0,
      collateralRequired: 0,
    },
  )

  const weeklyReturns =
    projectedYieldData.collateralRequired > 0
      ? new BigNumber(projectedYieldData.premiumsReceived).dividedBy(
          projectedYieldData.collateralRequired,
        )
      : new BigNumber(0)

  const weeklyCompoundingAPY = weeklyReturns
    .plus(1)
    .pow(52)
    .minus(1)
    .multipliedBy(100)
    .decimalPlaces(2)
    .toNumber()

  return {
    address: pool.id,
    ammDataProviderAddress,
    createdAt: dayjs.unix(poolCreatedTimestamp).toDate(),
    lpTokenAddress: pool.lpToken.id,
    lpTokenDecimals: pool.lpToken.decimals,
    lpTokenSupply,
    collateralTokenAddress: pool.collateralToken.id,
    collateralTokenSymbol: pool.collateralToken.symbol,
    collateralTokenDecimals: pool.collateralToken.decimals,
    collateralTokenName: pool.collateralToken.name,
    priceTokenAddress: pool.priceToken.id,
    priceTokenSymbol: pool.priceToken.symbol,
    priceTokenDecimals: pool.priceToken.decimals,
    priceTokenName: pool.priceToken.name,
    underlyingTokenAddress: pool.underlyingToken.id,
    underlyingTokenSymbol: pool.underlyingToken.symbol,
    underlyingTokenDecimals: pool.underlyingToken.decimals,
    underlyingTokenName: pool.underlyingToken.name,
    userBalance: lpTokenBalance,
    poolType,
    name,
    exchangeRate,
    poolValueLocked: onchainPoolValue
      .shiftedBy(-pool.collateralToken.decimals)
      .toNumber(),
    yieldSinceInception: new BigNumber(
      currentLpTokenValue / initialLpTokenValue - 1,
    )
      .div(dayjs().diff(dayjs.unix(poolCreatedTimestamp), "days"))
      .plus(1)
      .exponentiatedBy(365)
      .minus(1)
      .multipliedBy(100)
      .decimalPlaces(2)
      .toNumber(),
    yieldLastMonth: new BigNumber(
      currentLpTokenValue / valueOfLpTokensThirtyDaysAgo - 1,
    )
      .div(30)
      .plus(1)
      .exponentiatedBy(365)
      .minus(1)
      .multipliedBy(100)
      .decimalPlaces(2)
      .toNumber(),
    currentLpTokenValue,
    utilization,
    poolOptionBalances,
    poolCollateralTokenBalance,
    isActive,
    projectedYield: weeklyCompoundingAPY,
  }
}

const fetchPoolsOnChainData = async (
  readProvider: providers.Provider | undefined,
  poolsData: GetLiquidityPools,
  addresses: Addresses,
  userAddress: string | undefined,
  formatShortLocaleDate: (date: Date) => string,
) => {
  const result = await Promise.all(
    (poolsData?.amms ?? []).map(async (pool) => {
      const poolOnchainData = await fetchPoolOnchainData(
        readProvider,
        pool,
        addresses,
        userAddress,
        formatShortLocaleDate,
      )

      return poolOnchainData
    }),
  )

  return result
}

export const usePools = () => {
  const { address: userAddress, provider } = useWeb3()

  const { readProvider } = useSirenMarketsContext()

  const sirenGraphClient = useApolloClient()

  const queryClient = useQueryClient()

  const addresses = useAddresses()

  const { formatShortLocaleDate } = useLanguageContext()

  const { data: poolsData, isLoading: poolsDataLoading } = useQuery(
    [QueryKey.Pools],
    () =>
      sirenGraphClient
        .query<GetLiquidityPools, GetLiquidityPoolsVariables>({
          query: liquidityPoolsQuery,
          variables: {
            last30Days,
            upgradeTimestamp,
            recentEventsTimestamp,
          },
          fetchPolicy: "network-only",
        })
        .then((data) => ({ ...data.data, cacheKey: Math.random() })),
    {
      enabled: Boolean(readProvider),
      refetchInterval: 12000000,
    },
  )

  const poolsOnchainQueryEnabled = Boolean(
    readProvider &&
      poolsData &&
      addresses.ammDataProviderAddress &&
      addresses.priceOracleAddress &&
      addresses.erc1155ControllerAddress,
  )

  const { data: poolsOnchainData, isLoading: poolsOnchainDataLoading } =
    useQuery(
      [QueryKey.PoolsOnchainData, poolsData?.cacheKey],
      () =>
        fetchPoolsOnChainData(
          readProvider,
          poolsData!,
          addresses,
          userAddress,
          formatShortLocaleDate,
        ),
      {
        enabled: poolsOnchainQueryEnabled,
      },
    )

  const pools = useMemo(
    () =>
      (poolsData?.amms ?? []).map((pool, index) => {
        const poolOnchainData = poolsOnchainData
          ? poolsOnchainData[index]
          : DEFAULT_POOL_ONCHAIN_DATA

        return processPool(
          pool,
          poolOnchainData,
          addresses.ammDataProviderAddress,
        )
      }),
    [poolsData, poolsOnchainData, addresses.ammDataProviderAddress],
  )

  const poolsFetched = Boolean(poolsData) && !poolsDataLoading
  const poolsOnchainFetched =
    poolsOnchainQueryEnabled &&
    Boolean(poolsOnchainData) &&
    !poolsOnchainDataLoading

  const quoteProvideCapital = useCallback(
    async (collateralAmount: BigNumber, ammAddress: string) => {
      if (!provider) return

      const pool = pools.find((m) => m.address === ammAddress)

      if (!pool || !pool.ammDataProviderAddress) return

      const ammDataProviderContract = AmmDataProvider__factory.connect(
        pool.ammDataProviderAddress,
        provider,
      )
      const lpTokenContract = SimpleToken__factory.connect(
        pool.lpTokenAddress,
        provider,
      )
      const poolValue = await ammDataProviderContract.getTotalPoolValueView(
        pool.address,
        true,
      )

      const lpTokenSupply = await lpTokenContract.totalSupply()

      if (poolValue.eq(0) || lpTokenSupply.eq(0)) {
        return collateralAmount.toNumber()
      }

      const collateralAmountBN = new BigNumber(collateralAmount).shiftedBy(
        pool.collateralTokenDecimals,
      )

      const lpTokensEstimate = new BigNumber(lpTokenSupply.toString())
        .multipliedBy(collateralAmountBN)
        .dividedBy(poolValue.toString())

      return lpTokensEstimate
        .shiftedBy(-pool.collateralTokenDecimals)
        .decimalPlaces(4)
        .toNumber()
    },
    [pools, provider],
  )

  const provideCapital = useCallback(
    async (
      collateralAmount: BigNumber,
      expectedLpTokens: number,
      ammAddress: string,
    ) => {
      if (!provider || !userAddress) return
      const pool = pools.filter((m) => m.address === ammAddress)[0]
      if (!pool) return
      const signer = provider.getSigner()

      const collateralAmountBN = collateralAmount.shiftedBy(
        pool.collateralTokenDecimals,
      )

      const collateralTokenContract = SimpleToken__factory.connect(
        pool.collateralTokenAddress,
        signer,
      )

      const minLpTokens = new BigNumber(expectedLpTokens)
        .multipliedBy(1 - SLIPPAGE)
        .shiftedBy(pool.collateralTokenDecimals)
        .decimalPlaces(0)

      try {
        const currentAllowance = new BigNumber(
          (
            await collateralTokenContract.allowance(userAddress, pool.address)
          ).toString(),
        )

        if (collateralAmountBN.gt(currentAllowance)) {
          await (
            await collateralTokenContract.approve(
              pool.address,
              constants.MaxUint256,
            )
          ).wait()
        }

        const ammContract = MinterAmm__factory.connect(pool.address, signer)

        const estimatedGas = await ammContract.estimateGas.provideCapital(
          collateralAmountBN.toString(10),
          minLpTokens.toString(10),
        )

        await (
          await ammContract.provideCapital(
            collateralAmountBN.toString(10),
            minLpTokens.toString(10),
            {
              gasLimit: estimatedGas.mul(12).div(10),
            },
          )
        ).wait(1)

        setTimeout(() => {
          queryClient.invalidateQueries([QueryKey.Pools])
          queryClient.invalidateQueries([QueryKey.LockedLiquidity])
        }, REFETCH_TIMEOUT)
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
        throw error
      }
    },
    [pools, provider, userAddress, queryClient],
  )

  // Returns the expected sales value of any W and B tokens that are held by the AMM on the LP's behalf
  const getTokenSaleValue = useCallback(
    async (lpTokenAmount: BigNumber, ammAddress: string) => {
      if (!provider) return new BigNumber(0)

      const pool = pools.filter((m) => m.address === ammAddress)[0]

      if (!pool || !pool.ammDataProviderAddress) return new BigNumber(0)

      const ammDataProviderContract = AmmDataProvider__factory.connect(
        pool.ammDataProviderAddress,
        provider,
      )

      const tokenSaleValue =
        await ammDataProviderContract.getOptionTokensSaleValueView(
          pool.address,
          lpTokenAmount
            .shiftedBy(pool.lpTokenDecimals)
            .decimalPlaces(0)
            .toString(10),
        )

      return new BigNumber(tokenSaleValue.toString()).shiftedBy(
        -pool.collateralTokenDecimals,
      )
    },
    [pools, provider],
  )

  const withdrawCapital = useCallback(
    async (
      lpTokenAmount: BigNumber,
      expectedCollateral: BigNumber,
      sellTokens: boolean,
      ammAddress: string,
    ) => {
      if (!provider) return
      const pool = pools.filter((m) => m.address === ammAddress)[0]
      if (!pool) return
      const signer = provider.getSigner()

      const lpTokenAmountScaled = lpTokenAmount.shiftedBy(pool.lpTokenDecimals)

      const minCollateral = expectedCollateral
        .multipliedBy(1 - SLIPPAGE)
        .shiftedBy(pool.collateralTokenDecimals)
        .decimalPlaces(0)

      const ammContract = MinterAmm__factory.connect(pool.address, signer)

      try {
        const estimatedGas = await ammContract.estimateGas.withdrawCapital(
          lpTokenAmountScaled.toString(10),
          sellTokens,
          minCollateral.toString(10),
        )

        await (
          await ammContract.withdrawCapital(
            lpTokenAmountScaled.toString(10),
            sellTokens,
            minCollateral.toString(10),
            {
              gasLimit: estimatedGas.mul(12).div(10),
            },
          )
        ).wait(1)

        setTimeout(() => {
          queryClient.invalidateQueries([QueryKey.Pools])
          queryClient.invalidateQueries([QueryKey.PositionsData])
        }, REFETCH_TIMEOUT)
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
        throw error
      }
    },
    [pools, provider, queryClient],
  )

  const withdrawLockedCollateral = useCallback(
    async (expirationDates: string[], ammAddress: string) => {
      if (!provider) return
      const pool = pools.filter((m) => m.address === ammAddress)[0]
      if (!pool) return
      const signer = provider.getSigner()

      const ammContract = MinterAmm__factory.connect(pool.address, signer)

      try {
        const estimatedGas =
          await ammContract.estimateGas.withdrawLockedCollateral(
            expirationDates,
          )

        await (
          await ammContract.withdrawLockedCollateral(expirationDates, {
            gasLimit: estimatedGas.mul(12).div(10),
          })
        ).wait(1)

        setTimeout(() => {
          queryClient.invalidateQueries([QueryKey.LockedLiquidity])
        }, REFETCH_TIMEOUT)
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
        throw error
      }
    },
    [pools, provider, queryClient],
  )

  return {
    pools,
    poolsFetched,
    poolsOnchainFetched,
    quoteProvideCapital,
    provideCapital,
    getTokenSaleValue,
    withdrawCapital,
    withdrawLockedCollateral,
  }
}
