import { Button } from "@mui/material"
import { BigNumber } from "bignumber.js"
import { debounce } from "lodash"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useLanguageContext } from "../../../Contexts/LanguageContext"
import { Position } from "../../../Contexts/SirenMarketsContext"
import { numericRegex } from "../../../helpers/numericRegex"

import { usePositions, useSeries } from "../../../Contexts/SirenMarketsContext"
import {
  CheckNetworkButton,
  Details,
  EqualsSign,
  NumericInput,
  Side,
  TxFailure,
  TxInProgress,
  TxSuccess,
  Typography,
  VStack,
} from "../../common"

const SellPositionDialog: React.FC<{
  position: Position
  clearSelection(): void
}> = ({ position, clearSelection }) => {
  const { formatShortLocaleDate, formatMoney } = useLanguageContext()

  const inputRef = useRef<HTMLInputElement | null>(null)
  const [contractsToSell, setContractsToSell] = useState(
    BigNumber.min(position.bTokenBalance, 1).toString(),
  )
  const [outputExpected, setOutputExpected] = useState(new BigNumber(0))
  const { quoteSellOption } = useSeries()
  const { sellOption } = usePositions()
  const [txStatus, setTxStatus] = useState<
    "init" | "inProgress" | "success" | "error"
  >("init")
  const [isQuoting, setIsQuoting] = useState(false)

  const minTradeSize = new BigNumber(1000)
    .shiftedBy(-position.collateralTokenDecimals)
    .toNumber()

  const getQuote = useMemo(
    () =>
      debounce(
        async (quoteAmount: BigNumber, position: Position) => {
          setIsQuoting(true)
          const result = await quoteSellOption(quoteAmount, position.seriesId)

          if (result) {
            setOutputExpected(result)
          }

          setIsQuoting(false)
          inputRef.current && inputRef.current.focus()
        },
        500,
        { trailing: true },
      ),
    [quoteSellOption],
  )

  useEffect(() => {
    if (
      contractsToSell &&
      contractsToSell !== "" &&
      numericRegex.test(contractsToSell)
    ) {
      getQuote(new BigNumber(contractsToSell), position)
    }
  }, [contractsToSell, position, getQuote])

  const handleSellOption = async () => {
    setTxStatus("inProgress")
    try {
      await sellOption(
        position.seriesId,
        new BigNumber(contractsToSell),
        outputExpected,
        position.collateralTokenAddress,
      )
      setTxStatus("success")
    } catch (error) {
      console.log(error)
      setTxStatus("error")
    }
  }

  if (txStatus === "inProgress") {
    return <TxInProgress message="Selling your option contracts ..." />
  } else if (txStatus === "success") {
    return (
      <TxSuccess
        message="Your options have been sold."
        buttonMessage="Close"
        onConfirm={clearSelection}
      />
    )
  } else if (txStatus === "error") {
    return <TxFailure clearSelection={clearSelection} />
  }

  return (
    <>
      <Side.Content>
        <Details>
          <Details.Row label="Pair" value={position.pair} />
          <Details.Row
            label="Current Price"
            value={formatMoney(
              new BigNumber(
                position.type === "Call"
                  ? position.paymentPerUnderlying
                  : 1 / position.paymentPerUnderlying,
              )
                .decimalPlaces(2)
                .toNumber(),
            )}
          />
          <Details.Row
            label="Strike Price"
            value={formatMoney(position.strike)}
          />
          <Details.Row
            label="Contracts"
            value={position.bTokenBalance.toString()}
          />
          <Details.Row
            label="Expiration"
            value={formatShortLocaleDate(position.expiration)}
          />
        </Details>

        <VStack space={1}>
          <NumericInput
            type="number"
            min={0}
            max={position.bTokenBalance}
            value={contractsToSell}
            onChange={setContractsToSell}
            label="# of Contracts / bTokens"
            ref={inputRef}
          />

          <EqualsSign />

          <NumericInput
            type="number"
            value={outputExpected.decimalPlaces(4).toString(10)}
            label={`${position.collateralTokenSymbol.toUpperCase()} Expected`}
            disabled
            readOnly
          />
        </VStack>

        <Typography size="extra-small" color="textSecondary">
          Please confirm the trade details are correct before approving the
          transaction.
        </Typography>
      </Side.Content>

      <Side.Action>
        <CheckNetworkButton>
          <Button
            variant="primary"
            onClick={handleSellOption}
            disabled={
              contractsToSell === "" ||
              contractsToSell === "0" ||
              !numericRegex.test(contractsToSell) ||
              new BigNumber(contractsToSell).gt(position.bTokenBalance) ||
              new BigNumber(contractsToSell).lt(minTradeSize) ||
              isQuoting
            }
            fullWidth
          >
            Sell
          </Button>
        </CheckNetworkButton>
      </Side.Action>
    </>
  )
}

export default SellPositionDialog
