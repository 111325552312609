import { useWeb3 } from "@chainsafe/web3-context"
import * as Sentry from "@sentry/react"
import { ethers } from "ethers"
import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useSirenConfig } from "../SirenConfigContext"
import { SirenMarketsContextType } from "./types"

type SirenMarketsProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

const SirenMarketsContext = React.createContext<
  SirenMarketsContextType | undefined
>(undefined)

const SirenMarketsProvider = ({ children }: SirenMarketsProviderProps) => {
  const { address, provider, onboard, wallet, isReady, checkIsReady } =
    useWeb3()

  const { isWalletNetworkValid, activeConfig } = useSirenConfig()

  const [isConnecting, setIsConnecting] = useState(false)

  // Automatic login handler
  useEffect(() => {
    const autoLogin = !!localStorage.getItem("onboard.selectedWallet")

    if (!isReady && wallet && autoLogin) {
      checkIsReady()
    }
    // eslint-disable-next-line
  }, [isReady, wallet])

  const readProvider: ethers.providers.Provider | undefined = useMemo(() => {
    try {
      if (isReady && isWalletNetworkValid && provider) {
        return provider
      } else {
        return new ethers.providers.JsonRpcBatchProvider(activeConfig.rpcUrl)
      }
    } catch (error) {
      console.log(`Can't initialize provider`, error)

      return undefined
    }
  }, [activeConfig.rpcUrl, isWalletNetworkValid, isReady, provider])

  const handleSelectWalletConnect = useCallback(async () => {
    if (onboard && !isReady) {
      setIsConnecting(true)

      let walletReady = !!wallet

      if (!walletReady) {
        walletReady = await onboard.walletSelect()
      }

      walletReady && (await checkIsReady())

      setIsConnecting(false)
    }
  }, [onboard, setIsConnecting, isReady, checkIsReady, wallet])

  useEffect(() => {
    Sentry.setUser({ id: address })
  }, [address])

  const contextValue: SirenMarketsContextType = useMemo(
    () => ({
      handleSelectWalletConnect,
      isConnecting,
      readProvider,
      provider,
    }),
    [handleSelectWalletConnect, isConnecting, readProvider, provider],
  )

  return (
    <SirenMarketsContext.Provider value={contextValue}>
      {children}
    </SirenMarketsContext.Provider>
  )
}

function useSirenMarketsContext() {
  const context = React.useContext(SirenMarketsContext)

  if (context === undefined) {
    throw new Error(
      "useSirenMarketsContext must be used within a SirenMarketsProvider",
    )
  }

  return context
}

export { SirenMarketsProvider, useSirenMarketsContext }
