import React from "react"

interface ICashOutSvg {
  className?: string
}

const CashOutSvg = ({ className }: ICashOutSvg) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.17805 9.21711C3.41545 8.97971 3.80035 8.97971 4.03775 9.21711L6.35296 11.5323L8.66817 9.21711C8.90557 8.97971 9.29048 8.97971 9.52788 9.21711C9.76528 9.45451 9.76528 9.83942 9.52788 10.0768L6.78282 12.8219C6.54541 13.0593 6.16051 13.0593 5.92311 12.8219L3.17805 10.0768C2.94065 9.83942 2.94065 9.45451 3.17805 9.21711Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.56619 3.82126C7.09204 3.29542 7.80523 3 8.54889 3H12.392C12.7277 3 12.9999 3.27217 12.9999 3.6079C12.9999 3.94364 12.7277 4.21581 12.392 4.21581H8.54889C8.12768 4.21581 7.72373 4.38313 7.4259 4.68096C7.12806 4.9788 6.96074 5.38275 6.96074 5.80395V12.3921C6.96074 12.7278 6.68857 13 6.35284 13C6.0171 13 5.74493 12.7278 5.74493 12.3921V5.80395C5.74493 5.0603 6.04035 4.3471 6.56619 3.82126Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CashOutSvg
