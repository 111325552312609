import React, { VFC } from "react"
import { alpha, Box, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"

import { Typography } from "./common"

const useStyles = makeStyles<Theme>(({ breakpoints, spacing, palette }) => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: alpha(palette.colors.red, 0.1),
    color: palette.colors.red,

    height: 50,
    paddingRight: 50,
    paddingLeft: 50,

    [breakpoints.down("sm")]: {
      height: 60,
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
}))

export const RestrictionBanner: VFC = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Typography size="extra-small" weight="medium" align="center">
        Trading restricted in your current location. Users located in the US and
        UK are not allowed to trade.
      </Typography>
    </Box>
  )
}
