import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { debounce } from "lodash"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { BigNumber } from "bignumber.js"
import clsx from "clsx"

import { LiquidityPool } from "../../../../Contexts/SirenMarketsContext"
import { numericRegex } from "../../../../helpers/numericRegex"

import {
  HStack,
  InfoPopover,
  Side,
  VStack,
  Typography,
  EqualsSign,
  NumericInput,
  TxInProgress,
  TxSuccess,
  TxFailure,
} from "../../../common"
import { usePools, useSeries } from "../../../../Contexts/SirenMarketsContext"

const useStyles = makeStyles<Theme>(({ spacing, palette, breakpoints }) => ({
  radioItem: {
    borderRadius: 10,
    background: palette.colors.background2,
    padding: "16px 20px",
    border: "2px solid transparent",
    cursor: "pointer",

    "&:hover": {
      border: `2px solid ${palette.colors.primary}`,
    },
  },
  radioItemSelected: {
    border: `2px solid ${palette.colors.primary}`,
  },
}))

enum WithdrawType {
  Instant = "INSTANT",
  Partial = "PARTIAL",
}

const WithdrawLiquidityDialog: React.FC<{
  pool: LiquidityPool
  clearSelection(): void
}> = ({ pool, clearSelection }) => {
  const classes = useStyles()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { withdrawCapital, getTokenSaleValue } = usePools()
  const [txStatus, setTxStatus] = useState<
    "init" | "inProgress" | "success" | "error"
  >("init")

  const { series } = useSeries()
  const [tokenSaleValue, setTokenSaleValue] = useState(new BigNumber(0))
  const [isQuoting, setIsQuoting] = useState(false)
  const [withdrawType, setWithdrawType] = useState(WithdrawType.Instant)

  const userBalance = useMemo(
    () => pool.userBalance || new BigNumber(0),
    [pool],
  )
  const [withdrawAmount, setWithdrawAmount] = useState(userBalance.toString())
  const poolWTokensValue = useMemo(() => {
    return pool.poolType === "Call"
      ? pool.poolOptionBalances.reduce((total, balance) => {
          const wTokenValue =
            1 -
            (series.find((s) => s.wTokenIndex === balance.tokenIndex)
              ?.premium || 0)
          return total.plus(
            new BigNumber(wTokenValue).multipliedBy(balance.amount),
          )
        }, new BigNumber(0))
      : pool.poolOptionBalances.reduce((total, balance) => {
          const balSeries = series.find(
            (s) => s.wTokenIndex === balance.tokenIndex,
          )

          const wTokenValue = balSeries
            ? (1 - balSeries.premium) * balSeries.strike
            : 0 // This should never happen and is just kept here to keep TS happy

          return total.plus(
            new BigNumber(wTokenValue).multipliedBy(balance.amount),
          )
        }, new BigNumber(0))
  }, [pool.poolOptionBalances, pool.poolType, series])

  const getQuote = useMemo(
    () =>
      debounce(
        (quoteAmount: BigNumber) => {
          setIsQuoting(true)
          getTokenSaleValue(quoteAmount, pool.address)
            .then(setTokenSaleValue)
            .catch(console.error)
            .finally(() => {
              setIsQuoting(false)
              inputRef.current && inputRef.current.focus()
            })
        },
        500,
        { trailing: true },
      ),
    [pool.address, getTokenSaleValue],
  )

  useEffect(() => {
    setWithdrawAmount(pool.userBalance?.toString() || "0")
  }, [pool])

  useEffect(() => {
    withdrawAmount &&
      withdrawAmount !== "" &&
      withdrawAmount !== "0" &&
      numericRegex.test(withdrawAmount) &&
      getQuote(new BigNumber(withdrawAmount))
  }, [withdrawAmount, pool, getQuote])

  const handleWithdrawCapital = async () => {
    setTxStatus("inProgress")
    const expectedCollateral = pool.poolCollateralTokenBalance
      .dividedBy(pool.lpTokenSupply)
      .multipliedBy(withdrawAmount)
      .plus(withdrawType === WithdrawType.Instant ? tokenSaleValue : 0)

    try {
      await withdrawCapital(
        new BigNumber(withdrawAmount),
        expectedCollateral,
        withdrawType === WithdrawType.Instant,
        pool.address,
      )
      setTxStatus("success")
    } catch (error) {
      console.log(error)
      setTxStatus("error")
    }
  }

  useEffect(() => {
    setWithdrawAmount(pool.userBalance?.toString() || "0")
    // eslint-disable-next-line
  }, [pool])

  if (txStatus === "inProgress") {
    return (
      <TxInProgress message="Withdrawing your liquidity from the pool..." />
    )
  } else if (txStatus === "success") {
    return (
      <TxSuccess
        message="Your liquidity pool capital (LP Tokens) have been withdrawn."
        buttonMessage="Close"
        onConfirm={clearSelection}
      />
    )
  } else if (txStatus === "error") {
    return <TxFailure clearSelection={clearSelection} />
  }

  return (
    <>
      <Side.Content>
        <NumericInput
          min={0}
          max={userBalance}
          value={withdrawAmount}
          label="LP Tokens"
          onChange={setWithdrawAmount}
          ref={inputRef}
          errorMessage={
            new BigNumber(withdrawAmount).gt(userBalance)
              ? "Not enough tokens"
              : undefined
          }
        />

        <EqualsSign />

        <RadioGroup
          name="type"
          value={withdrawType}
          onChange={(event) =>
            setWithdrawType(event.target.value as WithdrawType)
          }
        >
          <VStack>
            <label
              className={clsx(classes.radioItem, {
                [classes.radioItemSelected]:
                  withdrawType === WithdrawType.Instant,
              })}
            >
              <VStack space={1}>
                <FormControlLabel
                  control={<Radio />}
                  value={WithdrawType.Instant}
                  label={
                    <InfoPopover text="Your entire collateral will be withdrawn; The locked portion will be automatically converted to the underlying asset with some loss due to the price impact.">
                      <Typography size="extra-small" weight="bold">
                        Instant cash-out
                      </Typography>
                    </InfoPopover>
                  }
                />
                <HStack justifyContent="space-between">
                  <Typography size="small" color="textSecondary">
                    Total amount
                  </Typography>
                  <Typography size="small" weight="bold">
                    {pool.poolCollateralTokenBalance
                      .dividedBy(pool.lpTokenSupply)
                      .multipliedBy(new BigNumber(withdrawAmount))
                      .plus(tokenSaleValue)
                      .decimalPlaces(4)
                      .toString()}{" "}
                    {pool.collateralTokenSymbol}
                  </Typography>
                </HStack>
              </VStack>
            </label>

            <label
              className={clsx(classes.radioItem, {
                [classes.radioItemSelected]:
                  withdrawType === WithdrawType.Partial,
              })}
            >
              <VStack space={1}>
                <FormControlLabel
                  control={<Radio />}
                  value={WithdrawType.Partial}
                  label={
                    <InfoPopover text="Only part of your collateral will be withdrawn; the locked portion will remain in the protocol and can be cashed-out later in the Portfolio module.">
                      <Typography size="extra-small" weight="bold">
                        Partial withdrawal
                      </Typography>
                    </InfoPopover>
                  }
                />
                <HStack justifyContent="space-between">
                  <Typography size="extra-small" color="textSecondary">
                    You receive now
                  </Typography>
                  <Typography size="extra-small" weight="bold">
                    {pool.poolCollateralTokenBalance
                      .dividedBy(pool.lpTokenSupply)
                      .multipliedBy(withdrawAmount)
                      .decimalPlaces(4)
                      .toString()}{" "}
                    {pool.collateralTokenSymbol}
                  </Typography>
                </HStack>
                <HStack justifyContent="space-between">
                  <Typography size="extra-small" color="textSecondary">
                    Receive later (est)
                  </Typography>
                  <Typography size="extra-small" weight="bold">
                    {poolWTokensValue
                      .dividedBy(pool.lpTokenSupply)
                      .multipliedBy(withdrawAmount)
                      .decimalPlaces(4)
                      .toString()}{" "}
                    {pool.collateralTokenSymbol}
                  </Typography>
                </HStack>
                <HStack justifyContent="space-between">
                  <Typography size="small" color="textSecondary" align="left">
                    Total
                  </Typography>
                  <Typography size="small" weight="bold" align="right">
                    {pool.poolCollateralTokenBalance
                      .plus(poolWTokensValue)
                      .dividedBy(pool.lpTokenSupply)
                      .multipliedBy(withdrawAmount)
                      .decimalPlaces(4)
                      .toString()}{" "}
                    {pool.collateralTokenSymbol}
                  </Typography>
                </HStack>
              </VStack>
            </label>
          </VStack>
        </RadioGroup>
      </Side.Content>
      <Side.Action>
        <Button
          variant="primary"
          onClick={handleWithdrawCapital}
          fullWidth
          disabled={
            withdrawAmount === "" ||
            withdrawAmount === "0" ||
            !numericRegex.test(withdrawAmount) ||
            new BigNumber(withdrawAmount).gt(userBalance) ||
            isQuoting
          }
        >
          Withdraw
        </Button>
      </Side.Action>
    </>
  )
}

export default WithdrawLiquidityDialog
