import { useTheme, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React from "react"
import { CircleLoader } from "react-spinners"

import { Side, Typography } from "./"

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  loadingWrapper: {
    marginTop: spacing(10),
    marginBottom: spacing(10),
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
  },
}))

export const TxInProgress = ({ message }: { message: string }) => {
  const classes = useStyles()

  const { palette } = useTheme()

  return (
    <Side.Content>
      <Typography variant="h3">{message}</Typography>
      <div className={classes.loadingWrapper}>
        <CircleLoader loading size={60} color={palette.colors.primary} />
      </div>
    </Side.Content>
  )
}
