import { SvgIcon, Switch, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React from "react"
import { usePalleteType } from "../Hooks/usePalleteType"
import { PaletteType } from "../theme"
import { HStack } from "./common"
import MoonSvg from "./icons/MoonSvg"
import SunSvg from "./icons/SunSvg"

const useStyles = makeStyles<Theme>(({ palette }) => ({
  themeSwitchContainer: {
    "&:hover .MuiSwitch-track": {
      backgroundColor: palette.themeColor(
        palette.colors.background3,
        palette.colors.primaryDark,
      ),
    },
  },
  themeSwitch: {
    marginLeft: "-25px !important",
    marginRight: "-25px !important",
    padding: 6,
  },
  themeSwitchIcon: {
    zIndex: 1,
    cursor: "pointer",
    display: "flex",

    "& svg": {
      color: palette.colors.toggle,
    },
  },
}))

export const ThemeSwitcher = () => {
  const classes = useStyles()

  const { paletteType, togglePalleteType } = usePalleteType()

  return (
    <HStack
      className={classes.themeSwitchContainer}
      space={0}
      alignItems="center"
      paddingX={2}
    >
      <label htmlFor="themeSwitch" className={classes.themeSwitchIcon}>
        <SvgIcon component={SunSvg} fontSize="small" />
      </label>

      <Switch
        checked={paletteType !== PaletteType.Dark}
        onChange={togglePalleteType}
        className={classes.themeSwitch}
        id="themeSwitch"
      />

      <label htmlFor="themeSwitch" className={classes.themeSwitchIcon}>
        <SvgIcon component={MoonSvg} fontSize="small" />
      </label>
    </HStack>
  )
}
