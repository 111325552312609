import { Paper, Theme, Skeleton, useTheme, alpha } from "@mui/material"
import { makeStyles } from "@mui/styles"
import useResizeObserver from "@react-hook/resize-observer"
import clsx from "clsx"
import {
  AreaStyleOptions,
  ChartOptions,
  createChart,
  DeepPartial,
  IChartApi,
  ISeriesApi,
  LastPriceAnimationMode,
  LineData,
  MouseEventParams,
  SeriesOptionsCommon,
  WhitespaceData,
} from "lightweight-charts"
import React, { useEffect, useRef, useState, useMemo } from "react"
import { throttle, merge } from "lodash"

import { ChartPeriod } from "../../constants"
import { useLanguageContext } from "../../Contexts/LanguageContext"
import { VStack } from "./Stack"
import { Typography } from "./Typography"

type ChartBackground = "background0" | "background2"

const getChartOptions = (
  theme: Theme,
  customOptions: DeepPartial<ChartOptions>,
  background: ChartBackground,
): DeepPartial<ChartOptions> => {
  const gridColor =
    background === "background0"
      ? theme.palette.colors.stroke
      : alpha(theme.palette.colors.background1, 0.8)

  return merge(
    {
      layout: {
        backgroundColor: theme.palette.colors[background],
        textColor: theme.palette.text.secondary,
        fontFamily: "DM Sans",
      },
      grid: {
        vertLines: {
          color: gridColor,
        },
        horzLines: {
          color: gridColor,
        },
      },
      timeScale: {
        fixLeftEdge: true,
        fixRightEdge: true,
        visible: false,
        borderColor: gridColor,
      },
      rightPriceScale: {
        borderColor: gridColor,
      },
      handleScale: false,
    },
    customOptions,
  )
}

const getAreaSeriesOptions = (
  theme: Theme,
  customOptions: DeepPartial<AreaStyleOptions & SeriesOptionsCommon>,
  empty: boolean,
): DeepPartial<AreaStyleOptions & SeriesOptionsCommon> =>
  merge(
    {
      topColor: alpha(theme.palette.colors.green, empty ? 0.1 : 0.5),
      bottomColor: alpha(theme.palette.colors.green, 0),
      lineColor: alpha(theme.palette.colors.green, empty ? 0 : 1),
      lineWidth: 2,
      lastPriceAnimation: empty
        ? LastPriceAnimationMode.Disabled
        : LastPriceAnimationMode.Continuous,

      lastValueVisible: !empty,
    },
    customOptions,
  )

const TOOLTIP_WIDTH = 172
const TOOLTIP_HEIGHT = 64
const TOOLTIP_MARGIN = 10

const PERIODS_WITH_TIME = [
  ChartPeriod["24H"],
  ChartPeriod["7d"],
  ChartPeriod["30d"],
]

const useTooltipStyles = makeStyles<Theme>(({ spacing, zIndex, palette }) => ({
  root: {
    position: "absolute",
    zIndex: zIndex.tooltip,
    pointerEvents: "none",
    userSelect: "none",
    backgroundColor: alpha(palette.colors.background2, 0.7),
    backdropFilter: "blur(8px)",
    border: `1px solid ${palette.colors.stroke}`,

    width: TOOLTIP_WIDTH,
    padding: spacing(1),
  },
}))

export enum ChartValueFormat {
  Percent = "PERCENT",
  Money = "MONEY",
}

type ChartTooltipProps = {
  top: number
  left: number
  time: number
  value: number
  period: ChartPeriod
  valueFormat: ChartValueFormat
}

const ChartTooltip: React.VFC<ChartTooltipProps> = ({
  top,
  left,
  time,
  value,
  period,
  valueFormat,
}) => {
  const classes = useTooltipStyles()

  const {
    formatMoney,
    formatPercents,
    formatLocaleDate,
    formatShortLocaleDate,
  } = useLanguageContext()

  return (
    <Paper
      style={{
        top,
        left,
      }}
      className={classes.root}
      elevation={0}
    >
      <VStack space={1}>
        <Typography size="extra-small" noWrap>
          {valueFormat === ChartValueFormat.Money
            ? `Price: ${formatMoney(value)}`
            : `Change: ${formatPercents(value)}`}
        </Typography>
        <Typography size="extra-small" noWrap>
          {`Date: ${
            PERIODS_WITH_TIME.includes(period)
              ? formatLocaleDate(new Date(time * 1000))
              : formatShortLocaleDate(new Date(time * 1000))
          }`}
        </Typography>
      </VStack>
    </Paper>
  )
}

const useChartSize = (target: React.RefObject<HTMLDivElement>) => {
  const [size, setSize] = useState<DOMRect>()

  React.useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect())
    }
  }, [target])

  useResizeObserver(target, (entry) => setSize(entry.contentRect))

  return size
}

type TooltipState = {
  top: number
  left: number
  visible: boolean
  value?: number
  time?: number
}

type ChartProps = {
  data: Array<{ time: number; value?: number }>
  period: ChartPeriod
  className?: string
  loading?: boolean
  options?: DeepPartial<ChartOptions>
  seriesOptions?: DeepPartial<AreaStyleOptions & SeriesOptionsCommon>
  valueFormat?: ChartValueFormat
  empty?: boolean
  background: ChartBackground
}

const useStyles = makeStyles<Theme, Pick<ChartProps, "loading" | "empty">>(
  ({ breakpoints, palette }) => ({
    root: {
      position: "relative",
      pointerEvents: ({ empty }) => (empty ? "none" : undefined),
    },
    container: {
      position: "absolute",
      visibility: ({ loading }) => (loading ? "hidden" : "visible"),
    },
    skeleton: {
      position: "absolute",
      width: "100%",
      height: "100%",
      borderRadius: 8,
      background: alpha(palette.colors.textSecondary, 0.1),

      "&:after": {
        opacity: 0.1,
      },

      [breakpoints.down("sm")]: {
        borderRadius: 0,
      },
    },
    emptyText: {
      position: "absolute",
      zIndex: 2,
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  }),
)

export const Chart: React.VFC<ChartProps> = ({
  data,
  period,
  loading,
  options = {},
  seriesOptions = {},
  valueFormat = ChartValueFormat.Money,
  empty = false,
  background,
  ...props
}) => {
  const classes = useStyles({ loading, empty })

  const theme = useTheme<Theme>()

  const chart = useRef<IChartApi>()
  const areaSeries = useRef<ISeriesApi<"Area">>()

  const handleCrosshairMove = useRef<(mouseEvent: MouseEventParams) => void>()

  const [tooltip, setTooltip] = useState<TooltipState>({
    top: 0,
    left: 0,
    visible: false,
  })

  const rootRef = useRef<HTMLDivElement>(null)
  const chartElRef = useRef<HTMLDivElement>(null)

  const size = useChartSize(rootRef)

  const chartOptions = useMemo(
    () => getChartOptions(theme, options, background),
    [theme, options, background],
  )

  useEffect(() => {
    if (chartElRef.current && !chart.current) {
      chart.current = createChart(chartElRef.current)

      areaSeries.current = chart.current.addAreaSeries()
    }
  }, [])

  const areaSeriesOptions = useMemo(
    () => getAreaSeriesOptions(theme, seriesOptions, empty),
    [theme, seriesOptions, empty],
  )

  useEffect(() => {
    if (chart.current) {
      chart.current.applyOptions(chartOptions)
    }
  }, [chartOptions])

  useEffect(() => {
    if (areaSeries.current) {
      areaSeries.current.applyOptions(areaSeriesOptions)
    }
  }, [areaSeriesOptions])

  useEffect(() => {
    if (chart.current && areaSeries.current) {
      areaSeries.current.setData(data as (LineData | WhitespaceData)[])

      chart.current.timeScale().fitContent()
    }
  }, [data])

  useEffect(() => {
    if (chart.current && areaSeries.current && size) {
      if (handleCrosshairMove.current) {
        chart.current.unsubscribeCrosshairMove(handleCrosshairMove.current)
      }

      handleCrosshairMove.current = throttle((mouseEvent: MouseEventParams) => {
        if (!size || !areaSeries.current) {
          return
        }

        if (
          mouseEvent.point === undefined ||
          !mouseEvent.time ||
          mouseEvent.point.x < 0 ||
          mouseEvent.point.x > size.width ||
          mouseEvent.point.y < 0 ||
          mouseEvent.point.y > size.height
        ) {
          setTooltip((state) => ({ ...state, visible: false }))
        } else {
          const value = mouseEvent.seriesPrices.get(areaSeries.current)

          if (value !== undefined) {
            const coordinate = areaSeries.current.priceToCoordinate(
              value as number,
            )

            let left = mouseEvent.point.x - 50

            if (coordinate === null) {
              return
            }

            left = Math.max(0, Math.min(size.width - TOOLTIP_WIDTH, left))

            const top =
              coordinate - TOOLTIP_HEIGHT - TOOLTIP_MARGIN > 0
                ? coordinate - TOOLTIP_HEIGHT - TOOLTIP_MARGIN
                : Math.max(
                    0,
                    Math.min(
                      size.height - TOOLTIP_HEIGHT - TOOLTIP_MARGIN,
                      coordinate + TOOLTIP_MARGIN,
                    ),
                  )

            setTooltip({
              top,
              left,
              visible: true,
              time: mouseEvent.time as number,
              value: value as number,
            })
          }
        }
      }, 50)

      chart.current.subscribeCrosshairMove(handleCrosshairMove.current)
    }
  }, [handleCrosshairMove, size, setTooltip])

  useEffect(() => {
    if (chart.current && size) {
      chart.current.resize(size.width, size.height)

      chart.current.timeScale().fitContent()
    }
  }, [size])

  return (
    <div ref={rootRef} className={clsx(props.className, classes.root)}>
      {loading && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          className={classes.skeleton}
        />
      )}
      <div ref={chartElRef} className={classes.container}>
        {empty && (
          <Typography
            className={classes.emptyText}
            variant="h2"
            color="colors.textTertiary"
          >
            Data unavailable
          </Typography>
        )}
        {!empty &&
          tooltip.visible &&
          tooltip.time &&
          tooltip.value !== undefined && (
            <ChartTooltip
              top={tooltip.top}
              left={tooltip.left}
              time={tooltip.time}
              value={tooltip.value}
              period={period}
              valueFormat={valueFormat}
            />
          )}
      </div>
    </div>
  )
}
