import { Skeleton, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React from "react"

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  root: {
    borderRadius: 20,
    minHeight: 260,
    height: "100%",

    [breakpoints.down("sm")]: {
      minHeight: 180,
    },
  },
}))

export const CardSkeleton = () => {
  const classes = useStyles()

  return (
    <Skeleton className={classes.root} variant="rectangular" animation="wave" />
  )
}
