import { useWeb3 } from "@chainsafe/web3-context"
import { Close } from "@mui/icons-material"
import {
  IconButton,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useState } from "react"
import { LiquidityPool } from "../../../../Contexts/SirenMarketsContext"
import { Side, SkeletonGrid, Typography } from "../../../common"
import { GetStartedSideContent } from "../../../GetStartedSideContent"
import ProvideLiquidityDialog from "./ProvideLiquidityDialog"
import WithdrawLiquidityDialog from "./WithdrawLiquidityDialog"

const useStyles = makeStyles<Theme>(({ spacing, breakpoints, palette }) => ({
  closeButton: {
    top: "10px",
    right: "10px",
    position: "absolute",
    borderRadius: 16,
    backgroundColor: palette.colors.background2,
    padding: 0,
  },
}))

export const EarnDetailsSide: React.FC<{
  open: boolean
  pool: LiquidityPool
  onClose: VoidFunction
}> = ({ pool, open, onClose }) => {
  const classes = useStyles()

  const { breakpoints } = useTheme()

  const desktop = useMediaQuery(breakpoints.up("md"))

  const [txType, setTxType] = useState<"provide" | "withdraw">("provide")

  const { wallet } = useWeb3()

  return (
    <Side open={open} onClose={onClose}>
      {wallet === undefined ? (
        <GetStartedSideContent description="Connect your wallet to provide or withdraw liquidity" />
      ) : pool ? (
        <>
          <Tabs
            value={txType}
            onChange={(e, newValue) => {
              setTxType(newValue)
            }}
            variant="fullWidth"
          >
            <Tab label="Provide" value="provide" />
            <Tab label="Withdraw" value="withdraw" />
          </Tabs>
          {txType === "provide" ? (
            <ProvideLiquidityDialog pool={pool} clearSelection={onClose} />
          ) : (
            txType === "withdraw" && (
              <WithdrawLiquidityDialog pool={pool} clearSelection={onClose} />
            )
          )}
          {!desktop && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                onClose()
              }}
              className={classes.closeButton}
              size="large"
            >
              <Close />
            </IconButton>
          )}
        </>
      ) : (
        <Side.Content space={3}>
          <Typography variant="h3">Select a pool to continue</Typography>
          <Typography size="small" color="textSecondary">
            To make a trade, select an pool from the table on the left
          </Typography>

          <SkeletonGrid columns={6} animation={false}>
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
          </SkeletonGrid>
        </Side.Content>
      )}
    </Side>
  )
}
