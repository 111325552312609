import { SkeletonProps } from "@mui/lab"
import { Box, BoxProps, Theme } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import React from "react"

import { SkeletonGridItem } from "./SkeletonGridItem"

type SkeletonGridProps = {
  columns: number
  gap?: number
  animation?: SkeletonProps["animation"]
} & BoxProps

const useStyles = makeStyles<Theme, SkeletonGridProps>(({ spacing }) => ({
  container: {
    width: "100%",
    gridTemplateColumns: ({ columns }) => `repeat(${columns}, 1fr)`,
    gridGap: (props) => spacing(props.gap ?? 2),
    maskImage: "linear-gradient(to bottom, black, transparent)",
  },
  item: {
    borderRadius: 8,
  },
}))

const SkeletonGridBase: React.FC<SkeletonGridProps> = ({
  children,
  columns,
  gap,
  animation,
  px = 0,
  ...rest
}) => {
  const classes = useStyles({ columns, gap })

  return (
    <Box display="grid" className={classes.container} px={px} {...rest}>
      {React.Children.map(children, (child) => {
        let result = child

        if (React.isValidElement(child)) {
          result = React.cloneElement(child, { animation })
        }

        return result
      })}
    </Box>
  )
}

export const SkeletonGrid = Object.assign(SkeletonGridBase, {
  Item: SkeletonGridItem,
})
