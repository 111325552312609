import { Web3Provider } from "@chainsafe/web3-context"
import { AddChainParams } from "@chainsafe/web3-context/dist/context/Web3Context"
import {
  Button,
  CssBaseline,
  Dialog,
  StyledEngineProvider,
  ThemeProvider,
  Typography,
} from "@mui/material"
import {
  ErrorBoundary,
  init,
  Integrations,
  showReportDialog,
} from "@sentry/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import React, { useMemo } from "react"
import { BrowserRouter } from "react-router-dom"
import "simplebar/dist/simplebar.min.css"

import { AppRoutes } from "./Components/AppRoutes"

import { VStack } from "./Components/common"
import config from "./config"
import { LanguageProvider } from "./Contexts/LanguageContext"
import { SirenConfigContextProvider } from "./Contexts/SirenConfigContext"
import { SirenMarketsProvider } from "./Contexts/SirenMarketsContext"
import { usePalleteType } from "./Hooks/usePalleteType"
import { createTheme, PaletteType } from "./theme"

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_SENTRY_DSN_URL &&
  process.env.REACT_APP_SENTRY_RELEASE
) {
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN_URL,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENV,
    denyUrls: [/extensions\//i, /^chrome:\/\//i, /chrome-extension:\//i],
    integrations: [
      new Integrations.GlobalHandlers({
        onunhandledrejection: false,
        onerror: false,
      }),
    ],
  })
}

const tokenConfig = Object.keys(config).reduce(
  (acc, chainId) =>
    Object.assign(acc, {
      [chainId]: config[Number(chainId)].erc20Tokens?.map((tokenAddr) => ({
        address: tokenAddr,
      })),
    }),
  {} as { [chainId: string]: { address: string }[] },
)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
})

const additionalChainConfigs = Object.keys(config).reduce((acc, c) => {
  const chainParam = config[Number(c)].addChainParams
  if (!!chainParam) {
    return acc.concat([
      {
        ...chainParam,
        chainName: config[Number(c)].networkName,
        chainId: Number(c),
      },
    ])
  } else {
    return acc
  }
}, [] as AddChainParams[])

const BLOCKNATIVE_KEY = process.env.REACT_APP_BLOCKNATIVE_KEY

const walletConnectConfig = Object.keys(config).reduce(
  (acc, chainId) =>
    Object.assign(acc, { [Number(chainId)]: config[Number(chainId)].rpcUrl }),
  {} as { [chainId: string]: string },
)

const NETWORK_IDS = Object.keys(config).map((net) => Number(net))
const AVAILABLE_LANGUAGES = [{ id: "en", label: "English" }]

const App: React.FC<{}> = () => {
  const { paletteType } = usePalleteType()

  const theme = useMemo(() => createTheme(paletteType), [paletteType])

  const onboardConfig = useMemo(
    () => ({
      walletSelect: {
        wallets: [
          {
            walletName: "metamask",
            preferred: true,
          },
          {
            walletName: "coinbase",
            preferred: true,
          },
          {
            walletName: "walletConnect",
            rpc: walletConnectConfig,
            preferred: true,
          },
          { walletName: "torus" },
        ],
      },
      darkMode: paletteType === PaletteType.Dark,
      dappId: BLOCKNATIVE_KEY,
    }),
    [paletteType],
  )

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <ErrorBoundary
            fallback={({ error, componentStack, eventId, resetError }) => (
              <Dialog open onClose={resetError}>
                <VStack p={4}>
                  <Typography paragraph>
                    An error occured and has been logged. If you would like to
                    provide additional info to help us debug and resolve the
                    issue, click the "Provide Additional Details" button
                  </Typography>
                  <Typography paragraph>{error?.message.toString()}</Typography>
                  <Typography paragraph>{componentStack}</Typography>
                  <Typography paragraph>{eventId}</Typography>
                  <Button
                    variant="secondary"
                    onClick={() => showReportDialog({ eventId: eventId || "" })}
                  >
                    Provide Additional Details
                  </Button>
                  <Button variant="secondary" onClick={resetError}>
                    Reset error
                  </Button>
                </VStack>
              </Dialog>
            )}
            onReset={() => window.location.reload()}
          >
            <Web3Provider
              networkIds={NETWORK_IDS}
              onboardConfig={onboardConfig}
              tokensToWatch={tokenConfig}
              additionalChainParams={additionalChainConfigs}
              checkNetwork={false}
            >
              <LanguageProvider availableLanguages={AVAILABLE_LANGUAGES}>
                <SirenConfigContextProvider>
                  <SirenMarketsProvider>
                    <BrowserRouter>
                      <AppRoutes />
                    </BrowserRouter>
                  </SirenMarketsProvider>
                </SirenConfigContextProvider>
              </LanguageProvider>
            </Web3Provider>
          </ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  )
}

export default App
