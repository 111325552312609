import { MenuItem } from "@mui/material"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import dayjs from "dayjs"
import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import { useLanguageContext } from "../../../Contexts/LanguageContext"

import AssetPairSelector from "./AssetPairSelector"
import {
  HStack,
  MainLayout,
  SkeletonGrid,
  VStack,
  Typography,
  Select,
  SideSkeleton,
} from "../../common"
import OptionsList from "./OptionsList"
import TradeOptionSide from "./TradeOptionSide"
import TradeOptionsHeader from "./TradeOptionsHeader"
import { useMedia } from "../../../Hooks/useMedia"
import { useSeries } from "../../../Contexts/SirenMarketsContext"

const convertPair = (pair: string) => pair.split("-").join(" / ")

export const TradeOptionsPage: React.FC<{}> = () => {
  const { pair } = useParams<{ pair: string }>()

  const { series } = useSeries()
  const [selectedSeriesId, setSelectedSeriesId] = useState<string | undefined>()

  const [optionType, setOptionType] = useState<"Call" | "Put">("Call")

  const pairs = useMemo(
    () => [
      ...new Set(series.filter((s) => s.status === "open").map((s) => s.pair)),
    ],
    [series],
  )
  const [selectedPair, setSelectedPair] = useState(pairs[0])

  const seriesTypes = useMemo(
    () => [
      ...new Set(
        series
          .filter((s) => s.pair === selectedPair && s.status === "open")
          .map((s) => s.type),
      ),
    ],
    [series, selectedPair],
  )

  useEffect(() => {
    if (!seriesTypes.includes(optionType)) {
      setOptionType(seriesTypes[0])
    }
  }, [seriesTypes, optionType])

  const expiryDates = useMemo(
    () =>
      [
        ...new Set(
          series
            .filter(
              (s) =>
                s.status === "open" &&
                s.type === optionType &&
                s.pair === selectedPair &&
                s.premium > 0.0001,
            )
            .map((s) => dayjs(s.expiration).unix()),
        ),
      ].sort((a, b) => a - b),
    [optionType, selectedPair, series],
  )

  const [selectedExpiryDate, setSelectedExpiryDate] = useState<
    number | undefined
  >(undefined)

  useEffect(() => {
    if (
      (!selectedExpiryDate || !expiryDates.includes(selectedExpiryDate)) &&
      expiryDates.length > 0
    ) {
      setSelectedExpiryDate(expiryDates[0])
    }
  }, [expiryDates, selectedExpiryDate])

  const selectOption = (optionId: string) => setSelectedSeriesId(optionId)
  const selectedOption = selectedSeriesId
    ? series.find((s) => s.seriesId === selectedSeriesId)
    : undefined

  const filteredSeries = useMemo(
    () =>
      series.filter(
        (s) =>
          s.status === "open" &&
          s.type === optionType &&
          s.pair === selectedPair &&
          dayjs(s.expiration).unix() === selectedExpiryDate &&
          s.premium > 0.0001,
      ),
    [optionType, selectedExpiryDate, selectedPair, series],
  )

  const currentSeries = filteredSeries[0]

  const { desktop, mobile } = useMedia()

  // Syncs selectedPair with the path
  useEffect(() => {
    const newPair =
      pair && pair !== "" && pairs.includes(convertPair(pair))
        ? convertPair(pair)
        : pairs[0]
    setSelectedPair(newPair)
  }, [pairs, selectedPair, pair])

  const handleChangeOptionType = (
    e: React.MouseEvent<HTMLElement>,
    newValue: "Call" | "Put" | null,
  ) => {
    newValue !== null && setOptionType(newValue)
  }
  const { formatShortLocaleDate } = useLanguageContext()

  return (
    <>
      <MainLayout.LeftSide>
        {pairs.length > 0 ? (
          <AssetPairSelector pairs={pairs} selectedPair={selectedPair} />
        ) : (
          <SkeletonGrid
            columns={desktop ? 1 : 7}
            gap={1}
            animation="wave"
            width="100%"
          >
            <SkeletonGrid.Item height={5} />
            <SkeletonGrid.Item height={5} />
            <SkeletonGrid.Item height={5} />
            <SkeletonGrid.Item height={5} />
            <SkeletonGrid.Item height={5} />
            <SkeletonGrid.Item height={5} />
            <SkeletonGrid.Item height={5} />
          </SkeletonGrid>
        )}
      </MainLayout.LeftSide>
      <MainLayout.Content>
        {currentSeries ? (
          <VStack>
            <TradeOptionsHeader series={currentSeries} />

            <HStack justifyContent="space-between" px={mobile ? 2 : 0}>
              <ToggleButtonGroup
                value={optionType}
                exclusive
                onChange={handleChangeOptionType}
              >
                <ToggleButton
                  value="Call"
                  disabled={!seriesTypes.includes("Call")}
                  size="large"
                >
                  Call
                </ToggleButton>
                <ToggleButton
                  value="Put"
                  disabled={!seriesTypes.includes("Put")}
                  size="large"
                >
                  Put
                </ToggleButton>
              </ToggleButtonGroup>

              <HStack alignItems="center">
                {!mobile && (
                  <Typography
                    size="extra-small"
                    weight="medium"
                    color="textSecondary"
                  >
                    Expiration
                  </Typography>
                )}

                <Select
                  value={selectedExpiryDate || ""}
                  onChange={(e) =>
                    setSelectedExpiryDate(Number(e.target.value))
                  }
                  variant="outlined"
                  size={mobile ? "small" : "medium"}
                >
                  {expiryDates.map((d) => (
                    <MenuItem value={d} key={d}>
                      <Typography
                        size={mobile ? "extra-small" : "small"}
                        weight={mobile ? "medium" : "bold"}
                      >
                        {formatShortLocaleDate(dayjs.unix(d).toDate())}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </HStack>
            </HStack>

            <OptionsList
              allSeries={filteredSeries}
              selectOption={selectOption}
              selectedSeriesId={selectedSeriesId}
            />
          </VStack>
        ) : (
          <SkeletonGrid columns={6} px={mobile ? 2 : 0} animation="wave">
            <SkeletonGrid.Item start={1} end={4} height={4} />
            <SkeletonGrid.Item start={1} end={2} height={4} />
            <SkeletonGrid.Item start={1} end={3} height={5} />
            <SkeletonGrid.Item start={4} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
            <SkeletonGrid.Item start={1} end={7} height={5} />
          </SkeletonGrid>
        )}
      </MainLayout.Content>

      <MainLayout.RightSide>
        {pairs.length > 0 ? (
          <TradeOptionSide
            series={selectedOption}
            clearSelection={() => setSelectedSeriesId(undefined)}
          />
        ) : (
          <SideSkeleton />
        )}
      </MainLayout.RightSide>
    </>
  )
}
