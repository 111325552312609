import { useLocalStorage } from "./useLocalStorage"

const LOCALSTORAGE_KEY = "layoutType"

export enum LayoutType {
  Cards = "CARDS",
  Table = "TABLE",
}

export const useLayoutType = () => {
  const { value: layoutType, setValue: setLayoutType } =
    useLocalStorage<LayoutType>(LOCALSTORAGE_KEY, LayoutType.Cards)

  return { layoutType, setLayoutType }
}
