import {
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Theme,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useMemo, useState } from "react"
import { useLanguageContext } from "../../../Contexts/LanguageContext"
import { Position } from "../../../Contexts/SirenMarketsContext"
import { utils } from "ethers"

import {
  EqualsSign,
  Details,
  Side,
  VStack,
  NumericInput,
  TxInProgress,
  TxSuccess,
  TxFailure,
  CheckNetworkButton,
} from "../../common"
import { usePositions } from "../../../Contexts/SirenMarketsContext"

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
  caption: {
    paddingLeft: 40,
    paddingRight: 40,

    [breakpoints.down("md")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
}))

const ExercisePositionDialog: React.FC<{
  position: Position
  clearSelection(): void
}> = ({ position, clearSelection }) => {
  const { formatLocaleDate, formatShortLocaleDate } = useLanguageContext()
  const classes = useStyles()

  const { exerciseOption } = usePositions()
  const [txStatus, setTxStatus] = useState<
    "init" | "inProgress" | "success" | "error"
  >("init")

  const handleExerciseOptions = async () => {
    setTxStatus("inProgress")
    try {
      await exerciseOption(position.seriesId)
      setTxStatus("success")
    } catch (error) {
      console.log(error)
      setTxStatus("error")
    }
  }
  const collateralToReceive = useMemo(
    () =>
      position.bTokenBalance.multipliedBy(
        position.collateralPerOptionToken || 0,
      ),
    [position],
  )
  const { breakpoints } = useTheme()
  const desktop = useMediaQuery(breakpoints.up("md"))

  if (txStatus === "inProgress") {
    return <TxInProgress message="Exercising your option contracts..." />
  } else if (txStatus === "success") {
    return (
      <TxSuccess
        message="Your options have been exercised."
        buttonMessage="Close"
        onConfirm={clearSelection}
      />
    )
  } else if (txStatus === "error") {
    return <TxFailure clearSelection={clearSelection} />
  }

  return (
    <>
      <Side.Content>
        <Grid item style={{ marginBottom: 25 }}>
          <Details>
            <Details.Row label="Pair" value={position.pair} />
            <Details.Row label="Type" value={position.type} />
            <Details.Row
              label="Expiration"
              value={
                desktop
                  ? formatLocaleDate(position.expiration)
                  : formatShortLocaleDate(position.expiration)
              }
            />
            <Details.Row
              label="Strike Price"
              value={utils.commify(position.strike)}
            />
            <Details.Row
              label="Settlement Price"
              value={utils.commify(
                position.settlementValue?.decimalPlaces(2).toString() || 0,
              )}
            />
            <Details.Row
              label="Size"
              value={position.bTokenBalance.toString()}
            />
          </Details>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 25 }}>
          <VStack space={1}>
            <NumericInput
              type="number"
              min={0}
              value={position.bTokenBalance.toString()}
              disabled
              label="# of Contracts"
            />

            <EqualsSign />

            <NumericInput
              value={collateralToReceive.decimalPlaces(4).toString(10)}
              label={`${position.collateralTokenSymbol.toUpperCase()}`}
              disabled
            />
          </VStack>
        </Grid>
        <Grid item className={classes.caption}>
          <Typography variant="caption" color="textSecondary">
            <i>
              Please confirm the trade details are correct before approving the
              transaction.
            </i>
          </Typography>
        </Grid>
      </Side.Content>

      <Side.Action>
        <CheckNetworkButton>
          <Button
            variant="primary"
            onClick={handleExerciseOptions}
            disabled={!position.bTokenBalance.gt(0)}
            fullWidth
          >
            Exercise
          </Button>
        </CheckNetworkButton>
      </Side.Action>
    </>
  )
}

export default ExercisePositionDialog
