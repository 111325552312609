import React from "react"

interface IArrowRightSvg {
  className?: string
}

const ArrowRightSvg = ({ className }: IArrowRightSvg) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.06661 14.6671C7.86661 14.6671 7.66661 14.6671 7.46661 14.6671C3.79994 14.3337 1.13327 11.0671 1.46661 7.40039C1.73327 4.20039 4.26661 1.66706 7.46661 1.40039C7.73327 1.40039 7.99994 1.53372 8.13327 1.73372C8.26661 1.93372 8.26661 2.26706 8.06661 2.46706C7.39994 3.33372 7.19994 4.40039 7.33327 5.46706C7.46661 6.53372 8.06661 7.46706 8.93327 8.06706C10.3333 9.13372 12.2666 9.13372 13.6666 8.06706C13.8666 7.93372 14.1333 7.86706 14.3999 8.00039C14.5999 8.13372 14.7333 8.40039 14.7333 8.66706C14.5999 10.4671 13.7333 12.0671 12.3333 13.2004C11.0666 14.1337 9.59994 14.6671 8.06661 14.6671ZM6.19994 2.93372C4.26661 3.60039 2.93327 5.33372 2.73327 7.46706C2.46661 10.4004 4.59994 13.0004 7.53327 13.2671C8.93327 13.4004 10.3333 13.0004 11.3999 12.0671C12.1333 11.4671 12.6666 10.6671 12.9999 9.80039C11.3333 10.4004 9.46661 10.1337 7.99994 9.06706C6.86661 8.20039 6.13327 7.00039 5.86661 5.60039C5.79994 4.66706 5.93327 3.80039 6.19994 2.93372Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowRightSvg
